import Link from 'next/link'
import React from 'react'
import { sendGTMData } from 'utils/gtm.js'
import { useTrans } from 'hooks'
import { useSelector } from 'react-redux'
import { ArrowRight, Logout } from 'react-components/atoms/Icons'
import { HeaderCart } from 'react-components/atoms'
import { api } from 'utils'
import { UTENTE } from 'utils/endpoint'
import { useState } from 'react'

const AreaPersonaleMenu = React.memo((props) => {
  const { cart, openCart } = props

  const t = useTrans()

  const { utente } = useSelector((state) => state.utente)
  const [open, setOpen] = useState(false)

  const handleGTM = (item) => {
    sendGTMData({
      event: 'GAevent',
      eventID: '03',
      eventCategory: 'header',
      eventAction: item.toGTMFormat(),
      eventLabel: 'non_valorizzata',
    })
  }

  const handleLogout = () => {
    handleGTM('esci')
    api.get(UTENTE + 'logout/').then((response) => {
      window.location.href = response.data.redirect_url
    })
  }

  return (
    <div className="userbar__container">
      <span className="userbar__hello">Ciao {utente.nome}</span>
      <HeaderCart />
      <div onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        <Link legacyBehavior href="/area-personale">
          <a
            className={`userbar__link ${open ? 'userbar__link--open' : ''}`}
            onClick={() => handleGTM('vai al profilo')}
          >
            {t('Vai al tuo profilo')}
            <ArrowRight />
          </a>
        </Link>
        <span
          className={`userbar__logout ${open ? 'userbar__logout--open' : ''}`}
          onClick={handleLogout}
        >
          <Logout />
          {t('Esci')}
        </span>
      </div>
    </div>
  )
})

export default AreaPersonaleMenu
