import React from 'react'
import { Image } from 'react-components/atoms'
import Link from 'next/link'
import { CAS_DOMAIN } from 'utils'
import { Button } from 'react-components/atoms'
import { sendProductClickGTM } from '../../../utils/gtm'

const CardAdozionale = (props) => {
  const {
    adozionale = {
      titolo: '',
      descrizione: '',
      main_image: 'https://via.placeholder.com/182x230',
      url: '',
    },
    className = '',
    position = 0,
  } = props

  const handleGTM = () => {
    sendProductClickGTM(adozionale, 'navigazione_standard', position)
  }

  return (
    <article className={`card-adozionale ${className}`}>
      <Link legacyBehavior href={adozionale.url}>
        <a onClick={handleGTM}>
          <Image
            className="card-adozionale__image"
            src={CAS_DOMAIN + adozionale?.main_image}
            alt={adozionale.titolo?.toAltTitle()}
            title={adozionale.titolo?.toAltTitle()}
            width={182}
            height={230}
          />
        </a>
      </Link>
      <p className="card-adozionale__title">{adozionale.titolo}</p>
      <div
        className="card-adozionale__description"
        dangerouslySetInnerHTML={{ __html: adozionale.descrizione }}
      />
      <Button
        className="card-adozionale__cta"
        label="Scopri e acquista"
        variant="ghost"
        href={adozionale.url}
      />
    </article>
  )
}

export default CardAdozionale
