import React, { useState } from 'react'
import { CAS_DOMAIN } from 'utils'
import { Info2 } from 'react-components/atoms/Icons'
import { Modal, Image } from 'react-components/atoms'

const BoxBundleItem = (props) => {
  const { className = '', item } = props

  const [open, setOpen] = useState(false)

  return (
    <div className={`box-bundle-item ${className ? ` ${className}` : ''}`}>
      {item.mainImage && (
        <Image
          src={CAS_DOMAIN + item.mainImage}
          alt={`${item.titolo}`}
          title={item.titolo.toAltTitle()}
          width={100}
          height={100}
        />
      )}
      <p className="box-bundle-item__title">{item.titolo}</p>
      <>
        <span className="box-bundle-item__helper" onClick={() => setOpen(true)}>
          <Info2 />
        </span>
        <Modal open={open} setOpen={setOpen} closeButton>
          <div className="box-bundle-item__modal">
            <div className="box-bundle-item__modal__image">
              {item.tooltipImage && (
                <Image
                  src={CAS_DOMAIN + item.tooltipImage}
                  alt={`${item.titolo}`}
                  title={item.titolo.toAltTitle()}
                  width={200}
                  height={200}
                />
              )}
            </div>
            <div className="box-bundle-item__modal__content">
              <div className="box-bundle-item__modal__title">{item.titolo}</div>
              <div
                className="box-bundle-item__modal__description"
                dangerouslySetInnerHTML={{ __html: item.descrizione }}
              />
            </div>
          </div>
        </Modal>
      </>
    </div>
  )
}

export default BoxBundleItem
