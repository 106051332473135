import React from 'react'
import { CheckboxMarked, CheckboxUnmarked } from 'react-components/atoms/Icons'

const SelectionButton = props => {
  const { visible = false, selected = false, setSelected = null, className = '' } = props

  return visible ? (
    <button
      className={`selection-button${selected ? ` selection-button--selected` : ''} ${
        className ? ` ${className}` : ''
      }`}
      type="button"
      onClick={() => setSelected(!selected)}
    >
      {selected ? <CheckboxMarked /> : <CheckboxUnmarked />}
    </button>
  ) : (
    <></>
  )
}

export default SelectionButton
