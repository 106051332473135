import React from 'react'
import { useTrans } from 'hooks'
import { CorrelatiCard } from 'react-components/molecules'
import { CatLibro, CatOggetto, CatScheda } from 'react-components/atoms/Icons'

const Correlati = props => {
  const { className = '', articoli = [], strumenti = [], lezioni = [] } = props

  if (!articoli.length && !strumenti.length && !lezioni.length) return null

  const t = useTrans()

  return (
    <div className={`correlati ${className ? ` ${className}` : ''}`}>
      <div className="correlati__container">
        <p className="correlati__title">{t('Potrebbero interessarti')}</p>
        <div className="correlati__content">
          {articoli.length ? (
            <CorrelatiCard title={t('Articoli')} items={articoli} icon={<CatLibro />} />
          ) : null}
          {strumenti.length ? (
            <CorrelatiCard title={t('Materiali')} items={strumenti} icon={<CatOggetto />} />
          ) : null}
          {lezioni.length ? (
            <CorrelatiCard title={t('Lezioni')} items={lezioni} icon={<CatScheda />} />
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Correlati
