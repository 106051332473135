import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Shield({ className }) {
  return (
    <IconBase name="shield" w={50} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.8292 18.2929C36.2197 18.6834 36.2197 19.3166 35.8292 19.7071L23.8292 31.7071C23.4506 32.0857 22.841 32.0989 22.4464 31.7372L16.4464 26.2372C16.0392 25.864 16.0117 25.2314 16.3849 24.8243C16.7581 24.4172 17.3907 24.3897 17.7978 24.7628L23.092 29.6159L34.415 18.2929C34.8055 17.9024 35.4387 17.9024 35.8292 18.2929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.122 5C25.4401 5 25.7393 5.15141 25.9277 5.40778C29.1908 9.84752 34.3848 10.9295 38.4082 10.8053C38.8225 10.7925 39.2218 10.7669 39.6025 10.7311C41.3929 10.5629 43.2439 11.8887 43.2439 13.8825V25.7407C43.2439 27.4548 42.4102 29.5699 41.2205 31.6788C40.0093 33.8258 38.347 36.1083 36.5174 38.1961C34.6889 40.2828 32.668 42.2038 30.7274 43.6125C28.8328 44.9878 26.8466 46 25.122 46C23.3973 46 21.4111 44.9878 19.5165 43.6125C17.5759 42.2038 15.555 40.2828 13.7265 38.1961C11.8969 36.1083 10.2346 33.8258 9.02344 31.6788C7.83372 29.5699 7 27.4548 7 25.7407V13.8813C7 11.8867 8.85195 10.563 10.6415 10.7312C14.7472 11.117 20.7126 10.3108 24.3162 5.40778C24.5046 5.15141 24.8038 5 25.122 5ZM25.122 7.59442C20.8804 12.4203 14.5861 13.1106 10.4544 12.7224C9.63748 12.6456 9 13.2578 9 13.8813V25.7407C9 26.9156 9.6124 28.6523 10.7654 30.6962C11.8969 32.702 13.4739 34.8732 15.2307 36.878C16.9885 38.8839 18.901 40.6943 20.6914 41.994C22.5278 43.327 24.0701 44 25.122 44C26.1738 44 27.7161 43.327 29.5525 41.994C31.3429 40.6943 33.2554 38.8839 35.0132 36.878C36.77 34.8732 38.347 32.702 39.4785 30.6962C40.6315 28.6523 41.2439 26.9156 41.2439 25.7407V13.8825C41.2439 13.2582 40.6057 12.6457 39.7896 12.7224C39.368 12.762 38.9268 12.7903 38.4699 12.8044C34.4204 12.9294 28.9388 11.9362 25.122 7.59442Z"
        fill="currentColor"
      />
    </IconBase>
  )
}

export default Shield
