import dynamic from 'next/dynamic'
import { renderToString } from 'react-dom/server'

const ReactTooltip = dynamic(() => import('react-tooltip'))
const Tooltip = dynamic(() => import('./Tooltip'))

const Icon = ({ id, classes = '', onClick = null, tooltip = null, title = '' }) => {
  return (
    <>
      {id &&
        (tooltip ? (
          <>
            <svg
              className={`icon ${classes}`}
              onClick={onClick}
              data-for={tooltip}
              data-tip={renderToString(<Tooltip value={tooltip} />)}
            >
              <use xlinkHref={`#${id}`}></use>
            </svg>
            <ReactTooltip
              id={tooltip}
              html
              type="light"
              place="right"
              effect="solid"
              className="react-tooltip"
            />
          </>
        ) : (
          <svg className={`icon ${classes}`} onClick={onClick}>
            {title && <title>{title}</title>}
            <use xlinkHref={`#${id}`}></use>
          </svg>
        ))}
    </>
  )
}

export default Icon
