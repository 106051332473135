import React, { useEffect } from 'react'
import { Modal, Button } from 'react-components/atoms'
import { useSelector } from 'react-redux'

const CalendarioAvventoModal = ({
  open,
  cella,
  setOpen,
  setStep,
  cellaCliccata,
  setCellaCliccata,
  icon,
  title,
  disclaimer,
  regalo,
  subdescription,
  className,
  labelCta,
  download,
  external,
  href,
  onClose = () => {},
  showLogin = () => {},
}) => {
  const { utente } = useSelector(state => state.utente)

  useEffect(() => {
    if (cellaCliccata) {
      setStep(2)
    }
  }, [cellaCliccata])

  const setStep2 = cella => {
    setOpen(false)
    if (!utente) {
      showLogin(cella)
    } else {
      onClose()
      setCellaCliccata(cella)
    }
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      setStep={setStep}
      cellaCliccata={cellaCliccata}
      setCellaCliccata={setCellaCliccata}
      closeButton={true}
      onClose={onClose}
      className="wmodal-calendario-avvento"
    >
      <div
        style={{ textAlign: 'center' }}
        className={className ? 'w-modal-variant-' + className : ''}
      >
        <Modal.Content />
        <img src={icon} />
        <p className="w-modal-title">{title}</p>
        <p className="w-modal-disclaimer">{disclaimer}</p>
        <p className="w-modal-gift">{regalo}</p>
        {subdescription && <p className="w-modal-subdescription">{subdescription}</p>}
        <Button
          colorVariant={className}
          label={labelCta}
          download={download}
          external={external}
          href={href}
          onClick={() => setStep2(cella)}
        />
      </div>
      <div
        style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: 15, justifyContent: 'center' }}
      ></div>
    </Modal>
  )
}

export default CalendarioAvventoModal
