import React, { useState, useEffect } from 'react'
import { Button, FormCheckbox, FormInput, Modal, ReactTooltip } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { sendGTMData } from 'utils/gtm.js'
import { useSelector } from 'react-redux'
import {
  AddCircle,
  CheckboxMarked,
  SaveDeselected,
  SaveSelected,
  Lock,
} from 'react-components/atoms/Icons'
import { api, hasPerm, SALVA, AGGIUNGI } from 'utils'
import { ELEMENTIRACCOLTA, ELEMENTOSALVATO, RACCOLTE } from 'utils/endpoint'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const Save = props => {
  const { item = null, variant = 'round', labelGTM = '' } = props

  if (!item) return null

  const { utente } = useSelector(state => state.utente)
  const { paginaCorrente } = useSelector(state => state.paginaCorrente)

  const t = useTrans()

  const [modal, setModal] = useState(false)
  const [saved, setSaved] = useState(false)

  const [raccolte, setRaccolte] = useState([])
  const [elementi, setElementi] = useState([])

  const [creating, setCreating] = useState(false)
  const [raccolta, setRaccolta] = useState('')

  useEffect(() => {
    if (hasPerm(utente, SALVA)) checkSaved()
    if (hasPerm(utente, AGGIUNGI)) fetchRaccolte()
  }, [])

  useEffect(() => {
    if (hasPerm(utente, AGGIUNGI) && raccolte.length) fetchElementi()
  }, [raccolte])

  const params = { content_type_id: item.model, object_id: item.id }

  const checkSaved = async () => {
    api.get(ELEMENTOSALVATO, { params }).then(response => {
      setSaved(response.data['salvato'])
    })
  }

  function handleSwitchSave() {
    if (saved) handleUnsave()
    else handleSave()
  }

  const handleSave = async () => {
    if (hasPerm(utente, SALVA)) {
      api.post(ELEMENTOSALVATO, { params }).then(response => {
        const saved = response.data['created']
        setSaved(saved)
        sendGTMData({
          event: 'GAevent',
          eventID: '16',
          eventCategory: 'bookmark',
          eventAction: 'salva',
          eventLabel: labelGTM,
          moreInfo: paginaCorrente?.item?.titolo.toGTMFormat() || '',
        })
      })
    }
  }

  const handleUnsave = async () => {
    api.delete(ELEMENTOSALVATO, { data: params }).then(response => {
      setSaved(!response.data.success)
    })
  }

  const fetchRaccolte = async () => {
    api.get(RACCOLTE, { params: { utenti__id: utente.id, all: true } }).then(response => {
      const raccolte = response.data.results
      setRaccolte(raccolte.map(({ id, titolo, pubblica }) => ({ id, titolo, pubblica })))
    })
  }

  const fetchElementi = async () => {
    const params = { raccolta: raccolte.map(({ id }) => id).join('/') }

    api.get(`${ELEMENTIRACCOLTA}ids/`, { params }).then(response => {
      setElementi(response.data)
    })
  }

  const handleAddTo = raccolta_id => {
    if (inRaccolta(raccolta_id)) return

    api
      .post(ELEMENTIRACCOLTA, {
        raccolta_id: raccolta_id,
        object_id: item.id,
        content_type_id: item.model,
      })
      .then(() => {
        fetchRaccolte() // refresh list

        const r = raccolte.find(r => r.id === raccolta_id)

        sendGTMData({
          event: 'GAevent',
          eventID: '17',
          eventCategory: 'raccolta',
          eventAction: 'aggiungi_contenuto',
          eventLabel: r?.pubblica ? 'pubblica' : 'privata',
        })
      })
  }

  function handleCreaRaccolta() {
    api
      .post(RACCOLTE, { titolo: raccolta, pubblica: false })
      .then(response => {
        handleAddTo(response.data.raccolta)
        setCreating(false)
        setRaccolta('')
        sendGTMData({
          event: 'GAevent',
          eventID: '17',
          eventCategory: 'raccolta',
          eventAction: 'crea_nuova_raccolta',
          eventLabel: 'privata',
        })
      })
      .catch(error => console.log(error))
  }

  function inRaccolta(raccolta_id) {
    return elementi.some(
      e =>
        e.raccolta_id === raccolta_id && e.content_type_id === item.model && e.object_id === item.id
    )
  }

  function canSave() {
    return Boolean(raccolta) && !raccolte.map(r => r.titolo).includes(raccolta)
  }

  const label = saved ? t('Rimuovi dai preferiti') : t('Salva nei preferiti')

  return (
    <div className={`save save--${variant}`}>
      <ReactTooltip label={label} id="save">
        <Button
          className="save__button"
          onClick={() => {
            sendGTMData({
              event: 'GAevent',
              eventID: '17',
              eventCategory: 'raccolta',
              eventAction: 'aggiungi_contenuto',
              eventLabel: 'privata',
            })
            setModal(true)
          }}
          icon={saved ? <SaveSelected /> : <SaveDeselected />}
        />
      </ReactTooltip>
      <Modal open={modal} setOpen={setModal} closeButton className="save-modal">
        <Modal.Content>
          <div className="save__favourites" key={saved} onClick={handleSwitchSave}>
            {saved ? <SaveSelected /> : <SaveDeselected />}
            <span>{label}</span>
          </div>
          <div className="save__add">
            <p className="save__add__title">{t('Aggiungi a una raccolta')}</p>
            {raccolte.map(r => (
              <FormCheckbox
                className="save__add__item"
                label={
                  <>
                    <span onClick={() => handleAddTo(r.id)}>{r.titolo}</span>
                    {!r.pubblica && <Lock />}
                  </>
                }
                value={r.id}
                checked={inRaccolta(r.id)}
                disabled={inRaccolta(r.id)}
                key={r.id}
              />
            ))}
          </div>
          <div className="save__create">
            {!creating ? (
              <p className="save__create__title" onClick={() => setCreating(true)}>
                <AddCircle />
                <span>{t('Crea una raccolta')}</span>
              </p>
            ) : (
              <div className="save__create__form">
                <div className="save__create__input">
                  <CheckboxMarked />
                  <FormInput
                    value={raccolta}
                    onChange={e => setRaccolta(e.target.value)}
                    placeholder={t('Titolo raccolta')}
                  />
                </div>
                <div className="save__create__buttons">
                  <Button label={t('Annulla')} variant="ghost" onClick={() => setCreating(false)} />
                  <Button
                    label={t('Salva')}
                    variant="primary"
                    disabled={!canSave()}
                    onClick={handleCreaRaccolta}
                  />
                </div>
              </div>
            )}
          </div>
        </Modal.Content>
      </Modal>
    </div>
  )
}

export default Save
