import React from 'react'
import { useTrans } from 'hooks'
import { Edit } from 'react-components/atoms/Icons'
import { FormError } from 'react-components/atoms'
import { RadioUnmarked, RadioMarked } from 'react-components/atoms/Icons'

const FormIndirizzoRadioOption = props => {
  const {
    id = '',
    name = '',
    indirizzo,
    checked = false,
    className = '',
    readOnly = false,
    disabled = false,
    onChange = () => {},
    onEdit = undefined,
  } = props

  const t = useTrans()

  const classNames = `
    form-indirizzo-radio-option 
    ${onEdit ? ' form-indirizzo-radio-option--editable' : ''}
    ${checked ? ' form-indirizzo-radio-option--checked' : ''}
    ${readOnly ? ' form-indirizzo-radio-option--readonly' : ''}
    ${disabled ? ' form-indirizzo-radio-option--disabled' : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className="form-indirizzo-radio-option__input-wrap">
        <input
          id={id}
          checked={checked}
          name={name}
          value={indirizzo.pk}
          className="form-indirizzo-radio-option__input"
          type="radio"
          readOnly={readOnly}
          disabled={disabled}
          onChange={onChange}
        />
        <span className="form-indirizzo-radio-option__styledinput">
          {checked ? <RadioMarked /> : <RadioUnmarked />}
        </span>
        <span className="form-indirizzo-radio-option__label">
          <span className="form-indirizzo-radio-option__title">
            {indirizzo.isFatturazione && !indirizzo.privato ? (
              indirizzo.ragioneSociale
            ) : (
              <>
                {indirizzo.nome} {indirizzo.cognome}
              </>
            )}
          </span>
          <span className="form-indiritto-radio-option__description">
            {indirizzo.indirizzo}, {indirizzo.civico} - {indirizzo.cap} {indirizzo.citta} (
            {indirizzo.provincia})
          </span>
        </span>
        {onEdit && (
          <button
            type="button"
            aria-label={t('Modifica indirizzo')}
            onClick={() => onEdit(indirizzo)}
            className="form-indirizzo-radio-option__edit-button"
          >
            <Edit />
          </button>
        )}
      </label>
    </div>
  )
}

const FormIndirizzoRadio = props => {
  const {
    id = '',
    name = '',
    value = null,
    className = '',
    status = '',
    errorMessage = '',
    readOnly = false,
    disabled = false,
    onChange = () => {},
    onEdit = undefined,
    indirizzi = [],
  } = props

  return (
    <div className={`form-indirizzo-radio ${className ? ` ${className}` : ''}`}>
      <div className="form-indirizzo-radio__options">
        {indirizzi?.map(indirizzo => (
          <FormIndirizzoRadioOption
            id={`${id}_${indirizzo.pk}`}
            name={name}
            indirizzo={indirizzo}
            checked={indirizzo.pk === value}
            onChange={onChange}
            readOnly={readOnly}
            disabled={disabled}
            onEdit={onEdit}
            key={`formindirizzoradiooption-${id}-${indirizzo.pk}`}
          />
        ))}
      </div>
      <FormError
        className="form-indirizzo-radio__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormIndirizzoRadio
