import { combineReducers } from 'redux'
import { HYDRATE } from 'next-redux-wrapper'
import menu from './containers/menu'
import raccolta from './containers/raccolta'
import toolbar from './containers/toolbar'
import traduzioni from './containers/traduzioni'
import utente from './containers/utente'
import paginaCorrente from './containers/pagina_corrente'

const rootReducer = (state, action) => {
  switch (action.type) {
    case HYDRATE:
      const nextState = {
        ...state, // use previous state
        ...action.payload, // apply delta from hydration
      }
      if (state.utente.utente) nextState.utente.utente = state.utente.utente // preserve utente value on client side navigation

      return nextState

    default:
      return combineReducers({
        traduzioni,
        menu,
        utente,
        toolbar,
        raccolta,
        paginaCorrente,
      })(state, action)
  }
}

export default rootReducer
