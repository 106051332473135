import React, { useState } from 'react'
import { Form, Formik } from 'formik'
import { useTrans } from 'hooks'
import { FormField, FormRadio } from 'react-components/atoms'
import { AreaPersonaleEditCard } from 'react-components/molecules'

import { REGISTRAZIONE } from 'utils/endpoint'
import { fetchUtente } from 'containers/utente'
import { useDispatch, useSelector } from 'react-redux'
import { api, checkboxMapping } from 'utils'

const AreaPersonaleConoscereContattiEditCard = props => {
  const { utente } = useSelector(state => state.utente)
  const dispatch = useDispatch()

  const t = useTrans()
  const [editMode, setEditMode] = useState(false)

  return (
    <Formik
      initialValues={{
        flag_contatti: checkboxMapping[utente?.flag_contatti] || '0',
      }}
      onSubmit={async (values, { resetForm }) => {
        await api.post(REGISTRAZIONE + 'edit_pubblico/', { ...values })
        dispatch(fetchUtente())
        resetForm()
        setEditMode(false)
      }}
      enableReinitialize
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <AreaPersonaleEditCard
            title={t('Gli utenti possono contattarti?')}
            description={t(
              'Scegli se vuoi ricevere domande da altri utenti sulle raccolte e agli articoli che pubblichi.'
            )}
            className="area-personale-conoscere-contatti-edit-card"
            editMode={editMode}
            setEditMode={setEditMode}
            onSubmit={() => handleSubmit()}
          >
            <FormField>
              <FormRadio
                name="flag_contatti"
                value={editMode ? values.flag_contatti : checkboxMapping[utente.flag_contatti]}
                options={[
                  {
                    value: '1',
                    label: 'Si, rendere il mio profilo pubblico',
                    disabled: !editMode && checkboxMapping[utente.flag_contatti] !== '1',
                  },
                  {
                    value: '0',
                    label: 'No, voglio mantenere il mio profilo privato',
                    disabled: !editMode && checkboxMapping[utente.flag_contatti] !== '0',
                  },
                ]}
                readOnly={!editMode}
                onChange={handleChange}
              />
            </FormField>
          </AreaPersonaleEditCard>
        </Form>
      )}
    </Formik>
  )
}

export default AreaPersonaleConoscereContattiEditCard
