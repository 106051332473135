import React from 'react'
import { useTrans } from 'hooks'

const AreaPersonaleMetodoPagamentoOrdineCard = props => {
  const { metodoPagamento = undefined, className = '' } = props

  const t = useTrans()

  return metodoPagamento ? (
    <div
      className={`area-personale-metodo-pagamento-ordine-card ${className ? ` ${className}` : ''}`}
    >
      <p className="area-personale-metodo-pagamento-ordine-card__title">
        {t('Metodo di pagamento')}
      </p>
      <div className="area-personale-metodo-pagamento-ordine-card__content">
        {metodoPagamento.image && (
          <img
            height={30}
            src={metodoPagamento.image}
            className="area-personale-metodo-pagamento-ordine-card__pagamento-image"
          />
        )}
        <span className="area-personale-metodo-pagamento-ordine-card__label">
          {metodoPagamento.nome}
        </span>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default AreaPersonaleMetodoPagamentoOrdineCard
