import React from 'react'

const AreaPersonaleDataFieldLayout = props => {
  const { children = null, className = '' } = props

  return (
    <div className={`area-personale-data-field-layout ${className ? ` ${className}` : ''}`}>
      {children}
    </div>
  )
}

export default AreaPersonaleDataFieldLayout
