import { useState } from 'react'

import { useTrans } from 'hooks'
import { Button } from 'react-components/atoms'
import { ELEMENTIRACCOLTA } from 'utils/endpoint'
import { api } from 'utils'

const FormContenutiRaccolte = ({ setContenutiRaccolteFromProps, contenuti }) => {
  const [edit, setEdit] = useState(false)
  const [selected, setSelected] = useState([])
  const t = useTrans()

  const handleSelected = id => {
    selected?.includes(id)
      ? setSelected(prev => prev?.filter(item => item !== id))
      : setSelected(prev => [...prev, id])
  }

  const onClose = () => {
    setSelected([])
    setEdit(false)
  }

  const _handleSubmit = () => {
    api.delete(`${ELEMENTIRACCOLTA}${id}`, { ids: selected })

    setContenutiRaccolteFromProps?.([...contenuti?.filter(i => !selected?.includes(i?.id))])

    onClose()
  }

  return (
    <div className="form-toggle-widget">
      <div className="form-toggle-widget__list">
        {/* //TODO  Inserire Card con prop edit per essere selezionate quando edit è true */}

        {contenuti?.map(contenuto => (
          <p
            onClick={() => {
              edit && handleSelected(contenuto?.id)
            }}
          >
            {contenuto?.elemento?.titolo}{' '}
            {selected?.includes(contenuto?.id) ? 'Selezionato' : 'Non Selezionato'}
          </p>
        ))}
      </div>
      {edit ? (
        <>
          <Button label={t('Annulla')} variant="ghost" onClick={() => onClose()} />
          <Button label={t('Elimina Contenuti')} onClick={() => _handleSubmit()} />
        </>
      ) : (
        <Button label={t('Seleziona Contenuti')} variant="ghost" onClick={() => setEdit(true)} />
      )}
    </div>
  )
}

export default FormContenutiRaccolte
