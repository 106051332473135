import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Download({ className }) {
  return (
    <IconBase name="download" w={29} className={className}>
      <line
        x1="14.625"
        y1="3.4502"
        x2="14.625"
        y2="20.1502"
        strokeWidth="2.5"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M23.5 12.4001L14.5 21.4001L5.5 12.4001"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        stroke="currentColor"
        fill="none"
      />
      <line
        x1="5.8501"
        y1="25.8501"
        x2="22.5501"
        y2="25.8501"
        stroke="currentColor"
        strokeWidth="2.5"
        strokeLinecap="round"
        fill="none"
      />
    </IconBase>
  )
}

export default Download
