import React, { useState } from 'react'
import { useTrans, useScuolaUtils } from 'hooks'
import {
  FormLayout,
  FormField,
  FormInput,
  FormCheckbox,
  FormRadio,
  FormSelect,
} from 'react-components/atoms'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { getFormikFieldProps } from 'components/forms-utils/utils'
import { useCreateIndirizzoMutation, useUpdateIndirizzoMutation } from 'gql/graphql'
import { CHECKCODICEFISCALE } from 'utils/endpoint'
import { api } from 'utils'

const validationSchema = (t, fatturazione) => {
  const message = t('Questo campo non può essere vuoto')

  const baseValidation = {
    nome: yup
      .string()
      .max(30, t('Troppo lungo'))
      .required(message),
    cognome: yup
      .string()
      .max(30, t('Troppo lungo'))
      .required(message),
    indirizzo: yup
      .string()
      .min(5, t('Troppo corto'))
      .required(message),
    civico: yup
      .string()
      .test('indirizzo-test', t('Inserisci il numero civico'), function(value) {
        return /\d/.test(value)
      })
      .required(message),
    citta: yup
      .string()
      .max(35, t('Troppo lungo'))
      .required(message),
    cap: yup
      .string()
      .min(5, 'Troppo corto')
      .max(5, t('Troppo lungo'))
      .matches(/^[0-9]{5}$/, 'Non è stato inserito un CAP valido')
      .required(message),
    provincia: yup.string().required(message),
    informazioniAggiuntive: yup.string(),
  }

  const dynamicValidation = fatturazione
    ? {
        nome: yup
          .string()
          .max(30, t('Troppo lungo'))
          .when('privato', {
            is: privato => privato && privato === '1',
            then: yup.string().required(message),
          }),
        cognome: yup
          .string()
          .max(30, t('Troppo lungo'))
          .when('privato', {
            is: privato => privato && privato === '1',
            then: yup.string().required(message),
          }),
        ragioneSociale: yup
          .string()
          .max(35, t('Troppo lungo'))
          .when('privato', {
            is: privato => privato && privato === '0',
            then: yup.string().required(message),
          }),
        cf: yup
          .string()
          .min(16, t('Troppo corto'))
          .max(16, t('Troppo lungo'))
          .matches(
            /^[A-Za-z]{6}[A-Za-z0-9]{2}[abcdehlmprstABCDEHLMPRST][A-Za-z0-9]{2}[A-Za-z][A-Za-z0-9]{3}[A-Za-z]$/gi,
            t('Non è stato inserito un CF valido')
          )
          .when('privato', {
            is: privato => privato && privato === '1',
            then: yup
              .string()
              .required(message)
              .test('cf-test', t('Non è stato inserito un CF valido'), async cf => {
                if (!cf || cf?.length < 16) return false
                const { data } = await api.get(CHECKCODICEFISCALE, { params: { cf } })
                return data?.status
              }),
          }),
        piva: yup
          .string()
          .min(11, t('Troppo corta'))
          .max(11, t('Troppo lunga'))
          .matches(/^[0-9]{11}$/, 'Non è stata inserita una PIVA valida')
          .when('privato', {
            is: privato => privato && privato === '0',
            then: yup.string().required(message),
          }),
        sdi: yup.string(),
        // .test('sdi-test', t('SDI o PEC obbligatori'), function(value) {
        //   const { privato, pec } = this.parent
        //   if (privato === '0' && !Boolean(value)) return Boolean(pec)
        //   return true
        // })
        pec: yup.string().email(t('Inserisci un email valida')),
        // .test('pec-test', t('PEC o SDI obbligatori'), function(value) {
        //   const { privato, sdi } = this.parent
        //   if (privato === '0' && !Boolean(value)) return Boolean(sdi)
        //   return true
        // }),
      }
    : {
        telefono: yup.string().required(message),
      }

  return yup.object().shape({
    ...baseValidation,
    ...dynamicValidation,
  })
}

const CheckoutIndirizzoForm = props => {
  const {
    titolo = '',
    indirizzo = null,
    boxed = false,
    head = undefined,
    actions = undefined,
    fatturazione = false,
    update = true,
    onSubmit = () => {},
    baseRefetchQueries = ['Checkout'],
    className = '',
  } = props

  const t = useTrans()

  const [createIndirizzo] = useCreateIndirizzoMutation()
  const [updateIndirizzo] = useUpdateIndirizzoMutation()

  const [save, setSave] = useState(false)

  const { ProvinciaItalianeAllSigla } = useScuolaUtils(false)

  const privatoOptions = [
    { value: '1', label: 'Privato' },
    { value: '0', label: 'Azienda / Libero professionista' },
  ]

  const initialValues = {
    privato: fatturazione && indirizzo ? (indirizzo?.privato ? '1' : '0') : '1',
    nome: indirizzo?.nome || '',
    cognome: indirizzo?.cognome || '',
    ragioneSociale: indirizzo?.ragioneSociale || '',
    indirizzo: indirizzo?.indirizzo || '',
    civico: indirizzo?.civico || '',
    informazioniAggiuntive: indirizzo?.informazioniAggiuntive || '',
    citta: indirizzo?.citta || '',
    cap: indirizzo?.cap || '',
    provincia: indirizzo?.provincia || '',
    telefono: indirizzo?.telefono || '',
    cf: indirizzo?.cf || '',
    piva: indirizzo?.piva || '',
    sdi: indirizzo?.sdi || '0000000',
    pec: indirizzo?.pec || '',
  }

  const refetchQueries = fatturazione
    ? ['IndirizziFatturazione'].concat(baseRefetchQueries)
    : ['IndirizziSpedizione'].concat(baseRefetchQueries)

  const formik = useFormik({
    validationSchema: validationSchema(t, fatturazione),
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
      const commonValues = {
        nome: values.nome,
        cognome: values.cognome,
        ragioneSociale: values.ragioneSociale,
        indirizzo: values.indirizzo,
        civico: values.civico,
        informazioniAggiuntive: values.informazioniAggiuntive,
        citta: values.citta,
        cap: values.cap,
        provincia: values.provincia,
        telefono: values.telefono,
        cf: values.cf,
        piva: values.piva,
        sdi: values.sdi,
        pec: values.pec,
        privato: values.privato === '1',
        default: true,
      }

      if (update && indirizzo) {
        await updateIndirizzo({
          variables: {
            input: {
              pk: indirizzo.pk,
              ...commonValues,
            },
          },
          refetchQueries,
        })
      } else {
        await createIndirizzo({
          variables: {
            input: {
              isFatturazione: fatturazione,
              ...commonValues,
            },
          },
          refetchQueries,
        })
      }
      resetForm()
      onSubmit()
    },
  })

  return (
    <div
      className={`checkout-indirizzo-form ${boxed ? 'checkout-indirizzo-form--boxed' : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      {!!titolo && <p className="checkout-indirizzo-form__title">{titolo}</p>}
      {!!head && <div className="checkout-indirizzo-form__head">{head}</div>}
      <form onSubmit={formik.handleSubmit}>
        <FormLayout>
          {fatturazione && (
            <FormField
              label={t('Chi sei?')}
              required
              layout="full"
              inline
              {...getFormikFieldProps(formik, 'privato', true)}
            >
              <FormRadio
                id="indirizzo_fatturazione_tipo"
                name="privato"
                options={privatoOptions}
                {...getFormikFieldProps(formik, 'privato')}
              />
            </FormField>
          )}
          {fatturazione && formik.values.privato === '0' ? (
            <FormField
              label={t('Ragione sociale')}
              required
              layout="half"
              {...getFormikFieldProps(formik, 'ragioneSociale', true)}
            >
              <FormInput
                placeholder={t('Ragione sociale')}
                name="ragioneSociale"
                {...getFormikFieldProps(formik, 'ragioneSociale')}
              />
            </FormField>
          ) : (
            <>
              <FormField
                label={t('Nome')}
                required
                layout="half"
                {...getFormikFieldProps(formik, 'nome', true)}
              >
                <FormInput
                  placeholder={t('Nome')}
                  name="nome"
                  {...getFormikFieldProps(formik, 'nome')}
                />
              </FormField>
              <FormField
                label={t('Cognome')}
                required
                layout="half"
                {...getFormikFieldProps(formik, 'cognome', true)}
              >
                <FormInput
                  placeholder={t('Cognome')}
                  name="cognome"
                  {...getFormikFieldProps(formik, 'cognome')}
                />
              </FormField>
            </>
          )}

          <FormField
            label={t('Indirizzo')}
            required
            layout="lg"
            {...getFormikFieldProps(formik, 'indirizzo', true)}
          >
            <FormInput
              placeholder={t('Indirizzo')}
              name="indirizzo"
              {...getFormikFieldProps(formik, 'indirizzo')}
            />
          </FormField>
          <FormField
            label={t('Numero civico')}
            required
            layout="md"
            {...getFormikFieldProps(formik, 'civico', true)}
          >
            <FormInput
              placeholder={t('Numero civico')}
              name="civico"
              {...getFormikFieldProps(formik, 'civico')}
            />
          </FormField>
          {!fatturazione && (
            <FormField
              label={t('Informazioni aggiuntive')}
              layout="full"
              {...getFormikFieldProps(formik, 'informazioniAggiuntive', true)}
            >
              <FormInput
                placeholder={t('Informazioni aggiuntive')}
                name="informazioniAggiuntive"
                {...getFormikFieldProps(formik, 'informazioniAggiuntive')}
              />
            </FormField>
          )}
          <FormField
            label={t('Città')}
            required
            layout="half"
            {...getFormikFieldProps(formik, 'citta', true)}
          >
            <FormInput
              placeholder={t('Città')}
              name="citta"
              {...getFormikFieldProps(formik, 'citta')}
            />
          </FormField>
          <FormField
            label={t('CAP')}
            required
            layout="half"
            {...getFormikFieldProps(formik, 'cap', true)}
          >
            <FormInput placeholder={t('CAP')} name="cap" {...getFormikFieldProps(formik, 'cap')} />
          </FormField>
          <FormField
            label={t('Provincia')}
            required
            layout="half"
            {...getFormikFieldProps(formik, 'provincia', true)}
          >
            <FormSelect
              searchable
              options={ProvinciaItalianeAllSigla()}
              placeholder={t('Provincia')}
              name="provincia"
              {...getFormikFieldProps(formik, 'provincia')}
              onChange={selectedOption => {
                formik.setFieldValue('provincia', selectedOption.value, true)
              }}
            />
          </FormField>
          {fatturazione ? (
            formik.values.privato === '1' ? (
              <FormField
                label={t('Codice Fiscale')}
                required
                layout="half"
                {...getFormikFieldProps(formik, 'cf', true)}
              >
                <FormInput
                  placeholder={t('Codice Fiscale')}
                  name="cf"
                  {...getFormikFieldProps(formik, 'cf')}
                />
              </FormField>
            ) : (
              <>
                <FormField
                  label={t('P.IVA')}
                  required
                  layout="half"
                  {...getFormikFieldProps(formik, 'piva', true)}
                >
                  <FormInput
                    placeholder={t('P.IVA')}
                    name="piva"
                    {...getFormikFieldProps(formik, 'piva')}
                  />
                </FormField>
                <FormField
                  label={t('Codice intermediario (SDI)')}
                  layout="half"
                  {...getFormikFieldProps(formik, 'sdi', true)}
                >
                  <FormInput
                    placeholder={t('SDI')}
                    name="sdi"
                    {...getFormikFieldProps(formik, 'sdi')}
                  />
                </FormField>
                <FormField
                  label={t('PEC')}
                  layout="half"
                  {...getFormikFieldProps(formik, 'pec', true)}
                >
                  <FormInput
                    placeholder={t('PEC')}
                    name="pec"
                    {...getFormikFieldProps(formik, 'pec')}
                  />
                </FormField>
                <FormField
                  label={t('Codice Fiscale')}
                  layout="half"
                  {...getFormikFieldProps(formik, 'cf', true)}
                >
                  <FormInput
                    placeholder={t('Codice Fiscale')}
                    name="cf"
                    {...getFormikFieldProps(formik, 'cf')}
                  />
                </FormField>
                {/* Lasciare FormField vuoto per il layout */}
                <FormField layout="half" />
              </>
            )
          ) : (
            <FormField
              label={t('Numero di cellulare')}
              required
              layout="half"
              {...getFormikFieldProps(formik, 'telefono', true)}
            >
              <FormInput
                placeholder={t('Numero di cellulare')}
                name="telefono"
                {...getFormikFieldProps(formik, 'telefono')}
              />
            </FormField>
          )}
          <FormField space="none" className="checkout-indirizzo-form__privacy-field">
            <FormCheckbox
              id="save"
              name="save"
              label={t('Salva indirizzo per i prossimi acquisti')}
              value={save}
              checked={save}
              onChange={() => setSave(!save)}
            />
          </FormField>
          <FormField space="none" className="checkout-indirizzo-form__required-label">
            <span>* {t('Campi obbligatori')}</span>
          </FormField>
        </FormLayout>
        {!!actions && <div className="checkout-indirizzo-form__actions">{actions}</div>}
      </form>
    </div>
  )
}

export default CheckoutIndirizzoForm
