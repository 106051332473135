import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { optionsRuoloProfile } from 'components/forms-utils/utils'
import { useTrans } from 'hooks'
import { Profilo, ArrowRightLong, Edit, CheckboxMarked, Module } from 'react-components/atoms/Icons'
import { ImageBox, Button, Modal } from 'react-components/atoms'

import { api, CAS_DOMAIN } from 'utils'
import { useRouter } from 'next/router'
import { fetchUtente } from 'containers/utente'
import { AVATAR } from 'utils/endpoint'

/* 
  TODO:
  - far arrivare anche inizio_membership come dato dell'utente
  - definire cosa succede al click sull'Edit
*/

const AreaPersonaleUserCard = (props) => {
  const {
    utente = {
      nome: '',
      cognome: '',
      ruolo: '',
      is_member: false,
      inizio_membership: '',
    },
    size = 'md',
    editable = false,
    className = '',
  } = props

  const t = useTrans()
  const [avatars, setAvatars] = useState()
  const [open, setOpen] = useState(false)

  const editImage = () => {
    api.get(AVATAR).then((res) => {
      setAvatars(res?.data)
      setOpen(true)
    })
  }

  return (
    <div
      className={`area-personale-user-card${size ? ` area-personale-user-card--${size}` : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      {utente.avatar ? (
        <ImageBox
          maxWidth={70}
          maxHeight={70}
          src={utente.avatar}
          alt={`${utente.nome} ${utente.cognome}`}
          classPrefix="area-personale-user-card"
          lazyload
        >
          {editable && (
            <button
              aria-label={t('Modifica immagine')}
              style={{ cursor: 'pointer' }}
              className="area-personale-user-card__edit-icon"
              type="button"
              onClick={editImage}
            >
              <Edit />
            </button>
          )}
        </ImageBox>
      ) : (
        <div className="area-personale-user-card__image-box area-personale-user-card__image-box--noimage">
          <Profilo />
          {editable && (
            <button
              aria-label={t('Modifica immagine')}
              style={{ cursor: 'pointer' }}
              className="area-personale-user-card__edit-icon"
              type="button"
              onClick={editImage}
            >
              <Edit />
            </button>
          )}
        </div>
      )}
      <div className="area-personale-user-card__content">
        <p className="area-personale-user-card__name">
          {utente.nome} {utente.cognome}
        </p>
        <p className="area-personale-user-card__type">
          {optionsRuoloProfile.filter((x) => x.value === utente.ruolo)?.[0]?.label}
        </p>
        {utente.is_member && (
          <p className="area-personale-user-card__member">
            <span>{t('Abbonato a io+')}</span>
            {/* TODO - farsi passare anche la data di inizio membership nell'utente */}
            {size === 'md' && utente?.membership?.dal && (
              <>
                {' '}
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('dal {0}', [`<b>${utente?.membership?.dal}</b>`]),
                  }}
                ></span>
              </>
            )}
          </p>
        )}
        {editable && size === 'md' ? (
          <a href="/area-personale/libreria/riviste">
            <Button
              variant="ghost"
              icon={<ArrowRightLong />}
              iconPos="right"
              label={t('Gestisci abbonamento')}
              className="area-personale-user-card__membership-link"
            />
          </a>
        ) : (
          <Button
            variant="ghost"
            icon={<ArrowRightLong />}
            iconPos="right"
            label={t('Visualizza profilo')}
            className="area-personale-user-card__profile-link"
          />
        )}
      </div>
      <AvatarSelectModal avatars={avatars} open={open} setOpen={setOpen} />
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    utente: state.utente.utente,
  }
}

export default connect(mapStateToProps, null)(AreaPersonaleUserCard)

const AvatarSelectModal = ({ open, avatars, setOpen }) => {
  const [selected, setSelected] = useState()

  const t = useTrans()

  const dispatch = useDispatch()

  const _handleSubmit = () => {
    const params = {
      id: selected,
    }
    api
      .post('/avatar/update_avatar/', params)
      .then(() => {
        dispatch(fetchUtente())
        setOpen(false)
      })
      .catch((err) => console.log(err))
  }
  return (
    <Modal open={open} setOpen={setOpen}>
      <div style={{ textAlign: 'center' }}>
        <Modal.Content title={t('Modifica il tuo avatar')} />
      </div>
      <div
        style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: 15, justifyContent: 'center' }}
      >
        {avatars?.map((a) => (
          <div style={{ margin: 2 }}>
            <ImageBox
              onClick={() => setSelected(a?.id)}
              maxWidth={70}
              maxHeight={70}
              src={a?.image}
              classPrefix="area-personale-user-card"
              lazyload
              cursorPointer
            >
              {a?.id === selected && (
                <button
                  aria-label={t('Seleziona immagine')}
                  className="area-personale-user-card__edit-icon"
                  type="button"
                >
                  <CheckboxMarked />
                </button>
              )}
            </ImageBox>
          </div>
        ))}
      </div>
      <Modal.Buttons>
        <Button label={t('Annulla')} variant="ghost" onClick={() => setOpen(false)} />
        <Button label={t('Conferma')} onClick={() => _handleSubmit()} disabled={!selected} />
      </Modal.Buttons>
    </Modal>
  )
}
