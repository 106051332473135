import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'

import { Button, WContainer } from 'react-components/atoms'
import { api } from 'utils'
import ArticoloCard from 'components/card/ArticoloCard'
import { useSelector } from 'react-redux'
import { ArrowRightLong } from 'react-components/atoms/Icons'
import { LISTINGDALLAREDAZIONE, UTENTE } from 'utils/endpoint'
import StrumentoCard from 'components/card/StrumentoCard'
import LezioneCard from 'components/card/LezioneCard'
import RaccoltaCard from 'components/card/RaccoltaCard'

const TypContenutiBox = props => {
  const { className = '' } = props

  const t = useTrans()

  const { utente } = useSelector(state => state.utente)

  const [items, setItems] = useState([])

  async function fetchItems() {
    const { data } = await api.get(LISTINGDALLAREDAZIONE, { params: { limit: 6 } })
    setItems(data.listing.elementi.object_list)
  }

  useEffect(() => {
    fetchItems()
  }, [])

  useEffect(() => {
    if (items.length) checkTools()
  }, [items?.length])

  function checkTools() {
    const params = items.map(({ id, model }) => ({
      content_type_id: model,
      object_id: id,
    }))

    api.get(UTENTE + 'tools/', { params }).then(response => {
      const enhancedItems = items.map(item => {
        const current = response.data.find(
          e => e.content_type_id === item.model && e.object_id === item.id
        )

        const { saved, following, file_url, pdf_file } = current

        return { extra: { ...item.extra, file_url, pdf_file }, saved, following, ...item }
      })

      setItems(enhancedItems)
    })
  }

  function renderCard(item) {
    switch (item.model_class) {
      case 'strumento':
        return <StrumentoCard strumento={item} horizontal />
      case 'articolo':
        return <ArticoloCard articolo={item} horizontal />
      case 'lezione':
        return <LezioneCard articolo={item} horizontal />
      default:
        return <RaccoltaCard raccolta={item} horizontal />
    }
  }

  return (
    <section className={`typ-contenuti-box ${className ? ` ${className}` : ''}`}>
      <WContainer>
        {utente.ruolo === 'Altro' ? (
          <>
            <p className="typ-contenuti-box__title">{t(`Contenuti recenti`)}</p>
            <p className="typ-contenuti-box__description">
              {t(
                `Per te una selezione degli articoli e dei materiali pubblicati di recente. Da cosa vuoi partire oggi?`
              )}
            </p>
          </>
        ) : utente.ruolo === 'Insegnante' ? (
          <>
            <p className="typ-contenuti-box__title">{t(`Contenuti per te e le tue lezioni`)}</p>
            <p className="typ-contenuti-box__description">
              {t(
                `Qui trovi alcuni spunti, articoli e materiali che pensiamo possano aiutarti a realizzare le tue prossime lezioni.`
              )}
            </p>
          </>
        ) : (
          <>
            <p className="typ-contenuti-box__title">{t(`Contenuti per te`)}</p>
            <p className="typ-contenuti-box__description">
              {t(
                `Ecco alcuni articoli e materiali che pensiamo possano interessarti. Da cosa vuoi partire oggi?`
              )}
            </p>
          </>
        )}
        <div className="typ-contenuti-box__cta">
          <Button
            label={t('Scopri tutti i contenuti')}
            variant="ghost"
            icon={<ArrowRightLong />}
            iconPos="right"
            href="/area-personale/dalla-redazione"
          />
        </div>
        <div className="typ-contenuti-box__list">
          {items.map(item => (
            <div className="typ-contenuti-box__item" key={`typ-contenuti-box__item__${item.id}`}>
              {renderCard(item)}
            </div>
          ))}
        </div>
      </WContainer>
    </section>
  )
}

export default TypContenutiBox
