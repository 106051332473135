import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function SaveDeselected({ className }) {
  return (
    <IconBase name="save-deselected" w={24} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.85695 3.19995H17.1427C18.2741 3.19995 19.1998 4.11195 19.1998 5.22662V21.44L11.9998 18.4L4.7998 21.44L4.81009 5.22662C4.81009 4.11195 5.72552 3.19995 6.85695 3.19995ZM11.9998 16.1909L17.1427 18.4V6.23995C17.1427 5.68262 16.6798 5.22662 16.1141 5.22662H7.88552C7.3198 5.22662 6.85695 5.68262 6.85695 6.23995V18.4L11.9998 16.1909Z"
        //  fill="#2A2A2A"
      />
    </IconBase>
  )
}

export default SaveDeselected
