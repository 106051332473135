import React, { useEffect, useRef, useState } from 'react'

import { TRADUZIONI } from '../../utils/endpoint'
import { api } from '../../utils'
import { connect } from 'react-redux'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const Trans = props => {
  const [valore, setValore] = useState(null)

  const mounted = useRef(false)
  useEffect(() => {
    mounted.current = true
    initializeValore()
  }, [props.traduzioni])

  const initializeValore = async () => {
    if (props.traduzioni.length) {
      const traduzione = props.traduzioni.find(t => t.chiave === props.chiave)

      if (traduzione) {
        setValore(traduzione.valore)
      } else {
        setValore(props.chiave)
        api
          .post(TRADUZIONI, { chiave: props.chiave })
          .then(response => console.log(response.data))
          .catch(error => console.log(error))
      }
    }
  }

  return <>{valore}</>
}

const mapStateToProps = state => {
  return {
    traduzioni: state.traduzioni.traduzioni,
  }
}

export default connect(mapStateToProps, null)(Trans)
