import Icon from './Icon'

function Modifica({ onClick = () => {}, horizontal = false }) {
  return (
    <div className={`modifica ${horizontal ? 'modifica--horizontal' : ''}`} onClick={onClick}>
      <Icon id={'pencil'} classes="icon--pencil" tooltip={'Modifica la raccolta'} />
    </div>
  )
}

export default Modifica
