import React from 'react'
import { useTrans } from 'hooks'
import { Promo } from 'react-components/atoms/Icons'
import { formatPrice } from 'utils'

const RiepilogoOrdine = (props) => {
  const {
    titolo = '',
    totaleProdotti = undefined,
    speseSpedizione = undefined,
    totaleServizi = undefined,
    sistemaSpedizione = undefined,
    promoApplicate = undefined,
    totale = undefined,
    className = '',
  } = props

  const t = useTrans()

  return (
    <div className={`riepilogo-ordine ${className ? ` ${className}` : ''}`}>
      {titolo && <p className="riepilogo-ordine__title">{titolo}</p>}
      <div className="riepilogo-ordine__rows">
        {!!totaleProdotti !== null && (
          <p className="riepilogo-ordine__row">
            <span>{t('Totale prodotti')}</span>
            <span>{formatPrice(totaleProdotti)}</span>
          </p>
        )}
        {!!totaleServizi !== null && totaleServizi > 0 && (
          <p className="riepilogo-ordine__row">
            <span>{t('Totale servizi')}</span>
            <span>{formatPrice(totaleServizi)}</span>
          </p>
        )}
        {sistemaSpedizione && (
          <p
            className={`riepilogo-ordine__row ${
              speseSpedizione ? '' : 'riepilogo-ordine__row--tertiary'
            }`}
          >
            <span>{t('Spese di spedizione')}</span>
            <span>{speseSpedizione ? formatPrice(speseSpedizione) : t('GRATIS')}</span>
          </p>
        )}
        {!!promoApplicate?.length > 0 &&
          promoApplicate.map((promo) => (
            <p key={promo.pk} className="riepilogo-ordine__row riepilogo-ordine__row--promo">
              <span className="riepilogo-ordine__row-label">
                <Promo className="riepilogo-ordine__row-icon" />
                <span>{promo.promotion?.promoCode || promo.promotion?.promoCoupon}</span>
              </span>
              <span>- {formatPrice(promo.absoluteDiscount)}</span>
            </p>
          ))}
      </div>
      {!!totale !== null && (
        <div className="riepilogo-ordine__totale">
          <span>{t('Totale')}</span>
          <span>{formatPrice(totale)}</span>
        </div>
      )}
    </div>
  )
}

export default RiepilogoOrdine
