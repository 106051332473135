import React from 'react'
import FormazioneDocenteBanner from '../FormazioneDocenteBanner/FormazioneDocenteBanner'
import BadgeOreFormazione from '../../atoms/BadgeOreFormazione/BadgeOreFormazione'
import FormazioneModuloIscrizione from '../FormazioneModuloIscrizione/FormazioneModuloIscrizione'
import Share from '../../organisms/Share/Share'

const FormazioneModuloCardHeader = props => {
  const { modulo = undefined } = props
  return (
    <div className="formazione-modulo-header">
      <div className="formazione-modulo-header__left">
        <h2 className="formazione-modulo-header__left__nome-corso">{modulo?.nome_corso}</h2>
        <h1 className="formazione-modulo-header__left__titolo">{modulo?.titolo}</h1>
        <div className="formazione-modulo-header__left__docente">
          <FormazioneDocenteBanner docente={modulo.docente} />
        </div>
        <p className="formazione-modulo-header__left__descrizione">{modulo?.descrizione}</p>
        <div className="formazione-modulo-header__left__docente">{modulo?.cognome_docente}</div>
        <div className="formazione-modulo-header__left__badge">
          <BadgeOreFormazione ore={modulo.ore_formazione} version={'white'} />
          <Share item={modulo} variant="square" isFormazione={true} labelGTM={'formazione'} />
        </div>
      </div>
      <div className="formazione-modulo-header__right">
        <Share item={modulo} variant="square" isFormazione={true} labelGTM={'formazione'} />
        <FormazioneModuloIscrizione item={modulo} />
      </div>
    </div>
  )
}

export default FormazioneModuloCardHeader
