import React from 'react'
import { useTrans } from 'hooks'
import { Button, Modal } from 'react-components/atoms'

import AreaPersonaleRaccoltaForm from '../AreaPersonaleRaccoltaForm/AreaPersonaleRaccoltaForm'

const AreaPersonaleAddRaccoltaModal = props => {
  const { open = false, setOpen = null } = props

  const t = useTrans()

  return (
    <Modal open={open} setOpen={setOpen} closeButton className="area-personale-add-raccolta-modal">
      <Modal.Content title={t('Crea una nuova raccolta')}>
        <AreaPersonaleRaccoltaForm />
      </Modal.Content>
    </Modal>
  )
}

export default AreaPersonaleAddRaccoltaModal
