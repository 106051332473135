import React from 'react'

const FormError = props => {
  const { message = '', visible = false, className = '' } = props

  return visible && message ? (
    <div className={'form-error' + (className ? ' ' + className : '')}>{message}</div>
  ) : (
    <></>
  )
}

export default FormError
