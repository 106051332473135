import React from 'react'

import { ProductJsonLd, CarouselJsonLd, FAQPageJsonLd } from 'next-seo'
import { DOMAIN } from 'utils'
import { BRAND, stripHTML } from 'utils'

const JsonLd = ({ item, type = 'prodotto' }) => {
  if (type === 'prodotto' && !(item.prodotto?.prezzoScontato || item.prodotto?.prezzo)) return null

  switch (type) {
    case 'prodotto': {
      return (
        <ProductJsonLd
          productName={item.titolo}
          description={item.descrizione?.replace(/<[^>]*>?/gm, '')}
          images={[`${DOMAIN}${item.main_image}`].concat(
            item.gallery?.map(img => `${DOMAIN}${img.image}`)
          )}
          gtin13={item.isbn}
          offers={[
            {
              price: item.prodotto?.prezzoScontato || item.prodotto?.prezzo,
              priceCurrency: 'EUR',
              itemCondition: 'https://schema.org/NewCondition',
              availability: `https://schema.org/${
                item.prodotto?.prezzo ? 'InStock' : 'OutOfStock'
              }`,
              url: `${DOMAIN}${item.url}`,
              seller: {
                name: BRAND,
              },
            },
          ]}
        />
      )
    }
    case 'corso': {
      return (
        <CarouselJsonLd
          ofType="course"
          data={item.moduli_in_corso.map(modulo => ({
            courseName: `${modulo.titolo} | ${item.titolo}`,
            description: modulo.descrizione,
            providerName: `${modulo.docente?.nome} ${modulo.docente?.cognome}`,
            url: `${DOMAIN}${modulo.url}`,
          }))}
        />
      )
    }
    case 'faq': {
      return (
        <FAQPageJsonLd
          mainEntity={item
            .reduce((res, categoria) => {
              res = res.concat(categoria.faqs)
              return res
            }, [])
            .map(faq => ({
              questionName: faq.domanda?.replace(/\"/g, "'"),
              acceptedAnswerText: stripHTML(faq.risposta?.replace(/\"/g, "'")),
            }))}
        />
      )
    }
    default: {
      return null
    }
  }
}

export default JsonLd
