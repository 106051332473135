import React, { useState, useRef, useEffect, useContext } from 'react'
import { useTrans } from 'hooks'
import { ArrowDown2, ArrowUp2, Promo } from 'react-components/atoms/Icons'
import { Button, FormError } from 'react-components/atoms'
import { CartContext } from 'utils/context'

const PromoCartAccordion = (props) => {
  const { promoApplicate = [], initOpen = false, className = '' } = props

  const t = useTrans()

  const { setPromoCode, removePromoCode } = useContext(CartContext)

  const [open, setOpen] = useState(initOpen)
  const [value, setValue] = useState('')
  const [error, setError] = useState(false)
  const [maxHeight, setMaxHeight] = useState(0)

  const innerContentRef = useRef(null)

  useEffect(() => {
    if (innerContentRef?.current?.clientHeight) setMaxHeight(innerContentRef?.current?.clientHeight)
  }, [open, error])

  function isApplied(promoCode) {
    return promoApplicate.some(
      (promo) =>
        promo.promotion?.promoCode === promoCode || promo.promotion?.promoCoupon === promoCode
    )
  }

  return (
    <div
      className={`promo-cart-accordion ${open ? 'promo-cart-accordion--open' : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <button className="promo-cart-accordion__trigger" onClick={() => setOpen(!open)}>
        <span className="promo-cart-accordion__icon">
          <Promo />
        </span>
        <span>{t('Hai un codice sconto?')}</span>
        <span className="promo-cart-accordion__arrow">{open ? <ArrowUp2 /> : <ArrowDown2 />}</span>
      </button>
      <div
        className="promo-cart-accordion__content"
        style={{
          maxHeight: !open ? 0 : maxHeight ? maxHeight : 'none',
        }}
      >
        <div className="promo-cart-accordion__inner-content" ref={innerContentRef}>
          <div className="promo-cart-accordion__form">
            <input
              type="text"
              placeholder={t('Inserisci codice sconto')}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              className="promo-cart-accordion__input"
            />
            <Button
              label={value && isApplied(value) ? t('Rimuovi') : t('Applica')}
              variant="ghost"
              className="promo-cart-accordion__submit"
              disabled={!value}
              onClick={async () => {
                if (value && isApplied(value)) {
                  const { status, message } = await removePromoCode(value)
                } else {
                  const { status, message } = await setPromoCode(value)
                  setError(!status)
                }
              }}
            />
          </div>
          <FormError
            message={t('Il codice inserito non è valido')}
            visible={error}
            className="promo-cart-accordion__error"
          />
        </div>
      </div>
    </div>
  )
}

export default PromoCartAccordion
