import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Devices({ className }) {
  return (
    <IconBase name="devices" w={20} className={className}>
      <path
        d="M3.33333 5.00001H18.3333V3.33334H3.33333C2.41667 3.33334 1.66667 4.08334 1.66667 5.00001V14.1667H0V16.6667H11.6667V14.1667H3.33333V5.00001ZM19.1667 6.66668H14.1667C13.7083 6.66668 13.3333 7.04168 13.3333 7.50001V15.8333C13.3333 16.2917 13.7083 16.6667 14.1667 16.6667H19.1667C19.625 16.6667 20 16.2917 20 15.8333V7.50001C20 7.04168 19.625 6.66668 19.1667 6.66668ZM18.3333 14.1667H15V8.33334H18.3333V14.1667Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default Devices
