import React, { useContext } from 'react'
import { Button } from 'react-components/atoms'
import { formatPrice } from 'utils'
import { CartContext } from 'utils/context'
import { useState } from 'react'
import { useTrans } from 'hooks'
import { AddCircle } from 'react-components/atoms/Icons'
import { sendGTMData } from '../../../utils/gtm'

const BoxAbbonamento = props => {
  const { box, className = '' } = props

  const context = useContext(CartContext)

  const t = useTrans()

  const [open, setOpen] = useState(false)

  const inPromo = box.prezzo !== box.prezzo_scontato

  return (
    <div className={`box-abbonamento ${className ? ` ${className}` : ''}`}>
      {box.etichetta && (
        <span className="box-abbonamento__tag" style={{ background: box.colore_etichetta }}>
          {box.etichetta}
        </span>
      )}
      <p className="box-abbonamento__title">{box.titolo}</p>
      <div className="box-abbonamento__middle">
        {inPromo && <span className="box-abbonamento__fullprice">{formatPrice(box.prezzo)}</span>}
        <span
          className={`box-abbonamento__price ${inPromo ? 'box-abbonamento__price--promo' : ''}`}
        >
          {formatPrice(box.prezzo_scontato)}
        </span>
        <Button
          className="box-abbonamento__cta"
          label={box.cta}
          variant="primary"
          colorVariant="red-light"
          onClick={() => {
            context.addAbbonamentoToCart(box.rivista, true, 'prodotti_correlati', box)
          }}
        />
      </div>
      <Button
        className="box-abbonamento__trigger"
        label={t(open ? 'Leggi di meno' : 'Leggi di più')}
        variant="ghost"
        onClick={() => setOpen(!open)}
        icon={<AddCircle />}
      />
      <div
        className={`box-abbonamento__text ${open ? 'box-abbonamento__text--open' : ''}`}
        dangerouslySetInnerHTML={{ __html: box.testo }}
      />
    </div>
  )
}

export default BoxAbbonamento
