import React, { useEffect, useState } from 'react'
import { FormToggleDiscipline } from 'react-components/organisms/Forms'
import { useSelector } from 'react-redux'
import { api } from 'utils'
import { DISCIPLINE } from 'utils/endpoint'
import { useTrans } from 'hooks'
import { Edit } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'
import { DisciplinaCard } from 'react-components/molecules'

const TabDiscipline = () => {
  const [discipline, setDiscipline] = useState([])
  const [edit, setEdit] = useState(false)
  const t = useTrans()

  const { utente } = useSelector(state => state.utente)

  useEffect(() => {
    fetchDiscipline()
  }, [])

  async function fetchDiscipline() {
    const response = await api.get(DISCIPLINE, {
      params: { elementi_seguiti__utente_id: utente?.id },
    })
    const { data } = response
    setDiscipline(data)
  }

  return (
    <div className="tab-discipline">
      {edit ? (
        <FormToggleDiscipline
          setEdit={setEdit}
          selectedFromProps={discipline}
          setDisciplineFromProps={setDiscipline}
        />
      ) : (
        <>
          <div className="tab-discipline__actions">
            <Button
              label={t('Aggiungi o elimina')}
              variant="ghost"
              onClick={() => setEdit(true)}
              icon={<Edit />}
              iconPos="right"
            />
          </div>
          <div className="tab-discipline__list">
            {discipline.map(disciplina => (
              <div className="tab-discipline__item">
                <DisciplinaCard disciplina={disciplina} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default TabDiscipline
