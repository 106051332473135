import React, { useState } from 'react'

import GenericMenuList from './GenericMenuList'
import Icon from '../../atomic/Icon'
import Link from 'next/link'
import { connect } from 'react-redux'
import dynamic from 'next/dynamic'
import { sendGTMData } from '../../../utils/gtm'

const Login = dynamic(() => import('../../atomic/Login'))

const GenericMenuListItem = ({ classes, with_icons, item, utente }) => {
  const handleGTM = (item) => {
    const headerClasses = ['header-top', 'header-side']
    if (item) {
      if (item === 'Scopri IO+') {
        item = 'scopri io plus'
      }
      if (item === 'Contatti & Assistenza') {
        item = 'contatti assistenza'
      }

      sendGTMData({
        event: 'GAevent',
        eventID: '03',
        eventCategory: 'header',
        eventAction: item.toGTMFormat(),
        eventLabel: 'non_valorizzata',
      })
    }

    const footerClasses = ['footer']
    if (item && footerClasses.includes(classes)) {
      sendGTMData({
        event: 'GAevent',
        eventID: '04',
        eventCategory: 'footer',
        eventAction: item.toGTMFormat(),
        eventLabel: 'non_valorizzata',
      })
    }
  }

  const classes_li = [[classes, '-menu', '__item'].join('')]

  const [login, setLogin] = useState(false)
  const handleLogin = (login) => setLogin(login)

  if (!with_icons) classes_li.push([classes, '-menu', '__item--noicon'].join(''))

  return (
    <li className={classes_li.join(' ')}>
      {item.final_url ? (
        item.blank ? (
          <Link legacyBehavior href={item.final_url}>
            <a href={item.final_url} target="_blank" onClick={() => handleGTM(item.name)}>
              {with_icons && item.key ? <Icon id={item.key} /> : null}
              {item.name}
            </a>
          </Link>
        ) : (
          <Link legacyBehavior href={item.final_url}>
            <a onClick={() => handleGTM(item.name)}>
              {with_icons && item.key ? <Icon id={item.key} /> : null}
              {item.name}
            </a>
          </Link>
        )
      ) : null}

      {item.children && item.children.length ? (
        <GenericMenuList menu={item.children} classes={classes} with_icons={with_icons} />
      ) : null}
      {login && <Login isOpen={login} setLogin={handleLogin} />}
    </li>
  )
}

const mapStateToProps = (state) => {
  return {
    utente: state.utente.utente,
  }
}

export default connect(mapStateToProps, null)(GenericMenuListItem)
