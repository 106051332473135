import React from 'react'

const Toggle = props => {
  const { checked = false, setChecked = () => {}, disabled = false, className = '' } = props

  return (
    <button
      type="button"
      className={`toggle${checked ? ' toggle--checked' : ''}${disabled ? ' toggle--disabled' : ''}${
        className ? ` ${className}` : ''
      }`}
      onClick={() => setChecked(!checked)}
    />
  )
}

export default Toggle
