export const setRaccolta = raccolta => ({
  type: 'SET_RACCOLTA',
  raccolta,
})

export const resetRaccolta = () => ({
  type: 'RESET_RACCOLTA',
})

export default function raccolta(
  state = {
    raccolta: null,
  },
  action
) {
  switch (action.type) {
    case 'SET_RACCOLTA': {
      return Object.assign({}, state, {
        raccolta: action.raccolta,
      })
    }

    case 'RESET_RACCOLTA': {
      return Object.assign({}, state, {
        raccolta: null,
      })
    }

    default:
      return state
  }
}
