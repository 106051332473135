import React from 'react'
import { Button } from 'react-components/atoms'

const TypActionBox = props => {
  const {
    title = '',
    description = '',
    ctaLabel = '',
    ctaLink = '',
    rounded = false,
    invertedButton = false,
    className = '',
  } = props

  return (
    <div
      className={`typ-action-box${rounded ? ' typ-action-box--rounded' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <p className="typ-action-box__title">{title}</p>
      <p className="typ-action-box__description">{description}</p>
      <div className="typ-action-box__cta">
        <Button label={ctaLabel} href={ctaLink} inverted={invertedButton} />
      </div>
    </div>
  )
}

export default TypActionBox
