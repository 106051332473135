import Link from 'next/link'
import React from 'react'

const ContenutoNumeroRivista = (props) => {
  const { contenuto, className = '' } = props

  if (!contenuto) return null

  const autore = contenuto?.extra?.utenti?.length ? contenuto?.extra.utenti[0] : null

  return (
    <div className={`contenuto-numero-rivista ${className ? ` ${className}` : ''}`}>
      {contenuto.label_numero_rivista && (
        <p className="contenuto-numero-rivista__label">{contenuto.label_numero_rivista}</p>
      )}
      <Link legacyBehavior href={contenuto.url}>
        <a className="contenuto-numero-rivista__title">{contenuto.titolo}</a>
      </Link>
      {autore && <span className="contenuto-numero-rivista__author">{autore.titolo}</span>}
    </div>
  )
}

export default ContenutoNumeroRivista
