import React from 'react'
import { FormError } from 'react-components/atoms'

const FormTextarea = props => {
  const {
    placeholder = '',
    name = '',
    value = '',
    size = 'md',
    className = '',
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const classNames = `
    form-textarea 
    form-textarea--${size}
    ${status ? ` form-textarea--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <div className="form-textarea__input-wrap">
        <textarea
          name={name}
          value={value}
          className="form-textarea__input"
          placeholder={placeholder}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
        />
      </div>
      <FormError
        className="form-textarea__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormTextarea
