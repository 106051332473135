import React from 'react'
import { Close } from 'react-components/atoms/Icons'
import { AreaPersonaleUserCard, AreaPersonaleMenu } from 'react-components/organisms'

const AreaPersonaleSidebar = props => {
  const { activeMenuKey = '', className = '' } = props

  function close() {
    document.body.classList.remove('w-noscroll')
    document.body.classList.remove('w-menuareapersonaleopen')
  }

  return (
    <>
      <div className={`area-personale-sidebar ${className ? ` ${className}` : ''}`}>
        <span className="area-personale-sidebar__close" onClick={close}>
          <Close />
        </span>
        <AreaPersonaleUserCard size="sm" />
        <AreaPersonaleMenu activeItemKey={activeMenuKey} />
      </div>
      <div className="area-personale-sidebar__backdrop" onClick={close} />
    </>
  )
}

export default AreaPersonaleSidebar
