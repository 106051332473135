import React from 'react'
import { Toggle } from 'react-components/atoms'

const WidgetToggle = props => {
  const {
    title = '',
    description = '',
    checked = false,
    setChecked = () => {},
    className = '',
  } = props

  return (
    <div className={`widget-toggle ${className ? ` ${className}` : ''}`}>
      <div className="widget-toggle__content">
        <p className="widget-toggle__title">{title}</p>
        <p className="widget-toggle__description">{description}</p>
      </div>
      <Toggle checked={checked} setChecked={setChecked} className="widget-toggle__toggle" />
    </div>
  )
}

export default WidgetToggle
