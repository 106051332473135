import React from 'react'
import { ImageBox } from 'react-components/atoms'

const TemaCard = props => {
  const {
    tema = {
      titolo: '',
      preview_image: '',
      main_image: '',
    },
    className = '',
    noUrl = false,
  } = props

  const thumb = tema?.preview_image ? tema.preview_image : tema.main_image ? tema.main_image : ''

  if (noUrl)
    return (
      <div className={`tema-card ${className ? ` ${className}` : ''}`}>
        <ImageBox
          lazyLoad
          maxWidth={200}
          maxHeight={120}
          src={thumb}
          alt={tema.titolo?.toAltTitle()}
          title={tema.titolo?.toAltTitle()}
          classPrefix={'tema-card'}
        />
        <div className="tema-card__title">{tema.titolo}</div>
      </div>
    )

  return (
    <a className={`tema-card ${className ? ` ${className}` : ''}`} href={tema.url}>
      <ImageBox
        lazyLoad
        maxWidth={200}
        maxHeight={120}
        src={thumb}
        alt={tema.titolo?.toAltTitle()}
        title={tema.titolo?.toAltTitle()}
        classPrefix={'tema-card'}
      />
      <div className="tema-card__title">{tema.titolo}</div>
    </a>
  )
}

export default TemaCard
