import React from 'react'
import { StickyStepsItem } from 'react-components/molecules'

export default function StickySteps({ items, visible = false }) {
  return (
    <div
      className={`sticky-steps ${visible ? 'sticky-steps--visible' : ''} ${
        !items.length ? 'sticky-steps--empty' : ''
      }`}
    >
      {items.map((item, index) => (
        <StickyStepsItem
          label={item.label}
          active={item.active}
          done={item.done}
          index={index}
          count={items.length}
          key={item.label}
        />
      ))}
    </div>
  )
}
