import React, { useEffect, useState } from 'react'
import { FormToggleTemi } from 'react-components/organisms/Forms'
import { useSelector } from 'react-redux'
import { api } from 'utils'
import { TEMI } from 'utils/endpoint'
import { useTrans } from 'hooks'
import { Edit } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'
import { TemaCard } from 'react-components/molecules'

const TabTemi = () => {
  const [temi, setTemi] = useState([])
  const [edit, setEdit] = useState(false)
  const t = useTrans()

  const { utente } = useSelector(state => state.utente)

  useEffect(() => {
    fetchTemi()
  }, [])

  async function fetchTemi() {
    const response = await api.get(TEMI, {
      params: { elementi_seguiti__utente_id: utente?.id },
    })
    const { data } = response
    setTemi(data)
  }

  return (
    <div className="tab-temi">
      {edit ? (
        <FormToggleTemi setEdit={setEdit} selectedFromProps={temi} setTemiFromProps={setTemi} />
      ) : (
        <>
          <div className="tab-temi__actions">
            <Button
              label={t('Aggiungi o elimina')}
              variant="ghost"
              onClick={() => setEdit(true)}
              icon={<Edit />}
              iconPos="right"
            />
          </div>
          <div className="tab-temi__list">
            {temi?.map(item => (
              <div className="tab-temi__item">
                <TemaCard tema={item} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default TabTemi
