import React from 'react'
import { FormError } from 'react-components/atoms'

const FormInputPassword = props => {
  const {
    placeholder = '',
    name = '',
    value = '',
    size = 'md',
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const classNames = `
    form-input-password 
    form-input-password--${size}
    ${status ? ` form-input-password--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <div className="form-input-password__input-wrap">
        <input
          type={'password'}
          name={name}
          value={value}
          className="form-input-password__input"
          placeholder={placeholder}
          disabled={disabled}
          readOnly={readOnly}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
        />
      </div>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormInputPassword
