import React from 'react'
import { useTrans } from 'hooks'
import { StrumentoLibro } from 'react-components/atoms/Icons'
import { LibroCard, AttivaContenutoCard, AggiungiContenutoCard } from 'react-components/molecules'

const TabLibri = props => {
  const { className = '', items = [] } = props

  const t = useTrans()

  return (
    <div className={`tab-libri ${className ? ` ${className}` : ''}`}>
      <div className="tab-libri__list">
        {items.map((libro, index) => (
          <LibroCard libro={libro} type="corsoadozionale" key={index} />
        ))}
        <AttivaContenutoCard
          title={
            !items.length
              ? t('Non ci sono contenuti attivi')
              : t('Sblocca nuovi contenuti digitali')
          }
          description={t(
            'Attiva nuovi corsi adozionali per fruire di tanti contenuti digitali a te dedicati'
          )}
          ctaLabel={t('Nuovi libri di testo')}
          ctaLink="/area-personale/libri"
          className="tab-libri__activate-cta"
        />
        <AggiungiContenutoCard
          icon={<StrumentoLibro />}
          ctaLabel={t('Aggiungi altri libri')}
          ctaLink="/area-personale/libri"
          className="tab-libri__add-cta"
        />
      </div>
    </div>
  )
}

export default TabLibri
