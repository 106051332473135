import { useEffect, useState } from 'react'

import { useTrans } from 'hooks'
import { ELEMENTOSEGUITO, TEMI } from 'utils/endpoint'
import { api } from 'utils'
import { Button, SelectionButton } from 'react-components/atoms'
import { TemaCard } from 'react-components/molecules'

const FormToggleTemi = ({ setTemiFromProps, afterSubmit, setEdit, selectedFromProps }) => {
  const [temi, setTemi] = useState([])
  const [selected, setSelected] = useState([...selectedFromProps?.map(i => i?.id)])
  const t = useTrans()

  useEffect(() => {
    // Fetch Temi
    api.get(TEMI).then(res => setTemi(res?.data))
    return () => {
      onClose()
    }
  }, [])

  const handleSelected = id => {
    selected?.includes(id)
      ? setSelected(prev => prev?.filter(item => item !== id))
      : setSelected(prev => [...prev, id])
  }

  const onClose = () => {
    setSelected([])
    afterSubmit?.()
  }

  const _handleSubmit = () => {
    api.post(`${ELEMENTOSEGUITO}bulk_create/`, {
      content_type_id: temi[0].model,
      object_ids: selected,
    })
    setTemiFromProps?.([...temi?.filter(i => selected?.includes(i?.id))])
    setEdit(false)
    onClose()
  }

  return (
    <div className="form-toggle-temi">
      <div className="form-toggle-temi__list">
        {temi?.map(item => (
          <div className="form-toggle-temi__item">
            <SelectionButton
              visible
              selected={selected?.includes(item?.id)}
              setSelected={() => handleSelected(item?.id)}
            />
            <TemaCard tema={item} noUrl />
          </div>
        ))}
      </div>
      <div className="form-toggle-temi__actions">
        <Button label={t('Salva le modifiche')} onClick={() => _handleSubmit()} />
        <Button label={t('Annulla modifiche')} variant="ghost" onClick={() => setEdit(false)} />
      </div>
    </div>
  )
}

export default FormToggleTemi
