import React, { useContext } from 'react'
import { Image } from 'react-components/atoms'
import { CAS_DOMAIN, formatPrice } from 'utils'
import { Button } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { CartContext } from 'utils/context'
import { sendProductClickGTM, sendGTMData } from '../../../utils/gtm'

const CardParascolastico = (props) => {
  const {
    parascolastico = {
      titolo: '',
      descrizione: '',
      main_image: '',
      prezzo: '',
    },
    className = '',
    position = 0,
    variant = 'default',
    parentList = '',
  } = props

  const t = useTrans()

  const context = useContext(CartContext)

  const prodotto = parascolastico.prodotto

  const handleGTM = (item) => {
    sendGTMData({
      event: 'GAevent',
      eventID: '27',
      eventCategory: 'card_volumi',
      eventAction: item.toGTMFormat(),
      eventLabel: parascolastico.titolo.toGTMFormat(),
    })
  }

  return (
    <article className={`card-parascolastico ${className} card-parascolastico--${variant}`}>
      {parascolastico.main_image && (
        <div className="card-parascolastico__image-box">
          <Image
            className="card-parascolastico__image"
            src={CAS_DOMAIN + parascolastico.main_image}
            alt={parascolastico.titolo?.toAltTitle()}
            title={parascolastico.titolo?.toAltTitle()}
            width={120}
            height={150}
          />
        </div>
      )}
      <div className="card-parascolastico__content">
        <p className="card-parascolastico__title">{parascolastico.titolo}</p>
        {parascolastico.isbn && (
          <p className="card-parascolastico__description">
            {t('ISBN:')} {parascolastico.isbn}
          </p>
        )}
        <div className="card-parascolastico__cta-box card-parascolastico__cta-box--first">
          {parascolastico.prezzo && (
            <div className="card-parascolastico__price">
              {prodotto?.prezzoScontato && prodotto?.prezzo !== prodotto?.prezzoScontato && (
                <span className="card-parascolastico__fullprice">
                  {formatPrice(prodotto?.prezzo)}
                </span>
              )}
              <span className="card-parascolastico__finalprice">
                {formatPrice(prodotto?.prezzoScontato || parascolastico.prezzo)}
              </span>
              {(prodotto?.scontoPercentuale || prodotto?.scontoAssoluto) && (
                <span className="card-parascolastico__discount">
                  -
                  {prodotto?.scontoPercentuale ? (
                    <>{prodotto?.scontoPercentuale}%</>
                  ) : (
                    formatPrice(prodotto?.scontoAssoluto)
                  )}
                </span>
              )}
            </div>
          )}
          {prodotto && prodotto?.prezzoScontato ? (
            <Button
              className="card-parascolastico__acquista"
              label={prodotto.forzatura ? t('Pre-ordina ora') : t('Acquista')}
              variant="primary"
              colorVariant={prodotto.forzatura ? 'darkblue' : ''}
              onClick={() => {
                handleGTM('acquista')
                context.addToCart(
                  prodotto,
                  1,
                  true,
                  'prodotti correlati',
                  null,
                  parascolastico,
                  'prodotti_correlati'
                )
              }}
            />
          ) : (
            parascolastico.link_esterno && (
              <Button
                className="card-parascolastico__acquista"
                label="Acquista"
                variant="primary"
                href={parascolastico.link_esterno}
                external
                newPage
                onClick={() => {
                  handleGTM('acquista')
                }}
              />
            )
          )}
        </div>
        <div className="card-parascolastico__cta-box card-parascolastico__cta-box--second">
          {parascolastico.link_sfogliabile && (
            <Button
              className="card-parascolastico__sfogliabile"
              label={t('Guarda lo sfogliabile')}
              variant="ghost"
              href={parascolastico.link_sfogliabile}
              newPage
              external
              onClick={() => {
                handleGTM('guarda sfogliabile')
              }}
            />
          )}
          <Button
            className="card-parascolastico__dettagli"
            label={t('Vedi dettagli')}
            labelMobile={t('Dettagli')}
            variant="ghost"
            href={parascolastico.url}
            onClick={() => {
              sendProductClickGTM(
                parascolastico,
                parentList ? parentList : 'prodotti_correlati',
                position
              )
              handleGTM('dettagli')
            }}
          />
        </div>
      </div>
    </article>
  )
}

export default CardParascolastico
