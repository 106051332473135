import React from 'react'
import Link from 'next/link'
// import { ArrowRightLong } from '../Icons'

const VoceMenuSecondoLivelloDesktop = (props) => {
  const { label = '', url, className = '', onClick = () => {}, blank = false } = props

  const classes = `secondo-desktop ${className}`

  return url ? (
    <Link legacyBehavior href={url}>
      <a
        className={classes}
        onClick={onClick}
        target={blank ? '_blank' : null}
        rel={blank ? 'nofollow' : null}
      >
        {label}
        {/* <ArrowRightLong /> */}
      </a>
    </Link>
  ) : (
    <span className={classes}>{label}</span>
  )
}

export default VoceMenuSecondoLivelloDesktop
