import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { api } from 'utils'
import { GETISCRIZIONEMODULO, ISCRIZIONEMODULO, MODULOFORMAZIONE } from 'utils/endpoint'
import Login from 'components/atomic/Login'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { useTrans } from 'hooks'
import Icon from 'components/atomic/Icon'
import { Button } from 'react-components/atoms'

const Modal = dynamic(() => import('components/atomic/Modal'))

export const WebinarContext = React.createContext()

const WebinarContextProvider = (props) => {
  const { modulo = undefined } = props
  const { utente } = useSelector((state) => state.utente)
  const t = useTrans()

  const [informazioniModulo, setInformazioniModulo] = useState([])

  const [login, setLogin] = useState(false)
  const [next, setNext] = useState('')
  const [comunicazione, setComunicazione] = useState(false)

  const fetchModulo = () => {
    if (modulo) {
      let params = { idModulo: modulo.id }
      api.get(`${MODULOFORMAZIONE}${GETISCRIZIONEMODULO}`, { params }).then((response) => {
        setInformazioniModulo(response.data)
      })
    }
  }

  useEffect(() => {
    fetchModulo()
  }, [])

  const iscrizioneUtenteModulo = () => {
    if (utente && modulo) {
      let params = { idModulo: modulo.id }
      api.get(`${MODULOFORMAZIONE}${ISCRIZIONEMODULO}`, { params }).then((response) => {
        if (response.data['success']) {
          setInformazioniModulo({ ...informazioniModulo, iscritto: true })
        }
      })
    } else {
      handleComunicazione()
    }
  }

  const handleComunicazione = () => {
    setComunicazione(true)
  }

  const handleLogin = (login, nextPage = '') => {
    if (nextPage) setNext(nextPage)
    setComunicazione(false)
    setLogin(login)
  }

  const handleGTMPopup = (label) => {
    dataLayer.push({
      event: 'GAevent',
      eventID: '25',
      eventCategory: 'registrazione_login_popup',
      eventAction: label.toGTMFormat(),
      eventLabel: 'non_valorizzata',
    })
  }

  const renderComunicazione = () => {
    return (
      <>
        <h3 className="title">
          {!utente
            ? t('Possono iscriversi solo gli utenti registrati.')
            : t('Possono visualizzare i contenuti solo gli utenti iscritti.')}
        </h3>
        <p
          className="disclaimer"
          dangerouslySetInnerHTML={{
            __html: !utente
              ? t(
                  "Registrati gratuitamente e scopri tutti i vantaggi. In regalo per te 30 giorni di prova a IO+, con tanti contenuti in esclusiva e l'accesso alla community dei docenti Giunti Scuola."
                )
              : modulo?.titolo
              ? t(
                  ' Iscriviti a <b>' +
                    modulo?.titolo +
                    '</b> per accedere ai contenuti dedicati e ai suoi webinar.'
                )
              : t(' Iscriviti al modulo per accedere ai contenuti dedicati e ai suoi webinar.'),
          }}
        />
        {!utente ? (
          <Link legacyBehavior href="/registrazione">
            <a
              className="button button--primary cta"
              type="button"
              onClick={() => setComunicazione(false)}
            >
              Registrati ora
            </a>
          </Link>
        ) : !informazioniModulo.iscritto ? (
          <Button
            className="button button--primary cta"
            type="button"
            onClick={() => {
              setComunicazione(false)
              handleGTMPopup('registrazione giuntiscuola')
              iscrizioneUtenteModulo()
            }}
            label={t('Iscriviti')}
          />
        ) : (
          <Button
            variant="checked"
            className="button button--primary cta"
            disabled={true}
            label={
              <div>
                <Icon id="check3" classes="icon--check" />
                {t('Sei iscritto')}
              </div>
            }
          >
            <Icon id="check3" classes="icon--check" />
          </Button>
        )}
        <div
          className="disclaimer"
          dangerouslySetInnerHTML={{
            __html: !utente
              ? t('Registrati ora e accedi a tanti contenuti, <b>sempre aggiornati</b>')
              : t(
                  'Iscriviti ora e accedi a tutti i contenuti del modulo, <b>sempre aggiornati</b>'
                ),
          }}
        />
        {!utente ? (
          <p>
            Sei già registrato?{' '}
            <a
              className="login"
              onClick={() => {
                handleLogin(true)
                handleGTMPopup('login')
              }}
            >
              Accedi
            </a>
          </p>
        ) : (
          <></>
        )}
      </>
    )
  }

  return (
    <WebinarContext.Provider
      value={{
        informazioniModulo,
        iscrizioneUtenteModulo,
        setComunicazione,
      }}
    >
      {props.children}
      {comunicazione && (
        <Modal
          isOpen={comunicazione}
          alert={false}
          close={() => setComunicazione(false)}
          classes="modal__content--wide"
        >
          <div className="modal__content__comunicazione">{renderComunicazione()}</div>
        </Modal>
      )}
      {login && <Login isOpen={login} next={next} setLogin={handleLogin} />}
    </WebinarContext.Provider>
  )
}

export { WebinarContextProvider }
