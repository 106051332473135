import React, { useEffect, useState } from 'react'
import { PlusCircle, Info2 } from 'react-components/atoms/Icons'
import { Button, FormIndirizzoRadio, FormCheckbox, NotificationBar } from 'react-components/atoms'
import { CheckoutSection } from 'react-components/molecules'
import { CheckoutIndirizzoForm } from 'react-components/organisms'
import { useIndirizziFatturazioneQuery } from 'gql/graphql'
import { useTrans } from 'hooks'
import { sendGTMData } from 'utils/gtm'

const FatturazioneCheckout = props => {
  const {
    className = '',
    showIndirizzoFatturazioneForm,
    setShowIndirizzoFatturazioneForm,
    indirizzoFatturazione,
    setIndirizzoFatturazione,
    editIndirizzoFatturazione = false,
    setEditIndirizzoFatturazione = () => {},
    stessoIndirizzoFatturazione,
    setStessoIndirizzoFatturazione = () => {},
    checkDatiFatturazione = () => {},
    hasDatiFatturazione = false,
    indirizzoSpedizioneData,
  } = props

  const t = useTrans()

  const [indirizzi, setIndirizzi] = useState([])
  const [editIndirizzo, setEditIndirizzo] = useState(null)

  const { data: indirizziFatturazione } = useIndirizziFatturazioneQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (indirizziFatturazione?.indirizzi)
      setIndirizzi(indirizziFatturazione.indirizzi.edges.map(({ node }) => node))
  }, [indirizziFatturazione])

  useEffect(() => {
    if (editIndirizzoFatturazione && indirizzoFatturazione && indirizzi.length) {
      setEditIndirizzo(indirizzi.find(i => i.pk === indirizzoFatturazione))
    }
  }, [editIndirizzoFatturazione, indirizzoFatturazione, indirizzi])

  useEffect(() => {
    if (editIndirizzo) {
      setStessoIndirizzoFatturazione(false) // reset
    }
  }, [editIndirizzo])

  useEffect(() => {
    if (indirizzoFatturazione && indirizzi.length) {
      const indirizzoAttuale = indirizzi.find(i => i.pk === indirizzoFatturazione)
      checkDatiFatturazione(indirizzoAttuale)
    }
  })

  function resetForm() {
    setEditIndirizzo(null)
    setShowIndirizzoFatturazioneForm(false)
  }

  const handleGTM = item => {
    sendGTMData({
      event: 'GAevent',
      eventID: '29',
      eventCategory: 'checkout',
      eventAction: 'dati_fatturazione',
      eventLabel: item.toGTMFormat(),
    })
  }

  return (
    <CheckoutSection
      titolo={t('Dati di fatturazione')}
      action={
        !showIndirizzoFatturazioneForm
          ? {
              label: t('Usa un altro indirizzo'),
              icon: <PlusCircle />,
              onClick: () => {
                handleGTM('usa un altro indirizzo')
                setShowIndirizzoFatturazioneForm(true)
              },
            }
          : undefined
      }
      className={`fatturazione-checkout ${className ? ` ${className}` : ''}`}
    >
      <>
        {indirizzi.length === 0 && !showIndirizzoFatturazioneForm && (
          <p className="fatturazione-checkout__empty">{t('Nessun indirizzo di fatturazione')}</p>
        )}
        {indirizzoFatturazione && !hasDatiFatturazione && (
          <NotificationBar
            icon={<Info2 />}
            label={t(
              "<b>CF</b> o <b>PIVA</b> mancanti. Modifica l'indirizzo per precedere all'acquisto."
            )}
            className="checkout-template__notification-bar"
          />
        )}
        {!editIndirizzo && (
          <FormIndirizzoRadio
            id={'indirizzofatturazione'}
            indirizzi={indirizzi}
            value={indirizzoFatturazione}
            onChange={e => setIndirizzoFatturazione(parseInt(e.target.value))}
            onEdit={setEditIndirizzo}
          />
        )}
        {(showIndirizzoFatturazioneForm || editIndirizzo) && (
          <CheckoutIndirizzoForm
            fatturazione
            titolo={editIndirizzo ? t('Modifica indirizzo') : t('Nuovo indirizzo')}
            boxed
            indirizzo={stessoIndirizzoFatturazione ? indirizzoSpedizioneData : editIndirizzo}
            update={!stessoIndirizzoFatturazione}
            onSubmit={() => {
              resetForm()
              setEditIndirizzoFatturazione(false)
              handleGTM('usa questo indirizzo')
            }}
            head={
              <div className="checkout-template__fatturazione-field">
                <FormCheckbox
                  id="checkout__stesso_indirizzo_fatturazione"
                  label={t('Usa gli stessi dati dell’indirizzo di spedizione')}
                  value={1}
                  checked={stessoIndirizzoFatturazione}
                  onChange={() => setStessoIndirizzoFatturazione(!stessoIndirizzoFatturazione)}
                />
              </div>
            }
            actions={
              <>
                <Button variant="ghost" label={t('Annulla')} onClick={resetForm} />
                <Button variant="secondary" label={t('Usa questo indirizzo')} type="submit" />
              </>
            }
          />
        )}
      </>
    </CheckoutSection>
  )
}

export default FatturazioneCheckout
