import React from 'react'
import { AbbonamentoCard } from 'react-components/molecules'

const ListingAbbonamento = props => {
  const { abbonamenti = [], className = '' } = props

  return abbonamenti.length > 0 ? (
    <div className="w-container-inner-small">
      <div className={`listing-abbonamento ${className ? ` ${className}` : ''}`}>
        {abbonamenti.map((item, index) => {
          return <AbbonamentoCard item={item} key={item.id} />
        })}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ListingAbbonamento
