import React from 'react'
import SlickSlider from 'react-slick'

const PagebuilderSlider = ({ editMode = false, children = null }) => {
  const settings = {
    dots: false,
    infinite: false,
    variableWidth: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return editMode ? (
    <section className="wslider-edit">{children}</section>
  ) : (
    <section className="wslider">
      <SlickSlider {...settings}>{children}</SlickSlider>
    </section>
  )
}

export default PagebuilderSlider
