import { UTENTE } from '../utils/endpoint'
import { api } from '../utils'

export const fetchEdit = headers => ({
  type: 'FETCH_EDIT',
  payload: api.get(UTENTE + 'get_edit/', { headers }),
})

export const setRoute = route => ({
  type: 'SET_ROUTE',
  route,
})

export const setItem = item => ({
  type: 'SET_ITEM',
  item,
})

export default function toolbar(
  state = {
    edit: false,
    fetching: false,
    fetched: false,
    error: null,
    item: {},
  },
  action
) {
  switch (action.type) {
    case 'FETCH_EDIT_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_EDIT_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_EDIT_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        edit: action.payload.data,
      }
    }
    case 'SET_ROUTE': {
      return Object.assign({}, state, {
        route: action.route,
      })
    }
    case 'SET_ITEM': {
      return Object.assign({}, state, {
        item: action.item,
      })
    }

    default:
      return state
  }
}
