import React from 'react'
import { Check, Ellipse } from 'react-components/atoms/Icons'

const StickyStepsItem = props => {
  const { label = '', className = '', done = false, active = false, index, count } = props

  const classes = done
    ? 'sticky-steps-item--done'
    : active
    ? 'sticky-steps-item--active'
    : 'sticky-steps-item--disabled'

  return (
    <div className={`sticky-steps-item ${classes}`}>
      {done ? <Check /> : <Ellipse />}
      <span className="sticky-steps-item__label">{label}</span>
      <div className="sticky-steps-item__progress" style={{ width: `${(index * 100) / count}%` }} />
    </div>
  )
}

export default StickyStepsItem
