import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function LightBulb({ className }) {
  return (
    <IconBase name="light-bulb" w={20} className={className}>
      <path
        d="M7.50033 17.5C7.50033 17.9167 7.83366 18.3333 8.33366 18.3333H11.667C12.167 18.3333 12.5003 17.9167 12.5003 17.5V16.6667H7.50033V17.5ZM10.0003 1.66666C6.75033 1.66666 4.16699 4.24999 4.16699 7.49999C4.16699 9.49999 5.16699 11.25 6.66699 12.25V14.1667C6.66699 14.5833 7.00033 15 7.50033 15H12.5003C13.0003 15 13.3337 14.5833 13.3337 14.1667V12.25C14.8337 11.1667 15.8337 9.41666 15.8337 7.49999C15.8337 4.24999 13.2503 1.66666 10.0003 1.66666Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default LightBulb
