import React from 'react'
import { FormError } from 'react-components/atoms'
import { RadioUnmarked, RadioMarked } from 'react-components/atoms/Icons'

const FormRadioOption = props => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    size = 'md',
    checked = false,
    className = '',
    readOnly = false,
    disabled = false,
    onClick = () => {},
  } = props

  const classNames = `
    form-radio-option 
    form-radio-option--${size}
    ${checked ? ' form-radio-option--checked' : ''}
    ${readOnly ? ' form-radio-option--readonly' : ''}
    ${disabled ? ' form-radio-option--disabled' : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className="form-radio-option__input-wrap">
        <input
          id={id}
          checked={checked}
          name={name}
          value={value}
          className="form-radio-option__input"
          type="radio"
          readOnly={readOnly}
          disabled={disabled}
          onClick={onClick}
        />
        <span className="form-radio-option__styledinput">
          {checked ? <RadioMarked /> : <RadioUnmarked />}
        </span>
        <span className="form-radio-option__label">{label}</span>
      </label>
    </div>
  )
}

const FormRadio = props => {
  const {
    id = '',
    name = '',
    value = null,
    size = 'md',
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onChange = () => {},
    options = [],
  } = props

  const onOptionClick = e => {
    if (!e.target.disabled && !e.target.readOnly) onChange(e)
  }

  const classNames = `
    form-radio 
    form-radio--${size}
    ${disabled ? ' form-checkbox--disabled' : ''}
    ${status ? ` form-checkbox--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <div className="form-radio__options">
        {options?.map(option => (
          <FormRadioOption
            id={`${id}-${option.value}`}
            name={name}
            label={option.label}
            value={option.value}
            size={option.size || size}
            checked={option.value === value}
            onClick={onOptionClick}
            readOnly={readOnly || option.readOnly}
            disabled={disabled || option.disabled}
            key={`formradiooption-${id}-${option.value}`}
          />
        ))}
      </div>
      <FormError
        className="form-radio__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormRadio
