import Icon from './Icon'
import React from 'react'
import { useTrans } from 'hooks'

const Ioplus = ({ tooltip = null, horizontal = false }) => (
  <div className={`ioplus ${horizontal ? 'ioplus--horizontal' : ''}`}>
    <Icon id="ioplus" tooltip={tooltip} />
  </div>
)

export const IoplusBar = ({ white = false }) => {
  const t = useTrans()

  return (
    <div className={`ioplusbar ${white ? 'ioplusbar--white' : ''}`}>
      <Ioplus />
      <p className="ioplusbar__text">{t('Contenuto riservato agli abbonati io+')}</p>
    </div>
  )
}

export default Ioplus
