import React, { useState } from 'react'
import FormazioneWebinarCard from '../../molecules/FormazioneWebinarCard/FormazioneWebinarCard'
import { ArrowDown2, ArrowUp2 } from 'react-components/atoms/Icons'

const ListingModuliWebinar = (props) => {
  const {
    webinars = [],
    titolo = '',
    sottotitolo = '',
    withBorder = false,
    ultimoContenuto = null,
  } = props
  const [open, setOpen] = useState(true)
  return webinars.length > 0 || ultimoContenuto ? (
    <div
      className={`corso-formazione-modulo__container-moduli ${
        withBorder ? 'corso-formazione-modulo__container-moduli--border' : ''
      }`}
    >
      <div className="corso-formazione-modulo__container-moduli__titolo">{titolo}</div>
      {sottotitolo ? (
        <div className="corso-formazione-modulo__container-moduli__container">
          <div className="corso-formazione-modulo__container-moduli__sottotitolo">
            <h3 className="corso-formazione-modulo__container-moduli__sottotitolo__text">
              {sottotitolo}
            </h3>
            <button
              className="corso-formazione-modulo__container-moduli__sottotitolo__trigger"
              onClick={() => setOpen(!open)}
            >
              <span className="prodotti-cart-accordion__arrow">
                {open ? <ArrowDown2 /> : <ArrowUp2 />}
              </span>
            </button>
          </div>
        </div>
      ) : (
        <div className="corso-formazione-modulo__container-moduli__spacing"></div>
      )}
      <div className="corso-formazione-modulo__container-moduli__listing">
        {open && ultimoContenuto && (
          <FormazioneWebinarCard item={ultimoContenuto} key={ultimoContenuto.id} />
        )}
        {open ? (
          webinars.map((item, index) => {
            return <FormazioneWebinarCard item={item} key={item.id} />
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ListingModuliWebinar
