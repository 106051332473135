import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function ArrowUp({ className }) {
  return (
    <IconBase name="arrow-right-long" w={30} h={30} className={className}>
      <path d="M5.43205 10.084C4.8327 10.6744 4.8327 11.6412 5.43205 12.2317C6.01842 12.8094 6.95978 12.8099 7.54678 12.2329L13.4774 6.40284L13.4774 29.1353C13.4774 29.9762 14.159 30.6578 14.9999 30.6578C15.8408 30.6578 16.5225 29.9762 16.5225 29.1353L16.5225 6.40284L22.4461 12.2387C23.0362 12.8201 23.9842 12.8184 24.5722 12.235C25.1698 11.6421 25.1681 10.6753 24.5684 10.0845L16.4036 2.04066C15.625 1.27363 14.3749 1.27363 13.5963 2.04066L5.43205 10.084Z" />
    </IconBase>
  )
}

export default ArrowUp
