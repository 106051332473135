import React from 'react'
import { useTrans } from 'hooks'
import { LogoSymbol } from 'react-components/atoms/Icons'
import { ImageBox } from 'react-components/atoms'

const AutoreCard = props => {
  const {
    autore = {
      main_image: '',
      avatar: '',
      nome: '',
      cognome: '',
      titolo: '',
      headline: '',
      redazionale: false,
    },
    className = '',
    noUrl = false,
  } = props

  const t = useTrans()

  const thumb = autore.main_image ? autore.main_image : autore.avatar ? autore.avatar : null
  const nome_cognome = autore.titolo ? autore.titolo : autore.nome + ' ' + autore.cognome

  if (noUrl)
    return (
      <div className={`wautore-card ${className ? ` ${className}` : ''}`}>
        <ImageBox
          src={thumb}
          maxWidth={164}
          maxHeight={157}
          alt={nome_cognome?.toAltTitle()}
          title={nome_cognome?.toAltTitle()}
          lazyLoad
          classPrefix="wautore-card"
        />
        <div className="wautore-card__content">
          <p className="wautore-card__fullname">
            {autore.nome?.capitalize()} {autore.cognome?.capitalize()}
          </p>
          <p className="wautore-card__type">{autore.headline}</p>
          {autore.redazionale && (
            <div className="wautore-card__redazione">
              <LogoSymbol />
              <span>{t('Redazione GiuntiScuola')}</span>
            </div>
          )}
        </div>
      </div>
    )

  return (
    <a className={`wautore-card ${className ? ` ${className}` : ''}`} href={autore.url}>
      <ImageBox
        src={thumb}
        maxWidth={164}
        maxHeight={157}
        alt={nome_cognome?.toAltTitle()}
        title={nome_cognome?.toAltTitle()}
        lazyLoad
        classPrefix="wautore-card"
      />
      <div className="wautore-card__content">
        <p className="wautore-card__fullname">
          {autore.nome?.capitalize()} {autore.cognome?.capitalize()}
        </p>
        <p className="wautore-card__type">{autore.headline}</p>
        {autore.redazionale && (
          <div className="wautore-card__redazione">
            <LogoSymbol />
            <span>{t('Redazione GiuntiScuola')}</span>
          </div>
        )}
      </div>
    </a>
  )
}

export default AutoreCard
