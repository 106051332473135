import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { TRADUZIONI } from 'utils/endpoint'
import { api } from 'utils'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const replaceSostituzioni = (value, sostituzioni) => {
  let result = value
  if (sostituzioni?.length) {
    result = Object.values(sostituzioni).reduce(
      (prev, sost, i) => prev.replace(`{${i}}`, sost),
      value
    )
  }
  return result
}

export default function useTrans() {
  const { traduzioni } = useSelector(state => state.traduzioni)
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true
  }, [traduzioni])

  useEffect(() => {
    mounted.current = true
  }, [])

  const getTraduzione = (chiave, sostituzioni) => {
    let result = ''
    if (!chiave) {
      return ''
    }
    try {
      const { valore } = traduzioni.find(t => t.chiave === chiave)
      result = replaceSostituzioni(valore || chiave, sostituzioni)
    } catch (error) {
      api
        .post(TRADUZIONI, { chiave })
        .then(() => console.log('Inserita Chiave', chiave))
        .catch(error => console.log(error))
      result = replaceSostituzioni(chiave, sostituzioni)
    }
    return result
  }

  return getTraduzione
}
