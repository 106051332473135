import React from 'react'
import { Close } from 'react-components/atoms/Icons'

const FormazioneTag = props => {
  const { label = '', selected = false, onClick = undefined, className = '' } = props

  const classNames = `formazione-tag ${selected ? ' formazione-tag--selected' : ''} ${
    className ? ` ${className}` : ''
  }`

  return label ? (
    onClick ? (
      <button className={`${classNames} formazione-tag--clickable`} onClick={onClick}>
        <span className="formazione-tag__label">{label}</span>
        {selected && (
          <span className="formazione-tag__close">
            <Close />
          </span>
        )}
      </button>
    ) : (
      <div className={classNames}>
        <span className="formazione-tag__label">{label}</span>
      </div>
    )
  ) : (
    <></>
  )
}

export default FormazioneTag
