import React from 'react'
import { useTrans } from 'hooks'
const BadgeTag = props => {
  const { title = '', version = null } = props
  const t = useTrans()
  return (
    <span className={`badgetag__tag ${version ? 'badgetag__tag--' + version : ''}`}>
      {t(title)}
    </span>
  )
}

export default BadgeTag
