import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Check({ className }) {
  return (
    <IconBase name="cat-scheda" w={16} className={className}>
      <path
        d="M6.28571 12.3333L2 8.01472L3.20857 6.79687L6.28571 9.889L12.7914 3.33334L14 4.55982L6.28571 12.3333Z"
        //  fill="#024598"
      />
    </IconBase>
  )
}

export default Check
