import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function LandingRivisteSi({ className }) {
  return (
    <IconBase name="landing-riviste-si" w={244} h={235} className={className}>
      <path
        d="M9.38522 204.064C6.54551 201.888 4.24885 199.083 2.67597 195.87C1.10308 192.657 0.296666 189.123 0.320076 185.545C0.322391 181.332 1.49693 177.202 3.71223 173.617C5.92752 170.033 9.09633 167.135 12.8642 165.249C16.632 163.362 20.8505 162.561 25.0476 162.935C29.2447 163.309 33.255 164.843 36.6302 167.365C53.2327 179.811 71.3877 187.399 92.673 187.399C112.368 187.399 124.25 179.423 124.25 166.351V165.663C124.25 153.229 116.738 146.881 80.1523 137.266C36.0292 125.746 7.53203 113.313 7.53203 68.9399V68.364C7.53203 27.8218 39.4475 1.00244 84.1966 1.00244C110.766 1.00244 133.904 8.02657 153.624 20.7977C156.935 22.8414 159.658 25.7105 161.526 29.1237C163.394 32.5369 164.343 36.3771 164.279 40.2674C164.314 43.2439 163.756 46.1974 162.638 48.956C161.519 51.7145 159.863 54.2228 157.765 56.3345C155.667 58.4463 153.17 60.1193 150.419 61.2559C147.668 62.3926 144.718 62.9702 141.742 62.955C137.424 62.9599 133.194 61.7352 129.547 59.4242C113.608 49.8333 98.5704 44.3993 83.5705 44.3993C65.0774 44.3993 55.3864 52.9885 55.3864 63.8565V64.47C55.3864 79.1443 64.7769 83.9272 102.627 93.8562C147.051 105.688 172.117 121.965 172.117 160.917V161.556C172.117 205.929 138.925 230.858 91.6714 230.858C62.0943 230.859 33.2823 221.458 9.3976 204.014"
        fill="currentColor"
        stroke="none"
      />
      <path
        d="M196.495 94.9709C196.495 82.4502 207.012 72.4336 220.009 72.4336C233.005 72.4336 243.535 82.5253 243.535 94.9709V212.453C243.535 224.974 233.005 234.99 220.009 234.99C207.012 234.99 196.495 224.899 196.495 212.453V94.9709Z"
        fill="currentColor"
        stroke="none"
      />
      <path
        d="M218.632 50.6725C223.545 50.6725 228.348 49.2153 232.434 46.4851C236.519 43.755 239.703 39.8746 241.583 35.3348C243.463 30.7949 243.954 25.7996 242.994 20.9805C242.035 16.1615 239.668 11.7352 236.192 8.26162C232.717 4.78802 228.29 2.42312 223.47 1.46604C218.65 0.508949 213.655 1.00267 209.117 2.88476C204.578 4.76685 200.699 7.95274 197.971 12.0395C195.243 16.1262 193.788 20.9303 193.79 25.8439C193.79 29.1055 194.433 32.3351 195.682 35.3483C196.93 38.3614 198.76 41.0991 201.067 43.4048C203.374 45.7105 206.112 47.5391 209.126 48.7861C212.14 50.0332 215.37 50.6741 218.632 50.6725"
        fill="currentColor"
        stroke="none"
      />
    </IconBase>
  )
}

export default LandingRivisteSi
