import React from 'react'

const DisciplinaCard = props => {
  const {
    disciplina = {
      color: '',
      titolo: '',
    },
    className = '',
    noUrl = false,
  } = props

  if (noUrl)
    return (
      <div
        className={`disciplina-card ${className ? ` ${className}` : ''}`}
        style={disciplina.color ? { backgroundColor: `#${disciplina.color}` } : {}}
      >
        <p className="disciplina-card__title">{disciplina.titolo}</p>
      </div>
    )

  return (
    <a
      className={`disciplina-card ${className ? ` ${className}` : ''}`}
      style={disciplina.color ? { backgroundColor: `#${disciplina.color}` } : {}}
      href={disciplina.url}
    >
      <p className="disciplina-card__title">{disciplina.titolo}</p>
    </a>
  )
}

export default DisciplinaCard
