import React, { useState, useEffect } from 'react'
import { UTENTE, ARTICOLI } from 'utils/endpoint'
import { slugify, api } from 'utils'
import { BoxElementiRivista } from 'react-components/organisms'
import { renderCardWithProps } from 'react-components/organisms/AreaPersonaleRaccoltaContenutiEditForm/AreaPersonaleRaccoltaContenutiEditForm'
import { useTrans } from 'hooks'

const SezioneRivista = props => {
  const { sezione, rivista, className = '' } = props

  if (!sezione) return null

  const t = useTrans()

  const [risorse, setRisorse] = useState([])

  useEffect(() => {
    initRisorse()
  }, [])

  useEffect(() => {
    if (risorse.length) checkTools()
  }, [risorse.length])

  async function initRisorse() {
    const params = {
      in_rivista: true,
      numero_rivista__rivista_id: rivista.id,
      attributi__valore_attributo_id: sezione.valore_attributo,
    }
    const response = await api.get(ARTICOLI, { params })
    const risorse = response.data.results
    setRisorse(risorse)
  }

  function checkTools() {
    const params = risorse.map(({ id, model }) => ({
      content_type_id: model,
      object_id: id,
    }))

    api.get(UTENTE + 'tools/', { params }).then(response => {
      const tmp = risorse.map(item => {
        const current = response.data.find(
          e => e.content_type_id === item.model && e.object_id === item.id
        )

        const { saved, following, file_url, pdf_file } = current

        return { extra: { ...item.extra, file_url, pdf_file }, saved, following, ...item }
      })

      setRisorse(tmp)
    })
  }

  return (
    <div
      id={slugify(sezione.titolo)}
      className={`sezione-rivista ${className ? ` ${className}` : ''}`}
    >
      <BoxElementiRivista
        className="sezione-rivista__box"
        elementi={risorse.slice(0, 4).map((risorsa, index) => (
          <>{renderCardWithProps(risorsa, { position: index + 1 })}</>
        ))}
        titolo={sezione.titolo}
        sottotitolo={sezione.testo}
        ctaLabel={t('Scopri di più')}
        ctaLink={`${rivista.url}${rivista.slug}/risorse?filtri_id=${sezione.valore_attributo}`}
      />
    </div>
  )
}

export default SezioneRivista
