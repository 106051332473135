import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function DashboardCustomize({ className }) {
  return (
    <IconBase name="dashboard-customize" w={24} className={className}>
      <path
        d="M3 5C3 3.89543 3.89543 3 5 3H9C10.1046 3 11 3.89543 11 5V9C11 10.1046 10.1046 11 9 11H5C3.89543 11 3 10.1046 3 9V5ZM13 5C13 3.89543 13.8954 3 15 3H19C20.1046 3 21 3.89543 21 5V9C21 10.1046 20.1046 11 19 11H15C13.8954 11 13 10.1046 13 9V5ZM3 15C3 13.8954 3.89543 13 5 13H9C10.1046 13 11 13.8954 11 15V19C11 20.1046 10.1046 21 9 21H5C3.89543 21 3 20.1046 3 19V15ZM18 13H16V16H13V18H16V21H18V18H21V16H18V13Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default DashboardCustomize
