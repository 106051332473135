import React from 'react'
import { useTrans } from 'hooks'
import { Image } from 'react-components/atoms'

const FormazioneDocenteBanner = (props) => {
  const { docente = undefined } = props
  const t = useTrans()
  return docente ? (
    <div className="formazione-docente-banner">
      <div className="formazione-docente-banner__immagine-profilo">
        <Image
          src={docente?.immagine ? docente.immagine : 'null'}
          width={50}
          height={50}
          alt={`${docente?.nome} ${docente?.cognome}`.toAltTitle()}
          title={`${docente?.nome} ${docente?.cognome}`.toAltTitle()}
        />
      </div>
      <div className="formazione-docente-banner__docente-dati">
        <p className="formazione-docente-banner__docente-dati__titolo">{t('Relatore')}</p>
        <div className="formazione-docente-banner__docente-dati__nome-cognome">
          <p>
            {docente?.nome} {docente?.cognome}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default FormazioneDocenteBanner
