import React, { useContext } from 'react'
import { useTrans } from 'hooks'
import { Button } from 'react-components/atoms'
import { LOGIN_URL } from 'utils'
import Icon from 'components/atomic/Icon'
import { Download } from 'react-components/atoms/Icons'
import { useRouter } from 'next/router'
import { WebinarContext } from 'utils/webinarContext'
import { formatDate } from '../../../utils'

const FormazioneModuloIscrizione = (props) => {
  const { item = undefined } = props
  const t = useTrans()
  const router = useRouter()
  const { informazioniModulo, iscrizioneUtenteModulo } = useContext(WebinarContext)

  const handleGTM = (label) => {
    dataLayer.push({
      event: 'GAevent',
      eventID: '34',
      eventCategory: 'formazione_pagina_dettaglio',
      eventAction: label.toGTMFormat(),
      eventLabel: item.titolo.toGTMFormat(),
      moreInfo: item.in_corso ? 'moduli_in_corso' : 'moduli_conclusi',
    })
  }

  const handleLogin = () => {
    router.replace(`${LOGIN_URL}?next=${router.asPath}`)
  }

  const handleNewsLetter = () => {
    router.replace(`https://gruppogiunti.magnewsemail.com/nl/gruppogiunti_page1637.mn`)
  }

  const LABELS = {
    presenza: {
      iscrizioneAncoraValida: t('Le iscrizioni termineranno il - EventoFormazione'),
      prossimoIncontro: t('Prossimo incontro il '),
      inizioCorso: t('Il corso si terrà il '),
    },
    online: {
      iscrizioneAncoraValida: t('Le iscrizioni termineranno il '),
      prossimoIncontro: t('Prossimo webinar il '),
      inizioCorso: t('Il corso inizierà il '),
    },
  }

  return (
    <div className="formazione-modulo-iscrizione">
      {item.link_esterno ? (
        <div className="formazione-modulo-iscrizione__button formazione-modulo-iscrizione__button--external">
          <Button label={t('Scopri di più')} href={item.link_esterno} newPage external />
        </div>
      ) : (
        <>
          <div className="formazione-modulo-iscrizione__button">
            {/* // Siamo ancora nel range in cui possiamo iscriverci */}
            {new Date(item.termine_iscrizione) > new Date() ? (
              informazioniModulo['iscritto'] ? (
                <Button
                  variant="checked"
                  label={
                    <div>
                      <Icon id="check3" classes="icon--check" />
                      {t('Sei iscritto')}
                    </div>
                  }
                >
                  <Icon id="check3" classes="icon--check" />
                </Button>
              ) : (
                //Se non è iscritto e c'è almeno un webinar non concluso allora mostro bottone di iscrizione
                <Button
                  label={t('Iscriviti')}
                  onClick={() => {
                    iscrizioneUtenteModulo(item.id)
                    handleGTM(t('Iscriviti'))
                  }}
                />
              )
            ) : (
              //Non ci sono più webinar futuri -> concluso
              <>
                <Button variant="squared" label={t('Il corso è terminato')} />
                {informazioniModulo['link_certificato'] && (
                  <div className="formazione-corso-concluso-card__cta-box">
                    <Button
                      label={t('Scarica certificato')}
                      icon={<Download />}
                      iconPos="right"
                      href={informazioniModulo['link_certificato']}
                      external
                      download
                    />
                  </div>
                )}
              </>
            )}
          </div>

          <div className="formazione-modulo-iscrizione__data">
            {new Date(item.termine_iscrizione) > new Date() ? (
              informazioniModulo['iscritto'] ? (
                <>
                  <p>
                    {item.webinars_conclusi.length > 0 || item.ultimo_contenuto
                      ? item.webinars_in_corso.length > 0
                        ? LABELS[item.tipologia].prossimoIncontro
                        : t('Il modulo è concluso')
                      : LABELS[item.tipologia].inizioCorso}
                    <span>
                      {item.webinars_in_corso.length > 0 ? item.prossimo_webinar_data : <></>}
                    </span>
                  </p>
                </>
              ) : (
                <>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: LABELS[item.tipologia].iscrizioneAncoraValida,
                    }}
                  ></div>
                </>
              )
            ) : (
              //Non ci sono più webinar futuri -> concluso
              <>
                {!informazioniModulo['link_certificato'] ? (
                  informazioniModulo['loggato'] ? (
                    <>
                      <p>
                        <a onClick={handleNewsLetter}>{t('Iscriviti alla newsletter')}</a>
                        {t(' per non perderti la prossima edizione')}
                      </p>
                    </>
                  ) : (
                    <>
                      <div className="formazione-modulo-iscrizione__data--box-guest">
                        <span>{t('Hai partecipato al corso?')}</span>
                        <p>
                          <a onClick={handleLogin}>{t('Effettua il login')}</a>
                          {t(' per scaricare il certificato e i contenuti associati al corso')}
                        </p>
                      </div>
                    </>
                  )
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default FormazioneModuloIscrizione
