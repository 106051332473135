import { useEffect, useState } from 'react'

import { useTrans } from 'hooks'
import { DISCIPLINE, ELEMENTOSEGUITO } from 'utils/endpoint'
import { api } from 'utils'
import { Button, SelectionButton } from 'react-components/atoms'
import { DisciplinaCard } from 'react-components/molecules'

const FormToggleDiscipline = ({
  setDisciplineFromProps,
  afterSubmit,
  setEdit,
  selectedFromProps,
}) => {
  const [discipline, setDiscipline] = useState([])
  const [selected, setSelected] = useState([...selectedFromProps?.map(i => i?.id)])
  const t = useTrans()

  useEffect(() => {
    // Fetch Discipline
    api.get(DISCIPLINE).then(res => setDiscipline(res?.data))
    return () => {
      onClose()
    }
  }, [])

  const handleSelected = id => {
    selected?.includes(id)
      ? setSelected(prev => prev?.filter(item => item !== id))
      : setSelected(prev => [...prev, id])
  }

  const onClose = () => {
    setSelected([])
    afterSubmit?.()
  }

  const _handleSubmit = () => {
    api.post(`${ELEMENTOSEGUITO}bulk_create/`, {
      content_type_id: discipline[0].model,
      object_ids: selected,
    })
    setDisciplineFromProps?.([...discipline?.filter(i => selected?.includes(i?.id))])
    setEdit(false)
    onClose()
  }

  return (
    <div className="form-toggle-discipline">
      <div className="form-toggle-discipline__list">
        {discipline?.map(disciplina => (
          <div className="form-toggle-discipline__item">
            <SelectionButton
              visible
              selected={selected?.includes(disciplina?.id)}
              setSelected={() => handleSelected(disciplina?.id)}
            />
            <DisciplinaCard disciplina={disciplina} noUrl />
          </div>
        ))}
      </div>
      <div className="form-toggle-discipline__actions">
        <Button label={t('Salva le modifiche')} onClick={() => _handleSubmit()} />
        <Button label={t('Annulla modifiche')} variant="ghost" onClick={() => setEdit(false)} />
      </div>
    </div>
  )
}

export default FormToggleDiscipline
