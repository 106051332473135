import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import SlickSlider from 'react-slick'
import { ImageBox, Button } from 'react-components/atoms'

import { useSelector } from 'react-redux'
import { useIsMobile } from 'hooks/useIsMobile'
import { CAS_DOMAIN } from 'utils'

const WidgetSlider = props => {
  const { slides = [], className = '' } = props

  const isMobile = useIsMobile()

  const [items, setItems] = useState(slides)
  const { menu } = useSelector(state => state.menu)

  useEffect(() => {
    initItems()
  }, [])

  function initItems() {
    const newMenu = menu?.find(m => m.key === 'home-new')
    const sliderMenu = newMenu?.children?.find(child => child.key === 'home-slider')
    if (sliderMenu)
      setItems(
        sliderMenu.children?.map(child => ({ ...child, link: child.final_url, title: child.name }))
      )
  }

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 4000,
    dots: false,
    responsive: [
      {
        breakpoint: 575,
        settings: {
          arrows: false,
          dots: true,
        },
      },
    ],
  }

  if (!items.length) return null

  return (
    <div className={`widget-slider ${className ? ` ${className}` : ''}`}>
      <div className="widget-slider__slider">
        <SlickSlider {...sliderSettings}>
          {items.map((item, i) => (
            <div className="widget-slider__slide" key={i}>
              <div
                className="widget-slider__slide__background"
                style={{
                  backgroundImage: `url(${CAS_DOMAIN +
                    (isMobile ? item.mobile_image : item.tablet_image)})`,
                }}
              >
                <div className="widget-slider__slide__container">
                  <div
                    className="widget-slider__slide__content"
                    style={{
                      color: item?.text_color ? `#${item.text_color}` : undefined,
                    }}
                  >
                    <p
                      className="widget-slider__slide__title"
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                    <p
                      className="widget-slider__slide__description"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                    <div
                      className={`widget-slider__slide__cta-box ${
                        item.name || item.desciption ? '' : 'bottom'
                      }`}
                    >
                      <Button
                        label={item?.cta?.length > 0 ? item?.cta : 'Scopri di più'}
                        inverted
                        href={item.final_url || item.link}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </SlickSlider>
      </div>
    </div>
  )
}

export default WidgetSlider
