import { useEffect, useState } from 'react'

export default function useIsClient(initialValue) {
  const [clientSide, setClientSide] = useState(initialValue)

  useEffect(() => {
    handleClientSide()
  }, [])

  function handleClientSide() {
    setClientSide(true)
  }

  return clientSide
}
