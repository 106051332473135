import React, { useState } from 'react'
import { Formik, validateYupSchema, yupToFormErrors } from 'formik'
import * as yup from 'yup'
import { useScuolaUtils, useTrans } from 'hooks'
import { getFormFieldStatus } from 'components/forms-utils/utils'
import {
  FormLayout,
  FormField,
  FormInput,
  FormSelect,
  AreaPersonaleDataField,
  AreaPersonaleDataFieldLayout,
} from 'react-components/atoms'
import { AreaPersonaleEditCard } from 'react-components/molecules'
import { altro } from 'components/registrazione/utils'

import { useRouter } from 'next/router'
import { updatePopupConfermaScuola } from '../PopupConfermaScuola/PopupConfermaScuola'

const AreaPersonaleScuolaEditCard = (props) => {
  const router = useRouter()

  const {
    scuola = {
      nome: '',
      citta: '',
      cap: '',
      provincia: '',
      regione: '',
      classe: '',
    },
    className = '',
    idToUpdate,
    ItaliaJSON,
    editModeFromProps,
    isPopupConfermaScuola = false,
  } = props

  const {
    ProvinciaItaliane,
    CittaItaliane,
    Cap,
    setCap,
    Scuole,
    getScuole,
    handleScuolaSubmit,
    checkEstero,
    checkNessunaDellePrecedenti,
  } = useScuolaUtils(false)
  const [editMode, setEditMode] = useState(editModeFromProps || false)
  const t = useTrans()

  const validationSchema = yup.object().shape({
    scuola: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    citta: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    cap: yup.string().nullable(),
    provincia: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    regione: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    classe: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    sezione: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
  })

  const validationSchemaEstero = yup.object().shape({
    scuola: yup.string().nullable(),
    citta: yup.string().nullable(),
    cap: yup.string().nullable(),
    provincia: yup.string().nullable(),
    regione: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    classe: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    sezione: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
  })

  const getSchema = (values) => {
    if (values.regione && values.regione === altro) {
      return validationSchemaEstero
    } else {
      return validationSchema
    }
  }

  const classiOptions = [
    { label: '1', value: '1' },
    { label: '2', value: '2' },
    { label: '3', value: '3' },
    { label: '4', value: '4' },
    { label: '5', value: '5' },
  ]

  return (
    <div className={`area-personale-scuola-edit-card ${className ? ` ${className}` : ''}`}>
      <Formik
        isInitialValid={false}
        initialValues={{
          scuola: scuola?.scuola || '',
          citta: scuola?.citta || '',
          cap: scuola?.cap || '',
          provincia: scuola?.provincia || '',
          regione: scuola?.regione || '',
          classe: scuola?.classe || '',
          sezione: scuola?.sezione || '',
        }}
        validate={(values) => {
          try {
            const schema = getSchema(values)
            validateYupSchema(values, schema, true, values)
          } catch (err) {
            return yupToFormErrors(err) //for rendering validation errors
          }

          return {}
        }}
        onSubmit={(values) => {
          handleScuolaSubmit(
            values,
            isPopupConfermaScuola
              ? async () => {
                  await updatePopupConfermaScuola()
                    .then((res) => {
                      console.log(res)
                      router?.reload()
                    })
                    .catch((err) => console.error(err, 'edit from ispopuoscuola'))
                }
              : () => router?.reload(),
            false,
            idToUpdate
          )
        }}
      >
        {({
          values,
          touched,
          errors,
          handleBlur,
          handleChange,
          setFieldValue,
          handleSubmit,
          resetForm,
          isValid,
        }) => {
          return (
            <AreaPersonaleEditCard
              editMode={editMode}
              setEditMode={setEditMode}
              onSubmit={handleSubmit}
              onCancel={() => resetForm()}
              disabled={!isValid}
            >
              {editMode ? (
                <>
                  <FormLayout>
                    <FormField
                      label={`${t('Regione')}*`}
                      status={getFormFieldStatus({ touched, errors }, 'regione')}
                      errorMessage={errors?.regione}
                    >
                      <FormSelect
                        value={values.regione}
                        options={ItaliaJSON}
                        onBlur={handleBlur('regione')}
                        onChange={(selectedOption) => {
                          setFieldValue('provincia', null)
                          setFieldValue('citta', null)
                          setFieldValue('cap', null)
                          setFieldValue('scuola', null, true)
                          setFieldValue('scuola_id', null, true)
                          setFieldValue('regione', selectedOption.value, true)
                          setFieldValue('classe', null, true)
                        }}
                        status={getFormFieldStatus({ touched, errors }, 'regione')}
                        searchable
                      />
                    </FormField>
                    <FormField
                      label={`${t('Provincia')}${checkEstero(values) ? '' : '*'}`}
                      status={getFormFieldStatus({ touched, errors }, 'provincia')}
                      errorMessage={errors?.provincia}
                    >
                      {checkEstero(values) ? (
                        <FormInput
                          value={values?.provincia}
                          onBlur={handleBlur('provincia')}
                          onChange={handleChange('provincia')}
                          status={getFormFieldStatus({ touched, errors }, 'provincia')}
                        />
                      ) : (
                        <FormSelect
                          value={values.provincia || ''}
                          options={values?.regione ? ProvinciaItaliane(values?.regione) : []}
                          onBlur={handleBlur('provincia')}
                          onChange={(selectedOption) => {
                            setFieldValue('citta', null)
                            setFieldValue('cap', null)
                            setFieldValue('scuola', null, true)
                            setFieldValue('scuola_id', null, true)

                            setFieldValue('provincia', selectedOption.value, true)
                          }}
                          status={getFormFieldStatus({ touched, errors }, 'provincia')}
                          searchable
                        />
                      )}
                    </FormField>
                    <FormField
                      label={`${t('Città')}${checkEstero(values) ? '' : '*'}`}
                      status={getFormFieldStatus({ touched, errors }, 'citta')}
                      errorMessage={errors?.citta}
                    >
                      {checkEstero(values) ? (
                        <FormInput
                          value={values?.citta}
                          onBlur={handleBlur('citta')}
                          onChange={handleChange('citta')}
                          status={getFormFieldStatus({ touched, errors }, 'citta')}
                        />
                      ) : (
                        <FormSelect
                          options={values?.provincia ? CittaItaliane(values?.provincia) : []}
                          value={values.citta || ''}
                          onBlur={handleBlur('citta')}
                          onChange={(selectedOption) => {
                            setFieldValue('cap', null, true)
                            getScuole(selectedOption?.label)
                            setFieldValue('scuola', null, true)
                            setFieldValue('scuola_id', null, true)

                            setCap(selectedOption?.cap?.flatMap((cp) => ({ label: cp, value: cp })))
                            setFieldValue('citta', selectedOption?.label, true)
                          }}
                          status={getFormFieldStatus({ touched, errors }, 'citta')}
                          searchable
                        />
                      )}
                    </FormField>
                    <FormField
                      label={`${t('CAP')}`}
                      status={getFormFieldStatus({ touched, errors }, 'cap')}
                      errorMessage={errors?.cap}
                    >
                      {checkEstero(values) ? (
                        <FormInput
                          value={values?.cap}
                          onBlur={handleBlur('cap')}
                          onChange={handleChange('cap')}
                          status={getFormFieldStatus({ touched, errors }, 'cap')}
                        />
                      ) : (
                        <FormSelect
                          options={values?.citta ? Cap ?? [] : []}
                          value={values.cap || ''}
                          onBlur={handleBlur('cap')}
                          onChange={(selectedOption) => {
                            setFieldValue('scuola', null, true)
                            setFieldValue('cap', selectedOption?.label, true)
                            getScuole(null, selectedOption?.value)
                          }}
                          status={getFormFieldStatus({ touched, errors }, 'cap')}
                          searchable
                        />
                      )}
                    </FormField>
                    <FormField
                      label={`${t('Scuola di appartenenza')}${checkEstero(values) ? '' : '*'}`}
                      status={getFormFieldStatus({ touched, errors }, 'scuola')}
                      errorMessage={errors?.scuola}
                      helper={t('Seleziona una scuola corrispondente al cap inserito')}
                    >
                      {checkEstero(values) ? (
                        <FormInput
                          value={values?.scuola}
                          onBlur={handleBlur('scuola')}
                          onChange={handleChange('scuola')}
                          status={getFormFieldStatus({ touched, errors }, 'scuola')}
                        />
                      ) : (
                        <FormSelect
                          value={values.scuola}
                          options={values?.citta ? Scuole ?? [] : []}
                          onBlur={handleBlur('scuola')}
                          onChange={(selectedOption) => {
                            setFieldValue('scuola', selectedOption.value, true)
                            setFieldValue('scuola_id', selectedOption?.scuola_id)
                          }}
                          status={getFormFieldStatus({ touched, errors }, 'scuola')}
                          searchable
                        />
                      )}
                    </FormField>
                    {checkNessunaDellePrecedenti(values) ? (
                      <FormField
                        label={`${t('Nome della scuola')}${checkEstero(values) ? '' : '*'}`}
                        status={getFormFieldStatus({ touched, errors }, 'nuova')}
                        errorMessage={errors?.nuova}
                        helper={t('Inserisci il nome della scuola')}
                      >
                        <FormInput
                          value={values?.nuova}
                          onBlur={handleBlur('nuova')}
                          onChange={handleChange('nuova')}
                          status={getFormFieldStatus({ touched, errors }, 'nuova')}
                        />
                      </FormField>
                    ) : null}
                    <FormField
                      label={`${t('Classe')}*`}
                      status={getFormFieldStatus({ touched, errors }, 'classe')}
                      errorMessage={errors?.classe}
                    >
                      <FormSelect
                        value={values.classe}
                        options={classiOptions}
                        onBlur={handleBlur('classe')}
                        onChange={(selectedOption) =>
                          setFieldValue('classe', selectedOption.value, true)
                        }
                        status={getFormFieldStatus({ touched, errors }, 'classe')}
                      />
                    </FormField>
                    <FormField
                      label={`${t('Sezione')}*`}
                      status={getFormFieldStatus({ touched, errors }, 'sezione')}
                      errorMessage={errors?.sezione}
                    >
                      <FormInput
                        value={values.sezione}
                        onBlur={handleBlur('sezione')}
                        onChange={handleChange('sezione')}
                        status={getFormFieldStatus({ touched, errors }, 'sezione')}
                      />
                    </FormField>
                  </FormLayout>
                </>
              ) : (
                <>
                  <AreaPersonaleDataFieldLayout>
                    <AreaPersonaleDataField
                      label={t('Scuola di appartenenza')}
                      value={scuola?.scuola}
                    />
                    <AreaPersonaleDataField label={t('Città')} value={scuola?.citta} />
                    <AreaPersonaleDataField
                      label={t('CAP')}
                      value={scuola?.cap}
                      layoutMobile="md"
                    />
                    <AreaPersonaleDataField
                      label={t('Provincia')}
                      value={scuola?.provincia}
                      layoutMobile="md"
                    />
                    <AreaPersonaleDataField label={t('Regione')} value={scuola?.regione} />
                    <AreaPersonaleDataField
                      label={t('Classe e sezione')}
                      value={scuola?.classe ? `${scuola?.classe}  ${scuola?.sezione}` : ''}
                    />
                  </AreaPersonaleDataFieldLayout>
                </>
              )}
            </AreaPersonaleEditCard>
          )
        }}
      </Formik>
    </div>
  )
}

export default AreaPersonaleScuolaEditCard
