import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { MinusCircle, PlusCircle } from 'react-components/atoms/Icons'
import { Image } from 'react-components/atoms'
import { CAS_DOMAIN } from 'utils'
import Link from 'next/link'
import useWindowDimensions from 'hooks/useWindowDimensions'

const ListingRivistaByAnno = (props) => {
  const { className = '', anno = null, lista_riviste = [] } = props
  const [visible, setVisible] = useState(false)
  const t = useTrans()
  const { width } = useWindowDimensions()
  const num_item = width < 768 ? 2 : 5

  return lista_riviste ? (
    <div className={`listing-rivista-by-anno ${className ? ` ${className}` : ''}`}>
      <div className="listing-rivista-by-anno__container-anno">
        <p className="listing-rivista-by-anno__anno">{anno}</p>
        {lista_riviste.length > num_item ? (
          <div className="listing-rivista-by-anno__button-desktop">
            <button
              className="listing-rivista-by-anno__button"
              onClick={() => setVisible(!visible)}
            >
              {visible === true ? <MinusCircle /> : <PlusCircle />}
              <p>{visible === true ? t('Nascondi') : t('Mostra tutti')}</p>
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className="listing-rivista-by-anno__container-listing">
        {lista_riviste.map((item, index) => {
          const thumb = item.main_image
            ? item.main_image
            : '/images/demo/rivista-copertina-detail.png'

          return (
            <div
              className={`listing-rivista-by-anno__list-item ${
                visible === true ? '' : index >= num_item ? 'invisible' : ''
              }`}
              key={item.id}
            >
              <Link legacyBehavior href={item.url}>
                <a>
                  <Image
                    src={CAS_DOMAIN + thumb}
                    alt={item.titolo?.toAltTitle()}
                    title={item.titolo?.toAltTitle()}
                    className="listing-rivista-by-anno__list-item__image"
                    width={200}
                    height={272}
                    priority={index === 0}
                  />
                </a>
              </Link>
              {item?.mese && (
                <p className="listing-rivista-by-anno__list-item__mese">
                  {item?.mese ? `${item?.mese.toUpperCase()}` : ''} {item?.anno_solare}
                </p>
              )}
              <Link legacyBehavior href={item.url}>
                <a className="listing-rivista-by-anno__list-item__title">{item.titolo}</a>
              </Link>
            </div>
          )
        })}
      </div>
      {lista_riviste.length > num_item ? (
        <div className="listing-rivista-by-anno__button-mobile">
          <button className="listing-rivista-by-anno__button " onClick={() => setVisible(!visible)}>
            {visible === true ? <MinusCircle /> : <PlusCircle />}
            <p>{visible === true ? t('Nascondi') : t('Mostra tutti')}</p>
          </button>
        </div>
      ) : (
        ''
      )}
    </div>
  ) : (
    <></>
  )
}

export default ListingRivistaByAnno
