import React, { useRef } from 'react'
import SlickSlider from 'react-slick'
import Evidenza from 'components/atomic/Evidenza'
import { sendGTMData } from '../../../utils/gtm'

const sliderSettings = {
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 2,
  variableWidth: true,
  arrows: false,
}

const FiltriCatalogo = (props) => {
  const { label = '', filtri = [], className = '', onClick = () => {}, visible = true } = props

  if (!visible || !filtri.length) return null

  let slider = useRef(null)

  const handleGTM = (item, label, selected) => {
    if (selected) {
      sendGTMData({
        event: 'GAevent',
        eventID: '12',
        eventCategory: 'filtri_risorse',
        eventAction: label.split(' ').pop().toGTMFormat(),
        eventLabel: item.nome.toGTMFormat(),
      })
    }
  }

  return (
    <div className={`filtri-catalogo ${className}`}>
      <span className="filtri-catalogo__label">{label}</span>
      <SlickSlider
        className="filtri-catalogo__slider"
        ref={(self) => (slider = self)}
        {...sliderSettings}
      >
        {filtri.map((item) => (
          <Evidenza
            item={item}
            selected={item.selected}
            onClick={() => {
              onClick(item)
              handleGTM(item, label, !item.selected)
            }}
            className={item?.className ? item.className : ''}
            disabled={item.disabled ? item.disabled : false}
          />
        ))}
      </SlickSlider>
    </div>
  )
}

export default FiltriCatalogo
