import React from 'react'
import { getDiscipline, getTemi } from 'utils'

const NewTags = props => {
  const { tassonomia = [], className = '', discipline = false, temi = false } = props

  return (
    <div className={`new-tags ${className ? ` ${className}` : ''}`}>
      {discipline &&
        getDiscipline(tassonomia).map((d, index) => (
          <span className="new-tags__item">
            {index > 0 ? ', ' : ''}
            {d}
          </span>
        ))}
      {temi &&
        getTemi(tassonomia).map((t, index) => (
          <span className="new-tags__item">
            {index > 0 || (index === 0 && !discipline) ? ', ' : ''}
            {t}
          </span>
        ))}
    </div>
  )
}

export default NewTags
