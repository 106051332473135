import { fetchUtente } from 'containers/utente'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function useUtente() {
  const router = useRouter()
  const dispatch = useDispatch()

  const { utente, csrfToken, fetching } = useSelector(state => state.utente)

  useEffect(() => {
    dispatch(fetchUtente())
  }, [router.asPath])

  useEffect(() => {
    if (csrfToken)
      document.cookie = `csrftoken=${csrfToken}; expires=${new Date(
        Date.now() + 86400 * 1000 * 364
      ).toUTCString()}; path=/; secure`
  }, [csrfToken])

  return [utente, fetching]
}
