import React from 'react'
import { ContenutoNumeroRivista } from 'react-components/atoms'

const BoxContenutiNumeroRivista = props => {
  const { contenuti = [], titolo = '', className = '' } = props

  return (
    <div className={`box-contenuti-numero-rivista ${className ? ` ${className}` : ''}`}>
      <p className={`box-contenuti-numero-rivista__title`}>{titolo}</p>
      {contenuti.map((item, index) => (
        <ContenutoNumeroRivista
          className={`box-contenuti-numero-rivista__item ${
            item.label_numero_rivista ? 'box-contenuti-numero-rivista__item--labeled' : ''
          }`}
          key={index}
          contenuto={item}
        />
      ))}
    </div>
  )
}

export default BoxContenutiNumeroRivista
