import React from 'react'
import { useTrans } from 'hooks'
import { GETISCRIZIONEMODULO, MODULOFORMAZIONE } from '../../../utils/endpoint'
import { api } from '../../../utils'
import { useEffect, useState } from 'react'
import { WImage, Button } from 'react-components/atoms'

const FormazioneModuloCardProfilo = props => {
  const { item = undefined, className = '' } = props
  const [informazioniModulo, setInformazioniModulo] = useState([])
  const t = useTrans()

  useEffect(() => {
    let params = { idModulo: item.id }
    api.get(`${MODULOFORMAZIONE}${GETISCRIZIONEMODULO}`, { params }).then(response => {
      setInformazioniModulo(response.data)
    })
  }, [])

  const handleGTM = label => {
    dataLayer.push({
      event: 'GAevent',
      eventID: '36',
      eventCategory: 'formazione_area_riservata',
      eventAction: label.toGTMFormat(),
      eventLabel: item.titolo.toGTMFormat(),
      moreInfo: item.in_corso ? 'moduli_in_corso' : 'moduli_conclusi',
    })
  }

  return item ? (
    <div
      className={`formazione-modulo-card ${
        item.iscrizioneAttiva ? ' formazione-modulo-card--iscrizione' : ''
      } ${item.in_corso ? '' : ' formazione-modulo-card--concluso'} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="formazione-modulo-card-profilo__head">
        <div className="formazione-modulo-card-profilo__head__image">
          <WImage src={item.main_image} maxHeight={120} alt={item.titolo} title={item.titolo} />
        </div>
        <div className="formazione-modulo-card-profilo__head__container">
          <p className="formazione-modulo-card-profilo__head__container__intro">
            {item.nome_corso}
          </p>
          <p className="formazione-modulo-card-profilo__head__container__title">{item.titolo}</p>
        </div>
      </div>
      <div className="formazione-modulo-card-profilo__content">
        <div className="formazione-modulo-card-profilo__content__cta-box">
          {informazioniModulo['link_certificato'] ? (
            <Button
              variant="secondary"
              label={t('Scarica certificato')}
              href={informazioniModulo['link_certificato']}
              onClick={() => handleGTM(t('Scarica certificato'))}
            />
          ) : (
            <></>
          )}

          <Button
            variant="primary"
            label={t('Vedi materiali')}
            href={item.url}
            onClick={() => handleGTM(t('Vedi materiali'))}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default FormazioneModuloCardProfilo
