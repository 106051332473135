import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { Badge, Button } from 'react-components/atoms'

import { UTENTE, WIDGET } from 'utils/endpoint'
import { api } from 'utils'
import StrumentoCard from 'components/card/StrumentoCard'
import ArticoloCard from 'components/card/ArticoloCard'
import LezioneCard from 'components/card/LezioneCard'
import RaccoltaCard from 'components/card/RaccoltaCard'

const WidgetCard = props => {
  const { title = '', description = '', cta = '', link = '', className = '' } = props

  const t = useTrans()

  const [risorsa, setRisorsa] = useState(null)

  useEffect(() => {
    initRisorsa()
  }, [])

  useEffect(() => {
    if (risorsa) checkTools(risorsa)
  }, [risorsa])

  async function initRisorsa() {
    const response = await api.get(`${WIDGET}dalla_redazione/`)
    const { data } = response
    setRisorsa(data.risorsa)
  }

  async function checkTools(item) {
    const params = [
      {
        content_type_id: item.model,
        object_id: item.id,
      },
    ]

    api.get(UTENTE + 'tools/', { params }).then(response => {
      const current = response.data.find(
        e => e.content_type_id === item.model && e.object_id === item.id
      )

      const { saved, following, file_url, pdf_file } = current

      const enhancedItem = {
        extra: { ...item.extra, file_url, pdf_file },
        saved,
        following,
        ...item,
      }

      setRisorsa(enhancedItem)
    })
  }

  function renderCard(item) {
    switch (item.model_class) {
      case 'strumento':
        return <StrumentoCard strumento={item} horizontal />
      case 'articolo':
        return <ArticoloCard articolo={item} horizontal />
      case 'lezione':
        return <LezioneCard articolo={item} horizontal />
      default:
        return <RaccoltaCard raccolta={item} horizontal />
    }
  }

  if (!risorsa) return null

  return (
    <div className={`widget-card ${className ? ` ${className}` : ''}`}>
      <div className="widget-card__content">
        <Badge label={t('Nuovi')} className="widget-card__badge" />
        {title && <p className="widget-card__title">{title}</p>}
        {description && <p className="widget-card__description">{description}</p>}
        {cta && link && (
          <Button label={cta} variant="secondary" href={link} className="widget-card__cta" />
        )}
      </div>
      <div className="widget-card__card">{renderCard(risorsa)}</div>
    </div>
  )
}

export default WidgetCard
