import React, { useEffect } from 'react'

import { api } from 'utils'
import { ARTICOLI, RACCOLTE, STRUMENTI, UTENTE } from 'utils/endpoint'
import { useState } from 'react'
import { renderCardWithProps } from '../AreaPersonaleRaccoltaContenutiEditForm/AreaPersonaleRaccoltaContenutiEditForm'

const GenericPageTab = props => {
  const { tab, children, className = '' } = props

  const [raccolte, setRaccolte] = useState([])
  const [strumenti, setStrumenti] = useState([])
  const [articoli, setArticoli] = useState([])

  const initializeElementi = async () => {
    if (tab.articoli.length) {
      api
        .get(ARTICOLI, { params: { id__in: tab.articoli.join(','), page_size_custom: 200 } })
        .then(response => setArticoli(response.data.results))
    }
    if (tab.strumenti.length) {
      api
        .get(STRUMENTI, { params: { id__in: tab.strumenti.join(','), page_size_custom: 200 } })
        .then(response => setStrumenti(response.data.results))
    }
    if (tab.raccolte.length) {
      api
        .get(RACCOLTE, { params: { id__in: tab.raccolte.join(','), page_size_custom: 200 } })
        .then(response => setRaccolte(response.data.results))
    }
  }

  useEffect(() => {
    initializeElementi()
  }, [])

  useEffect(() => {
    if (raccolte.length) checkTools(raccolte, setRaccolte)
  }, [raccolte.length])

  useEffect(() => {
    if (strumenti.length) checkTools(strumenti, setStrumenti)
  }, [strumenti.length])

  useEffect(() => {
    if (articoli.length) checkTools(articoli, setArticoli)
  }, [articoli.length])

  async function checkTools(items, setItems) {
    const params = items.map(({ id, model }) => ({
      content_type_id: model,
      object_id: id,
    }))

    const response = await api.get(UTENTE + 'tools/', { params })
    const enhancedItems = items.map(item => {
      const current = response.data.find(
        e => e.content_type_id === item.model && e.object_id === item.id
      )

      const { saved, following, file_url, pdf_file } = current

      return { ...item, saved, following, extra: { ...item.extra, file_url, pdf_file } }
    })

    setItems(enhancedItems)
  }

  const renderItems = items => {
    if (!items.length) return null

    return (
      <div className="generic-page-tab__listing">
        {items.map(item => (
          <div className="generic-page-tab__listing__item" key={item.id}>
            {renderCardWithProps(item, { isEmbedded: true })}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className={`generic-page-tab ${className ? ` ${className}` : ''}`}>
      {children}
      {renderItems(raccolte)}
      {renderItems(strumenti)}
      {renderItems(articoli)}
    </div>
  )
}

export default GenericPageTab
