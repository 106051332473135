import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  PinterestIcon,
  PinterestShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share'
import React, { useState, useEffect } from 'react'
import { Button, Modal, ReactTooltip } from 'react-components/atoms'
import { Copy, ShareIcon } from 'react-components/atoms/Icons'
import { useTrans } from 'hooks'
import Icon from 'components/atomic/Icon'
import { sendGTMData } from 'utils/gtm.js'
import { useSelector } from 'react-redux'
import { CAS_DOMAIN } from 'utils'

const Share = props => {
  const {
    item = null,
    variant = 'round',
    labelGTM = '',
    print = false,
    isFormazione = false,
  } = props

  if (!item) return null

  const mediaUrl = `${CAS_DOMAIN}${item.main_image}`

  const { paginaCorrente } = useSelector(state => state.paginaCorrente)

  const t = useTrans()

  const [modal, setModal] = useState(false)
  const [url, setUrl] = useState('')

  const [copiedToClipboard, setCopiedToClipboard] = useState(false)

  useEffect(() => {
    setUrl(window.location.href)
  }, [])

  const handlePrint = () => {
    handleGTMCta('stampa')
    window.print()
  }

  const handleCopyToClipboard = () => {
    const ua = window.navigator.userAgent
    const isIE = /MSIE|Trident/.test(ua)

    if (!isIE) {
      window.navigator.clipboard.writeText(window.location.href).then(
        () => {
          handleGTMCta('copia_link')
          setCopiedToClipboard(true)
          setTimeout(() => setCopiedToClipboard(false), 2000)
        },
        err => alert('Could not copy text: ', err)
      )
    } else {
      // TODO: gestione IE
    }
  }

  const handleGTMCta = ctaAction => {
    sendGTMData({
      event: 'GAevent',
      eventID: '16',
      eventCategory: isFormazione ? 'formazione_pagina_dettaglio' : 'cta_banner',
      eventAction: ctaAction,
      eventLabel: labelGTM,
      moreInfo: isFormazione
        ? item.titolo.toGTMFormat()
        : paginaCorrente?.item?.titolo.toGTMFormat() || '',
    })
  }

  return (
    <div className={`share share--${variant}`}>
      <ReactTooltip label={t('Condividi')} id="share">
        <Button className="share__button" onClick={() => setModal(true)} icon={<ShareIcon />} />
      </ReactTooltip>
      <Modal open={modal} setOpen={setModal} closeButton className="share-modal">
        <Modal.Content title={t('Condividi')}>
          <div className="share__social">
            <WhatsappShareButton
              url={url}
              className="share__social__button"
              onClick={() => handleGTMCta('share_whatsapp')}
            >
              <WhatsappIcon size={30} bgStyle={{ fill: '#F5F5F5' }} iconFillColor="#50c949" />
            </WhatsappShareButton>
            <FacebookShareButton
              url={url}
              className="share__social__button"
              onClick={() => handleGTMCta('share_facebook')}
            >
              <FacebookIcon size={30} bgStyle={{ fill: '#F5F5F5' }} iconFillColor="#1773ea" />
            </FacebookShareButton>
            {item.main_image && (
              <PinterestShareButton
                url={url}
                media={mediaUrl}
                className="share__social__button"
                onClick={() => handleGTMCta('share_pinterest')}
              >
                <PinterestIcon size={30} bgStyle={{ fill: '#F5F5F5' }} iconFillColor="#b7081b" />
              </PinterestShareButton>
            )}
            <LinkedinShareButton
              url={url}
              className="share__social__button"
              onClick={() => handleGTMCta('share_linkedin')}
            >
              <LinkedinIcon size={30} bgStyle={{ fill: '#F5F5F5' }} iconFillColor="#0174b4" />
            </LinkedinShareButton>
          </div>
          <div className="share__links">
            <Button
              icon={<Icon id="email" />}
              label={t('Invia per email')}
              href={`mailto:?subject=GIUNTI Scuola | ${item.titolo}&body=${url}`}
              external
              onClick={() => handleGTMCta('email')}
            />
            <Button icon={<Copy />} label={t('Copia link')} onClick={handleCopyToClipboard} />
            {print && (
              <Button icon={<Icon id="print" />} label={t('Stampa')} onClick={handlePrint} />
            )}
          </div>
        </Modal.Content>
      </Modal>
      {copiedToClipboard && (
        <div className="copied-to-clipboard">{t('Il link è stato copiato nei tuoi appunti!')}</div>
      )}
    </div>
  )
}

export default Share
