import { UTENTE } from '../utils/endpoint'
import { api } from '../utils'

export const fetchUtente = headers => ({
  type: 'FETCH_UTENTE',
  payload: api.get(UTENTE, { headers }),
})

export default function reducer(
  state = {
    utente: '',
    fetching: false,
    fetched: false,
    error: null,
    inMaintenance: false,
    csrfToken: '',
  },
  action
) {
  switch (action.type) {
    case 'FETCH_UTENTE_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_UTENTE_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_UTENTE_FULFILLED': {
      return {
        ...state,
        fetching: false,
        fetched: true,
        utente: action.payload.data,
        inMaintenance: action.payload.headers['x-maintenance'],
        csrfToken: action.payload.headers['x-csrftoken'],
      }
    }

    default: {
      return state
    }
  }
}
