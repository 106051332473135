import React from 'react'
import { Button } from 'react-components/atoms'
import { useTrans } from 'hooks'

const BoxContenutiLibro = props => {
  const {
    libro = {
      titolo: '',
    },
    className = '',
    adozionale = false,
  } = props

  if (!libro.box_contenuti_libro) return null

  const t = useTrans()

  return (
    <div className={`box-contenuti-libro ${className}`}>
      <div className="box-contenuti-libro__content">
        <p className="box-contenuti-libro__title">
          {t('Tanti contenuti digitali inclusi nel prezzo')}
        </p>
        <p className="box-contenuti-libro__description">
          {t('Acquistando questo volume avrai a disposizione tanti contenuti digitali extra.')}
        </p>
      </div>
      <Button
        className="box-contenuti-libro__cta"
        label="Scopri i contenuti extra"
        href={`#contenuti-extra`}
        variant="secondary"
        inverted
      />
    </div>
  )
}

export default BoxContenutiLibro
