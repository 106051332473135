import React from 'react'
import Link from 'next/link'
import { Loader } from 'react-components/atoms/Icons'

const Button = (props) => {
  const {
    label = '',
    labelMobile = null,
    type = 'button',
    variant = 'primary', // 'ghost'
    colorVariant = '', // orange, darkblue, white, red, yellow, green, grey, black
    size = 'md', // md, sm
    disabled = false,
    icon = null,
    iconPos = 'left',
    onClick = () => {},
    href = null,
    newPage = false,
    className = '',
    loading = false,
    external = false,
    inverted = false,
    download = false,
  } = props

  const classeNames = `wbutton wbutton--${variant} wbutton--${size}${
    colorVariant ? ` wbutton--color-${colorVariant}` : ''
  }${inverted ? ' wbutton--inverted' : ''}${icon && !label ? ' wbutton--only-icon' : ''}${
    icon && iconPos === 'right' ? ' wbutton--icon-right' : ''
  }${loading ? ' wbutton--loading' : ''}${className ? ` ${className}` : ''}`

  const buttonContent = (
    <>
      {icon && <span className="wbutton__icon">{icon}</span>}
      {label && (
        <span className={`wbutton__label${labelMobile !== null ? ' wbutton__label--desktop' : ''}`}>
          {label}
        </span>
      )}
      {labelMobile && <span className="wbutton__label wbutton__label--mobile">{labelMobile}</span>}
      {loading && (
        <span className="wbutton__loader">
          <Loader />
        </span>
      )}
    </>
  )

  return href ? (
    external ? (
      <a
        className={classeNames}
        download={download ? true : false}
        href={href}
        target={newPage ? '_blank' : null}
        rel={newPage ? 'nofollow' : null}
        onClick={onClick}
      >
        {buttonContent}
      </a>
    ) : (
      <Link legacyBehavior href={href}>
        <a className={classeNames} download={download ? true : false} onClick={onClick}>
          {buttonContent}
        </a>
      </Link>
    )
  ) : (
    <button type={type} className={classeNames} disabled={disabled} onClick={onClick}>
      {buttonContent}
    </button>
  )
}

export default Button
