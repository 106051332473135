import React from 'react'
import { useTrans } from 'hooks'
import { slugify } from 'utils'
import { Info2 } from 'react-components/atoms/Icons'
import { ReactTooltip } from 'react-components/atoms'

const CartSection = props => {
  const { titolo = '', num = 0, helper = undefined, children = undefined, className = '' } = props

  const t = useTrans()

  return (
    <div className={`cart-section ${className ? ` ${className}` : ''}`}>
      {!!titolo && (
        <p className={'cart-section__head'}>
          <span className="cart-section__title">{titolo}</span>
          {num > 0 && (
            <span className="cart-section__num">
              ({t(num === 1 ? '{0} prodotto' : '{0} prodotti', [num])})
            </span>
          )}
          {helper && (
            <ReactTooltip id={slugify(helper)} label={helper}>
              <span className="cart-section__helper">
                <Info2 />
              </span>
            </ReactTooltip>
          )}
        </p>
      )}
      {children}
    </div>
  )
}

export default CartSection
