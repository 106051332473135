import React from 'react'
import { WImage } from 'react-components/atoms'

const ImageBox = props => {
  const {
    src = '',
    alt = '',
    title = '',
    classPrefix = '',
    maxWidth = 0,
    maxHeight = 0,
    lazyLoad = false,
    children = null,
    onClick = () => '',
    cursorPointer = false,
  } = props

  return (
    <div
      className={`image-box${classPrefix ? ` ${classPrefix}__image-box` : ''}`}
      onClick={() => onClick?.()}
      style={cursorPointer ? { cursor: 'pointer' } : {}}
    >
      <WImage
        src={src}
        alt={alt}
        title={title}
        classPrefix={classPrefix}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
        lazyLoad={lazyLoad}
      />
      {children}
    </div>
  )
}

export default ImageBox
