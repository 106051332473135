import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Time({ className }) {
  return (
    <IconBase name="time" w={20} className={className}>
      <path
        d="M9.99175 1.66666C5.39175 1.66666 1.66675 5.39999 1.66675 9.99999C1.66675 14.6 5.39175 18.3333 9.99175 18.3333C14.6001 18.3333 18.3334 14.6 18.3334 9.99999C18.3334 5.39999 14.6001 1.66666 9.99175 1.66666ZM10.0001 16.6667C6.31675 16.6667 3.33341 13.6833 3.33341 9.99999C3.33341 6.31666 6.31675 3.33332 10.0001 3.33332C13.6834 3.33332 16.6667 6.31666 16.6667 9.99999C16.6667 13.6833 13.6834 16.6667 10.0001 16.6667Z"
        //  fill="#323232"
      />
      <path
        d="M10.4167 5.83334H9.16675V10.8333L13.5417 13.4583L14.1667 12.4333L10.4167 10.2083V5.83334Z"
        //  fill="#323232"
      />
    </IconBase>
  )
}

export default Time
