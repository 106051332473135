import React from 'react'

const BibliographyBox = props => {
  const { title = '', text = '', className = '' } = props

  return (
    <div className={`wbibliography-box ${className ? ` ${className}` : ''}`}>
      <div className="wbibliography-box__title">{title}</div>
      <div className="wbibliography-box__text">{text}</div>
      {props.children}
    </div>
  )
}

export default BibliographyBox
