import React from 'react'

const TargetBox = props => {
  const {
    color = 'default', // 'default' | 'white'
    children = '',
    className = '',
  } = props

  return (
    <div
      className={`wtarget-box ${color ? `wtarget-box--${color}` : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="wtarget-box__text">{children}</div>
    </div>
  )
}

export default TargetBox
