import React from 'react'

const AreaPersonaleDataField = props => {
  const {
    label = '',
    value = '',
    layout = 'md',
    layoutMobile = 'full',
    space = 'md',
    className = '',
  } = props

  return (
    <div
      className={`area-personale-data-field
      ${layout ? ` area-personale-data-field--layout-${layout}` : ''}
      ${layoutMobile ? ` area-personale-data-field--layout-mobile-${layoutMobile}` : ''}
      ${space ? ` area-personale-data-field--space-${space}` : ''}
      ${className ? ` ${className}` : ''}`}
    >
      <p className="area-personale-data-field__label">{label}</p>
      <p className="area-personale-data-field__value">{value}</p>
    </div>
  )
}

export default AreaPersonaleDataField
