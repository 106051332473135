import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { Close, Info2 } from 'react-components/atoms/Icons'
import { Button, FormInput, FormMetodoPagamentoRadio, ReactTooltip } from 'react-components/atoms'

const FormCartaDocenteRadioOption = props => {
  const {
    id = '',
    name = '',
    metodoPagamento = undefined,
    checked = false,
    onChange = undefined,
    readOnly = false,
    disabled = false,
    codice = '',
    setCodice = undefined,
    importo = 0,
    erroreCartaDocente = '',
    setErroreCartaDocente = '',
    setImporto = undefined,
    importoInsufficiente = false,
    applicaCodice = undefined,
    className = '',
  } = props

  const t = useTrans()
  const [value, setValue] = useState(codice)

  const checkCodice = () => {
    applicaCodice(value)
  }

  const removeCodice = () => {
    setImporto(0)
    setCodice('')
    setErroreCartaDocente('')
    setValue('')
  }

  useEffect(() => {
    setValue(codice)
  }, [codice])

  return metodoPagamento ? (
    codice ? (
      <FormMetodoPagamentoRadio.Option
        id={id}
        name={name}
        metodoPagamento={{
          ...metodoPagamento,
          nome: t('Usa il buono “Carta del docente”'),
          descrizione: codice,
          images: [],
          onRemove: () => removeCodice(),
          importo: importo,
        }}
        checked={checked || (codice && importoInsufficiente)}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
        hideButton={codice && importoInsufficiente}
        showImage={false}
      />
    ) : checked ? (
      <div className={`form-carta-docente-radio-option ${className ? ` ${className}` : ''}`}>
        <p className="form-carta-docente-radio-option__label">
          <span className="form-carta-docente-radio-option__label-text">
            {t('Inserisci un buono "Carta del docente"')}
          </span>
          <ReactTooltip
            id={'formcartadocenteradiooption_helper'}
            label={t('Info carta del docente')}
          >
            <span className="form-carta-docente-radio-option__helper">
              <Info2 />
            </span>
          </ReactTooltip>
        </p>
        <div className="form-carta-docente-radio-option__form">
          <FormInput
            placeholder="Inserisci codice"
            value={value}
            onChange={e => setValue(e.target.value)}
            className="form-carta-docente-radio-option__input"
            status={erroreCartaDocente ? 'error' : ''}
          />
          <Button label={t('Applica')} variant="secondary" onClick={checkCodice} />
        </div>
        <button
          type="button"
          aria-label={t('Chiudi')}
          className="form-carta-docente-radio-option__close"
          onClick={() => {
            removeCodice()
            onChange({
              target: {
                value: null,
              },
            })
          }}
        >
          <Close />
        </button>
      </div>
    ) : (
      <FormMetodoPagamentoRadio.Option
        id={id}
        name={name}
        metodoPagamento={metodoPagamento}
        checked={checked}
        onChange={onChange}
        readOnly={readOnly}
        disabled={disabled}
      />
    )
  ) : (
    <></>
  )
}

export default FormCartaDocenteRadioOption
