import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Hamburger({ className }) {
  return (
    <IconBase name="hamburger" w={24} className={className}>
      <rect x="3" y="5" width="18" height="2" rx="1" fill="#2A2A2A" />
      <rect x="3" y="11" width="18" height="2" rx="1" fill="#2A2A2A" />
      <rect x="3" y="17" width="18" height="2" rx="1" fill="#2A2A2A" />
    </IconBase>
  )
}

export default Hamburger
