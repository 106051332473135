import React from 'react'
import FormazioneModuloCard from '../../molecules/FormazioneModuloCard/FormazioneModuloCard'

const ListingCorsoFormazioneModuli = props => {
  const { moduli = [], titolo = '', sottotitolo = '' } = props

  return moduli.length > 0 ? (
    <div className="corso-formazione__container-moduli">
      <h3 className="corso-formazione__container-moduli__titolo">{titolo}</h3>
      {sottotitolo ? (
        <div className="corso-formazione__container-moduli__sottotitolo">{sottotitolo}</div>
      ) : (
        <div className="corso-formazione__container-moduli__spacing"></div>
      )}
      <div className="corso-formazione__container-moduli__listing">
        {moduli.map((item, index) => {
          return <FormazioneModuloCard item={item} key={index} latest={item.novita} />
        })}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ListingCorsoFormazioneModuli
