import React, { useEffect, useState } from 'react'
import { Image } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { api, CAS_DOMAIN } from 'utils'
import { Button, Modal } from 'react-components/atoms'

import { CORSOADOZIONALE } from 'utils/endpoint'
import { useSelector } from 'react-redux'
import { sendGTMData } from 'utils/gtm.js'

const canActivate = utente => {
  // adottatario
  if (utente.is_adottatario) return true
  // se di scuola primaria (genitore/studente) o utente validato come non docente -> GroupID non docente
  else return utente.ordine_di_scuola === 'Scuola primaria'
}

const initialError = { error: false, ordine: false }

const AttivaLibroCard = props => {
  const {
    libro = {
      main_image: '',
      titolo: '',
      descrizione: '',
    },
    className = '',
    attivo = false,
  } = props

  const t = useTrans()

  const [active, setActive] = useState(attivo)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(initialError)

  useEffect(() => {
    setActive(attivo)
  }, [attivo])

  const { utente } = useSelector(state => state.utente)

  const handleGTM = () => {
    sendGTMData({
      event: 'GAevent',
      eventID: '19',
      eventCategory: 'area_riservata',
      eventAction: 'adozionali',
      eventLabel: 'attiva_adozionale',
    })
  }

  const handleActivate = async () => {
    setLoading(true)
    handleGTM()

    api.post(CORSOADOZIONALE + 'activate/', { id: libro.id }).then(response => {
      setLoading(false)
      if (response.data['error']) {
        setActive(false)
        setError({ error: true, ordine: response.data['ordine'] })
      } else {
        setActive(response.data['attivo'])
      }
    })
  }
  return (
    <div className={`attiva-libro-card ${className ? ` ${className}` : ''}`}>
      <div className="attiva-libro-card__image-box">
        <Image
          src={
            libro.main_image
              ? `${CAS_DOMAIN}${libro.main_image}`
              : 'https://via.placeholder.com/166x210'
          }
          width={166}
          height={210}
          layout="responsive"
        />
      </div>
      <div className="attiva-libro-card__content">
        <p className="attiva-libro-card__title">{libro?.titolo}</p>
        <p className="attiva-libro-card__description">{libro?.tipologia}</p>
        <div className="attiva-libro-card__cta">
          {active ? (
            <p className="attiva-libro-card__activated">{t('Questo contenuto è attivo')}</p>
          ) : loading ? (
            <Button label={t('Attivando')} disabled />
          ) : canActivate(utente) ? ( // se docente adottatario o genitore/studente di scuola primaria -> cta attiva
            <Button label={t('Attiva')} onClick={handleActivate} />
          ) : null}
        </div>
      </div>
      <Modal
        open={error.error}
        setOpen={() => setError(initialError)}
        closeButton
        className="attiva-libro-card__modal"
      >
        <Modal.Content
          title={t('Si è verificato un errore')}
          description={t(error.ordine ? 'Ordine di scuola errato' : 'Errore generico')}
        />
      </Modal>
    </div>
  )
}

export default AttivaLibroCard
