import React from 'react'

const ColorBox = props => {
  const {
    color = '', // '' | 'white'
    padding = '', // '' | 'no-padding'
    className = '',
    children = '',
  } = props

  return (
    <div
      className={`wcolor-box ${color ? `wcolor-box--${color}` : ''} ${
        padding ? `wcolor-box--${padding}` : ''
      } ${className ? ` ${className}` : ''}`}
    >
      {children}
    </div>
  )
}

export default ColorBox
