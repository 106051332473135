import React, { useEffect, useMemo, useState } from 'react'

import Link from 'next/link'
import { useSelector } from 'react-redux'
import { sendGTMData } from 'utils/gtm.js'
import { SubMenu } from 'react-components/organisms'
import { useRouter } from 'next/router'

const MainMenu = React.memo(() => {
  const { menu } = useSelector((state) => state.menu)

  const mainMenu = menu.find((k) => k.key == 'main')

  if (!mainMenu) return null

  const router = useRouter()

  const [current, setCurrent] = useState(null)

  const isCurrent = (item) => current && current.name == item.name

  function clearCurrent() {
    if (current) setCurrent(null)
  }

  useEffect(() => {
    window.addEventListener('scroll', clearCurrent)
    return () => window.removeEventListener('scroll', clearCurrent)
  })

  useEffect(() => {
    clearCurrent() // cambio pagina
  }, [router.asPath])

  useEffect(() => {
    handleToggleStickyClass() // gestione z-index sticky-actions rispetto al menu
  }, [current])

  function handleToggleStickyClass() {
    if (current) {
      document.body.classList.add('w-menu-open')
    } else {
      document.body.classList.remove('w-menu-open')
    }
  }

  const handleGTM = (firstLevelItem = null, secondLevelItem = null, thirdLevelItem = null) => {
    if (firstLevelItem) {
      let gtmData = {
        event: 'GAevent',
        eventID: '01',
        eventCategory: 'menu',
        eventAction: firstLevelItem.toGTMFormat(),
        eventLabel: '',
        moreInfo: '',
      }

      if (secondLevelItem) {
        gtmData['eventLabel'] = secondLevelItem.toGTMFormat()
      }

      if (thirdLevelItem) {
        gtmData['moreInfo'] = thirdLevelItem.toGTMFormat()
      }

      sendGTMData(gtmData)
    }
  }

  const mainMenuItems = useMemo(
    () =>
      Object.values(mainMenu.children ? mainMenu.children : []).map((item, k) => (
        <li
          className={`nav__item ${isCurrent(item) ? 'nav__item--active' : ''}`}
          onClick={() => {
            setCurrent(item)
          }}
          key={k}
        >
          {item.final_url ? (
            <Link legacyBehavior href={item.final_url}>
              <a
                onClick={() => {
                  handleGTM(item.name)
                }}
                target={item.blank ? '_blank' : null}
              >
                {item.name}
              </a>
            </Link>
          ) : (
            <a>{item.name}</a>
          )}
          <SubMenu
            vocePrimoLivello={item}
            handleGTM={handleGTM}
            open={item === current}
            onMouseLeave={clearCurrent}
          />
        </li>
      )),
    [mainMenu, current]
  )

  return (
    <nav id="navigation" className="header__nav" onMouseLeave={clearCurrent}>
      <ul className="nav">{mainMenuItems}</ul>
    </nav>
  )
})

export default MainMenu
