import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Info2({ className }) {
  return (
    <IconBase name="info2" w={24} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        stroke="none"
      />
      <rect x="11" y="11" width="2" height="7" rx="1" stroke="none" />
      <circle cx="12" cy="8" r="1" stroke="none" />
    </IconBase>
  )
}

export default Info2
