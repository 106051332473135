import React from 'react'
import Link from 'next/link'
import { useTrans } from 'hooks'
import { ArrowLeftLong, LogoColored } from 'react-components/atoms/Icons'
import { WContainer, Button } from 'react-components/atoms'

const CheckoutHeader = (props) => {
  const { className = '' } = props

  const t = useTrans()

  return (
    <div className={`checkout-header ${className ? ` ${className}` : ''}`}>
      <WContainer>
        <div className="checkout-header__layout">
          <Button
            href="/cart"
            variant="ghost"
            colorVariant="black"
            label={t('Torna al carrello')}
            icon={<ArrowLeftLong />}
            className="checkout-header__back"
          />
          <Link legacyBehavior href="/">
            <a className="checkout-header__logo">
              <LogoColored />
            </a>
          </Link>
        </div>
        <div />
      </WContainer>
    </div>
  )
}

export default CheckoutHeader
