import React, { useState, useMemo } from 'react'
import dynamic from 'next/dynamic'
import {
  SCARICA,
  downloadables,
  getTipoStrumento,
  hasPerm,
  isFlipchart,
  mappingCta,
  isSme,
  LEZIONE,
  ARTICOLO,
} from 'utils'
import Link from 'next/link'
import { sendGTMData } from 'utils/gtm.js'
import { useSelector } from 'react-redux'
import Icon from 'components/atomic/Icon'
import { Button } from 'react-components/atoms'
import { Save, Share } from 'react-components/organisms'
import Trans from 'components/atomic/Trans'

const EmbeddedVimeo = dynamic(() => import('components/atomic/EmbeddedVimeo'))
const Login = dynamic(() => import('components/atomic/Login'))
const Modal = dynamic(() => import('components/atomic/Modal'))

const initialComunicazione = {
  stato: false,
  type: '',
}

const Bar = (props) => {
  const { item = null, save = false, share = false, labelGTM = '', print = false, children } = props

  if (!item) return null

  const { paginaCorrente } = useSelector((state) => state.paginaCorrente)
  const { utente } = useSelector((state) => state.utente)

  // MODAL
  const [downloadModal, setDownloadModal] = useState(false)
  const [vimeoModal, setVimeoModal] = useState(false)
  const [login, setLogin] = useState(false)
  const [next, setNext] = useState('')
  const [comunicazione, setComunicazione] = useState(initialComunicazione)

  const handleLogin = (login, nextPage = '') => {
    if (nextPage) setNext(nextPage)
    setComunicazione(initialComunicazione)
    setLogin(login)
  }

  const handleGTMCta = (ctaAction) => {
    sendGTMData({
      event: 'GAevent',
      eventID: '16',
      eventCategory: 'cta_banner',
      eventAction: ctaAction,
      eventLabel: labelGTM,
      moreInfo: paginaCorrente?.item?.titolo.toGTMFormat() || '',
    })
  }

  let cta = null
  let tipoStrumento = null

  if (item.model_class === 'strumento') {
    tipoStrumento = useMemo(() => getTipoStrumento(item), [item])

    const ctaLabel = item.cta ? item.cta : mappingCta[tipoStrumento.split(' ')[0]]

    const isDownloadable = downloadables.includes(tipoStrumento.split(' ')[0])

    if (item.extra?.id_vimeo) {
      cta = (
        <Button
          label={ctaLabel}
          className="bar__cta"
          onClick={() => {
            setVimeoModal(true)
            handleGTMCta('guarda')
          }}
        />
      )
    } else if (item.extra.link_esterno || item.extra.file_url) {
      cta = (
        <a
          className="bar__cta wbutton wbutton--primary wbutton--md"
          download={
            isDownloadable
              ? isFlipchart(item)
                ? item.titolo + '.flipchart'
                : isSme(item)
                ? item.titolo + '.sme'
                : true
              : false
          }
          target="_blank"
          href={item.extra.link_esterno || item.extra.file_url}
          onClick={() => handleGTMCta(ctaLabel == 'ISCRIVITI' ? 'iscriviti' : 'download')}
        >
          {ctaLabel === 'Scarica' ? <Icon id="download" classes="icon--download" /> : null}
          {ctaLabel}
        </a>
      )
    } else {
      cta = (
        <Button
          icon={ctaLabel === 'Scarica' ? <Icon id="download" classes="icon--download" /> : null}
          label={ctaLabel}
          className="bar__cta disabled"
          onClick={() => handleDownload(true)}
        />
      )
    }
  } else if (item.model_class === ARTICOLO || item.model_class === LEZIONE) {
    cta = (
      <Button
        icon={<Icon id="download" classes="icon--download" />}
        label={'Scarica'}
        className={`bar__cta ${!hasPerm(utente, SCARICA, item) ? 'disabled' : ''}`}
        onClick={() => handleDownload(false)}
      />
    )
  }

  const handleDownload = (force = false) => {
    if (hasPerm(utente, SCARICA, item) && !force) {
      sendGTMData({
        event: 'GAevent',
        eventID: '16',
        eventCategory: 'cta_banner',
        eventAction: 'download',
        eventLabel: labelGTM,
        moreInfo: paginaCorrente?.item?.titolo.toGTMFormat() || '',
      })
      setDownloadModal(true)
    } else {
      setComunicazione({ stato: true, type: SCARICA })
    }
  }

  const renderComunicazione = (utente, item) => {
    if (item.con_codice) {
      return (
        <>
          <h3 className="title">Inserisci il codice ISBN e sblocca questo contenuto!</h3>
          <p className="disclaimer">
            Sotto il codice a barre del libro trovi il codice ISBN, inseriscilo per accedere subito
            a tutti i contenuti del libro.
          </p>
          {utente ? (
            <Link legacyBehavior href="/area-personale/libreria">
              <a className="button button--primary cta" type="button">
                Inserisci il codice
              </a>
            </Link>
          ) : (
            <button
              className="button button--primary cta"
              type="button"
              onClick={() => handleLogin(true, 'area-personale-libreria')}
            >
              Inserisci il codice
            </button>
          )}
        </>
      )
    } else if (!utente) {
      return (
        <>
          <h3 className="title">Questo contenuto è riservato agli utenti registrati.</h3>
          <p className="disclaimer">
            Registrati gratuitamente e scopri tutti i vantaggi. In regalo per te 30 giorni di prova
            a IO+, con tanti contenuti in esclusiva e l'accesso alla community dei docenti Giunti
            Scuola.
          </p>
          <Link legacyBehavior href="/registrazione">
            <a
              className="button button--primary cta"
              type="button"
              onClick={() => setComunicazione(initialComunicazione)}
            >
              Registrati ora
            </a>
          </Link>
          <p className="disclaimer">
            Registrati ora e accedi a tanti contenuti, <b>sempre aggiornati</b>
          </p>
          <p>
            Sei già registrato?{' '}
            <a className="login" onClick={() => handleLogin(true)}>
              Accedi
            </a>
          </p>
        </>
      )
    } else if (!utente.is_member) {
      return (
        <>
          <h3 className="title">
            Questo contenuto è riservato agli abbonati IO+, la community dei docenti Giunti Scuola.
          </h3>
          <p className="disclaimer">
            Provalo gratuitamente per 30 giorni, e scopri tutti i materiali a disposizione per te:
            percorsi didattici, materiali per le lezioni e molto altro.
          </p>
          <a className="button button--primary cta" type="button" href="/io-piu">
            Abbonati a io+
          </a>
          {/* <p className="disclaimer">
            per te, <b>30 giorni di prova gratuita</b>
          </p> */}
          <p>
            Sei già abbonato?{' '}
            <a className="login" onClick={() => handleLogin(true)}>
              Accedi
            </a>
          </p>
        </>
      )
    }

    return null
  }

  return (
    <>
      <div className={`bar`}>
        <div className="bar__actions">
          {save && <Save item={item} labelGTM={labelGTM} />}
          {share && <Share item={item} labelGTM={labelGTM} print={print} />}
        </div>
        {cta}
        {children}
      </div>
      {vimeoModal && (
        <Modal
          isOpen={vimeoModal}
          alert={false}
          close={() => setVimeoModal(false)}
          classes="modal__content--wide"
        >
          <div className="modal__content__vimeo">
            <EmbeddedVimeo id={item.extra?.id_vimeo} />
          </div>
        </Modal>
      )}
      {downloadModal && (
        <Modal isOpen={downloadModal} alert={false} close={() => setDownloadModal(false)}>
          <div className="modal__content__download">
            <p className="title">
              <Trans chiave="Scarica" />
            </p>
            <p className="disclaimer">
              <Trans chiave="Lorem ipsum scarica articolo" />
            </p>
            <div className="w-container content">
              <div className="row">
                {item.extra.zip_file && (
                  <div className="col col-md-6 content__item">
                    <p>
                      <Trans chiave="Ottieni lo zip completo dei materiali" />
                    </p>
                    <a className="button button--primary" href={item.extra.zip_file} download>
                      <Trans chiave="Scarica .zip" />
                    </a>
                  </div>
                )}
                <div className="col col-md-6 content__item">
                  {item.extra.pdf_file && (
                    <>
                      <p>
                        <Trans chiave="Scarica il PDF" />
                      </p>
                      <a className="button button--primary" href={item.extra.pdf_file} download>
                        <Trans chiave="Scarica .pdf" />
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {comunicazione.stato && (
        <Modal
          isOpen={comunicazione.stato}
          alert={false}
          close={() => setComunicazione(initialComunicazione)}
          classes="modal__content--wide"
        >
          <div className="modal__content__comunicazione">{renderComunicazione(utente, item)}</div>
        </Modal>
      )}
      {login && <Login isOpen={login} next={next} setLogin={handleLogin} />}
    </>
  )
}

export default Bar
