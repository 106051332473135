import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Loader({ className }) {
  return (
    <IconBase name="loader" w={20} h={21} className={className}>
      <path
        d="M2 10.3334C2 14.7517 5.58172 18.3334 10 18.3334C14.4183 18.3334 18 14.7517 18 10.3334C18 5.9151 14.4183 2.33337 10 2.33337"
        strokeWidth="3"
        strokeLinecap="round"
        className="strokeme"
        fill="none"
      />
    </IconBase>
  )
}

export default Loader
