import React, { useContext, useEffect, useState } from 'react'
import { Image } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { api, CAS_DOMAIN, isRinnovabile } from 'utils'
import { CartContext } from 'utils/context'
import { Button, Badge } from 'react-components/atoms'
import Link from 'next/link'
import { ArrowRightLong } from 'react-components/atoms/Icons'
import { RIVISTE } from 'utils/endpoint'

const RivistaCardWideAbbonato = (props) => {
  const { className = '', rivista = null } = props

  const context = useContext(CartContext)

  const t = useTrans()

  const [subscription, setSubscription] = useState(null)

  useEffect(() => {
    if (rivista) initSubscription()
  }, [rivista])

  async function initSubscription() {
    const { data } = await api.get(`${RIVISTE}subscriptions/${rivista.rivista.id}/`)
    setSubscription(data?.subscription)
  }

  const rinnovabile = isRinnovabile(subscription) && rivista?.rivista.box_abbonamenti?.length > 0
  return (
    rivista && (
      <div className={`rivista-card-wide-abbonato ${className ? ` ${className}` : ''}`}>
        <div className="rivista-card-wide-abbonato__container__content-scadenza">
          <p className="rivista-card-wide-abbonato__title">{rivista?.titolo}</p>
          {rivista?.numero && (
            <div className="rivista-card-wide-abbonato__actions">
              <Link legacyBehavior href={rivista.rivista.url}>
                <a className="rivista-card-wide-abbonato__cta">{t('Vedi tutti i numeri')}</a>
              </Link>
            </div>
          )}
          {subscription && subscription.is_valid && (
            <div className="rivista-card-wide-abbonato__container-scadenza">
              <div className="rivista-card-wide-abbonato__badges">
                <Badge label={t('Cartaceo')} className="grey" />
                <Badge label={t('Digitale')} className="grey" />
              </div>
              <div>
                <p className="rivista-card-wide-abbonato__status">
                  {t('Il tuo abbonamento scade il')} <strong>{subscription.scadenza}</strong>
                </p>
                {rinnovabile && (
                  <div className="rivista-card-wide-abbonato__member-cta">
                    <Button
                      variant="ghost"
                      colorVariant="orange"
                      label={t('Rinnova')}
                      icon={<ArrowRightLong />}
                      iconPos="right"
                      onClick={() =>
                        context.addAbbonamentoToCart(
                          rivista.rivista.id,
                          true,
                          'pdp',
                          rivista.rivista
                        )
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {subscription && !subscription.is_valid && rinnovabile && (
            <div className="rivista-card-wide-abbonato__member-cta">
              <Button
                variant="ghost"
                colorVariant="orange"
                label={t('Rinnova')}
                icon={<ArrowRightLong />}
                iconPos="right"
                onClick={() => context.addAbbonamentoToCart(rivista.rivista.id)}
              />
            </div>
          )}
        </div>
        <div className="rivista-card-wide-abbonato__container__content-sfoglia">
          <div className="rivista-card-wide-abbonato__image-box">
            <Image
              src={CAS_DOMAIN + (rivista?.main_image || rivista.rivista?.main_image)}
              width={156}
              height={210}
              alt={rivista?.titolo?.toAltTitle()}
              title={rivista?.titolo?.toAltTitle()}
              //layout="responsive"
            />
            <div className="rivista-card-wide-abbonato__content-title-mobile">
              {rivista?.numero && (
                <p className="rivista-card-wide-abbonato__numero-mobile">
                  {rivista?.mese ? `${rivista?.mese.toUpperCase()}` : ''} {rivista?.anno_solare}
                </p>
              )}

              <p className="rivista-card-wide-abbonato__title-numero-mobile">
                {rivista?.rivista.titolo}
              </p>
            </div>
          </div>

          <div className="rivista-card-wide-abbonato__content-box">
            {rivista?.numero && (
              <p className="rivista-card-wide-abbonato__numero">
                {rivista?.mese ? `${rivista?.mese.toUpperCase()}` : ''} {rivista?.anno_solare}
              </p>
            )}
            <p className="rivista-card-wide-abbonato__title-numero">{rivista?.rivista.titolo}</p>
            <p className="rivista-card-wide-abbonato__descrizione-numero">
              {rivista?.rivista.descrizione.length > 230
                ? rivista.rivista.descrizione.slice(0, 230) + '...'
                : rivista.rivista.descrizione}
            </p>
            {rivista?.link_esterno && (
              <Button
                className="rivista-card-wide-abbonato__sfoglia"
                label={t('Sfoglia')}
                variant="secondary"
                href={rivista?.link_esterno}
                external
                newPage
              />
            )}
            {rivista.url && (
              <Link legacyBehavior href={rivista.url}>
                <a className="rivista-card-wide-abbonato__cta-numero">{t('Tutti i contenuti')}</a>
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  )
}

export default RivistaCardWideAbbonato
