import React from 'react'

const ChevronLeft = () => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.38 2.99C7.87 2.5 8.66 2.5 9.15 2.99L17.46 11.3C17.85 11.69 17.85 12.32 17.46 12.71L9.15 21.02C8.66 21.51 7.87 21.51 7.38 21.02C6.89 20.53 6.89 19.74 7.38 19.25L14.62 12L7.37 4.75C6.89 4.27 6.89 3.47 7.38 2.99Z"
      stroke="none"
    />
  </svg>
)

export default ChevronLeft
