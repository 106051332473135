import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function CatScheda({ className }) {
  return (
    <IconBase name="cat-scheda" w={30} className={className}>
      <path
        // fill="#2A2A2A"
        d="M15.5,0.9C15,0.3,14.2,0,13.4,0H2.9C1.3,0,0,1.4,0,3v24c0,1.6,1.3,3,2.9,3h17.6c1.6,0,2.9-1.4,2.9-3
	V10.2c0-0.8-0.3-1.6-0.9-2.1L15.5,0.9z M16.1,24H7.3c-0.8,0-1.5-0.7-1.5-1.5S6.5,21,7.3,21h8.8c0.8,0,1.5,0.7,1.5,1.5
	S16.9,24,16.1,24z M16.1,18H7.3c-0.8,0-1.5-0.7-1.5-1.5c0-0.8,0.7-1.5,1.5-1.5h8.8c0.8,0,1.5,0.7,1.5,1.5C17.6,17.3,16.9,18,16.1,18
	z M13.2,9V2.2l8.1,8.2h-6.6C13.8,10.5,13.2,9.8,13.2,9z"
      />
    </IconBase>
  )
}

export default CatScheda
