import React from 'react'
import { NotificationBar } from 'react-components/atoms'
import { Info2 } from 'react-components/atoms/Icons'
import { useTrans } from 'hooks'

const MessaggioCheckout = (props) => {
  const { className = '' } = props

  const t = useTrans()

  // se la traduzione è vuota -> nascondo il componente
  const messaggioCheckoutKey = 'messaggio-checkout'
  const messaggioCheckout = t(messaggioCheckoutKey)

  return (
    messaggioCheckout !== messaggioCheckoutKey && (
      <NotificationBar
        icon={<Info2 />}
        variant="info-red"
        label={messaggioCheckout}
        className={`messaggio-checkout ${className ? ` ${className}` : ''}`}
      />
    )
  )
}

export default MessaggioCheckout
