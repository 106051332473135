import React, { useState } from 'react'
import { CELLECALENDARIO } from 'utils/endpoint'
import { api, getGiornoCorrente } from 'utils'
import { useTrans } from 'hooks'
import { CalendarioAvventoModal } from 'react-components/molecules'
import { getDateStringFromDay } from 'utils'
import { useRouter } from 'next/router'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const CalendarioAvventoCellaStep2 = props => {
  const {
    cella = {
      aperta: false,
      image: '',
      mobile_image: '',
      giorno: '',
      titolo: '',
      url: '',
    },
    setStep,
    cellaCliccata,
    setCellaCliccata,
    className,
    refreshCelle = () => {},
  } = props

  const t = useTrans()
  const [open, setOpen] = useState(false)
  const router = useRouter()

  const giornoCorrente = getGiornoCorrente(router)
  const meseCorrente = new Date().getMonth()

  const icona_regalo_yellow = '/images/calendario_avvento/icona_regalo_yellow.png'
  const tick_green = '/images/calendario_avvento/tick_green.png'

  async function handleOpen() {
    //  ATTENZIONE MODIFICA FATTA A CALENDARIO FINITO: eliminato if da ripristinare per il nuovo calendario
    if (meseCorrente == 0) {
      setOpen(true)
      await api.post(CELLECALENDARIO + 'apri/', { cella: cella.id })
    } else {
      if (cella.giorno <= giornoCorrente) {
        setOpen(true)
        await api.post(CELLECALENDARIO + 'apri/', { cella: cella.id })
      }
    }
  }

  return (
    <div className={`cella-step2--${className}`} onClick={handleOpen}>
      <p className="number">{cella.giorno}</p>
      {cella.aperta && <img className="icon_calendario" src={tick_green} />}
      {cella.aperta === false && cella.giorno < giornoCorrente && (
        <img className="icon_calendario" src={icona_regalo_yellow} />
      )}

      <CalendarioAvventoModal
        cella={cella}
        open={open}
        setOpen={setOpen}
        setStep={setStep}
        icon={cella.aperta ? tick_green : icona_regalo_yellow}
        title={getDateStringFromDay(cella.giorno)}
        disclaimer={t('Ti abbiamo regalato')}
        regalo={cella.titolo}
        cellaCliccata={cella}
        setCellaCliccata={setCellaCliccata}
        className={cella.aperta ? 'green' : 'yellow'}
        labelCta={t('Clicca qui per scaricare!')}
        subdescription={
          cella.aperta
            ? t('Pssst: hai già scaricato questa risorsa!')
            : t('Te lo sei perso? Recupera subito!')
        }
        download={true}
        external={true}
        href={cella.url}
        onClose={refreshCelle}
      />
    </div>
  )
}

export default CalendarioAvventoCellaStep2
