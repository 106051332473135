import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function LandingRivisteVs({ className }) {
  return (
    <IconBase name="landing-riviste-vs" w={462} h={427} className={className}>
      <g style={{ mixBlendMode: 'overlay' }}>
        <path d="M448.467 176.909H334.021L448.467 278.211V176.909Z" stroke="none" />
      </g>
      <g style={{ mixBlendMode: 'overlay' }}>
        <path d="M349.312 426.857L225.596 322.415V426.857H349.312Z" stroke="none" />
      </g>
      <g style={{ mixBlendMode: 'overlay' }}>
        <path
          d="M283.99 179.907C278.504 180.941 273.139 182.535 267.982 184.665C254.758 190.193 243.528 199.583 235.774 211.596C227.929 224.14 223.943 238.696 224.306 253.468C224.359 259.771 225.145 266.046 226.647 272.168C228.015 278.498 230.082 284.658 232.812 290.535C237.377 299.135 243.012 307.128 249.584 314.326C259.568 325.769 270.329 336.516 281.792 346.491C295.649 359.18 309.985 371.392 324.798 383.129C333.925 390.314 341.81 396.404 348.691 401.496C355.572 406.587 366.61 415.057 382.523 427C388.496 427 393.561 426.476 397.814 426.048C401.584 425.62 405.32 424.936 408.996 424.002C412.281 423.135 415.48 421.973 418.553 420.528C431.722 414.871 442.854 405.363 450.473 393.264C458.232 380.943 462.232 366.646 461.99 352.106C462.14 340.433 459.468 328.895 454.201 318.465C447.723 306.992 439.131 296.838 428.875 288.536L304.633 178.67C297.79 178.648 290.954 179.125 284.181 180.097"
          stroke="none"
        />
      </g>
      <path d="M224.306 0H96.6235L157.502 176.91L224.306 0Z" stroke="none" />
      <path
        d="M129.404 249.948L146.034 206.22L75.8849 0H-69L15.5799 249.948H129.404Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default LandingRivisteVs
