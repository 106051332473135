import React, { useState } from 'react'
import Link from 'next/link'
import { Form, Formik } from 'formik'
import { useTrans } from 'hooks'
import { FormField, FormRadio } from 'react-components/atoms'
import { AreaPersonaleEditCard } from 'react-components/molecules'

import { useDispatch, useSelector } from 'react-redux'
import { REGISTRAZIONE } from 'utils/endpoint'
import { api, checkboxMapping } from 'utils'
import { fetchUtente } from 'containers/utente'

const AreaPersonaleNewsletterPrivacyEditCard = (props) => {
  const { utente } = useSelector((state) => state.utente)
  const dispatch = useDispatch()

  const t = useTrans()
  const [editMode, setEditMode] = useState(false)

  return (
    <Formik
      initialValues={{
        privacy_commerciale: checkboxMapping[utente?.privacy_commerciale] || '0',
      }}
      onSubmit={async (values, { resetForm }) => {
        await api.post(REGISTRAZIONE + 'edit_privacy/', { ...values })
        dispatch(fetchUtente())
        resetForm()
        setEditMode(false)
      }}
      enableReinitialize
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <AreaPersonaleEditCard
            title={t('Gestione privacy')}
            description={
              <>
                <p>
                  {t(
                    'Accetto il trattamento dei dati, per la durata prevista dall’informativa, per restare aggiornato su novità e promozioni dalle aziende del Gruppo Giunti.'
                  )}
                </p>
                <p>
                  {t(
                    'Acconsento alla profilazione per restare aggiornato su novità e promozioni dalle aziende del Gruppo Giunti in modo personalizzato.'
                  )}
                </p>
                <p>
                  <Link legacyBehavior href="/privacy-policy">
                    <a target="_blank">{t('Termini e condizioni')}</a>
                  </Link>
                </p>
              </>
            }
            className="area-personale-newsletter-privacy-edit-card"
            editMode={editMode}
            setEditMode={setEditMode}
            onSubmit={handleSubmit}
          >
            <FormField>
              <FormRadio
                id="newsletter-privacy"
                name="privacy_commerciale"
                value={
                  editMode
                    ? values.privacy_commerciale
                    : checkboxMapping[utente.privacy_commerciale]
                }
                options={[
                  {
                    value: '1',
                    label: 'Acconsento',
                    disabled: !editMode && checkboxMapping[utente.privacy_commerciale] !== '1',
                  },
                  {
                    value: '0',
                    label: 'Non acconsento',
                    disabled: !editMode && checkboxMapping[utente.privacy_commerciale] !== '0',
                  },
                ]}
                readOnly={!editMode}
                onChange={handleChange}
              />
            </FormField>
          </AreaPersonaleEditCard>
        </Form>
      )}
    </Formik>
  )
}

export default AreaPersonaleNewsletterPrivacyEditCard
