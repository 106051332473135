import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Dashboard({ className }) {
  return (
    <IconBase name="dashboard" w={20} className={className}>
      <path
        d="M2.5 10.8333H9.16667V2.5H2.5V10.8333ZM2.5 17.5H9.16667V12.5H2.5V17.5ZM10.8333 17.5H17.5V9.16667H10.8333V17.5ZM10.8333 2.5V7.5H17.5V2.5H10.8333Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default Dashboard
