import React from 'react'
import { BundlePanelItem } from 'react-components/molecules'
import { Close } from 'react-components/atoms/Icons'

const BundlePanel = (props) => {
  const { prodotto, className = '', closePanel = () => {} } = props

  return (
    <div className={`bundle-panel ${className ? ` ${className}` : ''}`}>
      <button onClick={closePanel} className="bundle-panel__close">
        <Close />
      </button>
      <p className="bundle-panel__title">{prodotto.titolo}</p>
      <div
        className="bundle-panel__description"
        dangerouslySetInnerHTML={{ __html: prodotto.descrizione }}
      />
      <div className="bundle-panel__items">
        {prodotto?.prodottiBundle?.map((item) => (
          <BundlePanelItem item={item.cartRow} />
        ))}
      </div>
    </div>
  )
}

export default BundlePanel
