import React, { useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import { useTrans } from 'hooks'
import { FormField, FormCheckbox } from 'react-components/atoms'
import { AreaPersonaleEditCard } from 'react-components/molecules'

import { UTENTE } from 'utils/endpoint'
import { api } from 'utils'

const AreaPersonaleNewsletterPreferitiEditCard = props => {
  const t = useTrans()

  const [editMode, setEditMode] = useState(false)
  const [newsletter, setNewsletter] = useState([])

  async function fetchNewsletter() {
    const response = await api.get(UTENTE + 'get_newsletter/')
    setNewsletter(response.data['newsletter'])
  }

  useEffect(() => {
    fetchNewsletter()
  }, [])

  const initialValues = newsletter.reduce(
    (obj, item) => ({
      ...obj,
      [item['value']]: item.iscritto,
    }),
    {}
  )

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={async (values, { resetForm }) => {
        await api.post(UTENTE + 'set_newsletter/', { ...values })
        fetchNewsletter()
        resetForm()
        setEditMode(false)
      }}
      enableReinitialize
    >
      {({ values, handleChange, handleSubmit }) => (
        <Form>
          <AreaPersonaleEditCard
            title={t('I miei argomenti preferiti per le newsletter')}
            className="area-personale-newsletter-preferiti-edit-card"
            editMode={editMode}
            setEditMode={setEditMode}
            onSubmit={handleSubmit}
          >
            <div className="area-personale-newsletter-preferiti-edit-card__list">
              {newsletter.map(preferenza => (
                <FormField key={preferenza.value}>
                  <FormCheckbox
                    id={preferenza.value}
                    label={preferenza.label}
                    name={preferenza.value}
                    value={values[preferenza.value]}
                    checked={editMode ? values[preferenza.value] : preferenza.iscritto}
                    readOnly={!editMode}
                    disabled={!editMode && !preferenza.iscritto}
                    onChange={handleChange}
                  />
                </FormField>
              ))}
            </div>
          </AreaPersonaleEditCard>
        </Form>
      )}
    </Formik>
  )
}

export default AreaPersonaleNewsletterPreferitiEditCard
