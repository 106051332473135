import React from 'react'
import Tooltip from 'components/atomic/Tooltip'
import BaseTooltip from 'react-tooltip'
import { renderToString } from 'react-dom/server'

const ReactTooltip = ({
  label = '',
  id = '',
  pos = 'left',
  className = 'react-tooltip--base',
  children = null,
}) => {
  return (
    <span
      data-for={id}
      data-tip={renderToString(<Tooltip value={label} />)}
      data-offset="{'left': 5}"
    >
      {children}
      <BaseTooltip
        id={id}
        html
        type="light"
        place={pos}
        effect="solid"
        wrapper="span"
        className={`react-tooltip ${className}`}
      />
    </span>
  )
}

export default ReactTooltip
