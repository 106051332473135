import { fetchUtente } from 'containers/utente'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-components/atoms'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { api, CAS_DOMAIN, LOGIN_URL } from 'utils'
import { POPUP, UTENTE } from 'utils/endpoint'

const PopupGenerico = () => {
  const router = useRouter()

  const dispatch = useDispatch()
  const { utente } = useSelector((state) => state.utente)

  const [open, setOpen] = useState(false)
  const [popup, setPopup] = useState(null)

  function hasSession(id) {
    return sessionStorage.getItem(`popup-generico-${id}`)
  }

  function close(id) {
    sessionStorage.setItem(`popup-generico-${id}`, true)
  }

  function checkUser(popup) {
    if (popup.guest && popup.logged) return true
    if (popup.guest) return !Boolean(utente)
    if (popup.logged) return Boolean(utente)
  }

  useEffect(() => {
    fetchPopup()
  }, [])

  useEffect(() => {
    if (popup && !(popup.aggiornamento_privacy && utente?.aggiornamento_privacy)) setOpen(true)
  }, [popup])

  async function fetchPopup() {
    const { data } = await api.get(POPUP)
    if (!data) return
    const popup = data.find(
      (p) => p.object_url && p.object_url.replace(/\/$/g, '') === router.asPath.replace(/\/$/g, '')
    )
    if (popup && !hasSession(popup.id) && checkUser(popup)) {
      setPopup(popup)
    }
  }

  async function aggiornamentoPrivacy() {
    if (!popup?.aggiornamento_privacy) return

    await api.post(`${UTENTE}aggiornamento_privacy/`)
    dispatch(fetchUtente())
  }

  return popup ? (
    <Modal open={open} setOpen={setOpen} closeButton onClose={() => close(popup?.id)}>
      <div className="popup-generico">
        <h2 className="popup-generico__title">{popup.title}</h2>
        {popup.image && (
          <img className="popup-generico__image" src={CAS_DOMAIN + popup.image} alt={popup.alias} />
        )}
        <p className="popup-generico__description">{popup.description}</p>
        <Button
          className="popup-generico__cta"
          variant="primary"
          label={popup.cta}
          href={popup.url.includes(LOGIN_URL) ? `${popup.url}?next=${router.asPath}` : popup.url}
          newPage={popup.blank}
          external
          onClick={() => {
            close(popup?.id)
            aggiornamentoPrivacy()
          }}
        />
      </div>
    </Modal>
  ) : null
}

export default PopupGenerico
