import React from 'react'

function Separator({ className }) {
  return (
    <svg
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--separator ${className ? className : ''}`}
    >
      <path
        d="M2 9.5C2 9.22386 2.22386 9 2.5 9H17.5C17.7761 9 18 9.22386 18 9.5C18 9.77614 17.7761 10 17.5 10H2.5C2.22386 10 2 9.77614 2 9.5Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default Separator
