import { useEffect, useState } from 'react'
import { widgetMapping } from 'pages/area-personale/index'
import { WidgetToggle } from 'react-components/atoms'

import { api } from 'utils'
import { WIDGET } from 'utils/endpoint'
import usePermissions from 'hooks/usePermissions'

const FormToggleWidget = ({ selected, setSelected }) => {
  const { filterWidgetByType } = usePermissions()
  const [widgets, setWidgets] = useState([])

  useEffect(() => {
    fetchWidgets()
  }, [])

  async function fetchWidgets() {
    const response = await api.get(WIDGET)
    const { data } = response

    setWidgets(
      filterWidgetByType(data)?.map(w => ({
        ...w,
        widget_options: w,
        widget: widgetMapping[w.type],
      }))
    )
  }

  const handleSelected = id => {
    selected?.includes(id)
      ? setSelected(prev => prev?.filter(item => item !== id))
      : setSelected(prev => [...prev, id])
  }

  return (
    <div className="form-toggle-widget">
      <div className="form-toggle-widget__list">
        {widgets?.map(widget => (
          <WidgetToggle
            title={widget.widget_options.title}
            description={widget.widget_options.description}
            checked={selected?.includes(widget.id)}
            setChecked={() => handleSelected(widget.id)}
          />
        ))}
      </div>
    </div>
  )
}

export default FormToggleWidget
