import GenericMenu from './menu/GenericMenu'
import Icon from '../atomic/Icon'
import React from 'react'
import { sendGTMData } from '../../utils/gtm'
import { useTrans } from 'hooks'

const Footer = React.memo(() => {
  const handleGTM = (itemType, item) => {
    if (itemType == 'newsletter') {
      sendGTMData({
        event: 'GAevent',
        eventID: '07',
        eventCategory: 'newsletter',
        eventAction: 'iscrizione_nl',
        eventLabel: 'iscrizione_nl_ok',
      })
    }

    if (itemType && item) {
      if (itemType == 'footer') {
        sendGTMData({
          event: 'GAevent',
          eventID: '04',
          eventCategory: 'footer',
          eventAction: item,
          eventLabel: 'non_valorizzata',
        })
      }
      if (itemType == 'social') {
        sendGTMData({
          event: 'GAevent',
          eventID: '06',
          eventCategory: 'social',
          eventAction: item,
          eventLabel: 'non_valorizzata',
        })
      }
    }
  }

  const t = useTrans()

  return (
    <footer id="footer">
      <div className="footer__content">
        <div className="footer__logo">
          <a
            href="/"
            className="footer__logo-link"
            onClick={() => handleGTM('footer', 'logo_footer')}
          >
            <Icon id="logo-giuntiscuola-color" />
          </a>
        </div>
        <div className="footer__center">
          <GenericMenu id="footer" classes="footer" />
        </div>
        <div className="footer__right">
          <ul className="footer__social">
            <li className="footer__social-item">
              <a
                href="https://it-it.facebook.com/giuntiscuola/"
                target="_blank"
                onClick={() => handleGTM('social', 'facebook')}
                target="_blank"
              >
                <Icon id="facebook" />
              </a>
            </li>
            <li className="footer__social-item">
              <a
                href="https://www.instagram.com/giunti_scuola/"
                target="_blank"
                onClick={() => handleGTM('social', 'instagram')}
                target="_blank"
              >
                <Icon id="instagram" />
              </a>
            </li>
            <li className="footer__social-item">
              <a
                href="https://www.youtube.com/user/GiuntiScuola"
                target="_blank"
                onClick={() => handleGTM('social', 'youtube')}
                target="_blank"
              >
                <Icon id="youtube" />
              </a>
            </li>
            <li className="footer__social-item">
              <a
                href="https://vimeo.com/giuntiscuola"
                target="_blank"
                onClick={() => handleGTM('social', 'vimeo')}
              >
                <Icon id="vimeo" />
              </a>
            </li>
            <li className="footer__social-item">
              <a
                href="https://www.pinterest.it/giuntiscuola/"
                target="_blank"
                onClick={() => handleGTM('social', 'pinterest')}
              >
                <Icon id="pinterest" classes="icon--pinterest" />
              </a>
            </li>
            <li className="footer__social-item">
              <a
                href="https://www.linkedin.com/company/giuntiscuola/"
                target="_blank"
                onClick={() => handleGTM('social', 'linkedin')}
              >
                <Icon id="linkedin" classes="icon--linkedin" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="footer-credits">
        <div className="footer-credits__content">
          <div className="footer-credits__copy">
            Copyright © {new Date().getFullYear()} Giunti Scuola P. IVA 05492160485, tutti i diritti
            riservati
          </div>
          <div className="footer-credits__links">
            <div className="footer-credits__link">
              <a href={t('/condizioni-di-vendita')}>{t('Condizioni di vendita')}</a>
            </div>
            <div className="footer-credits__link" onClick={() => Cookiebot.renew()}>
              <a>{t('Gestisci i cookie')}</a>
            </div>
            <div className="footer-credits__link">
              <a
                href="https://gruppogiunti.magnewsemail.com/nl/gruppogiunti_page1637.mn"
                onClick={() => handleGTM('newsletter')}
                target="_blank"
              >
                Iscriviti alla newsletter
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
})

export default Footer
