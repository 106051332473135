import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function IoPiu({ className }) {
  return (
    <IconBase name="io-piu" w={64} h={50} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.18919 22.1982C7.44536 22.1982 9.25029 23.9223 9.25029 26.0775V44.3965C9.25029 46.5516 7.44536 48.2758 5.18919 48.2758C2.93302 48.2758 1.12808 46.5516 1.12808 44.3965V26.0775C0.902468 23.9223 2.70515 22.1982 5.18919 22.1982ZM0 13.5775C0 10.7758 2.48179 8.62061 5.18919 8.62061C8.12221 8.62061 10.3784 10.7758 10.3784 13.5775C10.3784 16.3792 8.12221 18.5344 5.18919 18.5344C2.25617 18.5344 0 16.3792 0 13.5775Z"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.786 36.2067C31.786 32.3274 29.4054 29.9567 25.9482 29.9567C22.4865 29.9567 20.1103 32.5429 20.1103 36.2067C20.1103 39.8705 22.4865 42.4567 25.9482 42.4567C29.4054 42.4567 31.786 39.8705 31.786 36.2067ZM12.1082 36.2067C12.1082 28.4481 18.3806 22.4136 25.9482 22.4136C33.5157 22.4136 39.7838 28.4481 39.7838 36.2067C39.7838 43.9653 33.5157 49.9998 25.9482 49.9998C18.3806 49.9998 12.1082 43.9653 12.1082 36.2067Z"
        stroke="none"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.4398 10.8223H45.4519V3.83289C45.4519 1.80371 47.0352 0 49.2972 0C51.3329 0 53.1425 1.57825 53.1425 3.83289V10.8223H60.1546C62.1903 10.8223 63.9999 12.4005 63.9999 14.6552C63.9999 16.6843 62.4165 18.4881 60.1546 18.4881H53.1425V25.4775C53.1425 27.5066 51.5591 29.3103 49.2972 29.3103C47.2614 29.3103 45.4519 27.7321 45.4519 25.4775V18.4881H38.4398C36.404 18.4881 34.5945 16.9098 34.5945 14.6552C34.8207 12.626 36.404 10.8223 38.4398 10.8223Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default IoPiu
