import React from 'react'
import { useTrans } from 'hooks'
import { Cart } from 'react-components/atoms/Icons'
import { EmptyMessage } from 'react-components/molecules'

const EmptyCartMessage = props => {
  const { className = '' } = props

  const t = useTrans()

  return (
    <EmptyMessage
      icon={<Cart />}
      titolo={t('Il tuo carrello è ancora vuoto')}
      descrizione={t('Libri, riviste e tanti materiali digitali: trova la risorsa per te.')}
      ctaLabel={t('Libri parascolastico')}
      href="/parascolastico"
      ctaLabel2={t('Libri di testo')}
      href2="/corsoadozionale"
      ctaLabel3={t('Riviste')}
      href3="/riviste"
      className={className}
    />
  )
}

export default EmptyCartMessage
