import React, { useEffect, useState } from 'react'
import Link from 'next/link'
import { useTrans } from 'hooks'
import {
  LightBulb,
  Devices,
  Logout,
  Puzzle,
  Book,
  Dashboard,
  Profilo,
  Plus,
  Minus,
  Calendar,
  Lock,
  ShoppingBag,
  Pin2,
} from 'react-components/atoms/Icons'
import { Badge } from 'react-components/atoms'

import { LISTAVALIDAZIONI, UTENTE } from 'utils/endpoint'
import { ADMIN_DOMAIN, api, AREA_AGENTI_URL } from 'utils'
import { useSelector } from 'react-redux'
import { sendGTMData } from '../../../utils/gtm'

const getAreaPersonaleMenuItemsGroups = (utente) => [
  [
    {
      key: 'bacheca',
      label: 'Bacheca',
      icon: <Dashboard />,
      link: '/area-personale',
    },
  ],
  [
    {
      key: 'profilo',
      label: 'Il mio profilo',
      icon: <Profilo />,
      items: [
        {
          key: 'profilo__dati',
          label: 'I miei dati',
          link: '/area-personale/profilo',
        },
        {
          key: 'profilo__scuole',
          label: 'Le mie scuole',
          link: '/area-personale/profilo/scuole',
        },
        {
          key: 'profilo__conoscere',
          label: 'Mi voglio far conoscere',
          link: '/area-personale/profilo/conoscere',
        },
        {
          key: 'profilo__newlsetter',
          label: 'Le mie newsletter',
          link: '/area-personale/profilo/newsletter',
        },
      ],
    },
    {
      key: 'attiva',
      label: 'Attiva codice',
      icon: <Lock />,
      link: '/area-personale/libreria',
    },
  ],
  [
    {
      key: 'libreria',
      label: 'La mia libreria',
      icon: <Book />,
      items: [
        {
          key: 'libreria__libri',
          label: 'I miei libri',
          link: '/area-personale/libreria/libri',
        },
        {
          key: 'libreria__contenuti',
          label: 'I miei contenuti',
          link: '/area-personale/libreria/contenuti',
        },
        {
          key: 'libreria__raccolte',
          label: 'Le mie raccolte',
          link: '/area-personale/libreria/raccolte',
        },
        {
          key: 'libreria__riviste',
          label: 'Le mie riviste',
          link: '/area-personale/libreria/riviste',
        },
      ],
    },
    {
      key: 'area-formazione',
      label: 'Area formazione',
      icon: <Calendar />,
      link: '/area-personale/area-formazione',
    },
    {
      key: 'interessi',
      label: 'I miei interessi',
      icon: <Puzzle />,
      link: '/area-personale/miei-interessi',
    },
    {
      key: 'redazione',
      label: 'Dalla redazione',
      icon: <LightBulb />,
      link: '/area-personale/dalla-redazione',
    },
    {
      key: 'richieste',
      label: 'Richieste di validazione',
      icon: <Calendar />,
      link: utente.is_agente_abilitato
        ? `${ADMIN_DOMAIN}${AREA_AGENTI_URL}`
        : '/area-personale/richieste-validazione',
      blank: true,
    },
  ],
  [
    {
      key: 'ordini',
      label: 'I miei ordini',
      icon: <ShoppingBag />,
      link: '/area-personale/ordini',
    },
    {
      key: 'indirizzi',
      label: 'I miei indirizzi',
      icon: <Pin2 />,
      link: '/area-personale/indirizzi',
    },
  ],
  [
    {
      key: 'dispositivi',
      label: 'Dispositivi associati',
      icon: <Devices />,
      link: '/area-personale/dispositivi',
    },
    {
      key: 'logout',
      label: 'Esci',
      icon: <Logout />,
    },
  ],
]

const AreaPersonaleMenuItem = (props) => {
  const {
    label = '',
    link = '#',
    blank = false,
    icon = null,
    active = false,
    activeItemKey = '',
    level = 1,
    items = [],
    nuovo = false,
    className = '',
    logout = false,
    parent_label = '',
  } = props

  const [open, setOpen] = useState(active && items.length > 0)
  const t = useTrans()

  const handleLogout = () => {
    api.get(UTENTE + 'logout/').then((response) => {
      window.location.href = response.data.redirect_url
    })
  }
  const handleGTM = (label) => {
    if (level === 1) {
      sendGTMData({
        event: 'GAevent',
        eventID: '19',
        eventCategory: 'area_riservata',
        eventAction: label.toGTMFormat(),
        eventLabel: 'non_valorizzata',
      })
    } else {
      sendGTMData({
        event: 'GAevent',
        eventID: '19',
        eventCategory: 'area_riservata',
        eventAction: parent_label.toGTMFormat(),
        eventLabel: label.toGTMFormat(),
      })
    }
  }
  return (
    <div
      className={`area-personale-menu-item area-personale-menu-item--level-${level} area-personale-menu-item--parent${
        active ? ' area-personale-menu-item--active' : ''
      }${open ? ' area-personale-menu-item--open' : ''}${className ? ` ${className}` : ''}`}
    >
      {items.length > 0 ? (
        <>
          <button
            type="button"
            aria-label={label}
            onClick={() => setOpen(!open)}
            className="area-personale-menu-item__trigger"
          >
            {icon && <span className="area-personale-menu-item__icon">{icon}</span>}
            {label && <span className="area-personale-menu-item__label">{label}</span>}
            {nuovo && <Badge label={t('Nuovi')} />}
            <span className="area-personale-menu-item__toggle-icon">
              {open ? <Minus /> : <Plus />}
            </span>
          </button>
          <div className="area-personale-menu-item__list">
            {items.map((item) => (
              <AreaPersonaleMenuItem
                level={level + 1}
                active={activeItemKey === item.key || activeItemKey.startsWith(`${item.key}__`)}
                key={item.key}
                parent_label={label}
                {...item}
              />
            ))}
          </div>
        </>
      ) : !logout ? (
        <Link legacyBehavior href={link}>
          <a
            className="area-personale-menu-item__trigger"
            target={blank ? '_blank' : null}
            rel={blank ? 'nofollow' : null}
            onClick={() => handleGTM(label)}
          >
            {icon && <span className="area-personale-menu-item__icon">{icon}</span>}
            {label && <span className="area-personale-menu-item__label">{label}</span>}
            {nuovo && <Badge label={t('Nuovi')} className="area-personale-menu-item__badge" />}
          </a>
        </Link>
      ) : (
        <p className="area-personale-menu-item__trigger" onClick={handleLogout}>
          {icon && <span className="area-personale-menu-item__icon">{icon}</span>}
          {label && <span className="area-personale-menu-item__label">{label}</span>}
        </p>
      )}
    </div>
  )
}

const AreaPersonaleMenu = (props) => {
  const { activeItemKey = '' } = props

  const { utente } = useSelector((state) => state.utente)

  const [numRichieste, setNumRichieste] = useState({ totali: 0, nuove: 0 })

  const t = useTrans()

  useEffect(() => {
    if (utente) checkRichieste()
  }, [utente])

  function checkRichieste() {
    api
      .get(LISTAVALIDAZIONI)
      .then((response) => {
        const items = response.data
        setNumRichieste({
          totali: items.length,
          nuove: items.filter((richiesta) => !richiesta.validato).length,
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const groups = getAreaPersonaleMenuItemsGroups(utente)

  return (
    <div className="area-personale-menu">
      {groups.map((group, i) => (
        <>
          <div className="area-personale-menu__group" key={i}>
            {group.map((item) => {
              if (item.key == 'richieste' && !numRichieste.totali) return null
              return (
                <AreaPersonaleMenuItem
                  label={t(item.label)}
                  icon={item.icon}
                  link={item.link}
                  blank={item.blank}
                  active={activeItemKey === item.key || activeItemKey.startsWith(`${item.key}__`)}
                  activeItemKey={activeItemKey}
                  items={item.items}
                  nuovo={
                    (item.key === 'libreria' && utente && utente.has_nuove_risorse) ||
                    (item.key == 'richieste' && Boolean(numRichieste.nuove))
                  }
                  logout={item.key === 'logout'}
                  key={item.key}
                />
              )
            })}
            {i < groups.length - 2 && (
              <span className="area-personale-menu__section">{t(`${i}-area-personale`)}</span>
            )}
          </div>
        </>
      ))}
    </div>
  )
}

export default AreaPersonaleMenu
