import React from 'react'
import { useTrans } from 'hooks'
import { LandingRivistePc, IoPiu } from 'react-components/atoms/Icons'
import { WContainer } from 'react-components/atoms'
import { LandingRivisteBox, LandingRivisteSlider } from 'react-components/molecules'

const LandingRivisteDigitale = props => {
  const {
    item = undefined,
    contribuisciItem = undefined,
    setZoomModalItem = undefined,
    className = '',
  } = props

  const t = useTrans()

  return item ? (
    <div className={`landing-riviste-digitale ${className ? ` ${className}` : ''}`}>
      <span className="landing-riviste-digitale__bg">
        <IoPiu />
      </span>
      <WContainer>
        <div className={'landing-riviste-digitale__content'}>
          {!!item.name && (
            <p className="landing-riviste-digitale__title">
              <span className="landing-riviste-digitale__title-icon">
                <LandingRivistePc />
              </span>
              <span className="landing-riviste-digitale__title-label">{item.name}</span>
            </p>
          )}
          {!!item.description && (
            <div
              className="landing-riviste-digitale__description"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
        </div>
        <div className="landing-riviste-digitale__list">
          <LandingRivisteSlider items={item.children} setZoomModalItem={setZoomModalItem} />
        </div>
        {contribuisciItem && (
          <div className="landing-riviste-digitale__bottom">
            <LandingRivisteBox
              color="orange-dark"
              item={contribuisciItem}
              className="landing-riviste-digitale__box"
            />
            <div className="landing-riviste-digitale__iopiu">
              <IoPiu />
            </div>
          </div>
        )}
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default LandingRivisteDigitale
