export default function Custom404() {
  return (
    <main className="page404">
      <div className="w-container">
        <div className="row">
          <div className="col-12 col-md-5">
            <div className="page404__image-box">
              <img
                src={'/images/demo/404.png'}
                className="page404__image"
                alt={'Not found'?.toAltTitle()}
                title={'Not found'?.toAltTitle()}
              />
            </div>
          </div>
          <div className="col-12 col-md-7">
            <div className="page404__content">
              <h1 className="page404__title">Oops!</h1>
              <p className="page404__description">
                Non riusciamo a trovare la pagina che stavi cercando.
              </p>
              <p className="page404__errorcode">Codice errore: 404</p>
              <div className="page404__cta-box">
                <a className="page404__cta button button--primary" href="/">
                  Torna indietro
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}
