import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function LogoSymbol({ className }) {
  return (
    <IconBase name="logo-symbol" w={30} h={31} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7309 12.9794H26.7282C26.2403 10.6611 23.645 9.12084 20.7723 9.508C17.7915 9.90933 15.6437 12.2381 15.9778 14.7074C16.222 16.5174 17.7316 17.9013 19.6955 18.3514C18.8641 19.7191 17.4427 20.7153 15.7351 20.9456C12.7264 21.3522 9.95882 19.2411 9.55279 16.2351C9.53703 16.1312 9.52915 16.0237 9.52233 15.9198C9.14519 11.6327 12.9197 7.65559 18.1161 6.95524C20.3301 6.65568 22.45 7.00297 24.2185 7.82295C21.6621 4.7613 17.6423 3.0369 13.3982 3.60873C6.82985 4.49428 2.22385 10.5352 3.10891 17.106C3.9903 23.6726 10.0334 28.2781 16.6033 27.3905C23.1712 26.5081 27.7761 20.4661 26.8911 13.8964C26.8517 13.5874 26.7939 13.2826 26.7309 12.9794Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default LogoSymbol
