import Icon from './Icon'

function Scarica({ href = null, onClick = () => {}, download = false, horizontal = false }) {
  if (href) {
    return (
      <a
        className={`scarica ${horizontal ? 'scarica--horizontal' : ''}`}
        href={href}
        download={download}
        onClick={onClick}
      >
        <Icon id="download" classes="icon--download" tooltip={'Scarica'} />
      </a>
    )
  }

  return (
    <div className={`scarica ${horizontal ? 'scarica--horizontal' : ''}`} onClick={onClick}>
      <Icon id="download" classes="icon--download" tooltip={'Scarica'} />
    </div>
  )
}

export default Scarica
