import React from 'react'
import { WContainer } from 'react-components/atoms'

const LandingRivisteMotivi = props => {
  const { item = undefined, className = '' } = props

  return item ? (
    <div className={`landing-riviste-motivi ${className ? ` ${className}` : ''}`}>
      <WContainer>
        {!!item.name && (
          <p
            className="landing-riviste-motivi__title"
            dangerouslySetInnerHTML={{ __html: item.name }}
          />
        )}
        {item.children?.filter(x => !!x.description)?.length > 0 && (
          <div className="landing-riviste-motivi__list">
            {item.children
              ?.filter(x => !!x.description)
              .map((x, i) => (
                <div
                  className={`landing-riviste-motivi__item ${
                    i % 4 == 0 || i % 4 == 3 ? 'u-landing-riviste-bg-light' : ''
                  }`}
                  key={x.pk}
                  dangerouslySetInnerHTML={{ __html: x.description }}
                />
              ))}
          </div>
        )}
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default LandingRivisteMotivi
