import React from 'react'
import { Image } from 'react-components/atoms'
import Link from 'next/link'
import { CAS_DOMAIN } from 'utils'
import { sendProductClickGTM } from '../../../utils/gtm'

const NumeroRivistaCard = (props) => {
  const { numero, className = '', position, parentList } = props

  return (
    <div className={`numero-rivista-card ${className ? ` ${className}` : ''}`}>
      <Link legacyBehavior href={numero.url}>
        <a
          onClick={() =>
            sendProductClickGTM(numero, parentList ? parentList : 'navigazione_standard', position)
          }
        >
          <Image
            src={CAS_DOMAIN + numero.main_image}
            alt={numero.titolo?.toAltTitle()}
            title={numero.titolo?.toAltTitle()}
            className="numero-rivista-card__image"
            width={200}
            height={272}
          />
        </a>
      </Link>
      <p className="numero-rivista-card__data">
        {numero.mese} {numero.anno_solare}
      </p>
      <Link legacyBehavior href={numero.url}>
        <a className="numero-rivista-card__title">{numero.titolo}</a>
      </Link>
    </div>
  )
}

export default NumeroRivistaCard
