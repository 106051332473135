import React from 'react'

const TabTrigger = props => {
  const {
    icon = null,
    label = '',
    active = false,
    onClick = () => {},
    anchorId = '',
    variant = 'grey',
  } = props

  return (
    <button
      type="button"
      className={`tab-trigger${active ? ` tab-trigger--active` : ''} tab-trigger--${variant}`}
      onClick={onClick}
    >
      {icon && <span className="tab-trigger__icon">{icon}</span>}
      {label && (
        <a className="tab-trigger__label" href={`#${anchorId}`} id={anchorId}>
          {label}
        </a>
      )}
    </button>
  )
}

const Tab = props => {
  const { active = false, className = '', children = null, variant = 'grey' } = props

  return active ? (
    <div
      className={`tab${active ? ` tab--active` : ''}${
        className ? ` ${className}` : ''
      } tab--${variant}`}
    >
      {children}
    </div>
  ) : (
    <></>
  )
}

Tab.Trigger = TabTrigger

export default Tab
