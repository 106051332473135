import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { Info, DashboardCustomize } from 'react-components/atoms/Icons'
import { Button, Modal } from 'react-components/atoms'
import { WidgetCol } from 'react-components/molecules'
import { AreaPersonalePage, AreaPersonaleOnBoardingModal } from 'react-components/organisms'
import { FormToggleWidget } from 'react-components/organisms/Forms'

import { api, isDocente } from 'utils'
import { WIDGET } from 'utils/endpoint'
import { useSelector } from 'react-redux'
import Link from 'next/link'

const BachecaTemplate = ({ widgets, fetchWidgets }) => {
  const t = useTrans()
  const { utente } = useSelector((state) => state.utente)
  const [modalOpen, setModalOpen] = useState(false)
  const [onBoardingOpen, setOnBoardingOpen] = useState(
    !utente?.ultimo_accesso_area_personale ? true : false
  )
  const [selected, setSelected] = useState([])
  const [widgetGroups, setWidgetGroups] = useState([])
  const [modalAdotattarioOpen, setModalAdottatarioOpen] = useState(false)

  useEffect(() => {
    setWidgetGroups(getWidgetGroups(widgets))
  }, [widgets])

  useEffect(() => {
    if (utente && !utente.ultimo_accesso_area_personale) setOnBoardingOpen(true)
  }, [utente])

  async function handleSave() {
    await api.post(`${WIDGET}associa/`, { ids: selected })
  }

  useEffect(() => {
    if (!sessionStorage.getItem(`showed-modal-adottatario`)) {
      setModalAdottatarioOpen(true)
    }
  }, [utente])

  function closedModal() {
    sessionStorage.setItem(`showed-modal-adottatario`, true)
  }

  // raggruppa i widget a due a due se sono stackable e larghi uguali (si mettono in colonna)
  return (
    <AreaPersonalePage pagina={{}} activeMenuKey="bacheca" className="bacheca-template">
      {/* INIZIO POPUP LAVORI IN CORSO */}
      {utente.is_adottatario && (
        <Modal
          open={modalAdotattarioOpen}
          setOpen={() => {
            setModalAdottatarioOpen(false)
            closedModal()
          }}
          closeButton
        >
          <h3
            className="modal__body__title"
            style={{ textAlign: 'center', marginBottom: '20px', fontSize: '30px' }}
          >
            {t('Lavori in corso...')}
          </h3>
          <div
            className="modal__body__text"
            style={{ textAlign: 'center', marginBottom: '20px' }}
            dangerouslySetInnerHTML={{
              __html: t(
                'Se vuoi aggiornare la scuola per attivare un corso, torna a settembre: ci stiamo preparando al back to school!'
              ),
            }}
          />
        </Modal>
      )}
      {/* FINE POPUP LAVORI IN CORSO */}
      <div className="bacheca-template__head">
        <div className="bacheca-template__head__row-mobile">
          <h1 className="bacheca-template__title">{t('Bacheca')}</h1>
          <Button
            label={t('Come funziona?')}
            variant="ghost"
            icon={<Info />}
            className="bacheca-template__tooltip"
            onClick={() => setOnBoardingOpen(true)}
          />
        </div>
        <div className="bacheca-template__head__row-mobile">
          <Button
            label={t('Aggiungi o modifica')}
            variant="ghost"
            icon={<DashboardCustomize />}
            className="bacheca-template__open-widget"
            onClick={() => {
              //? Preseleziono i widget che ho nella bacheca
              const widgetGroup = widgetGroups?.map((widgetGroup) => widgetGroup)
              const selectedItems = widgetGroup
                ?.map((value) => value?.map((item) => widgets[item]?.id))
                ?.flat()
              setSelected([...selectedItems])
              setModalOpen(true)
            }}
          />
        </div>
      </div>
      <div className="bacheca-template__widget-list">
        {/* INIZIO banner propaganda 2023 */}
        {/* {utente && isDocente(utente) && (
          <WidgetCol size={1} bannerPropaganda>
            <Link legacyBehavior href="/corsoadozionale?novita">
              <a
                className="bacheca-template__widget-list__banner-propaganda"
                style={{
                  backgroundImage: `url(/images/widget/banner_propaganda_2023.png)`,
                }}
              />
            </Link>
          </WidgetCol>
        )} */}
        {/* FINE banner propaganda 2023 */}
        {widgetGroups?.map((widgetGroup, i) => (
          <WidgetCol
            size={widgets[widgetGroup[0]]?.size}
            widgets={widgetGroup.map((index) => widgets[index])}
            key={`widget-col__${i}`}
          />
        ))}
      </div>

      <Modal
        open={modalOpen}
        setOpen={(v) => {
          setModalOpen(v)
        }}
        closeButton
        className="bacheca-template__widget-modal"
      >
        <Modal.Content
          title={t('Aggiungi widget alla bacheca')}
          description={t(
            'Seleziona i widget da aggiungere alla tua bacheca e deseleziona quelli che vuoi rimuovere.'
          )}
        >
          <div className="bacheca-template__widget-modal__form-wrap">
            <FormToggleWidget selected={selected} setSelected={setSelected} />
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            label={t('Salva')}
            onClick={() => {
              handleSave().then(() => fetchWidgets())
              setModalOpen(false)
            }}
          />
        </Modal.Buttons>
      </Modal>
      <AreaPersonaleOnBoardingModal open={onBoardingOpen} setOpen={setOnBoardingOpen} />
    </AreaPersonalePage>
  )
}

export default BachecaTemplate

export const getWidgetGroups = (widgets) => {
  let groups = []
  for (let i = 0; i < widgets.length; i++) {
    if (
      widgets[i].stackable &&
      widgets[i - 1]?.stackable &&
      widgets[i].size === widgets[i - 1].size &&
      groups[groups.length - 1].length < 2
    )
      groups[groups.length - 1] = [i - 1, i]
    else groups.push([i])
  }
  return groups
}
