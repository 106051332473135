import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function ArrowRightLong({ className }) {
  return (
    <IconBase name="arrow-right-long" w={25} h={24} className={className}>
      <path d="M16.51 11H4.5V13H16.51V16L20.5 12L16.51 8V11Z" stroke="none" />
    </IconBase>
  )
}

export default ArrowRightLong
