import React from 'react'
import { Image } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { ArrowRightLong, DBookEasy } from 'react-components/atoms/Icons'
import { Button, Badge } from 'react-components/atoms'

const ElementoSbloccatoCard = props => {
  const {
    elemento = {
      main_image: '',
      titolo: '',
      nuovo: true,
    },
    className = '',
  } = props

  const t = useTrans()

  return (
    <div className={`elemento-sbloccato-card ${className ? ` ${className}` : ''}`}>
      <div className="elemento-sbloccato-card__image-box">
        <Image
          src={elemento.main_image ? elemento.main_image : 'https://via.placeholder.com/191x241'}
          alt={elemento.titolo.toAltTitle()}
          title={elemento.titolo.toAltTitle()}
          className="elemento-sbloccato-card__image"
          layout="responsive"
          width={191}
          height={241}
        />
      </div>
      <div className="elemento-sbloccato-card__head">
        <Badge label={t('Nuovi')} />
        <p className="elemento-sbloccato-card__title">{elemento.titolo}</p>
      </div>
      {elemento.link_dbook_easy && (
        <>
          <div className="elemento-sbloccato-card__logo">
            <DBookEasy />
          </div>
          <div className="elemento-sbloccato-card__actions">
            <Button
              label={t('Sfoglia online')}
              variant="ghost"
              colorVariant="darkblue"
              icon={<ArrowRightLong />}
              iconPos="right"
              href={elemento.link_dbook_easy}
            />
            <Button
              label={t('Fruizione offline')}
              variant="ghost"
              colorVariant="darkblue"
              icon={<ArrowRightLong />}
              iconPos="right"
              href="/dbookeasy-libro-digitale-gs"
            />
          </div>
          <div className="elemento-sbloccato-card__cta">
            <Button
              label={t('Vedi tutti i libri')}
              variant="secondary"
              href="/area-personale/libri"
            />
          </div>
        </>
      )}
    </div>
  )
}

export default ElementoSbloccatoCard
