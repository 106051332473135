import * as Yup from 'yup'

export const insegnante = 'i'
export const genitore = 'g'
export const studente = 's'
export const altro = 'a'

export const optionsRuolo = [
  {
    value: insegnante,
    label: 'Insegnante',
  },
  {
    value: genitore,
    label: 'Genitore',
  },
  {
    value: studente,
    label: 'Studente',
  },
  {
    value: altro,
    label: 'Altro',
  },
]

export const optionsRuoloProfile = [
  {
    value: 'Insegnante',
    label: 'Insegnante',
  },
  {
    value: 'Genitore',
    label: 'Genitore',
  },
  {
    value: 'Studente',
    label: 'Studente',
  },
  {
    value: 'Altro',
    label: 'Altro',
  },
]

export let fields = {
  ruolo: '',
  nome: '',
  cognome: '',
  email: '',
  telefono: '',
  richiesta: '',
  privacy: false,
  commerciale: false,
  profilazione: false,
}

export let fieldsPubblica = {
  ruolo: '',
  nome: '',
  cognome: '',
  email: '',
  telefono: '',
  privacy: false,
  commerciale: false,
  profilazione: false,
  titolo: '',
  files: [],
}

export const schemaContatti = Yup.object().shape({
  nome: Yup.string().min(2, 'Troppo corto').max(50, 'Troppo lungo').required('Obbligatorio'),
  cognome: Yup.string().min(2, 'Troppo corto').max(50, 'Troppo lungo').required('Obbligatorio'),
  email: Yup.string().email('Email non valida').required('Obbligatorio'),
  ruolo: Yup.object().required('Obbligatorio'),
  privacy: Yup.boolean()
    .required('Obbligatorio')
    .oneOf([true], 'Devi accettare i Termini e le Condizioni'),
  richiesta: Yup.string().min(10, 'Troppo corto').max(255, 'Troppo lungo').required('Obbligatorio'),
})

export const schemaEsperto = Yup.object().shape({
  email: Yup.string().email('Email non valida').required('Obbligatorio'),
  ruolo: Yup.object().required('Obbligatorio'),
  privacy: Yup.boolean()
    .required('Obbligatorio')
    .oneOf([true], 'Devi accettare i Termini e le Condizioni'),
  richiesta: Yup.string().min(10, 'Troppo corto').max(255, 'Troppo lungo').required('Obbligatorio'),
})

const FILE_SIZE = 5120 * 1024

export const schemaPubblica = Yup.object().shape({
  nome: Yup.string().min(2, 'Troppo corto').max(50, 'Troppo lungo').required('Obbligatorio'),
  cognome: Yup.string().min(2, 'Troppo corto').max(50, 'Troppo lungo').required('Obbligatorio'),
  email: Yup.string().email('Email non valida').required('Obbligatorio'),
  ruolo: Yup.object().required('Obbligatorio'),
  privacy: Yup.boolean()
    .required('Obbligatorio')
    .oneOf([true], 'Devi accettare i Termini e le Condizioni'),
  titolo: Yup.string().min(5, 'Troppo corto').max(255, 'Troppo lungo').required('Obbligatorio'),
  files: Yup.array()
    .of(
      Yup.mixed().test(
        'fileSize',
        'Dimensione file troppo grande',
        (value) => value && value.size <= FILE_SIZE
      )
    )
    .required('Devi inserire almeno un file')
    .test('len', 'Massimo 3 file', (val) => val.length <= 3),
})

export const optionsClassi = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
]

export const getFormFieldStatus = (formikProps, propName) =>
  formikProps?.touched?.[propName] ? (formikProps?.errors?.[propName] ? 'error' : 'success') : null

export function getFormikFieldProps(formik, name, excludeErrors) {
  return {
    value: formik.values[name],
    onBlur: formik.handleBlur(name),
    onChange: formik.handleChange(name),
    status: getFormFieldStatus({ touched: formik.touched, errors: formik.errors }, name),
    errorMessage: !excludeErrors ? formik.errors[name] : '',
  }
}
