import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useTrans } from 'hooks'
import { CalendarioAvventoCellaStep2 } from 'react-components/molecules'
import { Button } from 'react-components/atoms'
import { getGiornoCorrente, getDateStringFromDay } from 'utils'
import { useRouter } from 'next/router'

const CalendarioAvventoStep2 = props => {
  const { celle = [], step, setStep, cellaCliccata, setCellaCliccata, refreshCelle } = props

  const t = useTrans()
  const { utente } = useSelector(state => state.utente)

  const router = useRouter()

  const giornoCorrente = getGiornoCorrente(router)

  const celle_aperte = () => {
    let num_celle_aperte = celle.filter(cella => cella.aperta == true).length
    if (num_celle_aperte == 1) {
      return num_celle_aperte + ' dono'
    }
    return num_celle_aperte + ' doni'
  }

  const urlDaScaricare = cellaCliccata.url
  const iconaRegaloYellow = '/images/calendario_avvento/icona_regalo_yellow.png'

  function handleBack() {
    setCellaCliccata(null)
    setStep(1)
  }

  useEffect(() => {
    refreshCelle()
  }, [])

  return (
    <div className="calendario-avvento-step2">
      <div className="calendario-avvento-step2-regalo-scartato">
        <p className="date">{getDateStringFromDay(cellaCliccata.giorno)}</p>
        <p className="title">{t('Complimenti, oggi hai ricevuto')}</p>
        <p className="description">{cellaCliccata.titolo}</p>
        <Button
          colorVariant="yellow"
          label={t('Clicca qui per scaricare!')}
          download={true}
          external={true}
          href={urlDaScaricare}
        />
      </div>
      <div className="calendario-avvento-step2-riepilogo">
        <div className="calendario-avvento-text-step2">
          <img src={iconaRegaloYellow} />
          <p className="title">{t('Riepilogo')}</p>
          <p className="name">{t('Ciao ') + utente.nome}!</p>
          <p className="description">
            Finora hai scartato {celle_aperte()} del tuo Calendario dell’Avvento. <br />
            Ne hai perso qualcuno? Niente paura, recuperali qui sotto cliccando sui giorni
            corrispondenti... e ricordati di tornare a trovarci domani!
          </p>
        </div>

        <div className="calendario-avvento-list-step2">
          {celle.length ? (
            celle.map(cella => (
              <CalendarioAvventoCellaStep2
                cella={cella}
                setStep={setStep}
                cellaCliccata={cellaCliccata}
                setCellaCliccata={setCellaCliccata}
                key={cella.id}
                className={
                  cella.aperta === true || cella.id === cellaCliccata.id
                    ? 'open'
                    : cella.giorno > giornoCorrente
                    ? 'future'
                    : 'close'
                }
                refreshCelle={refreshCelle}
              />
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>

      <div className="calendario-avvento-back-step1">
        <Button
          colorVariant="green"
          label={t("Torna al calendario dell'Avvento!")}
          onClick={handleBack}
        />
      </div>
    </div>
  )
}

export default CalendarioAvventoStep2
