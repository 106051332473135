import React from 'react'
import { Image } from 'react-components/atoms'
import { Info2 } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'

const BannerInserto = (props) => {
  const {
    title = '',
    text = '',
    imageSrc = '',
    titolo = '',
    landingRivista = false,
    iconVersion = false,
    buttonVersion = false,
    buttonText = '',
    buttonClickFunction = () => {},
    pdpVersion = false,
    pdpVitaScolastica = false,
    width = 142,
    height = 48,
    icon = null,
  } = props

  const showTitle = title && !pdpVitaScolastica

  const insertoBoxClasses = [
    'inserto-box',
    iconVersion ? 'inserto-box--icon-version' : '',
    buttonVersion ? 'inserto-box--button-version' : '',
    pdpVersion ? 'inserto-box--pdp-version' : '',
    pdpVitaScolastica ? 'inserto-box--pdp-vita-scolastica' : '',
  ].join(' ')

  const insertoBoxContainerClasses = [
    'inserto-box-container',
    landingRivista ? 'inserto-box-container--landing-rivista' : '',
    iconVersion ? 'inserto-box-container--icon-version' : '',
    pdpVitaScolastica ? 'inserto-box-container--pdp-vita-scolastica' : '',
  ].join(' ')

  const insertoBoxContainerTitleClasses = [
    'inserto-box-container-title',
    landingRivista ? 'inserto-box-container-title--landing-rivista' : '',
    iconVersion ? 'inserto-box-container-title--icon-version' : '',
    buttonVersion ? 'inserto-box-container-title--button-version' : '',
    pdpVersion ? 'inserto-box-container-title--pdp-version' : '',
  ].join(' ')

  const insertoBoxContainerTextClasses = [
    'inserto-box-container-text',
    landingRivista ? 'inserto-box-container-text--landing-rivista' : '',
    iconVersion ? 'inserto-box-container-text--icon-version' : '',
    buttonVersion ? 'inserto-box-container-text--button-version' : '',
    pdpVersion ? 'inserto-box-container-text--pdp-version' : '',
  ].join(' ')

  return (
    <div className={insertoBoxClasses}>
      <div className={insertoBoxContainerClasses}>
        {buttonVersion || pdpVitaScolastica ? (
          <Button
            variant="primary"
            label={buttonText}
            icon={icon}
            iconPos="right"
            onClick={buttonClickFunction}
          />
        ) : null}

        <div className="inserto-box-container-content">
          {showTitle ? <p className={insertoBoxContainerTitleClasses}>{title}</p> : null}
          <p
            className={insertoBoxContainerTextClasses}
            dangerouslySetInnerHTML={{ __html: text }}
          ></p>
        </div>

        <div
          className={`inserto-box-container-image-container ${
            pdpVitaScolastica ? 'inserto-box-container-image-container--pdp-vita-scolastica' : ''
          }`}
        >
          {iconVersion ? (
            <div className="inserto-box-container-icon">
              <Info2 />
            </div>
          ) : !pdpVersion && !pdpVitaScolastica ? (
            <>
              <Image
                src={imageSrc}
                className="riviste-side__item__image"
                width={width}
                height={height}
                alt={titolo?.toAltTitle()}
                title={titolo?.toAltTitle()}
              />
              {buttonVersion ? (
                <p className="inserto-box-container-title--button-version-mobile">{title}</p>
              ) : null}
            </>
          ) : pdpVitaScolastica ? (
            <>
              <div className="inserto-box-container-image-pdp-vita-scolastica">
                <Image
                  src={imageSrc}
                  className="riviste-side__item__image"
                  width={width}
                  height={height}
                  alt={titolo?.toAltTitle()}
                  title={titolo?.toAltTitle()}
                />
              </div>
              <p className="inserto-box-container-title--pdp-vita-scolastica">{title}</p>
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default BannerInserto
