import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Edit({ className }) {
  return (
    <IconBase name="edit" w={24} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.301 5.09053C21.6996 5.48914 21.6996 6.13305 21.301 6.53166L19.4306 8.40207L15.5978 4.56927L17.4682 2.69886C17.8668 2.30025 18.5107 2.30025 18.9093 2.69886L21.301 5.09053ZM3.19995 20.2889V17.1817C3.19995 17.0386 3.25106 16.916 3.35326 16.8138L14.5042 5.66289L18.337 9.49569L7.17584 20.6466C7.08386 20.7488 6.95099 20.7999 6.81812 20.7999H3.71099C3.42481 20.7999 3.19995 20.575 3.19995 20.2889Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default Edit
