import React from 'react'

const Label = props => {
  const {
    text = '',
    variant = '', // "" | "tag" (default quella per l'omaggio, tag è quella usata negli indirizzi)
    color = '', // "" | "teal" (default arancio primary)
    className = '',
  } = props

  return text ? (
    <div
      className={`label ${variant ? `label--${variant}` : ''} ${color ? `label--${color}` : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      {text}
    </div>
  ) : (
    <></>
  )
}

export default Label
