import React, { useState, useEffect, useRef } from 'react'
import { useTrans } from 'hooks'
import { ReactTooltip } from 'react-components/atoms'
import { Info2 } from 'react-components/atoms/Icons'
import { formatPrice } from 'utils'
import { FormCheckbox } from 'react-components/atoms'
import { ArrowDown2, ArrowUp2 } from 'react-components/atoms/Icons'

const GaranziaTile = ({
  nome,
  durata,
  descrizione,
  prezzo,
  onChange,
  checked,
  disabled,
  inputName,
  styleInverted = false,
  noTitle = false,
  tooltip = '',
}) => {
  const t = useTrans()

  const [open, setOpen] = useState(true)
  const [maxHeight, setMaxHeight] = useState(0)
  const innerContentRef = useRef(null)
  const [maxHeightMobile, setMaxHeightMobile] = useState(0)
  const innerContentMobileRef = useRef(null)

  useEffect(() => {
    if (innerContentRef?.current?.clientHeight) setMaxHeight(innerContentRef?.current?.clientHeight)
    if (innerContentMobileRef?.current?.clientHeight)
      setMaxHeightMobile(innerContentMobileRef?.current?.clientHeight)
  }, [open])

  const tileContainerClass = styleInverted
    ? 'garanzia_tile__container--inverted'
    : `garanzia_tile__container ${noTitle ? 'garanzia_tile__container--hide' : ''}`

  return (
    <div className={tileContainerClass}>
      {!styleInverted && (
        <>
          <span className={`garanzia_tile__container__title`} onClick={() => setOpen(!open)}>
            {t('Proteggi il tuo prodotto')}
          </span>
          <span className={`garanzia_tile__container__arrow`} onClick={() => setOpen(!open)}>
            {open ? <ArrowUp2 /> : <ArrowDown2 />}
          </span>
          <div
            className={`garanzia_tile__container__info`}
            style={{
              maxHeight: !open ? 0 : maxHeight ? maxHeight : 'none',
            }}
          >
            <div className={`garanzia_tile__container__info__inner`} ref={innerContentRef}>
              <FormCheckbox
                id={inputName}
                name={inputName}
                value={checked}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
              />
              <span className="garanzia_tile__container__info__inner__name">
                {nome} - {durata} {t('mesi')}
              </span>
              <ReactTooltip
                id={'asd'}
                label={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}
              >
                <span className="form-carta-docente-radio-option__helper">
                  <Info2 />
                </span>
              </ReactTooltip>
              <p className="garanzia_tile__container__info__inner__prezzo">{formatPrice(prezzo)}</p>
            </div>
          </div>
        </>
      )}
      {!styleInverted && (
        <div
          className="garanzia_tile__inverted_mobile"
          style={{
            maxHeight: !open ? 0 : maxHeightMobile ? maxHeightMobile : 'none',
          }}
        >
          <div className="garanzia_tile__container__info__inner" ref={innerContentMobileRef}>
            <div>
              <FormCheckbox
                id={inputName}
                name={inputName}
                value={checked}
                checked={checked}
                onChange={onChange}
                disabled={disabled}
              />
            </div>
            <div>
              <span className="garanzia_tile__container__info__inner__name">
                {nome} - {durata} {t('mesi')}
              </span>
              <div className="garanzia_tile__container--inverted__info__inner__inverted">
                <p className="garanzia_tile__container--inverted__info__inner__prezzo">
                  {formatPrice(prezzo)}
                </p>
                <ReactTooltip label={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}>
                  <span className="form-carta-docente-radio-option__helper">
                    <Info2 />
                  </span>
                </ReactTooltip>
              </div>
            </div>
          </div>
        </div>
      )}
      {styleInverted && (
        <div className="garanzia_tile__container__info__inner">
          <div>
            <FormCheckbox
              id={inputName}
              name={inputName}
              value={checked}
              checked={checked}
              onChange={onChange}
              disabled={disabled}
            />
          </div>
          <div>
            <span className="garanzia_tile__container__info__inner__name">
              {nome} - {durata} {t('mesi')}
            </span>
            <div className="garanzia_tile__container--inverted__info__inner__inverted">
              <p className="garanzia_tile__container--inverted__info__inner__prezzo">
                {formatPrice(prezzo)}
              </p>
              <ReactTooltip
                id={'asd'}
                label={<div dangerouslySetInnerHTML={{ __html: tooltip }} />}
              >
                <span className="form-carta-docente-radio-option__helper">
                  <Info2 />
                </span>
              </ReactTooltip>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default GaranziaTile
