import React from 'react'
import { ArrowRight } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'

const WidgetLink = props => {
  const { title = '', className = '' } = props

  return (
    <div className={`widget-link ${className ? ` ${className}` : ''}`}>
      <span className="widget-link__label">{title}</span>
      <Button className="widget-link__button" icon={<ArrowRight />} />
    </div>
  )
}

export default WidgetLink
