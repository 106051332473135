import React from 'react'

function Target({ className }) {
  return (
    <svg
      viewBox="-5 -3 29 29"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--target ${className ? className : ''}`}
    >
      <defs>
        <clipPath id="pb-target_clip-path">
          <path
            d="M0,0H19.841V19.852H0Z"
            transform="translate(0 0.148)"
            fill="currentColor"
            stroke="none"
          />
        </clipPath>
      </defs>
      <g transform="translate(0 0)">
        <path
          d="M5.732,1.5a4.2,4.2,0,0,1,.966.122L7.9.424A5.732,5.732,0,0,0,1.679,9.785,5.732,5.732,0,0,0,11.04,3.569l-1.2,1.2A4.219,4.219,0,1,1,5.732,1.5"
          transform="translate(4.191 7.775)"
          fill="currentColor"
          stroke="none"
        />
        <g transform="translate(0 3.431)">
          <path
            d="M0,0H19.841V19.852H0Z"
            transform="translate(0 0.148)"
            fill="none"
            stroke="none"
          />
          <g clipPath="url(#pb-target_clip-path)">
            <path
              d="M17.237,5.758A8.426,8.426,0,1,1,14.1,2.611l1.092-1.093a9.933,9.933,0,1,0,3.143,3.146Z"
              transform="translate(0 0.148)"
              fill="currentColor"
              stroke="none"
            />
          </g>
        </g>
        <path
          d="M15.234,3.071H12.782a.272.272,0,0,1-.273-.272V.346A.345.345,0,0,0,11.921.1L9.348,2.675a.274.274,0,0,0-.072.127l-.8,3.244L2.9,11.621a2.126,2.126,0,0,0-2.292.421,2.073,2.073,0,1,0,3.539,1.466,2.034,2.034,0,0,0-.179-.834L9.535,7.107l3.243-.8a.272.272,0,0,0,.127-.071l2.573-2.574a.344.344,0,0,0-.244-.588"
          transform="translate(7.85 0)"
          fill="currentColor"
          stroke="none"
        />
      </g>
    </svg>
  )
}

export default Target
