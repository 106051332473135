import { LEZIONE, MEMBER, SALVA, api, hasPerm, isDisabled, EMAIL_REDAZIONE } from 'utils'
import React, { useEffect, useMemo, useState } from 'react'

import { ELEMENTOSALVATO } from 'utils/endpoint'
import Ioplus from 'components/atomic/Ioplus'
import Link from 'next/link'

import { useSelector } from 'react-redux'
import { sendGTMData, sendProductClickGTM } from 'utils/gtm.js'
import Salva from 'components/atomic/Salva'
import Icon from 'components/atomic/Icon'
import Scarica from 'components/atomic/Scarica'

const ArticoloCard = React.memo((props) => {
  const {
    articolo,
    classes = '',
    isEmbedded = false,
    onRemove = null,
    horizontal = false,
    size = '',
    variant = '',
    position = null,
    parentList = '',
  } = props

  const { utente } = useSelector((state) => state.utente)

  const [saved, setSaved] = useState(false)

  const thumb = articolo?.preview_image
    ? articolo?.preview_image
    : articolo?.main_image
    ? articolo?.main_image
    : '/images/card/articolo.jpg'

  const disabled = useMemo(() => isDisabled(articolo, utente), [articolo, utente])

  const params = {
    content_type_id: articolo?.model,
    object_id: articolo?.id || articolo?.object_id,
  }

  const handleGTM = (action) => {
    sendGTMData({
      event: 'GAevent',
      eventID: '15',
      eventCategory: 'interazione_contenuti',
      eventAction: action,
      eventLabel: articolo?.tipologia === LEZIONE ? 'lezione' : 'articolo',
      moreInfo: articolo?.titolo.toGTMFormat(),
    })
  }

  const handleSave = async () => {
    if (hasPerm(utente, SALVA)) {
      handleGTM('bookmark')
      api.post(ELEMENTOSALVATO, { params }).then(() => checkSaved())
    }
  }

  const handleUnsave = async () => {
    if (hasPerm(utente, SALVA)) {
      handleGTM('bookmark')
      api.delete(ELEMENTOSALVATO, { data: params }).then(() => checkSaved())
    }
  }

  const checkSaved = async () => {
    api.get(ELEMENTOSALVATO, { params }).then((response) => {
      setSaved(response.data['salvato'])
    })
  }

  useEffect(() => {
    setSaved(articolo?.saved)
  }, [articolo?.saved])

  useEffect(() => {
    if (isEmbedded && hasPerm(utente, SALVA)) checkSaved()
  }, [])

  const autore = articolo?.extra?.utenti?.length ? articolo?.extra.utenti[0] : null

  const classRedazionale =
    (autore && autore.redazionale) || !autore ? 'articolo-card__author--redazione' : ''
  const classi = `articolo-card ${classes} ${disabled ? 'articolo-card--disabled' : ''}${
    horizontal ? ' articolo-card--horizontal' : ''
  }${variant ? ` articolo-card--${variant}` : ''}${size ? ` articolo-card--${size}` : ''}`

  let autoreDetail = null

  if (!autore || autore.email === EMAIL_REDAZIONE) {
    autoreDetail = <span>Redazione Giuntiscuola</span>
  } else {
    autoreDetail = <span>{autore.titolo}</span>
  }

  return (
    <article className={classi}>
      {articolo?.extra?.membership == MEMBER && (
        <Ioplus tooltip="Contenuto riservato agli abbonati io+" horizontal={horizontal} />
      )}
      {articolo?.extra?.pdf_file && (
        <Scarica
          href={articolo?.extra?.pdf_file}
          horizontal={horizontal}
          download
          onClick={() => handleGTM('download')}
        />
      )}
      {hasPerm(utente, SALVA) ? (
        saved ? (
          <Salva onClick={handleUnsave} horizontal={horizontal} saved />
        ) : (
          <Salva onClick={handleSave} horizontal={horizontal} />
        )
      ) : null}
      {onRemove && <Icon id="remove" classes="icon--remove" onClick={onRemove} tooltip="ELIMINA" />}
      <Link legacyBehavior href={articolo?.url ?? '#'}>
        <a
          className="articolo-card__image-box"
          style={
            thumb
              ? {
                  backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 30%), url(${thumb})`,
                }
              : {}
          }
          onClick={() => {
            handleGTM('click')
            sendProductClickGTM(
              articolo,
              parentList ? parentList : 'navigazione_standard',
              position
            )
          }}
        >
          <p className={`articolo-card__author ${classRedazionale}`}>di {autoreDetail}</p>
        </a>
      </Link>
      <Link legacyBehavior href={articolo?.url ?? '#'}>
        <a
          className="articolo-card__content"
          onClick={() => {
            handleGTM('click')
            sendProductClickGTM(
              articolo,
              parentList ? parentList : 'navigazione_standard',
              position
            )
          }}
        >
          <p className="articolo-card__title">{articolo?.titolo}</p>
          <p className="articolo-card__abstract">{articolo?.descrizione}</p>
          {variant === 'horizontal-noimage' && (
            <p className={`articolo-card__author ${classRedazionale}`}>di {autoreDetail}</p>
          )}
        </a>
      </Link>
    </article>
  )
})

export default ArticoloCard
