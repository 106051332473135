import React from 'react'
import { useTrans } from 'hooks'
import { StrumentoLibro } from 'react-components/atoms/Icons'
import { LibroCard, AttivaContenutoCard, AggiungiContenutoCard } from 'react-components/molecules'

const TabGiuntiEdu = props => {
  const { className = '', items = [] } = props

  const t = useTrans()

  return (
    <div className={`tab-giunti-edu ${className ? ` ${className}` : ''}`}>
      <div className="tab-giunti-edu__list">
        {items.map(libro => (
          <LibroCard libro={libro} layout="parascolastico" type="libri" />
        ))}
        <AttivaContenutoCard
          title={
            !items.length ? t('Non ci sono contenuti attivi') : t('Attiva nuovi contenuti digitali')
          }
          description={t(
            'Attiva codici in tuo possesso per fruire di tanti contenuti digitali a te deidicati'
          )}
          ctaLabel={t('Attiva codice')}
          ctaLink="/area-personale/libreria"
          className="tab-giunti-edu__activate-cta"
        />
        <AggiungiContenutoCard
          icon={<StrumentoLibro />}
          ctaLabel={t('Aggiungi altri libri')}
          ctaLink="/area-personale/libreria"
          className="tab-giunti-edu__add-cta"
        />
      </div>
    </div>
  )
}

export default TabGiuntiEdu
