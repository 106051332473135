import React from 'react'
import { Button } from 'react-components/atoms'
import { ArrowRightLong } from 'react-components/atoms/Icons'

const VoceMenuTile = (props) => {
  const { item, className = '', onClick = () => {}, imageInHeader = false, variant = '' } = props

  const classes = `menu-tile ${className} ${imageInHeader ? 'menu-tile--header' : ''} ${
    variant ? `menu-tile--${variant}` : ''
  }`

  return (
    <div className={classes}>
      {item.image && (
        <img
          className="menu-tile__image"
          src={item.image}
          alt={item.name?.toAltTitle()}
          title={item.name?.toAltTitle()}
        />
      )}
      <div className="menu-tile__content">
        {item.name && <p className="menu-tile__title">{item.name}</p>}
        {item.description && <p className="menu-tile__description">{item.description}</p>}
        {item.final_url && (
          <Button
            className="menu-tile__cta"
            label={item.cta || 'Vedi tutti'}
            variant="ghost"
            href={item.final_url}
            icon={<ArrowRightLong />}
            iconPos="right"
            onClick={onClick}
            newPage={item.blank}
            external={item.blank}
          />
        )}
      </div>
    </div>
  )
}

export default VoceMenuTile
