import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function MinusCircle({ className }) {
  return (
    <IconBase name="minus-circle" w={16} className={className}>
      <path
        opacity="0.8"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33337 7.99992C1.33337 4.31992 4.32004 1.33325 8.00004 1.33325C11.68 1.33325 14.6667 4.31992 14.6667 7.99992C14.6667 11.6799 11.68 14.6666 8.00004 14.6666C4.32004 14.6666 1.33337 11.6799 1.33337 7.99992ZM4.66671 8.66659H11.3334V7.33325H4.66671V8.66659Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default MinusCircle
