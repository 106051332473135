import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { SelectDropdown } from 'react-components/atoms/Icons'
import { FormError } from 'react-components/atoms'

const FormSelectDropdownIndicator = () => (
  <div className="form-select__input__dropdown-indicator">
    <SelectDropdown />
  </div>
)

const FormSelect = props => {
  const {
    placeholder = '',
    name = '',
    value = null,
    size = 'md',
    colorVariant = '',
    className = '',
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    options = [],
    searchable = false,
  } = props

  const classNames = ` 
    form-select 
    form-select--${size}
    ${status ? ` form-select--${status}` : ''}
    ${colorVariant ? ` form-select--${colorVariant}` : ''}
    ${className ? ` ${className}` : ''}`

  const [target, setTarget] = useState(null)

  useEffect(() => {
    setTarget(document)
  }, [])

  return (
    <div className={classNames}>
      <Select
        className={`form-select__input ${searchable ? 'form-select__input--searchable' : ''}`}
        classNamePrefix="form-select__input"
        closeMenuOnSelect
        isClearable={false}
        isSearchable={searchable}
        isDisabled={disabled || false}
        instanceId={name}
        name={name}
        placeholder={placeholder}
        components={{ DropdownIndicator: FormSelectDropdownIndicator }}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        options={options}
        menuPortalTarget={target?.body}
        styles={{ menuPortal: base => ({ ...base, zIndex: 15000 }) }}
        value={
          options?.find(option => option.selected) ||
          options?.find(option => option.value === value) ||
          value ||
          ''
        }
      />
      <FormError
        className="form-select__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormSelect
