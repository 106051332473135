import React from 'react'

const Video = (props) => {
  const {
    provider = 'youtube', // 'youtube' | 'vimeo'
    videoId = '',
    autoplay = false,
    loop = false,
    controls = false,
    related = false,
    modest = false,
    className = '',
  } = props

  const videoUrl = `https://${
    provider === 'vimeo' ? 'player.vimeo.com/video/' : 'www.youtube.com/embed/'
  }${videoId}?${autoplay ? 'autoplay=1&' : ''}${loop ? 'loop=1&' : ''}${
    provider === 'youtube' && !controls ? 'showinfo=0&controls=0&' : ''
  }${provider === 'youtube' && related ? '' : 'rel=0&'}${
    provider === 'youtube' && modest ? 'modestbranding=1' : ''
  }`

  return (
    <div className={`wvideo ${className ? ` ${className}` : ''}`}>
      <iframe src={videoUrl} frameBorder="0" allowFullScreen={true} loading="lazy"></iframe>
    </div>
  )
}

export default Video
