import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function StrumentoLibro({ className }) {
  return (
    <IconBase name="strumento-libro" w={31} h={30} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4813 5.27606C17.4562 3.77793 20.3162 3.23315 22.972 3.23315C24.9468 3.23315 27.0442 3.53278 28.7875 4.30909C29.7817 4.75853 30.4491 5.72551 30.4491 6.82868V22.1914C30.4491 23.9755 28.7875 25.283 27.0715 24.8335C25.7368 24.493 24.3203 24.3432 22.9584 24.3432C20.8338 24.3432 18.5729 24.6837 16.7479 25.5962C15.9444 26.0048 15.0183 26.0048 14.2011 25.5962C12.3761 24.6973 10.1153 24.3432 7.99067 24.3432C6.62873 24.3432 5.21231 24.493 3.87761 24.8335C2.16157 25.2693 0.5 23.9619 0.5 22.1914V6.82868C0.5 5.72551 1.16735 4.75853 2.16157 4.30909C3.91847 3.53278 6.01586 3.23315 7.99067 3.23315C10.6465 3.23315 13.5065 3.77793 15.4813 5.27606ZM26.1045 21.9054C26.9489 22.0552 27.7388 21.4287 27.7388 20.5707V7.67308C27.7388 7.03297 27.2757 6.47457 26.6493 6.33838C25.4644 6.07961 24.225 5.95703 22.972 5.95703C20.6567 5.95703 17.32 6.8423 15.4813 7.99995V23.6759C17.32 22.5182 20.6567 21.633 22.972 21.633C24.0207 21.633 25.083 21.7147 26.1045 21.9054Z"
        stroke="none"
      />
      <path
        d="M18.1779 12.0995C17.7421 12.0995 17.3471 11.8271 17.2109 11.3913C17.0339 10.8601 17.3335 10.2745 17.8647 10.1111C19.962 9.43009 22.6723 9.21218 25.1647 9.49818C25.7231 9.56628 26.1316 10.0702 26.0635 10.6286C25.9954 11.187 25.4915 11.5956 24.9331 11.5275C22.7268 11.2687 20.3162 11.473 18.4912 12.0586C18.3822 12.0723 18.2732 12.0995 18.1779 12.0995Z"
        stroke="none"
      />
      <path
        d="M18.1779 15.722C17.7421 15.722 17.3471 15.4497 17.2109 15.0138C17.0339 14.4827 17.3335 13.897 17.8647 13.7336C19.9484 13.0526 22.6723 12.8347 25.1647 13.1207C25.7231 13.1888 26.1316 13.6928 26.0635 14.2512C25.9954 14.8095 25.4915 15.2181 24.9331 15.15C22.7268 14.8913 20.3162 15.0956 18.4912 15.6812C18.3822 15.7084 18.2732 15.722 18.1779 15.722Z"
        stroke="none"
      />
      <path
        d="M18.1779 19.3449C17.7421 19.3449 17.3471 19.0725 17.2109 18.6366C17.0339 18.1055 17.3335 17.5199 17.8647 17.3564C19.9484 16.6754 22.6723 16.4575 25.1647 16.7435C25.7231 16.8116 26.1316 17.3156 26.0635 17.874C25.9954 18.4324 25.4915 18.8273 24.9331 18.7728C22.7268 18.5141 20.3162 18.7184 18.4912 19.304C18.3822 19.3312 18.2732 19.3449 18.1779 19.3449Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default StrumentoLibro
