import * as Yup from 'yup'

export const insegnante = 'i'
export const genitore = 'g'
export const studente = 's'
export const altro = 'a'

export const nido = 'nido'
export const infanzia = 'infanzia'
export const primaria = 'primaria'
export const secondariaPrimo = 'secondaria-1'
export const secondariaSecondo = 'secondaria-2'

export const schemaStep1 = Yup.object().shape({
  nome: Yup.string()
    .min(2, 'Troppo corto')
    .max(50, 'Troppo lungo')
    .required('Obbligatorio'),
  cognome: Yup.string()
    .min(2, 'Troppo corto')
    .max(50, 'Troppo lungo')
    .required('Obbligatorio'),
  email: Yup.string()
    .email('Email non valida')
    .required('Obbligatorio'),
  confermaEmail: Yup.string()
    .email('Email non valida')
    .required('Obbligatorio')
    .oneOf([Yup.ref('email'), null], 'Le email non corrispondono'),
  password: Yup.string()
    .required('Obbligatorio')
    .matches(/^.{5,}$/, 'Deve contenere 5 caratteri'),
  confermaPassword: Yup.string()
    .required('Obbligatorio')
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono'),
})

export const schemaStep2 = Yup.object().shape({
  ruolo: Yup.object().required('Obbligatorio'),
  occupazione: Yup.array()
    .nullable()
    .when('ruolo', {
      is: ruolo => ruolo && ruolo.value === altro,
      then: Yup.array().required('Obbligatorio'),
    }),
  adottatario: Yup.object().when('ruolo', {
    is: ruolo => ruolo && ruolo.value === insegnante,
    then: Yup.object().required('Obbligatorio'),
  }),
  scuole: Yup.array().when('ruolo', {
    is: ruolo => ruolo && ruolo.value !== altro,
    then: Yup.array().of(
      Yup.object().shape({
        ordine: Yup.object().required('Obbligatorio'),
        regione: Yup.object().when('ordine', {
          is: ordine => ordine && ordine.value !== nido,
          then: Yup.object().required('Obbligatorio'),
        }),
        provincia: Yup.object().when('ordine', {
          is: ordine => ordine && ordine.value !== nido,
          then: Yup.object().required('Obbligatorio'),
        }),
        cap: Yup.object().when('ordine', {
          is: ordine => ordine && ordine.value !== nido,
          then: Yup.object(),
        }),
        citta: Yup.object().when('ordine', {
          is: ordine => ordine && ordine.value !== nido,
          then: Yup.object().required('Obbligatorio'),
        }),
        scuola: Yup.object().when('ordine', {
          is: ordine => ordine && ordine.value !== nido,
          then: Yup.object().required('Obbligatorio'),
        }),
        nuova: Yup.string().when('scuola', {
          is: scuola => scuola && scuola.value === altro,
          then: Yup.string().required('Obbligatorio'),
        }),
        classe: Yup.object().when('ordine', {
          is: ordine => ordine && ordine.value === primaria,
          then: Yup.object().required('Obbligatorio'),
        }),
        sezione: Yup.string().when('classe', {
          is: classe => classe && classe.value,
          then: Yup.string().required('Obbligatorio'),
        }),
      })
    ),
  }),
  privacy: Yup.boolean()
    .required('Obbligatorio')
    .oneOf([true], 'Devi accettare i Termini e le Condizioni'),
})

export const schemaStep2Estero = Yup.object().shape({
  ruolo: Yup.object().required('Obbligatorio'),
  occupazione: Yup.array()
    .nullable()
    .when('ruolo', {
      is: ruolo => ruolo && ruolo.value === altro,
      then: Yup.array().required('Obbligatorio'),
    }),
  adottatario: Yup.object().when('ruolo', {
    is: ruolo => ruolo && ruolo.value === insegnante,
    then: Yup.object().required('Obbligatorio'),
  }),
  scuole: Yup.array()
    .when('ruolo', {
      is: ruolo => ruolo && (ruolo.value === genitore || ruolo.value === studente),
      then: Yup.array().of(
        Yup.object().shape({
          ordine: Yup.object().required('Obbligatorio'),
          regione: Yup.object().when('ordine', {
            is: ordine => ordine && ordine.value !== nido,
            then: Yup.object().required('Obbligatorio'),
          }),
          provincia: Yup.string(),
          cap: Yup.string().when('ordine', {
            is: ordine => ordine && ordine.value !== nido,
            then: Yup.string(),
          }),
          citta: Yup.string(),
          nuova: Yup.string(),
        })
      ),
    })
    .when('ruolo', {
      is: ruolo => ruolo && ruolo.value === insegnante,
      then: Yup.array().of(
        Yup.object().shape({
          ordine: Yup.object().required('Obbligatorio'),
          regione: Yup.object().when('ordine', {
            is: ordine => ordine && ordine.value !== nido,
            then: Yup.object().required('Obbligatorio'),
          }),
          provincia: Yup.string(),
          cap: Yup.string().when('ordine', {
            is: ordine => ordine && ordine.value !== nido,
            then: Yup.string(),
          }),
          citta: Yup.string(),
          nuova: Yup.string().when('scuola', {
            is: scuola => scuola && scuola.value === altro,
            then: Yup.string().required('Obbligatorio'),
          }),
          classe: Yup.object().when('ordine', {
            is: ordine => ordine && ordine.value === primaria,
            then: Yup.object().required('Obbligatorio'),
          }),
          sezione: Yup.string().when('classe', {
            is: classe => classe && classe.value,
            then: Yup.string().required('Obbligatorio'),
          }),
        })
      ),
    }),
  privacy: Yup.boolean()
    .required('Obbligatorio')
    .oneOf([true], 'Devi accettare i Termini e le Condizioni'),
})

export const schemaRecuperaPassword = Yup.object().shape({
  email: Yup.string()
    .email('Email non valida')
    .required('Obbligatorio'),
})

export const schemaResetPassword = Yup.object().shape({
  password: Yup.string()
    .required('Obbligatorio')
    .matches(/^.{5,}$/, 'Deve contenere 5 caratteri'),
  confermaPassword: Yup.string()
    .required('Obbligatorio')
    .oneOf([Yup.ref('password'), null], 'Le password non corrispondono'),
})

export const optionsRuolo = [
  {
    value: insegnante,
    label: 'Insegnante',
  },
  {
    value: genitore,
    label: 'Genitore',
  },
  {
    value: studente,
    label: 'Studente',
  },
  {
    value: altro,
    label: 'Altro',
  },
]

export const optionsAdottatario = [
  {
    value: 1,
    label: 'Si, sono un docente adottatario',
    attributo: true,
  },
  {
    value: 2,
    label: 'No, non sono un docente adottatario',
    attributo: false,
  },
]

export const optionsOccupazioni = [
  {
    value: 1,
    label: 'Psicologia',
  },
  {
    value: 2,
    label: 'Altro',
  },
]

export const optionsOrdini = [
  {
    value: nido,
    label: "Nido d'infanzia",
  },
  {
    value: infanzia,
    label: "Scuola dell'infanzia",
  },
  {
    value: primaria,
    label: 'Scuola primaria',
  },
  {
    value: secondariaPrimo,
    label: 'Scuola secondaria di I grado',
  },
  {
    value: secondariaSecondo,
    label: 'Scuola secondaria di II grado',
  },
  {
    value: altro,
    label: 'Altro',
  },
]

export const optionsClassi = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '3',
  },
  {
    value: 4,
    label: '4',
  },
  {
    value: 5,
    label: '5',
  },
]

export const scuolaEmpty = {
  ordine: '',
  regione: '',
  provincia: '',
  cap: '',
  citta: '',
  scuola: '',
  nuova: '',
  classe: '',
  sezione: '',
}
