import React, { useRef, useState } from 'react'
import SlickSlider from 'react-slick'
import { useTrans } from 'hooks'
import { LandingRivisteSearch } from 'react-components/atoms/Icons'
import { WImage } from 'react-components/atoms'

const LandingRivisteSlider = props => {
  const {
    items = [],
    color = '', //, 'inherit' | ''
    className = '',
    setZoomModalItem = undefined,
  } = props

  const sliderRef = useRef()
  const [activeSlide, setActiveSlide] = useState(0)

  const t = useTrans()
  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: false,
    dots: true,
    customPaging: i => (
      <button className={color === 'inherit' ? 'u-landing-riviste-bg-default' : ''}></button>
    ),
    beforeChange: (oldIndex, newIndex) => setActiveSlide(newIndex),
  }

  return items.length > 0 ? (
    <div className={`landing-riviste-slider ${className ? ` ${className}` : ''}`}>
      <div
        className={`landing-riviste-slider__pagination ${
          color === 'inherit' ? 'u-landing-riviste-color-dark' : ''
        }`}
      >
        {items.map((x, i) => (
          <button
            key={x.pk}
            type="button"
            onClick={() => !!sliderRef?.current && sliderRef.current.slickGoTo(i)}
            className={`landing-riviste-slider__pagination-item ${
              i === activeSlide ? 'landing-riviste-slider__pagination-item--active' : ''
            }`}
          >
            {x.name}
          </button>
        ))}
      </div>
      <div
        className={`landing-riviste-slider__slider ${
          color === 'inherit' ? 'u-landing-riviste-color-default' : ''
        }`}
      >
        <SlickSlider {...settings} ref={sliderRef}>
          {items.map(x => (
            <div className={'landing-riviste-slider__slide'} key={x.pk}>
              <div className="landing-riviste-slider__slide-layout">
                <div className="landing-riviste-slider__slide-content">
                  {!!x.name && <p className="landing-riviste-slider__slide-title">{x.name}</p>}
                  {!!x.description && (
                    <div
                      className="landing-riviste-slider__slide-description"
                      dangerouslySetInnerHTML={{ __html: x.description }}
                    />
                  )}
                  {!x.disableZoom && (
                    <div className="landing-riviste-slider__slide-zoom">
                      <span className="landing-riviste-slider__slide-zoom-icon">
                        <LandingRivisteSearch />
                      </span>
                      <span className="landing-riviste-slider__slide-zoom-label">
                        {t('clicca sull’immagine per ingrandire!')}
                      </span>
                    </div>
                  )}
                </div>
                {x.disableZoom ? (
                  <div className="landing-riviste-slider__slide-image-box landing-riviste-slider__slide-image-box--no-zoom">
                    <WImage src={x.image} maxWidth={537} maxHeight={347} />
                  </div>
                ) : (
                  <button
                    type="button"
                    className="landing-riviste-slider__slide-image-box"
                    onClick={() => setZoomModalItem(x)}
                  >
                    <WImage src={x.image} maxWidth={537} maxHeight={347} />
                  </button>
                )}
              </div>
            </div>
          ))}
        </SlickSlider>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default LandingRivisteSlider
