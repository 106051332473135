import { useSelector } from 'react-redux'
import pick from 'lodash/pick'

export default function usePermissions() {
  const { utente } = useSelector(state => state?.utente)
  const getUserPermissions = () => {
    return pick(utente, ['is_adottatario', 'is_staff', 'is_old', 'is_member', 'ruolo'])
  }

  const getPermissions = ({ is_member, is_adottatario, ruolo }) => {
    if (ruolo === 'Insegnante' || ruolo === 'i') {
      return is_adottatario
        ? is_member
          ? allPermissions['docente-adottatario-abbonato']
          : allPermissions['docente-adottatario-non-abbonato']
        : is_member
        ? allPermissions['docente-abbonato']
        : allPermissions['docente-non-abbonato']
    } else {
      return !is_member
        ? allPermissions['genitore-studente-abbonato']
        : allPermissions['genitore-studente-non-abbonato']
    }
  }

  const filterWidgetByPermissions = widget => {
    if (utente) {
      const permissions = getPermissions(getUserPermissions())?.[0]
      return widget?.filter(({ key }) =>
        [
          ...permissions?.fisso,
          ...permissions.modificabile,
          ...permissions?.aggiungibile,
        ]?.includes(key)
      )
    }
  }

  const filterWidgetByType = widget => {
    if (utente) {
      const permissions = getPermissions(getUserPermissions())?.[0]
      return widget?.filter(({ key }) =>
        [...permissions.modificabile, ...permissions?.aggiungibile]?.includes(key)
      )
    }
  }

  return { getPermissions, getUserPermissions, filterWidgetByPermissions, filterWidgetByType }
}

export const WidgetType = [
  'attiva-prodotto',
  'mia-libreria',
  'agente',
  'formazione',
  'libri-di-testo',
  'abbonamento-io+',
  'dbookeasy',
  'sportelli',
  'le-mie-riviste',
  'scuola-store',
  'community',
  'i-miei-interessi',
  'le-mie-raccolte',
  'i-miei-contenuti',
]

export const allPermissions = {
  'genitore-studente-non-abbonato': [
    {
      fisso: ['abbonamento-io+', 'mia-libreria', 'scuola-store', 'i-miei-contenuti'],
      modificabile: [],
      aggiungibile: [
        'sportelli',
        'le-mie-riviste',
        'dbookeasy',
        'libri-di-testo',
        'formazione',
        'attiva-prodotto',
        'la-mia-libreria',
        'i-miei-interessi',
        'le-mie-raccolte',
      ],
    },
  ],
  'genitore-studente-abbonato': [
    {
      fisso: [],
      modificabile: [
        'la-mia-libreria',
        'sportelli',
        'le-mie-riviste',
        'le-mie-raccolte',
        'i-miei-contenuti',
      ],
      aggiungibile: [
        'dbookeasy',
        'scuola-store',
        'formazione',
        'abbonamento-io+',
        'libri-di-testo',
        'attiva-prodotto',
        'i-miei-interessi',
      ],
    },
  ],
  'docente-non-abbonato': [
    {
      fisso: ['abbonamento-io+', 'mia-libreria', 'i-miei-interessi', 'i-miei-contenuti', 'agente'],
      modificabile: [
        'libri-di-testo',
        'formazione',
        'le-mie-riviste',
        'scuola-store',
        'attiva-prodotto',
        'dbookeasy',
        'le-mie-raccolte',
      ],
      aggiungibile: [],
    },
  ],
  'docente-abbonato': [
    {
      fisso: [],
      modificabile: [
        'le-mie-riviste',
        'la-mia-libreria',
        'sportelli',
        'le-mie-raccolte',
        'i-miei-contenuti',
      ],
      aggiungibile: [
        'agente',
        'attiva-prodotto',
        'dbookeasy',
        'formazione',
        'libri-di-testo',
        'scuola-store',
        'abbonamento-io+',
        'i-miei-interessi',
      ],
    },
  ],
  'docente-adottatario-non-abbonato': [
    {
      fisso: [],
      modificabile: ['libri-di-testo', 'agente', 'dbookeasy', 'formazione', 'i-miei-contenuti'],
      aggiungibile: [
        'formazione',
        'sportelli',
        'la-mia-libreria',
        'le-mie-riviste',
        'scuola-store',
        'abbonamento-io+',
        'i-miei-interessi',
        'le-mie-raccolte',
      ],
    },
  ],
  'docente-adottatario-abbonato': [
    {
      fisso: [],
      modificabile: ['la-mia-libreria', 'dbookeasy', 'sportelli'],
      aggiungibile: [
        'libri-di-testo',
        'formazione',
        'le-mie-riviste',
        'scuola-store',
        'agente',
        'attiva-prodotto',
        'abbonamento-io+',
      ],
    },
  ],
}
