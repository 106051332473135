import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function CheckboxUnmarked({ className }) {
  return (
    <IconBase name="checkbox-unmarked" w={24} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19C20.1 3 21 3.9 21 5V19C21 20.1 20.1 21 19 21H5C3.9 21 3 20.1 3 19V5C3 3.9 3.9 3 5 3ZM19.2 19.2V4.8H4.8V19.2H19.2Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default CheckboxUnmarked
