import { useTrans } from 'hooks'
import { Button, Modal } from 'react-components/atoms'
import { Shield } from 'react-components/atoms/Icons'
import { formatPrice } from 'utils'
import React, { useContext } from 'react'
import { CartContext } from 'utils/context'

const PopupGaranzia = ({ open, setOpen, prodotto }) => {
  const t = useTrans()
  const garanzia = prodotto.garanzia ? prodotto.garanzia : null
  const context = useContext(CartContext)

  const addItemToCart = (prodotto, garanzia) => {
    context
      .addToCart(prodotto, 1, true, 'pdp', null, null, 'navigazione_standard', garanzia)
      .then(() => {
        setOpen(false)
      })
  }

  return (
    <Modal open={open} setOpen={setOpen} className="garanzia-modal">
      <div className="garanzia-modal__container">
        <Modal.Content
          title={t('Proteggi il tuo acquisto con la copertura illimitata per le riparazioni')}
          icon={<Shield />}
        >
          <div className="garanzia-modal__container__infos">
            {garanzia?.nome && garanzia?.durata && (
              <span className="garanzia-modal__container__infos__titolo">
                {garanzia.nome} - {garanzia.durata} {t('mesi')}
              </span>
            )}

            {garanzia?.prezzo && (
              <span className="garanzia-modal__container__infos__prezzo">
                {formatPrice(garanzia.prezzo)}
              </span>
            )}

            {garanzia?.descrizione && (
              <div
                className="garanzia-modal__container__infos__descrizione"
                dangerouslySetInnerHTML={{ __html: garanzia?.descrizione }}
              />
            )}
          </div>
        </Modal.Content>
        <Modal.Buttons>
          <Button
            label={t("Aggiungi l'assistenza")}
            onClick={() => addItemToCart(prodotto, true)}
          />
          <Button
            label={t('No, grazie')}
            variant="secondary"
            onClick={() => addItemToCart(prodotto, false)}
          />
        </Modal.Buttons>
      </div>
    </Modal>
  )
}
export default PopupGaranzia
