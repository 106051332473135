import Head from 'next/head'

function Manutenzione() {
  return (
    <>
      <Head>
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Service Unavailable | Giunti Scuola</title>
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:400,700,900"
          rel="stylesheet"
          type="text/css"
        />
      </Head>
      <style jsx global>{`
        body {
          color: #2a2a2a;
          background: #ffffff;
          font-family: 'Open Sans', Arial, Helvetica, sans-serif;
          line-height: 150%;
          margin: 0;
          padding: 0;
        }
        .logo {
          text-align: center;
          padding: 30px 0;
          background: #f78930;
          border-bottom: 1px solid #ccc;
        }
        .logo p {
          font-size: 40px;
          margin: 10px 0;
          color: #fce7ce;
          font-weight: 900;
        }
        .logo img {
          width: 250px;
          height: 50px;
        }
        .logo small {
          font-size: 20px;
        }
        .container {
          background: #f5f0e8;
          color: #25211e;
          padding: 50px 0;
        }
        .header {
          text-align: center;
        }
        .main {
          max-width: 1070px;
          margin: 0 auto;
        }
        .main .left {
          float: left;
          width: 45%;
          padding: 50px 2.5%;
        }
        .main .right {
          float: right;
          width: 45%;
          padding: 50px 2.5%;
        }
        h1 {
          font-size: 40px;
          line-height: 1.3;
        }
        h2 {
          font-size: 30px;
          line-height: 1.3;
        }
        p {
          font-size: 14px;
        }
        p.title {
          font-size: 20px;
          line-height: 24px;
          margin: 0;
          font-weight: bold;
        }
        small {
          font-size: 12px;
        }
        .line {
          max-width: 120px;
          border-top: 1px solid #999999;
          margin: 0 auto;
        }
        .clear {
          clear: both;
        }
        .footer {
          text-align: center;
          padding: 20px 0;
          font-size: 14px;
          color: #ffffff;
          background: #f78930;
        }
      `}</style>
      <div className="logo">
        <img src="https://giunti-scuola.s3.eu-central-1.amazonaws.com/static/images/svg/logo-giuntiscuola-bw.svg" />
      </div>
      <div
        className="container"
        style={{
          minHeight: 'calc(100vh - 266px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div className="header">
          <svg
            style={{ width: '100px', height: '100px', enableBackground: 'new 0 0 30 30' }}
            id="Layer_1"
            version="1.1"
            viewBox="0 0 30 30"
            xmlSpace="preserve"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <path
              fill="#C6C9CC"
              d="M6,9.3L3.9,5.8l1.4-1.4l3.5,2.1v1.4l3.6,3.6c0,0.1,0,0.2,0,0.3L11.1,13L7.4,9.3H6z M21,17.8c-0.3,0-0.5,0-0.8,0  c0,0,0,0,0,0c-0.7,0-1.3-0.1-1.9-0.2l-2.1,2.4l4.7,5.3c1.1,1.2,3,1.3,4.1,0.1c1.2-1.2,1.1-3-0.1-4.1L21,17.8z M24.4,14  c1.6-1.6,2.1-4,1.5-6.1c-0.1-0.4-0.6-0.5-0.8-0.2l-3.5,3.5l-2.8-2.8l3.5-3.5c0.3-0.3,0.2-0.7-0.2-0.8C20,3.4,17.6,3.9,16,5.6  c-1.8,1.8-2.2,4.6-1.2,6.8l-10,8.9c-1.2,1.1-1.3,3-0.1,4.1l0,0c1.2,1.2,3,1.1,4.1-0.1l8.9-10C19.9,16.3,22.6,15.9,24.4,14z"
            />
          </svg>
          <h1>Sito in manutenzione</h1>
          <div className="line"></div>
          <p>
            Stiamo lavorando per migliorare la vostra esperienza sul nostro sito, ci scusiamo per il
            disagio
          </p>
        </div>
      </div>
      <div className="footer">
        <strong>Giunti Scuola</strong>
        <br />
        Copyright © {new Date().getFullYear()}
        <br />
        P. IVA 05492160485
        <br />
        Tutti i diritti riservati
      </div>
    </>
  )
}

export default Manutenzione
