import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Puzzle({ className }) {
  return (
    <IconBase name="puzzle" w={20} className={className}>
      <g clipPath="url(#puzzle-clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 3.16666C12.5 1.878 11.3807 0.833328 9.99996 0.833328C8.61925 0.833328 7.49996 1.878 7.49996 3.16666V4.16666L4.66663 4.16666C3.00977 4.16666 1.66663 5.50981 1.66663 7.16666V8.33333H2.66663C3.95529 8.33333 4.99996 9.45262 4.99996 10.8333C4.99996 12.214 3.95529 13.3333 2.66663 13.3333H1.66663V16.1667C1.66663 17.8235 3.00977 19.1667 4.66663 19.1667H7.49996V18.1667C7.49996 16.878 8.61925 15.8333 9.99996 15.8333C11.3807 15.8333 12.5 16.878 12.5 18.1667V19.1667H13.6666C15.3235 19.1667 16.6666 17.8235 16.6666 16.1667V13.3333H17.6666C18.9553 13.3333 20 12.214 20 10.8333C20 9.45262 18.9553 8.33333 17.6666 8.33333H16.6666V7.16666C16.6666 5.50981 15.3235 4.16666 13.6666 4.16666L12.5 4.16666V3.16666Z"
          stroke="none"
        />
      </g>
      <defs>
        <clipPath id="puzzle-clip0">
          <rect width="20" height="20" fill="white" stroke="none" />
        </clipPath>
      </defs>
    </IconBase>
  )
}

export default Puzzle
