import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Ellipse({ className }) {
  return (
    <IconBase name="cat-scheda" w={10} className={className}>
      <circle
        cx="5"
        cy="5"
        r="5"
        //  fill="#024598"
      />
    </IconBase>
  )
}

export default Ellipse
