import React from 'react'
import { useTrans } from 'hooks'
import Target from '../../atoms/Icons/Target'

const EsploraAltroItem = () => {
  const t = useTrans()
  return (
    <div className="esplora-altro">
      <div className="esplora-altro__icon">
        <Target />
      </div>
      <div className="esplora-altro__text">{t('Vuoi scoprire altri corsi che fanno per te?*')}</div>
      <div className="esplora-altro__link">
        <a href="../formazione/corsi">{t("Vai all'elenco completo")}</a>
      </div>
    </div>
  )
}

export default EsploraAltroItem
