import React, { useState } from 'react'
import { Modal } from 'react-components/atoms'

const Figure = props => {
  const {
    overflow = 'left', // 'left' | 'right'
    src = '',
    alt = '',
    title = '',
    caption = '',
    className = '',
    zoommabile = false,
  } = props

  const [open, setOpen] = useState(false)

  const content = (
    <img
      src={src}
      className={`wfigure__image ${zoommabile ? 'wfigure__image--zoommabile' : ''}`}
      alt={alt}
      title={title}
      onClick={() => {
        if (zoommabile) {
          document.body.classList.add('w-scrolldown')
          setOpen(true)
        }
      }}
    />
  )

  const modal = zoommabile && (
    <Modal
      open={open}
      setOpen={() => {
        document.body.classList.remove('w-scrolldown')
        setOpen(false)
      }}
      closeButton={true}
      className="wmodal--lightbox"
    >
      {content}
    </Modal>
  )

  return (
    <figure className={`wfigure ${overflow ? `wfigure--overflow-${overflow}` : ''} ${className}`}>
      {content}
      {modal}
      <figcaption className="wfigure__caption">{caption}</figcaption>
    </figure>
  )
}

export default Figure
