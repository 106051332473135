import React from 'react'
import Link from 'next/link'

const CorrelatiCardItem = (props) => {
  const { item = null, tags = [], join = '', className = '' } = props

  if (!item) return null

  return (
    <article className={`correlati-card-item ${className ? ` ${className}` : ''}`}>
      <div className="correlati-card-item__tags">
        {tags.map((tag, index) => (
          <span key={tag}>
            {tag}
            {index + 1 < tags.length && join}
          </span>
        ))}
      </div>
      <Link legacyBehavior href={item.url}>
        <a className="correlati-card-item__title">{item.titolo}</a>
      </Link>
    </article>
  )
}

export default CorrelatiCardItem
