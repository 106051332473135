import React from 'react'
import { FormError } from 'react-components/atoms'

const FormField = props => {
  const {
    label = '',
    size = 'md',
    layout = 'md',
    layoutMobile = 'full',
    space = 'md',
    status = '',
    errorMessage = '',
    className = '',
    helper = '',
    children = null,
    inline = false,
    required = false,
  } = props

  const classNames = `form-field
    ${size ? ` form-field--${size}` : ''}
    ${layout ? ` form-field--layout-${layout}` : ''}
    ${layoutMobile ? ` form-field--layout-mobile-${layoutMobile}` : ''}
    ${space ? ` form-field--space-${space}` : ''}
    ${inline ? ` form-field--inline` : ''}
    ${status ? ` form-field--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      {label && (
        <div className="form-field__head">
          <span className="form-field__label">
            {label}
            {required && <>*</>}
          </span>
        </div>
      )}
      <div className="form-field__children">{children}</div>
      {helper && <span className="form-field__helper">{helper}</span>}
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormField
