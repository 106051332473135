import React from 'react'
import { useTrans } from 'hooks'
import { IoPiu } from 'components/icons'
import { Button, ImageBox, WContainer } from 'react-components/atoms'

const TypIoPiuBox = props => {
  const { className = '' } = props

  const t = useTrans()
  const items = [
    { id: 1, imageUrl: '/images/home2021/iopiu-home2021-1.png', nome: 'Risorse in esclusiva' },
    { id: 2, imageUrl: '/images/home2021/iopiu-home2021-2.png', nome: 'Una community a supporto' },
    {
      id: 3,
      imageUrl: '/images/home2021/iopiu-home2021-3.png',
      nome: 'Percorsi didattici personalizzati',
    },
    {
      id: 4,
      imageUrl: '/images/home2021/iopiu-home2021-4.png',
      nome: 'Professionisti pronti ad aiutarti',
    },
  ]

  return (
    <section className={`typ-io-piu-box ${className ? ` ${className}` : ''}`}>
      <WContainer>
        <p className="typ-io-piu-box__title">
          <IoPiu />
          <span>{t('Per noi vali di più')}</span>
        </p>
        <div className="typ-io-piu-box__description">
          <p>
            {t(
              'Io+ è il servizio in abbonamento unico che ti aiuta a sfruttare al meglio la piattaforma di Giunti Scuola.'
            )}
          </p>
          <p>
            <b>{t('Scopri tutto quello che abbiamo in serbo per te!')}</b>
          </p>
        </div>
        <div className="typ-io-piu-box__list">
          {items.map((item, index) => (
            <div className="typ-io-piu-box__item" key={index}>
              <ImageBox
                src={item.imageUrl}
                maxWidth={88}
                maxHeight={88}
                classPrefix="typ-io-piu-box__item"
              />
              <p className="typ-io-piu-box__item__content">{item.nome}</p>
            </div>
          ))}
        </div>
        <div className="typ-io-piu-box__cta">
          <Button label={t('Inizia a navigare')} href="/" />
        </div>
      </WContainer>
    </section>
  )
}

export default TypIoPiuBox
