import React from 'react'
import { useTrans } from 'hooks'
import { formatPrice } from 'utils'
import { Promo } from 'react-components/atoms/Icons'
import { ProdottoCartCard } from 'react-components/molecules'
import {
  prodottiSpediti,
  prodottiDigitali,
  prodottiAbbonamento,
  prodottiDropshipping,
  CART_SECTIONS,
  isIoPiu,
} from 'utils/cart'

const AreaPersonaleRiepilogoOrdineCard = (props) => {
  const { ordine = undefined, className = '' } = props

  const t = useTrans()

  const items = {
    spedito: prodottiSpediti(ordine?.righeOrdineProdotti),
    digitale: prodottiDigitali(ordine?.righeOrdineProdotti),
    abbonamento: prodottiAbbonamento(ordine?.righeOrdineProdotti),
    dropshipping: prodottiDropshipping(ordine?.righeOrdineProdotti),
  }

  return ordine ? (
    <div className={`area-personale-riepilogo-ordine-card ${className ? ` ${className}` : ''}`}>
      {CART_SECTIONS.filter((section) => items[section.tipo].length > 0).map((section) => (
        <div className="area-personale-riepilogo-ordine-card__section">
          <p className="area-personale-riepilogo-ordine-card__section-title">{t(section.titolo)}</p>
          <div className="area-personale-riepilogo-ordine-card__list">
            {items[section.tipo].map((x) => (
              <ProdottoCartCard
                key={x.pk}
                tipologia={x.prodotto.tipologia}
                titolo={x.prodotto.titolo}
                mainImage={x.prodotto.mainImage}
                prezzo={x.prezzoUnitario * x.quantitaOrdinata}
                prezzoScontato={x.prezzoTotaleScontato}
                labelText={x.omaggio ? 'Omaggio' : ''}
                showQuantita
                changeQuantita={false}
                quantita={x.quantitaOrdinata}
                isIoPiu={isIoPiu(x.prodotto)}
                riga={x}
                rinnovo={x.prodotto.rinnovo}
              />
            ))}
          </div>
        </div>
      ))}
      <div className="area-personale-riepilogo-ordine-card__bottom">
        <div className="area-personale-riepilogo-ordine-card__rows">
          {!!ordine.totaleProdottiScontato && (
            <p className="area-personale-riepilogo-ordine-card__row">
              <span>{t('Totale prodotti')}</span>
              <span>{formatPrice(ordine.totaleProdottiScontato)}</span>
            </p>
          )}
          {!!ordine.prezzoTotaleServizi && (
            <p className="area-personale-riepilogo-ordine-card__row">
              <span>{t('Totale servizi')}</span>
              <span>{formatPrice(ordine.prezzoTotaleServizi)}</span>
            </p>
          )}
          {!!ordine.costoSpedizione && (
            <p className="area-personale-riepilogo-ordine-card__row">
              <span>{t('Spese di spedizione')}</span>
              <span>{formatPrice(ordine.costoSpedizione)}</span>
            </p>
          )}
          {ordine.promoCode && ordine.promozioneValoreAssoluto && (
            <p className="area-personale-riepilogo-ordine-card__row area-personale-riepilogo-ordine-card__row--promo">
              <span className="area-personale-riepilogo-ordine-card__row-label">
                <Promo className="area-personale-riepilogo-ordine-card__row-icon" />
                <span>{ordine.promoCode}</span>
              </span>
              <span>{formatPrice(ordine.promozioneValoreAssoluto)}</span>
            </p>
          )}
        </div>
        {!!ordine.prezzoTotaleScontato && (
          <div className="area-personale-riepilogo-ordine-card__totale">
            <span>{t('Totale')}</span>
            <span>{formatPrice(ordine.prezzoTotaleScontato)}</span>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default AreaPersonaleRiepilogoOrdineCard
