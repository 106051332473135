import React, { useEffect, useState } from 'react'
import { PlusCircle } from 'react-components/atoms/Icons'
import { Button, FormIndirizzoRadio } from 'react-components/atoms'
import { CheckoutSection } from 'react-components/molecules'
import { CheckoutIndirizzoForm } from 'react-components/organisms'
import { useIndirizziSpedizioneQuery } from 'gql/graphql'
import { useTrans } from 'hooks'
import { sendGTMData } from '../../../utils/gtm'

const SpedizioneCheckout = props => {
  const {
    className = '',
    showIndirizzoSpedizioneForm,
    setShowIndirizzoSpedizioneForm,
    indirizzoSpedizione,
    setIndirizzoSpedizione,
  } = props

  const t = useTrans()

  const [indirizzi, setIndirizzi] = useState([])
  const [editIndirizzo, setEditIndirizzo] = useState(null)

  const { data: indirizziSpedizione, loading } = useIndirizziSpedizioneQuery({
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (indirizziSpedizione?.indirizzi) {
      setIndirizzi(indirizziSpedizione.indirizzi.edges.map(({ node }) => node))
    }
    if (!loading && !indirizziSpedizione?.indirizzi?.edges?.length) {
      setShowIndirizzoSpedizioneForm(true)
    }
  }, [indirizziSpedizione, loading])

  function resetForm() {
    setEditIndirizzo(null)
    setShowIndirizzoSpedizioneForm(false)
  }

  const handleGTM = item => {
    sendGTMData({
      event: 'GAevent',
      eventID: '28',
      eventCategory: 'checkout',
      eventAction: 'dati_spedizione',
      eventLabel: item.toGTMFormat(),
    })
  }

  return (
    <CheckoutSection
      titolo={t('Dati di spedizione')}
      action={
        !showIndirizzoSpedizioneForm
          ? {
              label: t('Usa un altro indirizzo'),
              icon: <PlusCircle />,
              onClick: () => {
                handleGTM('usa un altro indirizzo')
                setShowIndirizzoSpedizioneForm(true)
              },
            }
          : undefined
      }
      className={`spedizione-checkout ${className ? ` ${className}` : ''}`}
    >
      <>
        {indirizzi.length === 0 && !showIndirizzoSpedizioneForm && (
          <p className="spedizione-checkout__empty">{t('Nessun indirizzo di spedizione')}</p>
        )}
        {!editIndirizzo && (
          <FormIndirizzoRadio
            id={'indirizzospedizione'}
            indirizzi={indirizzi}
            value={indirizzoSpedizione}
            onChange={e => setIndirizzoSpedizione(parseInt(e.target.value))}
            className="checkout-template__indirizzi-list"
            onEdit={setEditIndirizzo}
          />
        )}
        {(showIndirizzoSpedizioneForm || editIndirizzo) && (
          <CheckoutIndirizzoForm
            titolo={editIndirizzo ? t('Modifica indirizzo') : t('Nuovo indirizzo')}
            boxed
            indirizzo={editIndirizzo}
            onSubmit={() => {
              resetForm()
              handleGTM('usa questo indirizzo')
            }}
            actions={
              <>
                <Button variant="ghost" label={t('Annulla')} onClick={resetForm} />
                <Button variant="secondary" label={t('Usa questo indirizzo')} type="submit" />
              </>
            }
          />
        )}
      </>
    </CheckoutSection>
  )
}

export default SpedizioneCheckout
