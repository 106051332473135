import React from 'react'

const Search = () => (
  <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3905 12.5786H14.2939L20 18.2962L18.2962 20L12.5786 14.2939V13.3905L12.2699 13.0703C10.9663 14.191 9.27387 14.8656 7.43282 14.8656C3.32762 14.8656 0 11.538 0 7.43282C0 3.32762 3.32762 0 7.43282 0C11.538 0 14.8656 3.32762 14.8656 7.43282C14.8656 9.27387 14.191 10.9663 13.0703 12.2699L13.3905 12.5786ZM2.28702 7.43282C2.28702 10.2802 4.58548 12.5786 7.43282 12.5786C10.2802 12.5786 12.5786 10.2802 12.5786 7.43282C12.5786 4.58548 10.2802 2.28702 7.43282 2.28702C4.58548 2.28702 2.28702 4.58548 2.28702 7.43282Z"
      stroke="none"
    />
  </svg>
)

export default Search
