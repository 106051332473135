import {
  useAddItemMutation,
  useAddAbbonamentoMutation,
  useCartQuery,
  useRemoveItemMutation,
  useSetPromoCodeMutation,
  useUpdateGaranziaMutation,
  useRemovePromoCodeMutation,
} from 'gql/graphql'
import { useTrans } from 'hooks'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-components/atoms'
import { useSelector } from 'react-redux'
import { LOGIN_URL } from 'utils'
import { getPromoAdapted } from 'utils/cart'
import { getProductDetails, sendGTMData } from './gtm'

export const initialCart = {
  items: [],
  numProdotti: 0,
  totaleProdottiScontato: 0,
  prezzo: 0,
  promoApplicata: null,
  sistemaSpedizione: {
    prezzoScontato: 0,
  },
  totaleServizi: 0,
  promoCode: '',
}

export const CartContext = React.createContext(initialCart)

const CartContextProvider = (props) => {
  const router = useRouter()
  const t = useTrans()

  const initialInfoMessage = {
    title: t('Errore carrello'),
    text: '',
  }

  const { utente } = useSelector((state) => state.utente)

  const { loading, data, refetch } = useCartQuery({ fetchPolicy: 'network-only' })

  const [addItem, { data: addItemPayload, loading: addItemLoading, error: addItemError }] =
    useAddItemMutation()

  const [updateGaranzia] = useUpdateGaranziaMutation()
  const [
    addAbbonamento,
    { data: addAbbonamentoPayload, loading: addAbbonamentoLoading, error: addAbbonamentoError },
  ] = useAddAbbonamentoMutation()

  const [
    removeItem,
    { data: removeItemPayload, loading: removeItemLoading, error: removeItemError },
  ] = useRemoveItemMutation()

  const [
    setPromoCodeMutation,
    { data: setPromoCodePayload, loading: setPromoCodeLoading, error: setPromoCodeError },
  ] = useSetPromoCodeMutation()

  const [
    removePromoCodeMutation,
    { data: removePromoCodePayload, loading: removePromoCodeLoading, error: removePromoCodeError },
  ] = useRemovePromoCodeMutation()

  const [cart, setCart] = useState(initialCart)
  const [showCart, setShowCart] = useState(false)
  const [infoMessage, setInfoMessage] = useState(initialInfoMessage)

  const isLoading = loading || addItemLoading || addAbbonamentoLoading

  useEffect(() => {
    if (!router.asPath.includes('/checkout')) window.addEventListener('focus', refetch)
    return () => window.removeEventListener('focus', refetch)
  })

  useEffect(() => {
    if (data) initCart(data.cart)
  }, [data])

  useEffect(() => {
    if (showCart) closeCart()
    refetch()
  }, [router.asPath])

  function initCart(cart) {
    setCart(getPromoAdapted(cart))
  }

  function handleGTM(item, page, event = 'addToCart', discount, list_type, quantity = 1) {
    if (!item) return
    let dictionary = {
      event: event,
      ecommerce: {
        currencyCode: 'eur',
        eventPageLocator: page,
      },
    }
    dictionary.ecommerce[event == 'addToCart' ? 'add' : 'remove'] = {
      actionField: { list: list_type },
      products: [getProductDetails(item, discount, null, quantity)],
    }
    sendGTMData(dictionary)
  }

  async function addToCart(
    item,
    quantita = 1,
    show = true,
    page = '',
    discount = null,
    parent = null,
    list_type = null,
    garanzia = false
  ) {
    const response = await addItem({
      variables: {
        item: item.pk,
        quantita,
        garanzia,
      },
    })

    const {
      data: {
        addItem: { status, message },
      },
    } = response

    if (status) {
      handleGTM(
        parent ? parent : item,
        page,
        quantita == -1 ? 'removeFromCart' : 'addToCart',
        discount,
        list_type
      )
      refetch()
      if (show) openCart()
    }

    let showMessage = false
    // if (item.forzature.length > 0) showMessage = true

    return { status, message, showMessage }
  }

  async function toggleWarranty(riga, garanzia = false) {
    const response = await updateGaranzia({
      variables: {
        riga: riga,
        garanzia: garanzia,
      },
    })

    const {
      data: {
        updateGaranzia: { status, message },
      },
    } = response

    if (status) {
      refetch()
    }

    let showMessage = false

    return { status, message, showMessage }
  }

  async function addAbbonamentoToCart(
    rivista,
    show = true,
    page = '',
    rivista_object = null,
    list_type = null
  ) {
    if (!utente) {
      setInfoMessage({
        title: t('Ci sei quasi!'),
        text: t(
          `Per abbonarti devi registrarti su GiuntiScuola.it.<br/> Se hai già un profilo <a href='${LOGIN_URL}'><u>accedi qui</u></a>, altrimenti <a href='/registrazione'><u>registrati</u></a> e continua con gli acquisti.`
        ),
      })
    } else {
      const response = await addAbbonamento({
        variables: {
          rivista,
        },
      })

      if (rivista_object)
        handleGTM(rivista_object, 1, page, undefined, rivista_object.price, list_type)

      const {
        data: {
          addAbbonamento: { status },
        },
      } = response
      if (status) {
        refetch()
        if (show) openCart()
      } else {
        setInfoMessage({
          title: t('Abbonamento già acquistato'),
          text: t(`Hai già rinnovato questo abbonamento`),
        })
      }
    }
  }

  async function removeFromCart(
    item,
    quantity = 1,
    show = true,
    page = '',
    discount,
    list_type = 'navigazione_standard'
  ) {
    const response = await removeItem({
      variables: {
        item: item.pk,
      },
    }).then(handleGTM(item, page, 'removeFromCart', discount, list_type, quantity))

    const {
      data: {
        removeItem: { status },
      },
    } = response

    if (status) {
      refetch()
      if (show) openCart()
      return true
    } else {
      return false
    }
  }

  async function setPromoCode(promoCode) {
    const response = await setPromoCodeMutation({
      variables: {
        promoCode,
      },
    })

    const {
      data: {
        setPromoCode: { status, message },
      },
    } = response

    if (status) {
      refetch()
      return { status, message }
    } else {
      return { status, message }
    }
  }

  async function removePromoCode(promoCode) {
    const response = await removePromoCodeMutation({
      variables: {
        promoCode,
      },
    })

    const {
      data: {
        removePromoCode: { status, message },
      },
    } = response

    if (status) {
      refetch()
      return { status, message }
    } else {
      return { status, message }
    }
  }

  function openCart() {
    setShowCart(true)
    sendGTMData({
      event: 'GAevent',
      eventID: '03',
      eventCategory: 'header',
      eventAction: 'minicart'.toGTMFormat(),
      eventLabel: 'non_valorizzata',
    })
  }

  function closeCart() {
    setShowCart(false)
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        addAbbonamentoToCart,
        removeFromCart,
        setPromoCode,
        removePromoCode,
        isLoading,
        showCart,
        openCart,
        closeCart,
        toggleWarranty,
      }}
    >
      {props.children}
      <Modal open={infoMessage.text} setOpen={() => setInfoMessage(initialInfoMessage)} closeButton>
        <h3
          className="modal__body__title"
          style={{ textAlign: 'center', marginBottom: '20px', fontSize: '30px' }}
        >
          {infoMessage.title}
        </h3>
        <div
          className="modal__body__text"
          style={{ textAlign: 'center', marginBottom: '20px' }}
          dangerouslySetInnerHTML={{ __html: infoMessage.text }}
        />
      </Modal>
    </CartContext.Provider>
  )
}

export { CartContextProvider }
