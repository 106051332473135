import React from 'react'
import { useTrans } from 'hooks'
import { Bin, Edit } from 'react-components/atoms/Icons'
import { Button, Label } from 'react-components/atoms'

const AreaPersonaleIndirizzoCard = props => {
  const {
    indirizzo = {},
    onEdit = undefined,
    onDelete = undefined,
    onSetDefault = undefined,
    className = '',
  } = props

  const t = useTrans()

  return indirizzo ? (
    <div className={`area-personale-indirizzo-card ${className ? ` ${className}` : ''}`}>
      {indirizzo.default && (
        <Label
          variant="tag"
          color="teal"
          text={t(
            `Indirizzo di ${indirizzo.isFatturazione ? 'fatturazione' : 'spedizione'} predefinito`
          )}
          className="area-personale-indirizzo-card__predefinito-label"
        />
      )}
      {indirizzo.apg && (
        <Label
          variant="tag"
          text={t(`Indirizzo abbonamento`)}
          className="area-personale-indirizzo-card__predefinito-label"
        />
      )}
      <div className="area-personale-indirizzo-card__content">
        {indirizzo.isFatturazione && (
          <p className="area-personale-indirizzo-card__intro">
            {t(indirizzo.privato ? 'Privato' : 'Azienda / Libero professionista')}
          </p>
        )}
        {!!(indirizzo.nome || indirizzo.cognome) && (
          <p className="area-personale-indirizzo-card__title">
            {indirizzo.nome} {indirizzo.cognome}
          </p>
        )}
        <p>
          {indirizzo.indirizzo}, {indirizzo.civico} - {indirizzo.cap} {indirizzo.citta}{' '}
          {!!indirizzo.provincia && <>({indirizzo.provincia})</>}
        </p>
        {!!indirizzo.informazioniAggiuntive && <p>{indirizzo.informazioniAggiuntive}</p>}
        {!!indirizzo.telefono && <p>{indirizzo.telefono}</p>}
      </div>
      <div className="area-personale-indirizzo-card__bottom">
        <div className="area-personale-indirizzo-card__actions-wrap">
          <Button
            variant="ghost"
            label={t('Elimina')}
            icon={<Bin />}
            colorVariant="grey"
            size="sm"
            className="area-personale-indirizzo-card__remove"
            onClick={onDelete}
          />
          <Button
            variant="ghost"
            label={t('Modifica')}
            icon={<Edit />}
            colorVariant="grey"
            size="sm"
            className="area-personale-indirizzo-card__edit"
            onClick={onEdit}
          />
        </div>
        {!indirizzo.default && (
          <div className="area-personale-indirizzo-card__predefinito-wrap">
            <Button
              variant="ghost"
              label={t('Rendi predefinito')}
              size="sm"
              className="area-personale-indirizzo-card__predefinito-button"
              onClick={onSetDefault}
            />
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default AreaPersonaleIndirizzoCard
