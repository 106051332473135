import React, { useContext } from 'react'
import { useTrans } from 'hooks'
import { getYoutubeThumbnail, getVimeoThumbnail, datetimeParseItalian } from 'utils'
import { Calendar, Pin } from 'react-components/atoms/Icons'
import { Image, Button } from 'react-components/atoms'
import BadgeTag from 'react-components/atoms/BadgeTag/BadgeTag'
import Icon from 'components/atomic/Icon'
import { useSelector } from 'react-redux'
import { WebinarContext } from 'utils/webinarContext'

const FormazioneWebinarCard = (props) => {
  const { item = undefined, className = '' } = props
  const t = useTrans()
  const { informazioniModulo, setComunicazione } = useContext(WebinarContext)
  const variant = item
    ? item.ultimo_attivo // Se è l'ultimo webinar attivo
      ? 'current'
      : item.data && item.ora && datetimeParseItalian(item.data + ' ' + item.ora) < new Date()
      ? 'past'
      : 'future'
    : ''
  const { utente } = useSelector((state) => state.utente)

  const handleGTM = (label) => {
    dataLayer.push({
      event: 'GAevent',
      eventID: '35',
      eventCategory: 'formazione_moduli_correlati',
      eventAction: label.toGTMFormat(),
      eventLabel: item.titolo.toGTMFormat(),
      moreInfo: item.in_corso ? 'webinar_in_corso' : 'webinar_conclusi',
    })
  }

  const LABELS = {
    eventoformazione: {
      titolo: t('Evento'),
      dataFutura:
        item.ora && item.ora_fine
          ? `${t('Si terrà il')} <strong>${item.data}</strong> ${t('dalle ore')} <strong>${
              item.ora
            }</strong> ${t('alle ore')} <strong>${item.ora_fine}</strong>`
          : `${t('Si terrà il')} <strong>${item.data}</strong>`,
    },
    webinar: {
      titolo: t('Webinar'),
      dataFutura: item.ora
        ? `${t('Disponibile dal')} <strong>${item.data}</strong> ${t('alle')} <strong>${
            item.ora
          }</strong>`
        : `${t('Disponibile dal')} <strong>${item.data}</strong>`,
    },
  }

  return item ? (
    <div
      className={`formazione-webinar-card formazione-webinar-card--${variant} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div
        className={`formazione-webinar-card__content ${
          variant === 'future' ? 'formazione-webinar-card__content--future' : ''
        }`}
      >
        {!!item.position && (
          <p className="formazione-webinar-card__intro">{`${LABELS[item.model_class].titolo} #${
            item.position
          }`}</p>
        )}
        {!!item.titolo && <p className="formazione-webinar-card__title">{item.titolo}</p>}
        {!!item.data && variant !== 'future' && (
          <div className="formazione-webinar-card__date">
            <span className="formazione-webinar-card__date-icon">
              <Calendar />
            </span>
            <span
              className="formazione-webinar-card__date-label"
              dangerouslySetInnerHTML={{
                __html: `${t('Del')} <strong>${item.data}</strong>`,
              }}
            ></span>
          </div>
        )}
        {!!item.luogo && !!item.indirizzo && variant !== 'future' && (
          <div className="formazione-webinar-card__date">
            <span className="formazione-webinar-card__date-icon">
              <Pin />
            </span>
            <span
              className="formazione-webinar-card__date-label"
              dangerouslySetInnerHTML={{
                __html: `<strong>${item.indirizzo} - ${item.luogo}</strong>`,
              }}
            ></span>
          </div>
        )}
        {!!item.descrizione && (
          <div
            className="formazione-webinar-card__description"
            dangerouslySetInnerHTML={{ __html: item.descrizione }}
          />
        )}
        {!!item.data && variant === 'future' && (
          <div className="formazione-webinar-card__date-wrap">
            <div className="formazione-webinar-card__date">
              <span className="formazione-webinar-card__date-icon">
                <Calendar />
              </span>
              <span
                className="formazione-webinar-card__date-label"
                dangerouslySetInnerHTML={{
                  __html: LABELS[item.model_class].dataFutura,
                }}
              />
            </div>
          </div>
        )}
        {!!item.luogo && !!item.indirizzo && variant === 'future' && (
          <div className="formazione-webinar-card__date">
            <span className="formazione-webinar-card__date-icon">
              <Pin />
            </span>
            <span
              className="formazione-webinar-card__date-label"
              dangerouslySetInnerHTML={{
                __html: `<strong>${item.indirizzo} - ${item.luogo}</strong>`,
              }}
            ></span>
          </div>
        )}
        {!!item.url_file && variant !== 'future' && (
          <div className="formazione-webinar-card__cta-box">
            <Button
              label={t('Scarica i contenuti del webinar')}
              variant="secondary"
              href={item.url_file}
              onClick={() => handleGTM(t('Scarica i contenuti del webinar'))}
              external
              newPage
            />
          </div>
        )}
      </div>
      {variant !== 'future' && (
        <div className="formazione-webinar-card__image-box">
          <div className="formazione-webinar-card__image-box__icon">
            <a
              href={utente && informazioniModulo.iscritto ? item.link_esterno : null}
              target="_blank"
              onClick={() => {
                setComunicazione(!utente || !informazioniModulo.iscritto)
                handleGTM('play video')
              }}
            >
              <Icon id="play-button" classes="" />
            </a>
          </div>
          <Image
            src={
              item.provider == 'Youtube'
                ? getYoutubeThumbnail(item.link_esterno)
                : getVimeoThumbnail(item.link_esterno)
            }
            width={420}
            height={288}
            alt={item.titolo.toAltTitle()}
            title={item.titolo.toAltTitle()}
          />
        </div>
      )}
      {variant === 'current' && <BadgeTag title={'Ultimo contenuto'} version={'secondary-dark'} />}
      {variant === 'future' && item.in_arrivo && (
        <BadgeTag title={'In arrivo'} version={'primary'} />
      )}
    </div>
  ) : (
    <></>
  )
}

export default FormazioneWebinarCard
