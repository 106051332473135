import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Info({ className }) {
  return (
    <IconBase name="info" w={24} className={className}>
      <circle cx="12" cy="12" r="8" className="strokeme" strokeWidth="2" fill="none" />
      <line
        x1="12"
        y1="8"
        x2="12"
        y2="13"
        className="strokeme"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <circle cx="12" cy="16" r="1" stroke="none" />
    </IconBase>
  )
}

export default Info
