import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'components/forms-utils/utils'
import {
  FormLayout,
  FormField,
  FormInput,
  FormInputPassword,
  FormSelect,
  AreaPersonaleDataField,
  AreaPersonaleDataFieldLayout,
  FormCheckbox,
} from 'react-components/atoms'
import { AreaPersonaleEditCard } from 'react-components/molecules'

import { REGISTRAZIONE } from 'utils/endpoint'
import { sendGTMData } from 'utils/gtm.js'
import { optionsOrdini } from 'components/registrazione/utils'
import { api } from 'utils'
import { useDispatch } from 'react-redux'
import { fetchUtente } from 'containers/utente'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import italia from 'utils/italia.json'

const validationSchema = yup.object().shape({
  nome: yup
    .string()
    .min(2, 'Troppo corto')
    .max(50, 'Troppo lungo')
    .required('Obbligatorio'),
  cognome: yup
    .string()
    .min(2, 'Troppo corto')
    .max(50, 'Troppo lungo')
    .required('Obbligatorio'),
  email: yup
    .string()
    .email('Email non valida')
    .required('Obbligatorio'),
  password: yup.string().matches(/^.{5,}$/, 'Deve contenere 5 caratteri'),
  ordine: yup.object().required('Obbligatorio'),
  privacy: yup
    .bool()
    .nullable()
    .when('telefono', {
      is: telefono => telefono !== undefined,
      then: yup.bool().oneOf([true], 'Devi accettare le condizioni di privacy'),
    })
    .when('provincia', {
      is: provincia => provincia && provincia.value,
      then: yup.bool().oneOf([true], 'Devi accettare le condizioni di privacy'),
    }),
})

const AreaPersonaleDatiPersonaliEditCard = props => {
  const {
    utente = {
      nome: '',
      cognome: '',
      email: '',
      ordine_di_scuola: '',
    },
    className = '',
  } = props

  const [editMode, setEditMode] = useState(false)
  const [province, setProvince] = useState([])

  const t = useTrans()
  const dispatch = useDispatch()

  const handleSaveDati = values => {
    try {
      api
        .post(REGISTRAZIONE + 'edit_dati/', { ...values })
        .then(() => {
          setEditMode(false)
          sendGTMData({
            event: 'GAevent',
            eventID: '13',
            eventCategory: 'form',
            eventAction: 'dati_personali',
          })
          dispatch(fetchUtente())
        })
        .catch(error => console.log(error))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    initProvince()
  }, [])

  function initProvince() {
    const duplicated = italia.map(c => ({ value: c.sigla, label: c.provincia.nome }))
    setProvince(sortBy(uniqBy(duplicated, 'value'), ['value']))
  }

  return (
    <div className={`area-personale-dati-personali-edit-card ${className ? ` ${className}` : ''}`}>
      <Formik
        enableReinitialize
        isInitialValid={false}
        validationSchema={validationSchema}
        initialValues={{
          nome: utente?.nome,
          cognome: utente?.cognome,
          email: utente?.email,
          password: '',
          provincia: province.find(item => item.value === utente?.provincia),
          telefono: utente?.telefono || '',
          ordine: optionsOrdini?.find(item => item?.label === utente?.ordine_di_scuola),
          privacy: Boolean(utente?.telefono || utente?.provincia),
        }}
        onSubmit={values => {
          try {
            const params = { email: values?.email }
            api
              .get(REGISTRAZIONE + 'check_email/', { params })
              .then(response => {
                if (response.data.exists && values?.email === utente?.email) handleSaveDati(values)
              })
              .catch(error => console.log(error))
          } catch (error) {
            console.log(error)
          }
        }}
      >
        {({ values, touched, errors, handleBlur, handleChange, setFieldValue, handleSubmit }) => (
          <AreaPersonaleEditCard
            title={t('Dati personali')}
            editMode={editMode}
            setEditMode={setEditMode}
            onSubmit={() => handleSubmit()}
          >
            {editMode ? (
              <>
                <FormLayout>
                  <FormField
                    label={`${t('Nome')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'nome')}
                    errorMessage={errors?.nome}
                  >
                    <FormInput
                      value={values?.nome}
                      onBlur={handleBlur('nome')}
                      onChange={handleChange('nome')}
                      status={getFormFieldStatus({ touched, errors }, 'nome')}
                    />
                  </FormField>
                  <FormField
                    label={`${t('Cognome')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'cognome')}
                    errorMessage={errors?.cognome}
                  >
                    <FormInput
                      value={values?.cognome}
                      onBlur={handleBlur('cognome')}
                      onChange={handleChange('cognome')}
                      status={getFormFieldStatus({ touched, errors }, 'cognome')}
                    />
                  </FormField>
                  <FormField
                    label={`${t('Ordine di scuola')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'ordine')}
                    errorMessage={errors?.ordine}
                  >
                    <FormSelect
                      value={values?.ordine}
                      options={optionsOrdini}
                      onBlur={handleBlur('ordine')}
                      onChange={selectedOption => setFieldValue('ordine', selectedOption, true)}
                      status={getFormFieldStatus({ touched, errors }, 'ordine')}
                    />
                  </FormField>
                </FormLayout>
                <FormLayout>
                  <FormField
                    label={`${t('Provincia di residenza')}`}
                    status={getFormFieldStatus({ touched, errors }, 'provincia')}
                    errorMessage={errors?.provincia}
                  >
                    <FormSelect
                      value={values?.provincia}
                      options={province}
                      onBlur={handleBlur('provincia')}
                      onChange={selectedOption => setFieldValue('provincia', selectedOption, true)}
                      status={getFormFieldStatus({ touched, errors }, 'provincia')}
                      searchable
                    />
                  </FormField>
                  <FormField
                    label={`${t('Telefono')}`}
                    status={getFormFieldStatus({ touched, errors }, 'telefono')}
                    errorMessage={errors?.telefono}
                  >
                    <FormInput
                      placeholder={t('')}
                      value={values?.telefono}
                      onBlur={handleBlur('telefono')}
                      onChange={handleChange('telefono')}
                      status={getFormFieldStatus({ touched, errors }, 'telefono')}
                    />
                  </FormField>
                </FormLayout>
                <FormLayout>
                  <FormField
                    label={`${t('Email')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'email')}
                    errorMessage={errors?.email}
                  >
                    <FormInput
                      value={values?.email}
                      type="email"
                      onBlur={handleBlur('email')}
                      onChange={handleChange('email')}
                      status={getFormFieldStatus({ touched, errors }, 'email')}
                    />
                  </FormField>
                  <FormField
                    label={`${t('Password')}*`}
                    status={getFormFieldStatus({ touched, errors }, 'password')}
                    errorMessage={errors?.password}
                    helper={t('La password deve contenere almeno 5 caratteri')}
                  >
                    <FormInputPassword
                      placeholder={t('Inserisci una nuova password')}
                      value={values?.password}
                      onBlur={handleBlur('password')}
                      onChange={handleChange('password')}
                      status={getFormFieldStatus({ touched, errors }, 'password')}
                    />
                  </FormField>
                </FormLayout>
                <FormLayout>
                  <FormField
                    status={getFormFieldStatus({ touched, errors }, 'privacy')}
                    errorMessage={errors?.privacy}
                  >
                    <FormCheckbox
                      id={'privacy'}
                      label={
                        <>
                          {t('Ho letto e compreso l’')}
                          <a href="/privacy-policy" className="w-form__privacy-link">
                            informativa privacy
                          </a>{' '}
                          {t('e accetto integralmente i Termini d’uso.')}
                        </>
                      }
                      name={'privacy'}
                      value={values?.privacy}
                      checked={values?.privacy}
                      onChange={handleChange}
                    />
                  </FormField>
                </FormLayout>
              </>
            ) : (
              <>
                <AreaPersonaleDataFieldLayout>
                  <AreaPersonaleDataField label={t('Nome')} value={utente?.nome} />
                  <AreaPersonaleDataField label={t('Cognome')} value={utente?.cognome} />
                  <AreaPersonaleDataField
                    label={t('Ordine di scuola')}
                    value={utente?.ordine_di_scuola}
                  />
                </AreaPersonaleDataFieldLayout>
                <AreaPersonaleDataFieldLayout>
                  <AreaPersonaleDataField
                    label={t('Provincia di residenza')}
                    value={
                      utente?.provincia
                        ? province.find(item => item.value === utente?.provincia)?.label
                        : '-'
                    }
                  />
                  <AreaPersonaleDataField label={t('Telefono')} value={utente?.telefono || '-'} />
                </AreaPersonaleDataFieldLayout>
                <AreaPersonaleDataFieldLayout>
                  <AreaPersonaleDataField label={t('Email')} value={utente?.email} />
                  <AreaPersonaleDataField label={t('Password')} value={'••••••••'} />
                </AreaPersonaleDataFieldLayout>
              </>
            )}
          </AreaPersonaleEditCard>
        )}
      </Formik>
    </div>
  )
}

export default AreaPersonaleDatiPersonaliEditCard
