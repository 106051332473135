import React from 'react'

const WContainer = props => {
  const { variant = '', children = null } = props

  return variant === 'none' ? (
    <>{children}</>
  ) : (
    <div className={`w-container${variant ? `-${variant}` : ''}`}>{children}</div>
  )
}

export default WContainer
