import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { ImageBox } from 'react-components/atoms'
import { useIsMobile } from 'hooks/useIsMobile'
import { CalendarioAvventoModal } from 'react-components/molecules'
import { getGiornoCorrente, getDateStringFromDay } from 'utils'
import { useRouter } from 'next/router'
import Login from 'components/atomic/Login'

const CalendarioAvventoCellaStep1 = (props) => {
  const {
    cella = {
      aperta: false,
      image: '',
      mobile_image: '',
      giorno: '',
      titolo: '',
      url: '',
    },
    setStep,
    cellaCliccata,
    setCellaCliccata,
  } = props

  const t = useTrans()
  const isMobile = useIsMobile()

  const [open, setOpen] = useState(false)
  const [login, setLogin] = useState(false)
  const [next, setNext] = useState('/calendario-avvento')

  const domain = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3000'

  const retroA = `${domain}/images/calendario_avvento/RetroA.jpg`
  const retroB = `${domain}/images/calendario_avvento/RetroB.jpg`
  const regalo = `${domain}/images/calendario_avvento/scopri_regalo.png`

  const retroMobileA = `${domain}/images/calendario_avvento/RetroMobileA.jpg`
  const retroMobileB = `${domain}/images/calendario_avvento/RetroMobileB.jpg`
  const regaloMobile = `${domain}/images/calendario_avvento/scopri_regalo_mobile.png`

  const icona_regalo_red = `${domain}/images/calendario_avvento/icona_regalo_red.png`

  const router = useRouter()

  const giornoCorrente = getGiornoCorrente(router)
  const meseCorrente = new Date().getMonth()

  const desktopA = [1, 3, 5, 8, 10, 12, 13, 15, 17, 20, 22, 24]

  const mobileA = [1, 3, 6, 8, 9, 11, 14, 16, 17, 19, 22, 24]

  const getImgCella = () => {
    // Se è Gennaio mostro il regalo
    if (meseCorrente == 0) {
      if (isMobile) {
        return cella.mobile_image
      } else {
        return cella.image
      }
    }
    // nascondo le celle se non è dicembre
    if (meseCorrente + 1 < 12) return desktopA.includes(cella.giorno) ? retroA : retroB

    if (cella.giorno < giornoCorrente) {
      if (isMobile) return cella.mobile_image
      return cella.image
    } else {
      if (cella.giorno > giornoCorrente) {
        if (isMobile) {
          return mobileA.includes(cella.giorno) ? retroMobileA : retroMobileB
        } else {
          return desktopA.includes(cella.giorno) ? retroA : retroB
        }
      } else {
        if (isMobile) {
          return regaloMobile
        } else {
          return regalo
        }
      }
    }
  }

  function handleShowLogin(cella) {
    setNext(`/calendario-avvento?giorno=${cella.giorno}&step=2`)
    setLogin(true)
  }

  const handleLogin = (login) => setLogin(login)

  return (
    <div className="box">
      {/* ATTENZIONE MODIFICA FATTA A CALENDARIO FINITO: RIPRISTINARE GLI IF SUL GIORNO PER IL NUOVO CALENDARIO */}
      {meseCorrente !== 0 && cella.giorno > giornoCorrente && (
        <p className="number_cell">{cella.giorno}</p>
      )}
      {meseCorrente !== 0 && cella.giorno === giornoCorrente && (
        <div onClick={() => setOpen(true)}>
          <p className="number_cell_corrente">{cella.giorno}</p>
        </div>
      )}
      <ImageBox
        src={getImgCella()}
        maxWidth={164}
        maxHeight={157}
        alt={getDateStringFromDay(cella.giorno)?.toAltTitle()}
        title={getDateStringFromDay(cella.giorno)?.toAltTitle()}
        onClick={() => (cella.giorno === giornoCorrente || meseCorrente === 0 ? setOpen(true) : '')}
        cursorPointer={cella.giorno === giornoCorrente || meseCorrente === 0 ? true : false}
        lazyLoad
        classPrefix="wcella-card"
      />

      <CalendarioAvventoModal
        cella={cella}
        open={open}
        setOpen={setOpen}
        setStep={setStep}
        icon={icona_regalo_red}
        title={getDateStringFromDay(cella.giorno)}
        disclaimer={t('Oggi ti regaliamo')}
        regalo={cella.titolo}
        cellaCliccata={cellaCliccata}
        setCellaCliccata={setCellaCliccata}
        className="red"
        labelCta={t('Procedi...')}
        onClose={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        showLogin={handleShowLogin}
      />

      {login && <Login isOpen={login} next={next} setLogin={handleLogin} />}
    </div>
  )
}

export default CalendarioAvventoCellaStep1
