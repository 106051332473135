import React, { useState, useEffect } from 'react'
import { Button, ReactTooltip } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { sendGTMData } from 'utils/gtm.js'
import { useSelector } from 'react-redux'
import { Follow as FollowIcon } from 'react-components/atoms/Icons'
import { api, hasPerm, SEGUI } from 'utils'
import { ELEMENTOSEGUITO } from 'utils/endpoint'

api.defaults.xsrfCookieName = 'csrftoken'
api.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const Follow = props => {
  const { item = null, variant = 'round', labelGTM = '' } = props

  const { utente } = useSelector(state => state.utente)

  const { paginaCorrente } = useSelector(state => state.paginaCorrente)

  const t = useTrans()

  const [followed, setFollowed] = useState(false)

  useEffect(() => {
    if (hasPerm(utente, SEGUI)) checkFollowed()
  }, [])

  const params = { content_type_id: item.model, object_id: item.id }

  const checkFollowed = async () => {
    api.get(ELEMENTOSEGUITO, { params }).then(response => {
      const followed = response.data['seguito']
      setFollowed(followed)
    })
  }

  const handleFollow = async () => {
    api.post(ELEMENTOSEGUITO, { params }).then(response => {
      setFollowed(response.data['created'])

      sendGTMData({
        event: 'GAevent',
        eventID: '16',
        eventCategory: 'cta_banner',
        eventAction: 'segui',
        eventLabel: labelGTM,
        moreInfo: paginaCorrente?.item?.titolo.toGTMFormat() || '',
      })
    })
  }

  const handleUnFollow = async () => {
    api.delete(ELEMENTOSEGUITO, { data: params }).then(response => {
      setFollowed(!response.data.success)
    })
  }

  function handleSwitchFollow() {
    if (followed) handleUnFollow()
    else handleFollow()
  }

  if (!item || !hasPerm(utente, SEGUI)) return null

  const label = followed ? t('Smetti di seguire') : t('Segui')

  return (
    <div className={`follow follow--${variant} ${followed ? 'follow--followed' : ''}`}>
      <ReactTooltip label={label} id="follow">
        <Button className="follow__button" onClick={handleSwitchFollow} icon={<FollowIcon />} />
      </ReactTooltip>
    </div>
  )
}

export default Follow
