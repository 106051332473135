import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Plus({ className }) {
  return (
    <IconBase name="plus" w={12} className={className}>
      <line
        x1="1"
        y1="6"
        x2="11"
        y2="6"
        className="strokeme"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
      <line
        x1="6"
        y1="1"
        x2="6"
        y2="11"
        className="strokeme"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </IconBase>
  )
}

export default Plus
