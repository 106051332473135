import orderBy from 'lodash/orderBy'
import { useEffect, useState } from 'react'
import {
  WidgetCard,
  WidgetImageText,
  WidgetLink,
  WidgetSlider,
  WidgetAgente,
  WidgetIopiu,
} from 'react-components/molecules'
import BachecaTemplate from 'react-components/templates/BachecaTemplate/BachecaTemplate'
import { api } from 'utils'
import { WIDGET } from 'utils/endpoint'
import usePermissions from '../../hooks/usePermissions'

export const widgetMapping = {
  image_text: <WidgetImageText />,
  iopiu: <WidgetIopiu />,
  link: <WidgetLink />,
  card: <WidgetCard />,
  slider: <WidgetSlider />,
  agente: <WidgetAgente />,
}

export const DallaRedazioneWidget = {
  description: '',
  id: 999,
  type: 'card',
  size: 2,
  selected: true,
  title: 'Suggeriti dalla redazione',
  image: null,
  link: '/area-personale/dalla-redazione',
}

export const StaticWidget = {
  ...DallaRedazioneWidget,
  id: 998,
  widget_options: {
    ...DallaRedazioneWidget,
    title: 'Banner commerciale',
    type: 'slider',
    id: 998,
    link: null,
  },
  widget: widgetMapping['slider'],
  size: 2,
  link: null,
}

const BachecaPage = () => {
  const [widgets, setWidgets] = useState([])
  const { filterWidgetByPermissions } = usePermissions()

  useEffect(() => {
    fetchWidgets()
  }, [])

  async function fetchWidgets() {
    const response = await api.get(WIDGET, { params: { update: true } })
    const { data } = response

    const widgetArray = filterWidgetByPermissions(data).map((w) => ({
      ...w,
      widget_options: w,
      widget: widgetMapping[w.type],
    }))

    const sortedWidget = orderBy(widgetArray, ['selected'], ['desc'])

    const sizes = sortedWidget.filter((w) => w.selected).map((w) => w.size)

    const sum = sizes.reduce((a, b) => a + b, 0)

    let pos = 3

    const hasAgente = sizes.slice(0, pos).includes(2)

    if (sum <= 3) pos = sum
    else if (hasAgente) pos = 2

    // ? Inserisco Widget Slider Fisso nella Seconda Riga
    sortedWidget.splice(pos, 0, StaticWidget)

    // ? Setto il Widget che si deve trovare sempre nell'ultima posizione
    setWidgets([
      ...sortedWidget,
      {
        ...DallaRedazioneWidget,
        widget_options: DallaRedazioneWidget,
        widget: widgetMapping[DallaRedazioneWidget?.type],
      },
    ])
  }

  return <BachecaTemplate widgets={widgets.filter((w) => w.selected)} fetchWidgets={fetchWidgets} />
}

export const getServerSideProps = async () => {
  return { props: {} }
}

BachecaPage.loginRequired = true

export default BachecaPage
