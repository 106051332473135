import React from 'react'
import { List, Module } from 'react-components/atoms/Icons'

const ListToggle = props => {
  const { listMode = false, setListMode = null, className = '' } = props

  return (
    <button
      type="button"
      className={`list-toggle${listMode ? ' list-toggle--list' : ' list-toggle--module'}${
        className ? ` ${className}` : ''
      }`}
      onClick={() => setListMode(!listMode)}
    >
      <span className={`list-toggle__icon${!listMode ? ' list-toggle__icon--selected' : ''}`}>
        <Module />
      </span>
      <span className={`list-toggle__icon${listMode ? ' list-toggle__icon--selected' : ''}`}>
        <List />
      </span>
    </button>
  )
}

export default ListToggle
