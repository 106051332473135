import React, { useContext, useState, useEffect } from 'react'
import { Image } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { CAS_DOMAIN, api, isRinnovabile } from 'utils'
import { CartContext } from 'utils/context'
import { RIVISTE } from 'utils/endpoint'
import { Button, Badge } from 'react-components/atoms'
import Link from 'next/link'
import { ArrowRightLong } from 'react-components/atoms/Icons'
import { useSelector } from 'react-redux'

const RivistaCard = (props) => {
  const {
    rivista = null,
    numero = null,
    free = false,
    digitale = false,
    variant = 'abbonato', // 'no-abbonato'
    className = ' ',
    ioPiu = false,
  } = props

  const t = useTrans()

  const { utente } = useSelector((state) => state.utente)

  const context = useContext(CartContext)

  const [subscription, setSubscription] = useState(null)

  useEffect(() => {
    if (rivista) initSubscription()
  }, [rivista])

  async function initSubscription() {
    const { data } = await api.get(`${RIVISTE}subscriptions/${rivista.id}/`)
    setSubscription(data?.subscription)
  }

  const rinnovabile = isRinnovabile(subscription)

  return (
    <div
      className={`rivista-card ${variant ? ` rivista-card--${variant}` : ' rivista-card--basic'}${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="rivista-card__container">
        <div className="rivista-card__image-box">
          <Image
            src={CAS_DOMAIN + (numero?.main_image || rivista?.main_image)}
            width={90}
            height={121}
            alt={numero?.titolo?.toAltTitle()}
            title={numero?.titolo?.toAltTitle()}
            //layout="responsive"
          />
        </div>

        <div className="rivista-card__content">
          <div>
            <div className="rivista-card__badges">
              {free && <Badge label={t('Rivista online gratuita')} className="blue" />}
              {digitale && <Badge label={t('Digitale')} className="grey" />}
            </div>
            <p className="rivista-card__title">{ioPiu ? rivista.sottotitolo : numero?.titolo}</p>
            {ioPiu && subscription && (
              <p className="rivista-card__durata">
                Dal {subscription.inizio} al {subscription.scadenza}
              </p>
            )}
            {ioPiu && rinnovabile && (
              <Button
                className="rivista-card__rinnova"
                variant="ghost"
                colorVariant="orange"
                label={t('Rinnova')}
                icon={<ArrowRightLong />}
                iconPos="right"
                onClick={() => context.addAbbonamentoToCart(rivista.id)}
              />
            )}
          </div>

          <div className="rivista-card__actions">
            {ioPiu ? (
              !rinnovabile && (
                <Button
                  variant="secondary"
                  label={t('Scopri di più')}
                  className="rivista-card__action rivista-card__action--nav"
                  href={rivista.url}
                />
              )
            ) : (
              <>
                {free ? (
                  <Button
                    variant="secondary"
                    label={t('Scopri la rivista')}
                    className="rivista-card__action rivista-card__action--nav"
                    href={numero?.rivista.url}
                  />
                ) : (
                  <Button
                    variant="secondary"
                    label={t("Vedi l'ultimo numero")}
                    className="rivista-card__action rivista-card__action--nav"
                    href={numero?.url}
                  />
                )}
                {rivista && (
                  <div className="rivista-card__actions__abbonati">
                    <Link legacyBehavior href={`${rivista.url}archivio`}>
                      <a className="rivista-card__cta">{t('Tutti i numeri')}</a>
                    </Link>
                  </div>
                )}
                {rinnovabile ? (
                  <Button
                    variant="ghost"
                    colorVariant="orange"
                    label={t('Rinnova')}
                    icon={<ArrowRightLong />}
                    iconPos="right"
                    onClick={() => context.addAbbonamentoToCart(numero?.rivista?.id || rivista.id)}
                  />
                ) : (
                  !utente.is_member &&
                  !free && (
                    <div className="rivista-card__actions__no-abbonati">
                      <Button
                        variant="ghost"
                        colorVariant="orange"
                        label={t('Abbonati ora')}
                        icon={<ArrowRightLong />}
                        iconPos="right"
                        className="rivista-card__action rivista-card__action--all"
                        onClick={() => context.addAbbonamentoToCart(numero.rivista.id)}
                      />
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default RivistaCard
