import React, { useContext } from 'react'
import { Cart } from 'react-components/atoms/Icons'
import { CartContext } from 'utils/context'

const HeaderCart = props => {
  const { className = '', href = '' } = props

  const { cart, openCart } = useContext(CartContext)

  return href ? (
    <a className={`header-cart ${className ? ` ${className}` : ''}`} href={href}>
      <Cart />
      {!!cart?.numProdotti && <span className="header-cart__badge">{cart?.numProdotti}</span>}
    </a>
  ) : (
    <span className={`header-cart ${className ? ` ${className}` : ''}`} onClick={openCart}>
      <Cart />
      {!!cart?.numProdotti && <span className="header-cart__badge">{cart?.numProdotti}</span>}
    </span>
  )
}

export default HeaderCart
