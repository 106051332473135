import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function CatOggetto({ className }) {
  return (
    <IconBase name="cat-oggetto" w={30} className={className}>
      <path
        //  fill="#2A2A2A"
        d="M12.3,0.8l-6.1,9.8c-0.7,1.1,0.1,2.5,1.4,2.5h12.2c1.3,0,2.1-1.4,1.4-2.5l-6.1-9.8
	C14.4-0.3,12.9-0.3,12.3,0.8z"
      />
      <circle
        //  fill="#2A2A2A"
        cx="22.6"
        cy="22.6"
        r="7.1"
      />
      <path
        //  fill="#2A2A2A"
        d="M1.6,29.7h9.8c0.9,0,1.6-0.7,1.6-1.6v-9.8c0-0.9-0.7-1.6-1.6-1.6H1.6c-0.9,0-1.6,0.7-1.6,1.6v9.8
	C0,29,0.7,29.7,1.6,29.7z"
      />
    </IconBase>
  )
}

export default CatOggetto
