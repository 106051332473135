import React from 'react'
import { WImage } from 'react-components/atoms'

const LandingRivisteBox = props => {
  const {
    item = undefined,
    color = 'orange-dark', // 'orange-dark' | 'inherit' | 'white' | 'orange'
    className = '',
  } = props

  return (
    <div
      className={`landing-riviste-box ${
        item.image ? 'landing-riviste-box--image' : ''
      } ${`landing-riviste-box--${color}`} ${className ? ` ${className}` : ''}`}
    >
      {item.image ? (
        <WImage src={item.image} maxWidth={463} maxHeight={342} />
      ) : (
        <>
          {!!item.name && <p className="landing-riviste-box__title">{item.name}</p>}
          {!!item.description && (
            <div
              className="landing-riviste-box__description"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
        </>
      )}
    </div>
  )
}

export default LandingRivisteBox
