import React from 'react'
import { useTrans } from 'hooks'
import { formatPrice } from 'utils'
import { ArrowRightLong } from 'react-components/atoms/Icons'
import { ImageBox, Button } from 'react-components/atoms'
import { useIsMobile } from 'hooks/useIsMobile'

const AreaPersonaleOrdineCard = props => {
  const { ordine = undefined, advanced = false, className = '' } = props

  const isMobile = useIsMobile()
  const t = useTrans()

  const details = [
    { key: 'data_ordine', label: t('Data ordine'), value: ordine.data },
    { key: 'totale', label: t('Totale'), value: formatPrice(ordine.prezzoTotaleScontato) },
    { key: 'stato', label: t('Stato'), value: ordine.stato?.nome },
  ]

  const moreOrderLimit = isMobile ? 3 : 2

  return ordine ? (
    <div
      className={`area-personale-ordine-card ${
        advanced ? 'area-personale-ordine-card--advanced' : ''
      } ${className ? ` ${className}` : ''}`}
    >
      <p className="area-personale-ordine-card__title">{t('Ordine #{0}', [ordine.pk])}</p>
      <div className="area-personale-ordine-card__main">
        <div className="area-personale-ordine-card__content">
          <div className="area-personale-ordine-card__details">
            {details.map(x => (
              <div key={x.key} className="area-personale-ordine-card__detail">
                <span className="area-personale-ordine-card__detail-label">{x.label}</span>
                <span className="area-personale-ordine-card__detail-value">{x.value}</span>
              </div>
            ))}
          </div>
          {!!advanced && ordine.righeOrdine?.length > 0 && (
            <div className="area-personale-ordine-card__images">
              {ordine.righeOrdine
                .filter(x => x.prodotto?.mainImage)
                .slice(0, moreOrderLimit)
                .map(x => (
                  <ImageBox key={x.pk} src={x.prodotto.mainImage} maxWidth={70} maxHeight={88}>
                    {x.quantitaOrdinata > 1 && (
                      <span className="area-personale-ordine-card__images__quantity">
                        {x.quantitaOrdinata}
                      </span>
                    )}
                  </ImageBox>
                ))}
              {ordine.righeOrdine.filter(x => x.prodotto).length > moreOrderLimit && (
                <ImageBox src="/images/ecommerce/ordini_more.png" maxWidth={70} maxHeight={88}>
                  <span className="area-personale-ordine-card__images__more">
                    + altri {ordine.righeOrdine.filter(x => x.prodotto).length - moreOrderLimit}
                  </span>
                </ImageBox>
              )}
            </div>
          )}
        </div>
        <div className="area-personale-ordine-card__actions">
          {advanced && ordine.stato.chiave === 'spedito' && ordine.trackingUrl && (
            <Button
              variant="secondary"
              label={t('Traccia spedizione')}
              href={ordine.trackingUrl}
              className="area-personale-ordine-card__track"
              newPage
              external
            />
          )}
          <Button
            variant="ghost"
            label={t('Vedi dettagli')}
            icon={<ArrowRightLong />}
            iconPos="right"
            className="area-personale-ordine-card__cta"
            href={ordine.url}
          />
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default AreaPersonaleOrdineCard
