import React, { useEffect, useRef } from 'react'

const ScalapayBanner = (props) => {
  const { hidePrice = true, pdp = true, checkout = false } = props
  const scriptLoaded = useRef(false)

  useEffect(() => {
    if (!scriptLoaded.current) {
      const script1 = document.createElement('script')
      script1.src = 'https://cdn.scalapay.com/widget/v3/js/scalapay-widget.esm.js'
      script1.type = 'module'
      document.head.appendChild(script1)

      const script2 = document.createElement('script')
      script2.src = 'https://cdn.scalapay.com/widget/v3/js/scalapay-widget.js'
      script2.setAttribute('nomodule', '')
      document.head.appendChild(script2)

      scriptLoaded.current = true
    }
  }, [])

  const classeNames = `scalapay-banner ${pdp ? 'scalapay-banner--pdp' : ''} ${
    checkout ? 'scalapay-banner--checkout' : ''
  }`

  return (
    <div className={classeNames}>
      <scalapay-widget
        type={checkout ? 'checkout' : ''}
        show-title={'false'}
        frequency-number="30"
        number-of-installments="3"
        hide="false"
        hide-price={hidePrice}
        min="5"
        max="1500"
        amount-selectors='[".catalogo-page__price", ".riepilogo-ordine__totale"]'
        currency-position="before"
        currency-display="symbol"
        logo-size="100"
        theme="primary"
        locale="it"
      />
    </div>
  )
}

export default ScalapayBanner
