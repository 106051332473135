import React from 'react'
import { useTrans } from 'hooks'
import { Time, Calendar, Profilo } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'
import { GETISCRIZIONEMODULO, ISCRIZIONEMODULO, MODULOFORMAZIONE } from 'utils/endpoint'
import { api } from 'utils'
import Icon from 'components/atomic/Icon'
import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { useSelector } from 'react-redux'
import Login from 'components/atomic/Login'
import Link from 'next/link'
import { formatDate } from '../../../utils'

const Modal = dynamic(() => import('components/atomic/Modal'))

const FormazioneModuloCard = (props) => {
  const { item = undefined, className = '', latest = false, areaPersonale = false } = props

  const { utente } = useSelector((state) => state.utente)

  const [informazioniModulo, setInformazioniModulo] = useState([])

  const [login, setLogin] = useState(false)
  const [next, setNext] = useState('')
  const [comunicazione, setComunicazione] = useState(false)

  const t = useTrans()

  const handleGTM = (label) => {
    dataLayer.push({
      event: 'GAevent',
      eventID: areaPersonale ? '36' : '33',
      eventCategory: areaPersonale ? 'formazione_area_riservata' : 'formazione_moduli',
      eventAction: label.toGTMFormat(),
      eventLabel: item.titolo.toGTMFormat(),
      moreInfo: item.in_corso ? 'moduli_in_corso' : 'moduli_conclusi',
    })
  }

  const iscrizioneUtenteModulo = () => {
    handleGTM(t('Iscriviti'))
    if (utente) {
      let params = { idModulo: item.id }
      api.get(`${MODULOFORMAZIONE}${ISCRIZIONEMODULO}`, { params }).then((response) => {
        if (response.data['success']) {
          setInformazioniModulo({ ...informazioniModulo, iscritto: true })
        }
      })
    } else {
      handleComunicazione()
    }
  }

  useEffect(() => {
    if (!areaPersonale) {
      let params = { idModulo: item.id }
      api.get(`${MODULOFORMAZIONE}${GETISCRIZIONEMODULO}`, { params }).then((response) => {
        setInformazioniModulo(response.data)
      })
    }
  }, [])

  const handleGTMPopup = (label) => {
    dataLayer.push({
      event: 'GAevent',
      eventID: '25',
      eventCategory: 'registrazione_login_popup',
      eventAction: label.toGTMFormat(),
      eventLabel: 'non_valorizzata',
    })
  }

  const handleLogin = (login, nextPage = '') => {
    if (nextPage) setNext(nextPage)
    setComunicazione(false)
    setLogin(login)
  }

  const handleComunicazione = () => {
    setComunicazione(true)
  }

  const renderComunicazione = () => {
    return (
      <>
        <h3 className="title">{t('Possono iscriversi solo gli utenti registrati.')}</h3>
        <p className="disclaimer">
          {t(
            "Registrati gratuitamente e scopri tutti i vantaggi. In regalo per te 30 giorni di prova a IO+, con tanti contenuti in esclusiva e l'accesso alla community dei docenti Giunti Scuola."
          )}
        </p>
        <Link legacyBehavior href="/registrazione">
          <a
            className="button button--primary cta"
            type="button"
            onClick={() => {
              setComunicazione(false)
              handleGTMPopup('registrazione giuntiscuola')
            }}
          >
            Registrati ora
          </a>
        </Link>
        <div
          className="disclaimer"
          dangerouslySetInnerHTML={{
            __html: t('Registrati ora e accedi a tanti contenuti, <b>sempre aggiornati</b>'),
          }}
        />
        <p>
          Sei già registrato?{' '}
          <a
            className="login"
            onClick={() => {
              handleLogin(true)
              handleGTMPopup('login')
            }}
          >
            Accedi
          </a>
        </p>
      </>
    )
  }

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/')
    return new Date(`${year}/${month}/${day}`)
  }

  const getWebinarText = () => {
    const label = item.tipologia == 'online' ? 'Prossimo Webinar il' : 'Prossimo evento il'
    return item.in_corso
      ? item.prossimo_webinar_data
        ? parseDate(item.prossimo_webinar_data) > new Date()
          ? `${t(label)} <strong>${item.prossimo_webinar_data}</strong>`
          : `<strong>${item.prossimo_webinar_data}</strong>`
        : ''
      : `<strong>${item.prossimo_webinar_data}</strong>`
  }

  return item ? (
    <div
      className={`formazione-modulo-card ${
        item.iscrizioneAttiva ? ' formazione-modulo-card--iscrizione' : ''
      } ${item.in_corso ? '' : ' formazione-modulo-card--concluso'} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="formazione-modulo-card__head">
        {latest && <span className="formazione-modulo-card__tag">{t('Novità')}</span>}
        <p className="formazione-modulo-card__intro">{item.nome_corso}</p>
        <p className="formazione-modulo-card__title">{item.titolo}</p>
      </div>
      <div className="formazione-modulo-card__content">
        <div className="formazione-modulo-card__list">
          <div className="formazione-modulo-card__item">
            <span className="formazione-modulo-card__item-icon">
              <Time />
            </span>
            <span
              className="formazione-modulo-card__item-label"
              dangerouslySetInnerHTML={{
                __html:
                  item.ore_formazione && item.ore_formazione > 0
                    ? t(
                        `Vale <strong>${item.ore_formazione?.toString()} ore di formazione</strong>`
                      )
                    : t('Non rilascia attestato'),
              }}
            />
          </div>
          <div className="formazione-modulo-card__item">
            <span className="formazione-modulo-card__item-icon">
              <Calendar />
            </span>
            <span
              className="formazione-modulo-card__item-label"
              dangerouslySetInnerHTML={{
                __html: getWebinarText(),
              }}
            />
          </div>
          <div className="formazione-modulo-card__item">
            <span className="formazione-modulo-card__item-icon">
              <Profilo />
            </span>
            <span
              className="formazione-modulo-card__item-label"
              dangerouslySetInnerHTML={{
                __html: t('Relatore <strong>{0} {1}</strong>', [
                  item.docente?.nome,
                  item.docente?.cognome,
                ]),
              }}
            />
          </div>
        </div>
        {item.link_esterno ? (
          <div className="formazione-modulo-card__cta-box">
            <Button
              label={t('Scopri di più')}
              href={item.link_esterno}
              newPage
              external
              onClick={() => handleGTM(t('Scopri di piu'))}
            />
          </div>
        ) : (
          <>
            {!!item.slug && (
              <div className="formazione-modulo-card__cta-box">
                <Button
                  variant="secondary"
                  label={t('Leggi di più')}
                  href={item.url}
                  onClick={() => handleGTM(t('Leggi di piu'))}
                />
                {informazioniModulo['iscritto'] && !areaPersonale ? (
                  <Button
                    variant="checked"
                    label={
                      <div>
                        <Icon id="check3" classes="icon--check" />
                        {t('Sei iscritto')}
                      </div>
                    }
                  >
                    <Icon id="check3" classes="icon--check" />
                  </Button>
                ) : !areaPersonale && new Date(item.termine_iscrizione) > new Date() ? (
                  <Button label={t('Iscriviti')} onClick={iscrizioneUtenteModulo} />
                ) : (
                  <></>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {comunicazione && (
        <Modal
          isOpen={comunicazione}
          alert={false}
          close={() => setComunicazione(false)}
          classes="modal__content--wide"
        >
          <div className="modal__content__comunicazione">{renderComunicazione()}</div>
        </Modal>
      )}
      {login && <Login isOpen={login} next={next} setLogin={handleLogin} />}
    </div>
  ) : (
    <></>
  )
}

export default FormazioneModuloCard
