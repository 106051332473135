import React from 'react'
import { Button } from 'react-components/atoms'
import { useTrans } from 'hooks'

const RivistaCardWideAbbonatoProva = props => {
  const { className = '', giorni = null } = props
  const t = useTrans()

  return (
    giorni && (
      <div className={`rivista-card-wide-abbonato-prova ${className ? ` ${className}` : ''}`}>
        <div className="rivista-card-wide-abbonato-prova__container__content">
          <p className="rivista-card-wide-abbonato-prova__title">
            {t('Il digitale non ti basta?')}
          </p>
          <p className="rivista-card-wide-abbonato-prova__description">
            {t(
              'Approfitta del periodo di prova gratuito per scoprire gli oltre 35.000 contenuti per la scuola a tua disposizione, sfoglia le riviste Giunti Scuola in versione digitale e abbonati!'
            )}
          </p>
          <div className="rivista-card-wide-abbonato-prova__cta">
            <Button variant="primary" label={t('Abbonati subito')} href="/riviste" />
          </div>
        </div>
        <div className="rivista-card-wide-abbonato-prova__container__content-scadenza">
          <div className="rivista-card-wide-abbonato-prova__container__content-text">
            <p className="rivista-card-wide-abbonato-prova__scadenza">
              {t('Il tuo periodo di prova scade tra')}:
            </p>
            <div className="rivista-card-wide-abbonato-prova__giorni">
              <p className="rivista-card-wide-abbonato-prova__scadenza-text">
                {giorni} {t('giorni')}
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  )
}

export default RivistaCardWideAbbonatoProva
