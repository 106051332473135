import React from 'react'
import Link from 'next/link'
import { ChevronRight, IoPiu } from '../Icons'

const VoceMenuPrimoLivelloMobile = (props) => {
  const { item, className = '', onClick = () => {}, iopiu = false } = props

  const classes = `primo-mobile ${className} ${iopiu ? 'primo-mobile--iopiu' : ''}`

  return (
    <li className={classes} onClick={onClick}>
      {iopiu && <IoPiu />}
      {item.final_url ? (
        <Link legacyBehavior href={item.final_url}>
          <a target={item.blank ? '_blank' : null}>{item.name}</a>
        </Link>
      ) : (
        item.name
      )}
      {item.children.length ? <ChevronRight /> : null}
    </li>
  )
}

export default VoceMenuPrimoLivelloMobile
