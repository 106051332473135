import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function SelectDropdown({ className }) {
  return (
    <IconBase name="select-dropdown" w={13} h={8} className={className}>
      <path
        d="M1.06949 2.97877L5.55819 7.49047C6.2341 8.16984 7.32595 8.16984 8.00185 7.49047L12.4906 2.97877C13.5824 1.88133 12.8025 0 11.2601 0H2.28265C0.740199 0 -0.0223614 1.88133 1.06949 2.97877Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default SelectDropdown
