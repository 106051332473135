import React from 'react'
import { FormError } from 'react-components/atoms'
import {
  CheckboxUnmarked,
  CheckboxMarked,
  RadioUnmarked,
  RadioMarked,
} from 'react-components/atoms/Icons'

const FormCheckbox = props => {
  const {
    id = '',
    label = '',
    name = '',
    value = '',
    size = 'md',
    className = '',
    checked = false,
    radioStyle = false,
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
  } = props

  const classNames = `
    form-checkbox 
    form-checkbox--${size}
    ${checked ? ' form-checkbox--checked' : ''}
    ${readOnly ? ' form-checkbox--readonly' : ''}
    ${disabled ? ' form-checkbox--disabled' : ''}
    ${status ? ` form-checkbox--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className="form-checkbox__input-wrap">
        <input
          checked={checked}
          name={name}
          value={value}
          className="form-checkbox__input"
          type="checkbox"
          readOnly={readOnly}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={readOnly ? () => {} : onChange}
          id={id}
        />
        <span className="form-checkbox__styledinput">
          {radioStyle ? (
            checked ? (
              <RadioMarked />
            ) : (
              <RadioUnmarked />
            )
          ) : checked ? (
            <CheckboxMarked />
          ) : (
            <CheckboxUnmarked />
          )}
        </span>
        <span className="form-checkbox__label">{label}</span>
      </label>
      <FormError message={errorMessage} visible={status === 'error'} />
    </div>
  )
}

export default FormCheckbox
