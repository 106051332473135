import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Modal } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { Edit } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'

const AreaPersonaleEditCard = (props) => {
  const {
    title = '',
    description = '',
    editMode = false,
    setEditMode = null,
    children = null,
    className = '',
    onSubmit,
    onCancel,
    disabled,
  } = props

  const t = useTrans()
  const [modalAdottatarioOpen, setModalAdottatarioOpen] = useState(false)
  const { utente } = useSelector((state) => state?.utente)
  return (
    <div className={`area-personale-edit-card ${className ? ` ${className}` : ''}`}>
      {/* INIZIO POPUP LAVORI IN CORSO */}
      {utente.is_adottatario && (
        <Modal
          open={modalAdottatarioOpen}
          setOpen={() => setModalAdottatarioOpen(false)}
          closeButton
        >
          <h3
            className="modal__body__title"
            style={{ textAlign: 'center', marginBottom: '20px', fontSize: '30px' }}
          >
            {t('Lavori in corso...')}
          </h3>
          <div
            className="modal__body__text"
            style={{ textAlign: 'center', marginBottom: '20px' }}
            dangerouslySetInnerHTML={{
              __html: t(
                'Se vuoi aggiornare la scuola per attivare un corso, torna a settembre: ci stiamo preparando al back to school!'
              ),
            }}
          />
        </Modal>
      )}
      {/* FINE POPUP LAVORI IN CORSO */}
      <div className="area-personale-edit-card__head">
        <p className="area-personale-edit-card__title">{title}</p>
        {!editMode && (
          <Button
            label={t('Modifica')}
            labelMobile=""
            variant="ghost"
            icon={<Edit />}
            iconPos="right"
            onClick={
              () => (utente.is_adottatario ? setModalAdottatarioOpen(true) : setEditMode(true))
              // setEditMode(true)
            }
          />
        )}
      </div>
      {description && <div className="area-personale-edit-card__description">{description}</div>}
      <div className="area-personale-edit-card__body">{children}</div>
      {editMode && (
        <div className="area-personale-edit-card__actions">
          <Button
            disabled={disabled}
            label={t('Salva le modifiche')}
            onClick={() => onSubmit?.()}
          />
          <Button
            label={t('Annulla modifiche')}
            labelMobile={t('Annulla')}
            variant="ghost"
            onClick={() => {
              onCancel?.()
              setEditMode(false)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default AreaPersonaleEditCard
