import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Bin({ className }) {
  return (
    <IconBase name="bin" w={24} className={className}>
      <path
        d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default Bin
