import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Pin2({ className }) {
  return (
    <IconBase name="pin2" w={20} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.6668 8.49996C16.6668 4.34996 13.5002 1.66663 10.0002 1.66663C6.50016 1.66663 3.3335 4.34996 3.3335 8.49996C3.3335 11.15 5.37516 14.2666 9.44183 17.8583C9.7585 18.1333 10.2335 18.1333 10.5502 17.8583C14.6252 14.2666 16.6668 11.15 16.6668 8.49996ZM11.6668 8.33329C11.6668 9.24996 10.9168 9.99996 10.0002 9.99996C9.0835 9.99996 8.3335 9.24996 8.3335 8.33329C8.3335 7.41663 9.0835 6.66663 10.0002 6.66663C10.9168 6.66663 11.6668 7.41663 11.6668 8.33329Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default Pin2
