import React, { useEffect, useState } from 'react'
import { FormToggleAutori } from 'react-components/organisms/Forms'
import { useSelector } from 'react-redux'
import { api } from 'utils'
import { AUTORI } from 'utils/endpoint'
import { useTrans } from 'hooks'
import { Edit } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'
import { AutoreCard } from 'react-components/molecules'

const TabAutori = () => {
  const [autori, setAutori] = useState([])
  const [edit, setEdit] = useState(false)
  const t = useTrans()

  const { utente } = useSelector(state => state.utente)

  useEffect(() => {
    fetchAutori()
  }, [])

  async function fetchAutori() {
    const response = await api.get(AUTORI, {
      params: { elementi_seguiti__utente_id: utente?.id },
    })
    const { data } = response
    setAutori(data)
  }

  return (
    <div className="tab-autori">
      {edit ? (
        <FormToggleAutori
          setEdit={setEdit}
          selectedFromProps={autori}
          setAutoriFromProps={setAutori}
        />
      ) : (
        <>
          <div className="tab-autori__actions">
            <Button
              label={t('Aggiungi o elimina')}
              variant="ghost"
              onClick={() => setEdit(true)}
              icon={<Edit />}
              iconPos="right"
            />
          </div>
          <div className="tab-autori__list">
            {autori?.map(item => (
              <div className="tab-autori__item">
                <AutoreCard autore={item} />
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default TabAutori
