import React from 'react'
import { WContainer, WImage, Button } from 'react-components/atoms'

const LandingRivisteCta = props => {
  const { item = undefined, className = '' } = props

  return item ? (
    <div
      className={`landing-riviste-cta u-landing-riviste-bg-default ${
        className ? ` ${className}` : ''
      }`}
    >
      <WContainer>
        <div className="landing-riviste-cta__layout">
          <div className="landing-riviste-cta__content">
            {!!item.name && <p className="landing-riviste-cta__title">{item.name}</p>}
            {!!item.description && (
              <div
                className="landing-riviste-cta__description"
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}
            <div className="landing-riviste-cta__image-box">
              <WImage src={item.image} maxWidth={332} maxHeight={412} alt="" title="" />
            </div>
            {item.children?.length > 0 && (
              <div className="landing-riviste-cta__cta-box">
                {item.children.map((x, i) => (
                  <Button
                    label={x.name?.toUpperCase()}
                    href={x.url}
                    colorVariant={i > 0 ? 'black' : ''}
                    inverted={i > 0}
                    key={x.pk}
                    external={x.external}
                    newPage={x.newPage}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default LandingRivisteCta
