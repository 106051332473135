import React from 'react'

const AreaPersonaleBreadcrumbsItem = props => {
  const { label = '', url = '', last = false } = props

  return (
    <div
      className={`area-personale-breadcrumbs-item${
        !last && url ? ' area-personale-breadcrumbs-item--link' : ''
      }`}
    >
      {last || !url ? (
        <span className="breadcrumbs-item__label">{label}</span>
      ) : (
        <a
          className="area-personale-breadcrumbs-item__label area-personale-breadcrumbs-item__label--link"
          href={url}
        >
          {label}
        </a>
      )}
    </div>
  )
}

const AreaPersonaleBreadcrumbs = props => {
  const { breadcrumbs = [], className = '' } = props

  return (
    <section className={`area-personale-breadcrumbs ${className ? ` ${className}` : ''}`}>
      {breadcrumbs.map((item, index) => (
        <AreaPersonaleBreadcrumbsItem label={item.label} url={item.url} key={index} />
      ))}
    </section>
  )
}

AreaPersonaleBreadcrumbs.Item = AreaPersonaleBreadcrumbsItem

export default AreaPersonaleBreadcrumbs
