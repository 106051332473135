import React from 'react'

const Video = () => (
  <svg viewBox="0 0 72 74" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M36 1.47095C16.128 1.47095 0 17.5989 0 37.4709C0 57.3429 16.128 73.4709 36 73.4709C55.872 73.4709 72 57.3429 72 37.4709C72 17.5989 55.872 1.47095 36 1.47095ZM31.68 51.5109L48.492 38.9109C49.464 38.1909 49.464 36.7509 48.492 36.0309L31.68 23.4309C30.492 22.5309 28.8 23.3949 28.8 24.8709V50.0709C28.8 51.5469 30.492 52.4109 31.68 51.5109ZM7.2 37.4709C7.2 53.3469 20.124 66.2709 36 66.2709C51.876 66.2709 64.8 53.3469 64.8 37.4709C64.8 21.5949 51.876 8.67095 36 8.67095C20.124 8.67095 7.2 21.5949 7.2 37.4709Z"
      stroke="none"
    />
  </svg>
)

export default Video
