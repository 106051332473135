import React from 'react'
import Icon from 'components/atomic/Icon'

const Tassonomia = props => {
  const { label = '', materia = '', classe = '', iconName = '', className = '' } = props

  return (
    <div className={`tassonomia ${className ? ` ${className}` : ''}`}>
      {iconName && <Icon id={iconName} classes="tassonomia__icon" />}
      {label && <span className="tassonomia__label">{label}</span>}
      {materia && <span className="tassonomia__item">{` | ${materia}`}</span>}
      {classe && <span className="tassonomia__item">{` | Classe ${classe}`}</span>}
    </div>
  )
}

export default Tassonomia
