import React from 'react'
import { useTrans } from 'hooks'

import { CalendarioAvventoCellaStep1 } from 'react-components/molecules'

const CalendarioAvventoStep1 = props => {
  const { celle = [], setStep, cellaCliccata, setCellaCliccata } = props

  const t = useTrans()

  return (
    <div>
      <div className="calendario-avvento-text-step1">
        <p className="calendario-avvento-title-step1">{t("Calendario dell'Avvento 2021")}</p>
        <div className="calendario-avvento-description-step1">
          <p>{t('Ogni giorno, un regalo!')}</p>
          <p>
            {t(
              'Un sacco di doni ti aspettano con il Calendario dell’Avvento Giunti Scuola. Scoprili assieme a noi giorno per giorno!'
            )}
          </p>
        </div>
      </div>
      <div className="calendario-avvento-list-step1">
        {celle.length ? (
          celle.map(cella => (
            <CalendarioAvventoCellaStep1
              cella={cella}
              setStep={setStep}
              cellaCliccata={cellaCliccata}
              setCellaCliccata={setCellaCliccata}
              key={cella.id}
            />
          ))
        ) : (
          <div></div>
        )}
      </div>
    </div>
  )
}

export default CalendarioAvventoStep1
