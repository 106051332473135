import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function RadioMarked({ className }) {
  return (
    <IconBase name="radio-marked" w={24} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 12C3 7.032 7.032 3 12 3C16.968 3 21 7.032 21 12C21 16.968 16.968 21 12 21C7.032 21 3 16.968 3 12ZM4.8 12C4.8 15.978 8.022 19.2 12 19.2C15.978 19.2 19.2 15.978 19.2 12C19.2 8.022 15.978 4.8 12 4.8C8.022 4.8 4.8 8.022 4.8 12Z"
        stroke="none"
      />
      <circle cx="12" cy="12" r="4.5" stroke="none" />
    </IconBase>
  )
}

export default RadioMarked
