import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Close({ className }) {
  return (
    <IconBase name="close" w={26} className={className}>
      <path
        d="M20 7.41L18.59 6L13 11.59L7.41 6L6 7.41L11.59 13L6 18.59L7.41 20L13 14.41L18.59 20L20 18.59L14.41 13L20 7.41Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default Close
