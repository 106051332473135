import { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { AUTORI, ELEMENTOSEGUITO } from 'utils/endpoint'
import { api } from 'utils'
import { Button, SelectionButton } from 'react-components/atoms'
import { AutoreCard } from 'react-components/molecules'

import Link from 'next/link'
import { AddCircle } from 'react-components/atoms/Icons'

const FormToggleAutori = ({ setAutoriFromProps, afterSubmit, setEdit, selectedFromProps }) => {
  const [autori, setAutori] = useState([])
  const [selected, setSelected] = useState([...selectedFromProps?.map((i) => i?.id)])
  const t = useTrans()

  useEffect(() => {
    // Fetch Autori
    api.get(AUTORI, { params: { redazionale: true, limit: 9 } }).then((res) => setAutori(res?.data))
    return () => {
      onClose()
    }
  }, [])

  const handleSelected = (id) => {
    selected?.includes(id)
      ? setSelected((prev) => prev?.filter((item) => item !== id))
      : setSelected((prev) => [...prev, id])
  }

  const onClose = () => {
    setSelected([])
    afterSubmit?.()
  }

  const _handleSubmit = () => {
    api.post(`${ELEMENTOSEGUITO}bulk_create/`, {
      content_type_id: autori[0].model,
      object_ids: selected,
    })
    setAutoriFromProps?.([...autori?.filter((i) => selected?.includes(i?.id))])
    setEdit(false)
    onClose()
  }

  return (
    <div className="form-toggle-autori">
      <div className="form-toggle-autori__list">
        {/* //TODO Sostituire con Componente per Autori e Temi */}

        {autori?.map((item) => (
          <div className="form-toggle-autori__item">
            <SelectionButton
              visible
              selected={selected?.includes(item?.id)}
              setSelected={() => handleSelected(item?.id)}
            />
            <AutoreCard autore={item} noUrl />
          </div>
        ))}
        <div className="form-toggle-autori__item form-toggle-autori__item--more">
          <Link legacyBehavior href="/autori">
            <a className="form-toggle-autori__item__link">
              <AddCircle />
              <p>{t('Segui altri autori')}</p>
            </a>
          </Link>
        </div>
      </div>
      <div className="form-toggle-autori__actions">
        <Button label={t('Salva le modifiche')} onClick={() => _handleSubmit()} />
        <Button label={t('Annulla modifiche')} variant="ghost" onClick={() => setEdit(false)} />
      </div>
    </div>
  )
}

export default FormToggleAutori
