import React from 'react'
import { IoPiu } from 'react-components/atoms/Icons'

const WidgetIopiu = props => {
  const { title, description, className = '' } = props

  return (
    <div className={`widget-iopiu ${className ? ` ${className}` : ''}`}>
      <div className="widget-iopiu__head">
        {title && <p className="widget-iopiu__title">{title}</p>}
        <span className="widget-iopiu__icon">
          <IoPiu />
        </span>
      </div>
      {description && <p className="widget-iopiu__description">{description}</p>}
    </div>
  )
}

export default WidgetIopiu
