import React from 'react'
import SlickSlider from 'react-slick'
import { Image } from 'react-components/atoms'
import { CAS_DOMAIN } from 'utils'
import { useIsTablet } from 'hooks/useIsMobile'

const ThumbedSlider = props => {
  const { immagini = '', preload = false, className = '', altTitle = '' } = props

  if (!immagini.length) return null

  const isTablet = useIsTablet()

  const sliderSettings = {
    customPaging: function(i) {
      return (
        <a>
          <Image
            src={CAS_DOMAIN + immagini[i]}
            alt={altTitle}
            title={altTitle}
            width={38}
            height={48}
          />
        </a>
      )
    },
    dots: !isTablet,
    arrows: isTablet,
    dotsClass: 'slick-dots thumbed-slider__thumbs',
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <SlickSlider className={`thumbed-slider ${className}`} {...sliderSettings}>
      {immagini.map((src, index) => (
        <Image
          src={CAS_DOMAIN + src}
          alt={altTitle}
          title={altTitle}
          width={285}
          height={360}
          priority={preload && index === 0}
          key={src}
        />
      ))}
    </SlickSlider>
  )
}

export default ThumbedSlider
