import React from 'react'
import { AddCircle } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'

const AggiungiContenutoCard = props => {
  const { icon = null, ctaLabel = '', ctaLink = '', className = '' } = props

  return (
    <div className={`aggiungi-contenuto-card ${className ? ` ${className}` : ''}`}>
      {icon && <div className="aggiungi-contenuto-card__icon">{icon}</div>}
      {ctaLabel && ctaLink && (
        <Button
          label={ctaLabel}
          href={ctaLink}
          variant="ghost"
          colorVariant="darkblue"
          icon={<AddCircle />}
          iconPos="left"
        />
      )}
    </div>
  )
}

export default AggiungiContenutoCard
