import React, { useContext } from 'react'
import { useTrans } from 'hooks'
import { formatPrice, getRivistaDate, PRODOTTO_ABBONAMENTO } from 'utils'
import { Label, QuantityHandler, WImage, FormInput, FormSelect } from 'react-components/atoms'
import { useState } from 'react'
import { useEffect } from 'react'
import { useFindIssuesLazyQuery, useUpdateIssueMutation } from 'gql/graphql'
import GaranziaTile from 'react-components/molecules/GaranziaTile/GaranziaTile'
import { CartContext } from 'utils/context'

const ProdottoCartCard = (props) => {
  const {
    mainImage = null,
    tipologia = '',
    titolo = '',
    prezzo = null,
    prezzoScontato = null,
    quantita = undefined,
    showQuantita = false,
    changeQuantita = true,
    onQuantitaChange = undefined,
    onRemove = undefined,
    removable = false,
    labelText = null,
    boxed = false,
    bordered = false,
    layout = '', // "" | "wide"
    inCart = false,
    isIoPiu = false,
    riga = null,
    rinnovo = false,
    className = '',
    is_prevendita = false,
    disclaimerPrevendita = '',
    garanzia = undefined,
    garanziaApplicata = false,
    garanziaCheckboxDisabled = false,
    garanziaStyleInverted = false,
  } = props

  const t = useTrans()

  const showDates = inCart && !showQuantita && !rinnovo

  const domani = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
  const [dataInizio, setDataInizio] = useState(riga?.dataInizio || domani)
  const [issue, setIssue] = useState(null)
  const context = useContext(CartContext)
  const [findIssues, { loading, error, data: findIssuesData }] = useFindIssuesLazyQuery({
    fetchPolicy: 'cache-first',
  })

  const [updateIssue] = useUpdateIssueMutation()

  useEffect(() => {
    if (!isIoPiu && showDates) initIssues()
  }, [])

  useEffect(() => {
    if (dataInizio && showDates) updateRigaIssue()
  }, [dataInizio])

  useEffect(() => {
    if (issue && showDates) updateRigaIssue(true)
  }, [issue])

  useEffect(() => {
    if (findIssuesData?.findIssues?.length && !riga.dataInizio)
      setDataInizio(findIssuesData?.findIssues[0].nominalIssueDate)
  }, [findIssuesData?.findIssues?.length])

  async function initIssues() {
    await findIssues({ variables: { riga: riga.pk } })
  }

  async function updateRigaIssue(isIssue) {
    let data = issue

    if (!isIssue) {
      data = { cmIssue: '', nominalIssueDate: dataInizio }
    }

    await updateIssue({
      variables: { riga: riga.pk, issue: data },
    })
  }

  return (
    <div
      className={`prodotto-cart-card ${layout ? `prodotto-cart-card--${layout}` : ''} ${
        boxed ? 'prodotto-cart-card--boxed' : ''
      } ${bordered ? 'prodotto-cart-card--bordered' : ''} ${className ? ` ${className}` : ''}`}
    >
      <div className="prodotto-cart-card__main">
        <div className="prodotto-cart-card__image-box">
          <WImage
            src={mainImage}
            maxWidth={70}
            maxHeight={88}
            alt={titolo?.toAltTitle()}
            title={titolo?.toAltTitle()}
          />
        </div>
        <div className="prodotto-cart-card__content">
          {!!tipologia && <p className="prodotto-cart-card__intro">{tipologia}</p>}
          {!!titolo && <p className="prodotto-cart-card__title">{titolo}</p>}
          {!!(prezzo || prezzoScontato || labelText) && (
            <div className="prodotto-cart-card__price">
              {prezzo !== prezzoScontato && (
                <span className="prodotto-cart-card__full-price">{formatPrice(prezzo)}</span>
              )}
              {(prezzo || prezzoScontato) && (
                <span className="prodotto-cart-card__final-price">
                  {formatPrice(prezzoScontato)}
                </span>
              )}
              {labelText && <Label text={labelText} className="prodotto-cart-card__label" />}
              {riga?.percentageDiscount > 0 && (
                <span className="prodotto-cart-card__discount">
                  -<>{riga?.percentageDiscount}%</>
                </span>
              )}
            </div>
          )}
          {is_prevendita && disclaimerPrevendita && changeQuantita ? (
            <span className="prodotto-cart-card__quantity">{disclaimerPrevendita}</span>
          ) : (
            <></>
          )}
          {garanzia && !garanziaStyleInverted && (
            <div className="prodotto-cart-card__garanzia">
              <GaranziaTile
                nome={garanzia.nome}
                durata={garanzia.durata}
                descrizione={garanzia.descrizione}
                tooltip={garanzia.tooltip}
                prezzo={garanzia.prezzo}
                onChange={() => context.toggleWarranty(riga.pk, !garanziaApplicata)}
                checked={garanziaApplicata}
                disabled={garanziaCheckboxDisabled}
                inputName={riga.pk}
                styleInverted={garanziaStyleInverted}
              />
            </div>
          )}
        </div>
      </div>
      {showDates && (
        <div className="prodotto-cart-card__dates">
          <span className="prodotto-cart-card__dates__label">
            {isIoPiu ? t('Partirà in data') : t('La riceverai da')}
          </span>
          {isIoPiu ? (
            <FormInput
              className="prodotto-cart-card__dates__input"
              placeholder={t('Data di inizio')}
              name="dataInizio"
              type="date"
              value={dataInizio}
              onChange={(e) => setDataInizio(e.target.value)}
              min={domani}
              onKeyDown={(event) => {
                event.preventDefault()
              }}
            />
          ) : (
            <FormSelect
              className="prodotto-cart-card__dates__input"
              options={findIssuesData?.findIssues.map((i) => ({
                value: i.nominalIssueDate,
                label: i.descriptionPeriod,
                ...i,
              }))}
              placeholder={t('Data di inizio')}
              name="dataInizio"
              value={issue?.nominalIssueDate || dataInizio}
              onChange={(e) => {
                setIssue({
                  cmIssue: e.cmIssue,
                  nominalIssueDate: e.value,
                })
              }}
              size="sm"
            />
          )}
        </div>
      )}
      {!!(showQuantita || removable) && (
        <div
          className={`prodotto-cart-card__actions ${
            !showQuantita ? 'prodotto-cart-card__actions--no-quantity' : ''
          }`}
        >
          {showQuantita ? (
            changeQuantita ? (
              <QuantityHandler
                value={quantita}
                min={1}
                // max={/* TODO: Giacenza */}
                inputDisabled
                onChange={onQuantitaChange}
                className="prodotto-cart-card__quantity-handler"
              />
            ) : tipologia === PRODOTTO_ABBONAMENTO && dataInizio ? (
              !rinnovo && (
                <span className="prodotto-cart-card__quantity">
                  {isIoPiu ? t('Partirà in data') : t('La riceverai da')}{' '}
                  {isIoPiu ? new Date(dataInizio).toLocaleDateString() : getRivistaDate(dataInizio)}
                </span>
              )
            ) : is_prevendita && disclaimerPrevendita ? (
              <span className="prodotto-cart-card__quantity">{disclaimerPrevendita}</span>
            ) : (
              <span className="prodotto-cart-card__quantity">
                {t('Quantità:')} {quantita}
              </span>
            )
          ) : null}
          {removable && (
            <button className="prodotto-cart-card__remove" onClick={onRemove}>
              {t('Rimuovi')}
            </button>
          )}
        </div>
      )}
      {garanzia && garanziaStyleInverted && (
        <GaranziaTile
          nome={garanzia.nome}
          durata={garanzia.durata}
          descrizione={garanzia.descrizione}
          tooltip={garanzia.tooltip}
          prezzo={garanzia.prezzo}
          onChange={() => context.toggleWarranty(riga.pk, !garanziaApplicata)}
          checked={garanziaApplicata}
          disabled={garanziaCheckboxDisabled}
          inputName={riga.pk}
          styleInverted={garanziaStyleInverted}
        />
      )}
    </div>
  )
}

export default ProdottoCartCard
