import { TRADUZIONI } from '../utils/endpoint'
import { api } from '../utils'

// se client-side e non utente staff prendo da localStorage
export const fetchTraduzioni = (isCachable) => {
  let payload = null

  if (typeof window !== 'undefined' && isCachable) {
    if (localStorage?.getItem('traduzioni')) {
      payload = { data: localStorage.getItem('traduzioni') }
    }
  } else {
    payload = api.get(TRADUZIONI)
  }

  return {
    type: 'FETCH_TRADUZIONI',
    payload,
  }
}

export default function reducer(
  state = {
    traduzioni: [],
    fetching: false,
    fetched: false,
    error: null,
  },
  action
) {
  switch (action.type) {
    case 'FETCH_TRADUZIONI_PENDING': {
      return { ...state, fetching: true }
    }
    case 'FETCH_TRADUZIONI_REJECTED': {
      return { ...state, fetching: false, error: action.payload }
    }
    case 'FETCH_TRADUZIONI_FULFILLED': {
      const traduzioni = action.payload.data

      if (typeof window !== 'undefined') localStorage.setItem('traduzioni', action.payload.data)

      return {
        ...state,
        fetching: false,
        fetched: true,
        traduzioni,
      }
    }

    default: {
      return state
    }
  }
}
