import Link from 'next/link'
import { Close } from 'react-components/atoms/Icons'

function Evidenza({
  item,
  selected = false,
  onClick = () => {},
  className = '',
  disabled = false,
}) {
  const classes = `evidenza ${selected ? 'evidenza--selected' : ''} ${className}`

  if (item.url && !disabled)
    return (
      <Link legacyBehavior href={item.url} prefetch={false}>
        <a className={classes} title={item.nome} onClick={onClick}>
          {item.nome}
        </a>
      </Link>
    )
  else if (disabled) return <span className="evidenza evidenza--disabled">{item.nome}</span>
  else {
    return (
      <span className={classes} onClick={onClick}>
        {item.nome} {selected && <Close />}
      </span>
    )
  }
}

export default Evidenza
