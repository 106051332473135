import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Calendar({ className }) {
  return (
    <IconBase name="calendar" w={20} className={className}>
      <path
        d="M16.667 2.50001H15.8337V0.833344H14.167V2.50001H5.83366V0.833344H4.16699V2.50001H3.33366C2.41699 2.50001 1.66699 3.25001 1.66699 4.16668V17.5C1.66699 18.4167 2.41699 19.1667 3.33366 19.1667H16.667C17.5837 19.1667 18.3337 18.4167 18.3337 17.5V4.16668C18.3337 3.25001 17.5837 2.50001 16.667 2.50001ZM16.667 17.5H3.33366V6.66668H16.667V17.5Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default Calendar
