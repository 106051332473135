import React, { useContext } from 'react'
import { IoPiu } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'
import { Image } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { CartContext } from 'utils/context'
import { sendGTMData } from 'utils/gtm.js'

const RivistaCardWideNoAbbonato = props => {
  const { className = '', id = '', titolo = 'Il tuo abbonamento non è attivo' } = props

  const t = useTrans()
  const context = useContext(CartContext)

  const domain = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3000'
  const io = `${domain}/images/iopiu/io.png`

  return (
    <div id={id} className={`rivista-card-wide-noabbonato${className ? ` ${className}` : ''}`}>
      <div className="rivista-card-wide-noabbonato__container__content">
        <div className="rivista-card-wide-noabbonato__container__content-title">
          <div>
            <IoPiu />
          </div>
          <p className="rivista-card-wide-noabbonato__title">{t(titolo)}</p>
        </div>
        <p className="rivista-card-wide-noabbonato__description">
          {t(
            'Vuoi accedere a più di 35.000 contenuti per la tua vita scolastica e sfogliare le riviste digitali Giunti Scuola? Abbonati subito a io+.'
          )}
        </p>
        <div className="rivista-card-wide-noabbonato__cta">
          <Button
            variant="primary"
            label={t('Abbonati subito')}
            onClick={() => {
              context.addAbbonamentoToCart(8)
            }}
          />
          <Button
            variant="primary"
            colorVariant="orange"
            inverted={true}
            label={t('Scopri io+')}
            href="/io-piu"
          />
        </div>
      </div>
      <div className="rivista-card-wide-noabbonato__container__content-image">
        <Image
          src={io}
          width={340}
          height={236}
          alt="IO+"
          title="IO+"
          //layout="responsive"
        />
      </div>
    </div>
  )
}

export default RivistaCardWideNoAbbonato
