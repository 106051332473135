import { LEZIONE, MEMBER, SALVA, api, hasPerm, isDisabled } from 'utils'
import React, { useEffect, useMemo, useState } from 'react'

import { ELEMENTOSALVATO } from 'utils/endpoint'
import Ioplus from 'components/atomic/Ioplus'
import Link from 'next/link'

import { useSelector } from 'react-redux'
import { sendGTMData, sendProductClickGTM } from 'utils/gtm.js'
import Salva from 'components/atomic/Salva'
import Icon from 'components/atomic/Icon'

const LezioneCard = React.memo((props) => {
  const {
    articolo,
    classes = '',
    isEmbedded = false,
    onRemove = null,
    horizontal = false,
    size = '',
    variant = null,
    position = null,
    parentList = '',
  } = props

  const { utente } = useSelector((state) => state.utente)

  const [saved, setSaved] = useState(false)

  const thumb = '/images/card/lezione.jpg'

  const disabled = useMemo(() => isDisabled(articolo, utente), [articolo, utente])

  const params = { content_type_id: articolo.model, object_id: articolo.id || articolo.object_id }

  const handleGTM = (action) => {
    sendGTMData({
      event: 'GAevent',
      eventID: '15',
      eventCategory: 'interazione_contenuti',
      eventAction: action,
      eventLabel: 'lezione',
      moreInfo: articolo.titolo.toGTMFormat(),
    })
  }

  const handleSave = async () => {
    if (hasPerm(utente, SALVA)) {
      handleGTM('bookmark')
      api.post(ELEMENTOSALVATO, { params }).then(() => checkSaved())
    }
  }

  const handleUnsave = async () => {
    if (hasPerm(utente, SALVA)) {
      handleGTM('bookmark')
      api.delete(ELEMENTOSALVATO, { data: params }).then(() => checkSaved())
    }
  }

  const checkSaved = async () => {
    api.get(ELEMENTOSALVATO, { params }).then((response) => {
      setSaved(response.data['salvato'])
    })
  }

  useEffect(() => {
    setSaved(articolo.saved)
  }, [articolo.saved])

  useEffect(() => {
    if (isEmbedded && hasPerm(utente, SALVA)) checkSaved()
  }, [])

  const classi = `lezione-card ${classes} ${disabled ? 'lezione-card--disabled' : ''} ${
    horizontal ? 'lezione-card--horizontal' : ''
  } ${variant ? ` lezione-card--${variant}` : ''}${size ? ` lezione-card--${size}` : ''}`

  const classe = articolo.classe_lezione ? articolo.classe_lezione.nome : null

  return (
    <article className={classi}>
      {articolo.extra.membership == MEMBER && (
        <Ioplus tooltip="Contenuto riservato agli abbonati io+" horizontal={horizontal} />
      )}
      {hasPerm(utente, SALVA) ? (
        saved ? (
          <Salva onClick={handleUnsave} horizontal={horizontal} saved />
        ) : (
          <Salva onClick={handleSave} horizontal={horizontal} />
        )
      ) : null}
      {onRemove && <Icon id="remove" classes="icon--remove" onClick={onRemove} tooltip="ELIMINA" />}
      <Link legacyBehavior href={articolo.url}>
        <a
          className="lezione-card__image-box"
          style={thumb ? { backgroundImage: `url(${thumb})` } : {}}
          onClick={() => {
            handleGTM('click')
            sendProductClickGTM(
              articolo,
              parentList ? parentList : 'navigazione_standard',
              position
            )
          }}
        >
          {classe ? <p className="lezione-card__classe">LEZIONE Classe {classe}</p> : null}
        </a>
      </Link>
      <Link legacyBehavior href={articolo.url}>
        <a
          className="lezione-card__content"
          onClick={() => {
            handleGTM('click')
            sendProductClickGTM(
              articolo,
              parentList ? parentList : 'navigazione_standard',
              position
            )
          }}
        >
          <p className="lezione-card__title">{articolo.titolo}</p>
          <p className="lezione-card__abstract">{articolo.descrizione}</p>
        </a>
      </Link>
    </article>
  )
})

export default LezioneCard
