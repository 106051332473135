import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Share({ className }) {
  return (
    <IconBase name="share" w={24} className={className}>
      <path
        d="M18.6667 16.1463C17.8222 16.1463 17.0667 16.469 16.4889 16.9746L8.56667 12.5103C8.62222 12.2629 8.66667 12.0155 8.66667 11.7573C8.66667 11.4991 8.62222 11.2517 8.56667 11.0043L16.4 6.58305C17 7.12091 17.7889 7.45439 18.6667 7.45439C20.5111 7.45439 22 6.01291 22 4.22719C22 2.44148 20.5111 1 18.6667 1C16.8222 1 15.3333 2.44148 15.3333 4.22719C15.3333 4.48537 15.3778 4.73279 15.4333 4.98021L7.6 9.40146C7 8.8636 6.21111 8.53012 5.33333 8.53012C3.48889 8.53012 2 9.9716 2 11.7573C2 13.543 3.48889 14.9845 5.33333 14.9845C6.21111 14.9845 7 14.651 7.6 14.1132L15.5111 18.5882C15.4556 18.8141 15.4222 19.0508 15.4222 19.2874C15.4222 21.0194 16.8778 22.4286 18.6667 22.4286C20.4556 22.4286 21.9111 21.0194 21.9111 19.2874C21.9111 17.5555 20.4556 16.1463 18.6667 16.1463Z"
        //  fill="white"
      />
    </IconBase>
  )
}

export default Share
