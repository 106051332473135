import React, { useState, useRef } from 'react'
import { useTrans } from 'hooks'
import { ArrowDown2, ArrowUp2, ShoppingBag } from 'react-components/atoms/Icons'
import { ProdottoCartCard } from 'react-components/molecules'

import sumBy from 'lodash/sumBy'
import { isIoPiu } from 'utils/cart'

const ProdottiCartAccordion = (props) => {
  const { items = [], className = '' } = props

  const t = useTrans()
  const [open, setOpen] = useState(false)
  const innerContentRef = useRef(null)

  const numProdotti = sumBy(items, 'quantita')

  return items.length > 0 ? (
    <div
      className={`prodotti-cart-accordion ${open ? 'prodotti-cart-accordion--open' : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <button className="prodotti-cart-accordion__trigger" onClick={() => setOpen(!open)}>
        <ShoppingBag />
        <span>{t('I tuoi prodotti ({0})', [numProdotti])}</span>
        <span className="prodotti-cart-accordion__arrow">
          {open ? <ArrowUp2 /> : <ArrowDown2 />}
        </span>
      </button>
      <div
        className="prodotti-cart-accordion__content"
        style={{
          maxHeight: innerContentRef?.current && open ? innerContentRef.current.clientHeight : 0,
        }}
      >
        <div className="prodotti-cart-accordion__inner-content" ref={innerContentRef}>
          {items.map((x) => (
            <ProdottoCartCard
              key={x.prodotto.pk}
              tipologia={x.prodotto.tipologia}
              titolo={x.prodotto.titolo}
              mainImage={x.prodotto.mainImage}
              prezzo={x.total}
              prezzoScontato={x.totalDiscounted}
              labelText={x.omaggio ? t('Omaggio') : null}
              quantita={x.quantita}
              showQuantita
              changeQuantita={false}
              removable={false}
              boxed
              riga={x}
              isIoPiu={isIoPiu(x.prodotto)}
              rinnovo={x.prodotto.rinnovo}
              is_prevendita={x.prodotto.isPrevendita}
              disclaimerPrevendita={x.prodotto.disclaimerPrevendita}
              garanzia={x.prodotto.garanzia}
              garanziaApplicata={x.cartRow.garanzia}
              garanziaCheckboxDisabled={true}
              garanziaStyleInverted={true}
            />
          ))}
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default ProdottiCartAccordion
