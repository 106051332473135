import React from 'react'
import { ImageBox } from 'react-components/atoms'

const WidgetImageText = props => {
  const { image = '', title = '', description = '', className = '' } = props

  return (
    <div className={`widget-image-text ${className ? ` ${className}` : ''}`}>
      {image && (
        <ImageBox maxWidth={180} maxHeight={110} src={image} classPrefix="widget-image-text" />
      )}
      {title && <p className="widget-image-text__title">{title}</p>}
      {description && <p className="widget-image-text__description">{description}</p>}
    </div>
  )
}

export default WidgetImageText
