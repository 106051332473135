export const setPaginaCorrente = paginaCorrente => {
  return {
    type: 'SET_CURRENT_PAGE',
    paginaCorrente,
  }
}

export const resetPaginaCorrente = () => ({
  type: 'RESET_CURRENT_PAGE',
})

export default function paginaCorrente(
  state = {
    paginaCorrente: {},
  },
  action
) {
  switch (action.type) {
    case 'SET_CURRENT_PAGE': {
      return Object.assign({}, state, {
        paginaCorrente: action.paginaCorrente,
      })
    }

    case 'RESET_CURRENT_PAGE': {
      return Object.assign({}, state, {
        paginaCorrente: {},
      })
    }

    default:
      return state
  }
}
