import React from 'react'
import { useTrans } from 'hooks'
import { useIsMobile } from 'hooks/useIsMobile'
import { formatPrice } from 'utils'
import { FormError } from 'react-components/atoms'
import { RadioUnmarked, RadioMarked } from 'react-components/atoms/Icons'
import { FormCartaDocenteRadioOption } from 'react-components/atoms'
import ScalapayBanner from '../../organisms/ScalapayBanner/ScalapayBanner'

const FormMetodoPagamentoRadioOption = (props) => {
  const {
    id = '',
    name = '',
    metodoPagamento,
    checked = false,
    className = '',
    readOnly = false,
    disabled = false,
    onChange = () => {},
    showImage = true,
    hideButton = false,
  } = props

  const t = useTrans()
  const isMobile = useIsMobile()

  const classNames = `
    form-metodo-pagamento-radio-option 
    ${checked ? ' form-metodo-pagamento-radio-option--checked' : ''}
    ${readOnly ? ' form-metodo-pagamento-radio-option--readonly' : ''}
    ${disabled ? ' form-metodo-pagamento-radio-option--disabled' : ''}
    ${!!metodoPagamento.onRemove ? ' form-metodo-pagamento-radio-option--removable' : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <label htmlFor={id} className="form-metodo-pagamento-radio-option__input-wrap">
        <input
          id={id}
          checked={checked}
          name={name}
          value={metodoPagamento.pk}
          className="form-metodo-pagamento-radio-option__input"
          type="radio"
          readOnly={readOnly}
          disabled={disabled}
          onChange={onChange}
        />
        {(!hideButton || isMobile) && (
          <span className="form-metodo-pagamento-radio-option__styledinput">
            {checked ? <RadioMarked /> : <RadioUnmarked />}
          </span>
        )}
        <span className="form-metodo-pagamento-radio-option__label">
          <span
            className={`form-metodo-pagamento-radio-option__title ${
              hideButton && 'form-metodo-pagamento-radio-option__title--secondary'
            }`}
          >
            {metodoPagamento.nome}
          </span>
          {metodoPagamento.alias == 'scalapay' && (
            <span className="form-metodo-pagamento-radio-option__description_scalapay">
              <ScalapayBanner pdp={false} hidePrice={false} checkout={true} />
            </span>
          )}
          {!!metodoPagamento.descrizione && metodoPagamento.alias != 'scalapay' && (
            <span className="form-metodo-pagamento-radio-option__description">
              {metodoPagamento.descrizione}
            </span>
          )}
        </span>
        {!!metodoPagamento.importo && (
          <span className="form-metodo-pagamento-radio-option__importo">
            {formatPrice(metodoPagamento.importo)}
          </span>
        )}
        {!!metodoPagamento.onRemove && (
          <button
            type="button"
            className="form-metodo-pagamento-radio-option__remove"
            onClick={metodoPagamento.onRemove}
          >
            <span className="form-metodo-pagamento-radio-option__remove-label--desktop">
              {t('Rimuovi')}
            </span>
            <span className="form-metodo-pagamento-radio-option__remove-label--mobile">
              {t('Rimuovi buono')}
            </span>
          </button>
        )}
        {showImage && metodoPagamento.image && (
          <img
            className="form-metodo-pagamento-radio-option__image"
            src={metodoPagamento.image}
            alt={metodoPagamento.nome?.toAltTitle()}
            title={metodoPagamento.nome?.toAltTitle()}
          />
        )}
      </label>
    </div>
  )
}

const FormMetodoPagamentoRadio = (props) => {
  const {
    id = '',
    name = '',
    value = null,
    className = '',
    status = '',
    errorMessage = '',
    readOnly = false,
    disabled = false,
    onChange = () => {},
    metodiPagamento = [],
    codiceCartaDocente = '',
    erroreCartaDocente = '',
    setErroreCartaDocente = undefined,
    setCodiceCartaDocente = undefined,
    importoCartaDocente = 0,
    setImportoCartaDocente = undefined,
    importoInsufficiente = false,
    importoMancante = 0,
    applicaCodiceCartaDocente = undefined,
  } = props

  const t = useTrans()

  return (
    <div className={`form-metodo-pagamento-radio ${className ? ` ${className}` : ''}`}>
      <div className="form-metodo-pagamento-radio__options">
        {metodiPagamento?.map((metodoPagamento) =>
          metodoPagamento.alias === 'carta_docente' ? (
            <div key={`formmetodopagamentoradiooption-${id}-${metodoPagamento.pk}`}>
              <FormCartaDocenteRadioOption
                id={`${id}_${metodoPagamento.pk}`}
                name={name}
                metodoPagamento={metodoPagamento}
                checked={metodoPagamento.pk === value}
                onChange={onChange}
                readOnly={readOnly}
                disabled={disabled}
                codice={codiceCartaDocente}
                setCodice={setCodiceCartaDocente}
                importo={importoCartaDocente}
                setImporto={setImportoCartaDocente}
                importoInsufficiente={importoInsufficiente}
                applicaCodice={applicaCodiceCartaDocente}
                erroreCartaDocente={erroreCartaDocente}
                setErroreCartaDocente={setErroreCartaDocente}
              />
              {codiceCartaDocente && importoInsufficiente && importoMancante && (
                <div className="form-metodo-pagamento-radio__options__more">
                  <p>{t('I restanti {0}€ verranno pagati con', [importoMancante.toFixed(2)])}</p>
                </div>
              )}
            </div>
          ) : (
            <FormMetodoPagamentoRadioOption
              id={`${id}_${metodoPagamento.pk}`}
              name={name}
              metodoPagamento={metodoPagamento}
              checked={metodoPagamento.pk === value}
              onChange={onChange}
              readOnly={readOnly}
              disabled={disabled}
              key={`formmetodopagamentoradiooption-${id}-${metodoPagamento.pk}`}
            />
          )
        )}
      </div>
      <FormError
        className="form-metodo-pagamento-radio__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

FormMetodoPagamentoRadio.Option = FormMetodoPagamentoRadioOption

export default FormMetodoPagamentoRadio
