import React from 'react'
import { Check } from 'react-components/atoms/Icons'

const StepBarItem = props => {
  const { index = 1, step = '', status = 'future', onClick } = props

  return (
    <span className={`step-bar-item step-bar-item--${status}`} onClick={onClick}>
      <span className="step-bar-item__content">
        <span className="step-bar-item__dot">
          {status === 'past' ? <Check className="step-bar-item__dot-check" /> : index}
        </span>
        <span className="step-bar-item__label">{step}</span>
      </span>
    </span>
  )
}

const StepBar = props => {
  const { steps = [], activeStepIndex = 1, setActiveStepIndex = () => {}, className = '' } = props

  function getStatus(index) {
    return index + 1 === activeStepIndex ? 'active' : index < activeStepIndex ? 'past' : 'future'
  }

  return (
    <div className={`step-bar${className ? ` ${className}` : ''}`}>
      {steps?.length &&
        steps.map((step, index) => (
          <StepBarItem
            key={index}
            index={index + 1}
            step={step}
            status={getStatus(index)}
            onClick={() =>
              getStatus(index) === 'past' ? setActiveStepIndex(activeStepIndex - 1) : null
            }
          />
        ))}
    </div>
  )
}

StepBar.Item = StepBarItem

export default StepBar
