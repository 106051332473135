import React from 'react'
import { CorrelatiCardItem } from 'react-components/atoms'
import { getClasse, getMateria, getTemi, getTipoStrumento } from 'utils'

function getJoin(item) {
  switch (item.model_class) {
    case 'lezione':
      return ' | '
    case 'articolo':
      return ', '
    default:
      return ''
  }
}

function getTags(item) {
  switch (item.model_class) {
    case 'lezione':
      return [getMateria(item.tassonomia), getClasse(item.tassonomia)].filter(e => e)
    case 'articolo':
      return getTemi(item.tassonomia)
        .filter(e => e)
        .slice(0, 3)
    case 'strumento':
      return [getTipoStrumento(item)].filter(e => e)
    default:
      return []
  }
}

const CorrelatiCard = props => {
  const { title = '', icon = null, items = [], className = '' } = props

  if (!items.length) return null

  return (
    <article className={`correlati-card ${className ? ` ${className}` : ''}`}>
      <p className="correlati-card__title">
        {icon && icon}
        <span className="correlati-card__title__text">{title}</span>
      </p>
      <div className="correlati-card__content">
        {items.map((item, index) => (
          <CorrelatiCardItem item={item} tags={getTags(item)} join={getJoin(item)} key={index} />
        ))}
      </div>
    </article>
  )
}

export default CorrelatiCard
