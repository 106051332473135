import React from 'react'

const IoPiu = () => (
  <svg viewBox="0 0 71 55" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 10C2.64815 10 0 12.422 0 15.4495C0 18.6789 2.44444 20.8991 5.5 20.8991C8.55556 20.8991 11 18.4771 11 15.4495C11 12.422 8.55556 10 5.5 10ZM9.77778 29.3762C9.77778 27.156 7.94444 25.1377 5.5 25.1377C2.85185 25.1377 1.01852 26.9542 1.22222 29.3762V49.7616C1.22222 51.9817 3.25926 54.0001 5.5 54.0001C7.74074 54.0001 9.77778 52.1836 9.77778 49.7616V29.3762Z"
      stroke="none"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 25C19.9079 25 13 31.5132 13 40C13 48.2895 19.9079 55 28 55C36.0921 55 43 48.4868 43 40C43 31.5132 36.0921 25 28 25ZM28 33.0918C31.75 33.0918 34.3158 35.855 34.3158 39.9997C34.3158 43.9471 31.75 46.7102 28 46.9076C24.25 46.9076 21.6842 43.9471 21.6842 39.9997C21.6842 35.855 24.25 33.0918 28 33.0918Z"
      stroke="none"
    />
    <path
      d="M44.026 11.474H51.474V4.02597C51.474 1.81169 53.0844 0 55.5 0C57.7143 0 59.526 1.61039 59.526 4.02597V11.474H66.974C69.1883 11.474 71 13.0844 71 15.5C71 17.7143 69.3896 19.526 66.974 19.526H59.526V26.974C59.526 29.1883 57.9156 31 55.5 31C53.2857 31 51.474 29.3896 51.474 26.974V19.526H44.026C41.8117 19.526 40 17.9156 40 15.5C40.2013 13.487 42.013 11.474 44.026 11.474Z"
      stroke="none"
    />
  </svg>
)

export default IoPiu
