import React, { useEffect } from 'react'
import { Close } from 'react-components/atoms/Icons'

const ModalContent = props => {
  const { icon = null, title = '', description = '', children = null } = props

  return (
    <div className="wmodal__content">
      {(icon || title || description) && (
        <div className="wmodal__head">
          {icon && <p className="wmodal__icon">{icon}</p>}
          {title && <p className="wmodal__title">{title}</p>}
          {description && <p className="wmodal__description">{description}</p>}
        </div>
      )}
      {children}
    </div>
  )
}

const ModalButtons = props => {
  const { children = null } = props

  return <div className="wmodal__buttons">{children}</div>
}

const Modal = props => {
  const {
    open = false,
    setOpen = () => {},
    onClose = () => {},
    closeButton = false,
    className = '',
    children = null,
  } = props

  useEffect(() => {
    if (open) {
      document.body.classList.add('w-noscroll')
    } else {
      document.body.classList.remove('w-noscroll')
    }
  }, [open])

  const close = e => {
    e.stopPropagation()
    onClose()

    if (setOpen) {
      setOpen(false)
    }
  }

  return open ? (
    <>
      <div className="wmodal-overlay" />
      <div className={`wmodal ${className ? ` ${className}` : ''}`}>
        {closeButton && (
          <button type="button" className="wmodal__close" onClick={close}>
            <Close />
          </button>
        )}
        <div className="wmodal__body">{children}</div>
      </div>
    </>
  ) : (
    <></>
  )
}

Modal.Content = ModalContent
Modal.Buttons = ModalButtons

export default Modal
