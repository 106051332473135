import React from 'react'
import NextImage from 'next/legacy/image'
import { CAS_DOMAIN } from '../../../utils'

const myLoader = ({ src, width }) => {
  if (src?.includes('null')) return `https://via.placeholder.com/${width}`
  if (src?.includes('/media/filer') && !src?.includes(CAS_DOMAIN)) return `${CAS_DOMAIN}${src}`
  return src
}

const Image = (props) => {
  // const loaderProps = {
  //   ...props,
  //   loader: myLoader,
  // }

  const loaderProps = {
    ...props,
    src: myLoader(props),
  }

  return <NextImage {...loaderProps} />
}

export default Image
