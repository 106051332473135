import React from 'react'
import {
  LogoSymbolLandingRivisteBg,
  LandingRivisteLogoVs,
  LandingRivisteLogoSi,
  LandingRivisteLogoPs,
} from 'react-components/atoms/Icons'
import { WContainer, WImage } from 'react-components/atoms'

const LandingRivisteHero = props => {
  const {
    titolo = '',
    productCode = 'vs',
    assetImage = '',
    coverImage = '',
    className = '',
  } = props

  const logo =
    productCode === 'vs' ? (
      <LandingRivisteLogoVs />
    ) : productCode === 'si' ? (
      <LandingRivisteLogoSi />
    ) : productCode === 'ps' ? (
      <LandingRivisteLogoPs />
    ) : (
      undefined
    )

  return (
    <div
      className={`landing-riviste-hero landing-riviste-hero--${productCode} u-landing-riviste-bg-default ${
        className ? ` ${className}` : ''
      }`}
    >
      <span className={'landing-riviste-hero__logo-bg'}>
        <LogoSymbolLandingRivisteBg />
      </span>
      <WContainer>
        <div className={'landing-riviste-hero__layout'}>
          <div className={'landing-riviste-hero__content'}>
            {!!logo && <div className={'landing-riviste-hero__logo-box'}>{logo}</div>}
            {!!titolo && <p className="landing-riviste-hero__title">{titolo}</p>}
          </div>
          <div className={'landing-riviste-hero__images-box'}>
            <div className={'landing-riviste-hero__asset-image-box'}>
              <WImage
                src={assetImage}
                maxWidth={431}
                maxHeight={431}
                alt={titolo.toAltTitle()}
                title={titolo.toAltTitle()}
              />
            </div>
            <div className={'landing-riviste-hero__cover-image-box'}>
              <WImage
                src={coverImage}
                maxWidth={332}
                maxHeight={412}
                alt={titolo.toAltTitle()}
                title={titolo.toAltTitle()}
              />
            </div>
          </div>
        </div>
      </WContainer>
    </div>
  )
}

export default LandingRivisteHero
