import React from 'react'
import { Button } from 'react-components/atoms'
import SlickSlider from 'react-slick'

const BoxElementiRivista = props => {
  const {
    elementi = [],
    titolo = '',
    sottotitolo = '',
    ctaLabel = '',
    ctaLink = '',
    className = '',
  } = props

  const sliderSettings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  if (!elementi?.length) return null

  return (
    <div className={`box-elementi-rivista ${className ? ` ${className}` : ''}`}>
      <div className="box-elementi-rivista__top">
        <div className="box-elementi-rivista__left">
          <p className="box-elementi-rivista__title">{titolo}</p>
          {sottotitolo && <p className="box-elementi-rivista__subtitle">{sottotitolo}</p>}
        </div>
        {ctaLabel && ctaLink && (
          <div className="box-elementi-rivista__right">
            <Button
              className="box-elementi-rivista__cta"
              label={ctaLabel}
              variant="secondary"
              href={ctaLink}
            />
          </div>
        )}
      </div>
      <div className="box-elementi-rivista__slider">
        <SlickSlider {...sliderSettings}>
          {elementi.map((item, index) => (
            <div className="box-elementi-rivista__slide" key={index}>
              {item}
            </div>
          ))}
        </SlickSlider>
      </div>
      {ctaLabel && ctaLink && (
        <div className="box-elementi-rivista__bottom">
          <Button
            className="box-elementi-rivista__cta"
            label={ctaLabel}
            variant="secondary"
            href={ctaLink}
          />
        </div>
      )}
    </div>
  )
}

export default BoxElementiRivista
