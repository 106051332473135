import React, { useEffect, useState } from 'react'

import { useTrans } from '../../../hooks/index'
import { Button, FormField, FormSelect, ImageBox } from 'react-components/atoms'
import algoliasearch from 'algoliasearch/lite'
import {
  connectMenu,
  InstantSearch,
  connectStateResults,
  Hits,
  connectCurrentRefinements,
} from 'react-instantsearch-dom'
import orderBy from 'lodash/orderBy'
import { useSelector } from 'react-redux'
import { AGENTI_INDEX, api, getItalianPhone } from 'utils'
import { ArrowRightLong, Email, Phone, Pin } from 'react-components/atoms/Icons'
import { AGENTI } from 'utils/endpoint'
import { sendGTMData } from '../../../utils/gtm'

const searchClient = algoliasearch('U4BSY5DLQ2', '419789a02a88dfc3fa8d570f5e628b9f')

const RicercaAgente = () => {
  const t = useTrans()

  const { utente } = useSelector((state) => state?.utente)

  const [isScuolaSecondaria, setIsScuolaSecondaria] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [agente, setAgente] = useState(null)
  const [openSearch, setOpenSearch] = useState(false)

  const [provinciaRefined, setProvinciaRefined] = useState(false)
  const [ordineRefined, setOrdineRefined] = useState(false)
  const [scuolaRefined, setScuolaRefined] = useState(false)
  const [ordineScuola, setOrdineScuola] = useState(false)
  const [provincia, setProvincia] = useState(false)

  useEffect(() => {
    if (utente)
      api
        .get(`${AGENTI}mio/`)
        .then((res) => setAgente(res.data?.agente))
        .catch((err) => console.log(err))
  }, [utente])

  const handleGTM = () => {
    sendGTMData({
      event: 'GAevent',
      eventID: '26',
      eventCategory: 'filtri_agenti',
      eventAction: provincia.toGTMFormat(),
      eventLabel: ordineScuola.toGTMFormat(),
    })
  }

  return (
    <InstantSearch searchClient={searchClient} indexName={AGENTI_INDEX}>
      <div className={`ricerca-agente${openSearch || !showResults ? ' ricerca-agente--open' : ''}`}>
        <div className="ricerca-agente__select">
          <h1 className="ricerca-agente__title">{t('Trova l’agente più vicino a te')}</h1>
          <div className="ricerca-agente__content">
            <div className="ricerca-agente__fields">
              <AlgoliaSelect
                name="Provincia"
                placeholder={t('Seleziona la provincia')}
                attribute="province_competenza"
                transformItems={(items) => orderBy(items, ['label'], ['asc', 'desc'])}
                limit={150}
                setIsRefined={setProvinciaRefined}
                required
                disabled={showResults && scuolaRefined}
                setProvincia={setProvincia}
              />
              <AlgoliaSelect
                name="Ordine scuola"
                placeholder={t('Seleziona ordine di scuola')}
                attribute="ordini"
                setIsScuolaSecondaria={setIsScuolaSecondaria}
                limit={5}
                transformItems={(items) =>
                  orderBy(
                    items.find((i) => i.label === 'Scuola secondaria')
                      ? items
                      : items.concat([
                          { label: 'Scuola secondaria', value: 'Scuola secondaria', count: 0 }, // secondaria sempre presente
                        ]),
                    ['label', 'count'],
                    ['asc', 'desc']
                  )
                }
                setIsRefined={setOrdineRefined}
                required
                disabled={showResults && scuolaRefined}
                setOrdineScuola={setOrdineScuola}
              />
              <AlgoliaSelect
                name="Scuola di appartenenza"
                placeholder={t('Cerca la scuola o il comune')}
                attribute="scuola_competenza"
                limit={1000}
                transformItems={(items) => orderBy(items, ['label'], ['asc', 'desc'])}
                setIsRefined={setScuolaRefined}
                disabled={isScuolaSecondaria || !(provinciaRefined && ordineRefined)}
              />
            </div>
            <p className="ricerca-agente__required">{t('* Campi obbligatori')}</p>
            <div className="ricerca-agente__submit">
              <Button
                label={t('Cerca agente')}
                variant="primary"
                onClick={() => {
                  handleGTM()
                  setShowResults(true)
                }}
                disabled={isScuolaSecondaria || !(provinciaRefined && ordineRefined)}
              />
            </div>
            <CustomClearRefinements setShowResults={setShowResults} />
          </div>
          <div className="ricerca-agente__open-mobile">
            <Button label={t('Nuova ricerca')} onClick={() => setOpenSearch(true)} />
          </div>
        </div>
        {isScuolaSecondaria ? (
          <div className="ricerca-agente__wrapper">
            <div className="ricerca-agente-scuola-secondaria">
              <h1 className="ricerca-agente-scuola-secondaria__title">
                Il tuo agente per la scuola secondaria
              </h1>
              <p className="ricerca-agente-scuola-secondaria__description">
                Per informazioni e contatti con agenti per la scuola secondaria vai su{' '}
                <a href="https://www.giuntitvp.it/rete-commerciale/" target="_blank">
                  giuntitvp.it
                </a>
              </p>
            </div>
          </div>
        ) : showResults ? (
          <Results isScuolaSecondaria={isScuolaSecondaria} agente={agente}>
            <Hits hitComponent={Hit} />
          </Results>
        ) : (
          <NoResultsAndNoLogger agente={utente && agente} />
        )}
      </div>
    </InstantSearch>
  )
}

export default RicercaAgente

const Results = connectStateResults(({ searchState, searchResults, children, agente }) =>
  searchResults?.nbHits !== 0 ? (
    <div className="ricerca-agente__wrapper">
      {agente && <Hit hit={agente} logged={true} />}
      <p className="ricerca-agente__results-num">
        {searchResults?.nbHits} {searchResults?.nbHits === 1 ? 'risultato' : 'risultati'}
      </p>
      {children}
    </div>
  ) : null
)

const NewSelect = ({
  items,
  name,
  placeholder,
  refine,
  attribute,
  disabled,
  currentRefinement,
  required = false,
  setIsScuolaSecondaria = () => {},
  setIsRefined = () => {},
  setProvincia = () => {},
  setOrdineScuola = () => {},
}) => {
  const t = useTrans()
  const [selectedValue, setSelectedValue] = useState()

  const isRefined = items.some((item) => item.isRefined)

  useEffect(() => {
    setIsRefined(isRefined)
  }, [isRefined])

  return (
    <FormField label={`${t(name)}${required ? '*' : ''}`}>
      <FormSelect
        placeholder={placeholder}
        disabled={disabled}
        options={items}
        colorVariant="white"
        value={currentRefinement ? selectedValue : { value: null, label: placeholder }}
        searchable
        onChange={(e) => {
          setSelectedValue(e)
          refine(e?.value)
          if (name.includes('Provincia')) {
            setProvincia(e.value)
          }
          if (name.includes('Ordine')) {
            setOrdineScuola(e.value)
          }
          attribute === 'ordini' && e?.value?.includes('secondaria')
            ? setIsScuolaSecondaria(true)
            : setIsScuolaSecondaria(false)
        }}
      />
    </FormField>
  )
}

const Hit = ({ hit, logged }) => {
  const t = useTrans()
  return (
    <div className={`ricerca-agente-result${logged ? ' ricerca-agente-result--logged' : ''}`}>
      {logged && <div className="ricerca-agente-result__head">{t('Il tuo agente')}</div>}
      <div className="ricerca-agente-result__layout">
        <div className="ricerca-agente-result__content">
          <p className="ricerca-agente-result__name">
            {hit?.nome} {hit?.cognome}
          </p>
          <div className="ricerca-agente-result__tags">
            {/* TODO Vedere valori tags */}
            {/* <div className="ricerca-agente-result__tag ricerca-agente-result__tag--orange">
              {t('Vendita')}
            </div>
            <div className="ricerca-agente-result__tag ricerca-agente-result__tag--green">
              {t('Promozionale')}
            </div> */}
          </div>
          <div className="ricerca-agente-result__contacts">
            {hit.indirizzo && (
              <div className="ricerca-agente-result__contact">
                <span className="ricerca-agente-result__contact__icon">
                  <Pin />
                </span>
                <span className="ricerca-agente-result__contact__value">
                  {hit?.indirizzo}
                  {hit?.cap && ` - ${hit.cap}`}
                  {hit?.citta && ` ${hit.citta}`}
                  {hit?.provincia && ` (${hit.provincia})`}
                </span>
              </div>
            )}
            {(hit?.mobile || hit?.mobile2) && (
              <div className="ricerca-agente-result__contact">
                <span className="ricerca-agente-result__contact__icon">
                  <Phone />
                </span>
                <span className="ricerca-agente-result__contact__value">
                  {hit?.mobile} {hit?.mobile2}
                </span>
              </div>
            )}
            {hit?.email && (
              <div className="ricerca-agente-result__contact">
                <span className="ricerca-agente-result__contact__icon">
                  <Email />
                </span>
                <span className="ricerca-agente-result__contact__value">{hit.email}</span>
              </div>
            )}
          </div>
        </div>
        <div className="ricerca-agente-result__actions">
          {hit?.email && (
            <div>
              <Button
                variant="ghost"
                label={t("Manda un'email")}
                icon={<ArrowRightLong />}
                iconPos="right"
                href={`mailto:${hit.email}`}
              />
            </div>
          )}
          {(hit?.mobile || hit?.mobile2) && (
            <div>
              <Button
                variant="secondary"
                label={t('Contatta su Whatsapp')}
                href={`https://wa.me/${getItalianPhone(hit?.mobile || hit?.mobile2)}`}
                external
                newPage
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

const NoResultsAndNoLogger = ({ agente }) => {
  return agente ? (
    <div className="ricerca-agente__wrapper">
      <Hit hit={agente} logged={true} />
    </div>
  ) : (
    <div className="ricerca-agente-no-results">
      <ImageBox
        src="/images/trova-agente.png"
        maxWidth={475}
        maxHeight={291}
        classPrefix="ricerca-agente-no-results"
      />
      <p className="ricerca-agente-no-results__title">Trova il tuo agente di zona</p>
      <p className="ricerca-agente-no-results__description">
        Per <b>assistenza</b> contattaci a{' '}
        <a href="mailto:sos.scuola@giunti.it">
          <i>sos.scuola@giunti.it</i>
        </a>
        .<br />
        Per assistenza su <b>DBookeasy e digitale</b>, scrivi a{' '}
        <a href="mailto:giuntiscuoladigitale@giunti.it">
          <i>giuntiscuoladigitale@giunti.it</i>
        </a>
        <br />
        Per informazioni sulla <b>scuola secondaria</b> vai su{' '}
        <a href="https://www.giuntitvp.it/rete-commerciale/" target="_blank">
          giuntitvp.it
        </a>
      </p>
    </div>
  )
}

const ClearAll = ({ items, refine, setShowResults }) => {
  const t = useTrans()
  return (
    <div className="ricerca-agente__clear">
      <Button
        label={t('Nuova ricerca')}
        variant="ghost"
        onClick={() => {
          setShowResults(false)
          refine(items)
        }}
        disabled={!items.length}
      />
    </div>
  )
}

const AlgoliaSelect = connectMenu(NewSelect)
const CustomClearRefinements = connectCurrentRefinements(ClearAll)
