import React, { useContext, useState } from 'react'
import { Button } from 'react-components/atoms'
import { BoxBundleItem, GaranziaTile } from 'react-components/molecules'
import { CartContext } from 'utils/context'
import { formatPrice } from 'utils'
import { useTrans } from 'hooks'
import { ArrowRightLong } from 'react-components/atoms/Icons'
import { BundlePanel } from 'react-components/organisms'

const BoxBundle = (props) => {
  const { className = '', bundle } = props

  const t = useTrans()

  const context = useContext(CartContext)

  const [garanzia, setGaranzia] = useState(false)
  const [panelOpen, setPanelOpen] = useState(false)

  function handleOpen(value) {
    !panelOpen
      ? document.body.classList.add('w-noscroll')
      : document.body.classList.remove('w-noscroll')
    setPanelOpen(value)
  }

  const addItemToCart = (prodotto) => {
    context.addToCart(prodotto, 1, true, 'pdp', null, null, 'navigazione_standard', garanzia)
  }

  const prodotto = bundle.cartRow

  return (
    <article className={`box-bundle ${className ? ` ${className}` : ''}`}>
      {bundle.appliedPromotions.length > 0 && (
        <span className="box-abbonamento__tag">{bundle.appliedPromotions[0].promotion.name}</span>
      )}
      <div className="box-bundle__content">
        <p className="box-bundle__head">{t('Bundle')}</p>
        <p className="box-bundle__title">{prodotto?.titolo}</p>
        <div
          className="box-bundle__description"
          dangerouslySetInnerHTML={{ __html: prodotto?.descrizione }}
        />
        <Button
          className="box-bundle__cta"
          label={t('Vedi tutti gli articoli del bundle')}
          onClick={() => handleOpen(!panelOpen)}
          variant="ghost"
          icon={<ArrowRightLong />}
          iconPos="right"
        />
        <BundlePanel
          prodotto={prodotto}
          closePanel={() => handleOpen(!panelOpen)}
          className={`box-bundle__panel ${panelOpen ? 'box-bundle__panel--open' : ''}`}
        />
        <div className="box-bundle__price">
          {prodotto?.prezzoScontato && prodotto?.prezzo !== prodotto?.prezzoScontato && (
            <span className="catalogo-page__price catalogo-page__price--full">
              {formatPrice(prodotto?.prezzo)}
            </span>
          )}
          {prodotto?.prezzo && (
            <span className="catalogo-page__price">{formatPrice(prodotto?.prezzoScontato)}</span>
          )}
          {prodotto && prodotto?.prezzoScontato && (
            <Button
              className="catalogo-page__cta-box__cta"
              label={t('Acquista il bundle')}
              onClick={() => addItemToCart(prodotto)}
            />
          )}
        </div>
        {prodotto && prodotto.garanzia && (
          <div className="box-bundle__garanzia-tile">
            <GaranziaTile
              nome={prodotto.garanzia.nome}
              durata={prodotto.garanzia.durata}
              descrizione={prodotto.garanzia.descrizione}
              tooltip={prodotto.garanzia.tooltip}
              prezzo={prodotto.garanzia.prezzo}
              onChange={() => setGaranzia(!garanzia)}
              checked={garanzia}
              inputName={prodotto.pk}
              noTitle
            />
          </div>
        )}
      </div>
      <div className="box-bundle__items">
        {prodotto?.prodottiBundle?.map((item) => (
          <BoxBundleItem item={item.cartRow} className="box-bundle__items__item" />
        ))}
      </div>
    </article>
  )
}

export default BoxBundle
