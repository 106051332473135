import { ArrowUp } from 'react-components/atoms/Icons'

const ScrollUp = props => {
  const { visible = false } = props

  function handleScroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return (
    <div className={`scrollup ${visible ? 'scrollup--visible' : ''}`} onClick={handleScroll}>
      <ArrowUp />
    </div>
  )
}

export default ScrollUp
