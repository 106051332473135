import { useEffect, useState } from 'react'

const RES_SM_MIN = 576
const RES_MD_MIN = 768

export function useDeviceWidth(limit) {
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      function handleResize() {
        const width = window.innerWidth > 0 ? window.innerWidth : screen.width
        setIsMobile(width < limit)
      }
      window.addEventListener('resize', handleResize)
      handleResize()
      return () => window.removeEventListener('resize', handleResize)
    }
  }, [])

  return isMobile
}

export function useIsMobile() {
  return useDeviceWidth(RES_SM_MIN)
}

export function useIsTablet() {
  return useDeviceWidth(RES_MD_MIN)
}
