import React from 'react'
import { Button } from 'react-components/atoms'

const CheckoutSection = props => {
  const { titolo = '', action = undefined, children = undefined, className = '' } = props

  return (
    <div className={`checkout-section ${className ? ` ${className}` : ''}`}>
      {!!titolo && (
        <div className={'checkout-section__head'}>
          <p className="checkout-section__title">{titolo}</p>
          {!!action && (
            <div className="checkout-section__action-desktop">
              <Button {...action} variant="ghost" />
            </div>
          )}
        </div>
      )}
      {children}
      {!!action && (
        <div className="checkout-section__action-mobile">
          <Button {...action} variant="ghost" />
        </div>
      )}
    </div>
  )
}

export default CheckoutSection
