import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function ArrowUp2({ className }) {
  return (
    <IconBase name="arrow-up2" w={24} className={className}>
      <path
        d="M17.9305 13.0212L13.4418 8.50953C12.7659 7.83016 11.6741 7.83016 10.9981 8.50953L6.50944 13.0212C5.41759 14.1187 6.19749 16 7.73994 16L16.7173 16C18.2598 16 19.0224 14.1187 17.9305 13.0212Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default ArrowUp2
