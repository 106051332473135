import React from 'react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { useTrans } from 'hooks'
import { getFormFieldStatus } from 'components/forms-utils/utils'
import {
  FormLayout,
  FormField,
  FormInput,
  FormTextarea,
  FormRadio,
  Button,
} from 'react-components/atoms'

import { api } from 'utils'
import { sendGTMData } from 'utils/gtm.js'
import { RACCOLTE } from 'utils/endpoint'
import { useRouter } from 'next/router'

const AreaPersonaleRaccoltaForm = ({ setRaccolta, initialValues, raccoltaId }) => {
  const t = useTrans()
  const router = useRouter()
  const validationSchema = yup.object().shape({
    // TODO
    titolo: yup
      .string()
      .required(t('Questo campo non può essere vuoto'))
      .nullable(),
    descrizione: yup.string().nullable(),
    pubblica: yup
      .string()
      .required(t('Questo campo non può essere vuoto'))
      .nullable(),
  })

  const handleCreate = values => {
    try {
      sendGTMData({
        event: 'GAevent',
        eventID: '17',
        eventCategory: 'raccolta',
        eventAction: 'crea_nuova_raccolta',
        eventLabel: values?.pubblica ? 'pubblica' : 'privata',
      })

      api
        .post(RACCOLTE, { ...values, pubblica: values?.pubblica === 'si' ? true : false })
        .then(() => {
          router.reload()
        })
        .catch(error => console.log(error))
    } catch (error) {
      console.log(error)
    }
  }

  const handleUpdate = values => {
    try {
      sendGTMData({
        event: 'GAevent',
        eventID: '17',
        eventCategory: 'raccolta',
        eventAction: 'modifica_raccolta',
        eventLabel: values?.pubblica ? 'pubblica' : 'privata',
      })

      api
        .put(`${RACCOLTE}${raccoltaId}/`, {
          descrizione: values?.descrizione,
          titolo: values?.titolo,
          pubblica: values?.pubblica === 'si' ? true : false,
        })
        .then(() => {
          window.location.href = '/area-personale/libreria/raccolte' // force SSR
        })
        .catch(error => console.log(error))
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Formik
      isInitialValid={false}
      validationSchema={validationSchema}
      initialValues={
        initialValues || {
          titolo: '',
          descrizione: '',
          pubblica: 'no',
        }
      }
      onSubmit={values => {
        initialValues ? handleUpdate(values) : handleCreate(values)
      }}
    >
      {({ values, touched, errors, handleBlur, handleChange }) => (
        <Form>
          <FormLayout>
            <FormField
              label={`${t('Titolo della raccolta')}*`}
              status={getFormFieldStatus({ touched, errors }, 'titolo')}
              errorMessage={errors?.titolo}
              layout="full"
            >
              <FormInput
                placeholder={t('Inserisci titolo della raccolta')}
                value={values.titolo}
                onBlur={handleBlur('titolo')}
                onChange={handleChange('titolo')}
                status={getFormFieldStatus({ touched, errors }, 'titolo')}
              />
            </FormField>
            <FormField
              label={t('Descrizione')}
              status={getFormFieldStatus({ touched, errors }, 'descrizione')}
              errorMessage={errors?.descrizione}
              layout="full"
            >
              <FormTextarea
                placeholder={t('Inserisci la descrizione della raccolta')}
                value={values.descrizione}
                onBlur={handleBlur('descrizione')}
                onChange={handleChange('descrizione')}
                status={getFormFieldStatus({ touched, errors }, 'descrizione')}
              />
            </FormField>
            <FormField
              label={t('Vuoi rendere la tua raccolta visibile agli altri utenti?')}
              status={getFormFieldStatus({ touched, errors }, 'pubblica')}
              errorMessage={errors?.pubblica}
              layout="full"
              className="area-personale-raccolta-form__pubblica-form-field"
            >
              <FormRadio
                value={values?.pubblica}
                options={[
                  { value: 'no', label: 'No, mantieni la raccolta privata' },
                  { value: 'si', label: 'Si, rendi la raccolta pubblica' },
                ]}
                onBlur={handleBlur('pubblica')}
                onChange={handleChange('pubblica')}
                status={getFormFieldStatus({ touched, errors }, 'pubblica')}
              />
            </FormField>
          </FormLayout>
          <div className="area-personale-raccolta-form__submit-box">
            <Button
              type="submit"
              label={t(initialValues ? 'Salva modifiche' : 'Crea la tua raccolta')}
            />
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AreaPersonaleRaccoltaForm
