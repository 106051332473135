import React from 'react'
import { ChevronLeft } from 'react-components/atoms/Icons'
import { Button, AreaPersonaleBreadcrumbs } from 'react-components/atoms'
import { AreaPersonaleSidebar } from 'react-components/organisms'

import Head from 'next/head'

const AreaPersonalePage = props => {
  const {
    utente = {},
    pagina = {},
    headActions = null,
    hideSidebar = false,
    className = '',
    breadcrumbs = [],
    backButton = null,
    activeMenuKey = '',
    children = null,
  } = props

  return (
    <main
      className={`area-personale-page${hideSidebar ? ' area-personale-page--no-sidebar' : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <Head>
        <title>Area Personale | Giunti Scuola</title>
      </Head>
      {backButton &&
        (backButton.link ? (
          <Button
            {...{
              variant: 'ghost',
              icon: <ChevronLeft />,
              iconPos: 'left',
              ...backButton,
              className: `area-personale-page__back`,
              href: backButton.link,
            }}
          />
        ) : (
          /* TODO - onClick deve aprire il menu dell'area personale (vedi Header) */
          <button className={`area-personale-page__open-menu`}>
            <span className="area-personale-page__open-menu__icon">
              <ChevronLeft />
            </span>
            <span className="area-personale-page__open-menu__label">{backButton.label}</span>
          </button>
        ))}
      {!hideSidebar && <AreaPersonaleSidebar utente={utente} activeMenuKey={activeMenuKey} />}
      <div className="area-personale-page__main">
        {breadcrumbs.length > 0 && (
          <AreaPersonaleBreadcrumbs
            breadcrumbs={breadcrumbs}
            className="area-personale-page__breadcrumbs"
          />
        )}
        {(pagina?.titolo || headActions) && (
          <div className="area-personale-page__head">
            {pagina?.titolo && <h1 className="area-personale-page__title">{pagina?.titolo}</h1>}
            {headActions && <div className="area-personale-page__head-actions">{headActions}</div>}
          </div>
        )}
        {pagina?.descrizione && (
          <div
            className="area-personale-page__description"
            dangerouslySetInnerHTML={{ __html: pagina?.descrizione }}
          />
        )}
        {children}
      </div>
    </main>
  )
}

export default AreaPersonalePage
