import React from 'react'
import { useTrans } from 'hooks'

const AreaPersonaleIndirizzoOrdineCard = props => {
  const { indirizzo = undefined, fatturazione = false, className = '' } = props

  const t = useTrans()

  return indirizzo ? (
    <div className={`area-personale-indirizzo-ordine-card ${className ? ` ${className}` : ''}`}>
      <p className="area-personale-indirizzo-ordine-card__title">
        {t(fatturazione ? 'Indirizzo di fatturazione' : 'Indirizzo di spedizione')}
      </p>
      <div className="area-personale-indirizzo-ordine-card__content">
        {!!(indirizzo.nome || indirizzo.cognome) && (
          <p>
            {indirizzo.nome} {indirizzo.cognome}
          </p>
        )}
        <p>
          {indirizzo.indirizzo}, {indirizzo.civico} - {indirizzo.cap} {indirizzo.citta}{' '}
          {!!indirizzo.provincia && <>({indirizzo.provincia})</>}
        </p>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default AreaPersonaleIndirizzoOrdineCard
