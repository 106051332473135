import React from 'react'

function Figure({ className }) {
  return (
    <svg
      viewBox="-32 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--figure ${className ? className : ''}`}
    >
      <path
        fill="currentColor"
        d="M128 192a32 32 0 1 0-32-32 32 32 0 0 0 32 32zM416 32H32A32 32 0 0 0 0 64v384a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V64a32 32 0 0 0-32-32zm-32 320H64V96h320zM268.8 209.07a16 16 0 0 0-25.6 0l-49.32 65.75L173.31 244a16 16 0 0 0-26.62 0L96 320h256z"
      ></path>
    </svg>
  )
}

export default Figure
