import { useScuolaUtils, useTrans } from 'hooks'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-components/atoms'
import { useSelector } from 'react-redux'
import { api } from 'utils'
import { UTENTE, UTENTISCUOLE } from 'utils/endpoint'
import { AreaPersonaleScuolaEditCard } from '..'

export const updatePopupConfermaScuola = async () => {
  const result = await api.get(UTENTE + 'set_scuola_confermata/').catch(err => console.log(err))
  return result
}

export const getUltimaScuola = async () => {
  const result = await api.get(UTENTISCUOLE + 'get_ultima_scuola/').catch(err => console.log(err))
  return result
}

const PopupConfermaScuola = ({ isModal, setOpen, className }) => {
  const router = useRouter()

  const t = useTrans()

  const { ItaliaJSON } = useScuolaUtils()
  const { utente } = useSelector(state => state.utente)
  const [showForm, setShowForm] = useState(false)
  const [scuola, setScuola] = useState()

  useEffect(() => {
    getUltimaScuola()
      .then(res => setScuola(res?.data?.scuola))
      .catch(err => console.log(err))
  }, [])

  const scuolaObj = scuola?.scuola

  const labelScuola = `${scuolaObj?.classe}${scuolaObj?.classe ? '°' : ''}${scuolaObj?.sezione} - ${
    scuolaObj?.nome_scuola
  }`

  return showForm ? (
    <div className={`popup-conferma-scuola ${className ? ` ${className}` : ''}`}>
      <AreaPersonaleScuolaEditCard
        idToUpdate={scuola?.id}
        ItaliaJSON={ItaliaJSON}
        editModeFromProps={true}
        isPopupConfermaScuola
        scuola={{
          ...scuolaObj,
          scuola: scuolaObj?.nome_scuola,
          citta: scuolaObj?.comune ?? scuolaObj?.citta,
          cap: scuola?.cap_scuola ?? scuola?.cap,
        }}
      />
    </div>
  ) : (
    <div className={`popup-conferma-scuola ${className ? ` ${className}` : ''}`}>
      <h1 style={{ marginBottom: '20px' }}>{t('Aggiornamento scuole 2022/2023')}</h1>
      <p style={{ textAlign: 'center' }}>
        Ciao {utente.nome}, per poter rendere disponibili i nuovi libri di testo digitali che hai in
        uso nella tua classe, ti chiediamo di verificare i dati sulla tua scuola e la tua classe.
      </p>

      <div className={'popup-conferma-scuola__container-scuola'}>
        <p>
          <b>{labelScuola || 'Scuola'}</b>
        </p>
      </div>

      <div className={'popup-conferma-scuola__container-cta'}>
        <div className={'popup-conferma-scuola__container-cta__wrapper'}>
          <p>Hai cambiato scuola o classe?</p>
          <Button
            label="Modifica"
            variant="primary"
            onClick={() =>
              isModal ? router.push('/area-personale/profilo/scuole') : setShowForm(true)
            }
          />
        </div>
        <div className={'popup-conferma-scuola__spacer'}></div>
        <div className={'popup-conferma-scuola__container-cta__wrapper'}>
          <p>La scuola e classe sono le stesse?</p>
          <Button
            label="Conferma"
            variant="secondary"
            onClick={() => {
              updatePopupConfermaScuola().then(() => (isModal ? setOpen?.(false) : router.reload()))
            }}
          />
        </div>
      </div>
      <p style={{ textAlign: 'center' }}>Ricordati di salvare i dati nella tua area personale.</p>
    </div>
  )
}

export const ModalPopupConfermaScuola = () => {
  const router = useRouter()
  const [open, setOpen] = useState(true)
  const { utente } = useSelector(state => state.utente)
  const path = router.pathname

  const [show, setShow] = useState(false)

  function init() {
    if (!sessionStorage.getItem(`conferma-scuole`)) {
      setShow(true)
    }
  }

  function close() {
    sessionStorage.setItem(`conferma-scuole`, true)
    setShow(false)
  }

  useEffect(() => {
    init()
  }, [])

  return show &&
    path !== '/area-personale/profilo/scuole' &&
    utente &&
    !utente?.popup_scuola_confermata ? (
    <Modal open={open} setOpen={setOpen} closeButton onClose={close}>
      <PopupConfermaScuola setOpen={setOpen} isModal />
    </Modal>
  ) : null
}

export default PopupConfermaScuola
