import Link from 'next/link'
import { MEMBER, SALVA, api, downloadables, getTipoStrumento, hasPerm, isDisabled } from 'utils'
import React, { useEffect, useMemo, useState } from 'react'
import { ELEMENTOSALVATO } from 'utils/endpoint'
import Icon from 'components/atomic/Icon'
import Ioplus from 'components/atomic/Ioplus'
import { useSelector } from 'react-redux'
import { sendGTMData, sendProductClickGTM } from 'utils/gtm.js'
import Scarica from 'components/atomic/Scarica'
import Salva from 'components/atomic/Salva'

const StrumentoCard = React.memo((props) => {
  const {
    strumento,
    classes = '',
    isEmbedded = false,
    onRemove = null,
    horizontal = false,
    size = '',
    variant = null,
    position = null,
    parentList = '',
  } = props

  const { utente } = useSelector((state) => state.utente)

  const [saved, setSaved] = useState(false)

  const tipoStrumento = useMemo(() => getTipoStrumento(strumento), [strumento])

  const disabled = useMemo(() => isDisabled(strumento, utente), [strumento, utente])

  const thumb = strumento.main_image

  const classi = `strumento-card strumento-card--${tipoStrumento.split(' ')[0]} ${classes} ${
    disabled ? 'strumento-card--disabled' : ''
  } ${horizontal ? 'strumento-card--horizontal' : ''}${size ? ` strumento-card--${size}` : ''} ${
    thumb ? ' strumento-card--thumbed ' : ''
  } ${variant ? ` strumento-card--${variant}` : ''}`

  const isDownloadable = downloadables.includes(tipoStrumento.split(' ')[0])

  const params = {
    content_type_id: strumento.model,
    object_id: strumento.id || strumento.object_id,
  }

  let fileUrl = strumento.extra?.file_url || null

  const handleGTM = (action) => {
    sendGTMData({
      event: 'GAevent',
      eventID: '15',
      eventCategory: 'interazione_contenuti',
      eventAction: action,
      eventLabel: tipoStrumento.split(' ')[0],
      moreInfo: strumento.titolo.toGTMFormat(),
    })
  }

  const handleSave = async () => {
    handleGTM('bookmark')
    api.post(ELEMENTOSALVATO, { params }).then(() => checkSaved())
  }

  const handleUnsave = async () => {
    handleGTM('bookmark')
    api.delete(ELEMENTOSALVATO, { data: params }).then(() => checkSaved())
  }

  const checkSaved = async () => {
    api.get(ELEMENTOSALVATO, { params }).then((response) => {
      setSaved(response.data['salvato'])
    })
  }

  useEffect(() => {
    setSaved(strumento.saved)
  }, [strumento.saved])

  useEffect(() => {
    if (isEmbedded && hasPerm(utente, SALVA)) checkSaved()
  }, [])

  return (
    <article className={classi}>
      {isDownloadable && fileUrl ? (
        <Scarica
          href={fileUrl}
          download
          onClick={() => handleGTM('download')}
          horizontal={horizontal}
        />
      ) : (
        strumento.extra.membership == MEMBER && (
          <Ioplus tooltip="Contenuto riservato agli abbonati io+" horizontal={horizontal} />
        )
      )}
      {hasPerm(utente, SALVA) ? (
        saved ? (
          <Salva onClick={handleUnsave} horizontal={horizontal} saved />
        ) : (
          <Salva onClick={handleSave} horizontal={horizontal} />
        )
      ) : null}
      {onRemove && <Icon id="remove" classes="icon--remove" onClick={onRemove} tooltip="ELIMINA" />}
      <Link legacyBehavior href={strumento.url}>
        <a
          className="strumento-card__image-box"
          style={thumb ? { backgroundImage: `url(${thumb})` } : {}}
          onClick={() => {
            handleGTM('click')
            sendProductClickGTM(
              strumento,
              parentList ? parentList : 'navigazione_standard',
              position
            )
          }}
        />
      </Link>
      <Link legacyBehavior href={strumento.url}>
        <a
          className="strumento-card__content"
          onClick={() => {
            handleGTM('click')
            sendProductClickGTM(
              strumento,
              parentList ? parentList : 'navigazione_standard',
              position
            )
          }}
        >
          <p className="strumento-card__title">{strumento.titolo}</p>
          <p className="strumento-card__category">
            <Icon id={`cat-${tipoStrumento.split(' ')[0]}`} />
            <span>{tipoStrumento}</span>
          </p>
        </a>
      </Link>
    </article>
  )
})

export default StrumentoCard
