import React from 'react'
import { useTrans } from 'hooks'
import Link from 'next/link'
import { Phone } from 'react-components/atoms/Icons'

const BoxAdozioneCorso = (props) => {
  const { className = '' } = props

  const t = useTrans()

  return (
    <div className={`box-adozione-corso ${className}`}>
      <div className="box-adozione-corso__container">
        <div className="box-adozione-corso__content">
          <p className="box-adozione-corso__title">{t('Hai già adottato un corso?')}</p>
          <div
            className="box-adozione-corso__description"
            dangerouslySetInnerHTML={{
              __html: t(
                'Trovi tutte le risorse e i servizi dedicati a te nella libreria della tua area personale. Scopri anche la grande community <a target="_blank" href="https://www.facebook.com/groups/395184707693677]"><u>Star bene a scuola</u></a> dove confrontarti quotidianamente con altri insegnanti. Se hai bisogno di assistenza contatta SOS Scuola:'
              ),
            }}
          />
        </div>
        <div className="box-adozione-corso__cards">
          <Link legacyBehavior href="/contatti">
            <a className="box-adozione-corso__card">
              <Phone />
              <div className="box-adozione-corso__card__content">
                <p className="box-adozione-corso__card__title">{t('S.O.S. Scuola')}</p>
                <p className="box-adozione-corso__card__description">
                  {t(
                    'Una linea diretta di assistenza e informazione: scrivi a sos.scuola@giunti.it o chiama lo 055 5062424'
                  )}
                </p>
              </div>
            </a>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default BoxAdozioneCorso
