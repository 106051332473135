import React from 'react'
import { Visibility } from 'react-components/atoms/Icons'

const ObserveBox = props => {
  const { title = '', text = '', className = '' } = props

  return (
    <div className={`wobserve-box ${className ? ` ${className}` : ''}`}>
      {!!title && (
        <div className="wobserve-box__head">
          <span className="wobserve-box__icon">
            <Visibility />
          </span>
          <div className="wobserve-box__title">{title}</div>
        </div>
      )}
      {!!text && <div className="wobserve-box__text">{text}</div>}
    </div>
  )
}

export default ObserveBox
