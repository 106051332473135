import React, { useEffect, useState } from 'react'
import { useTrans } from 'hooks'
import { Pin, Phone, Email, ArrowRightLong } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'

import { api, getItalianPhone } from 'utils'
import { AGENTI } from 'utils/endpoint'

const WidgetAgente = props => {
  const { className = '' } = props

  const t = useTrans()

  const [agente, setAgente] = useState(null)

  useEffect(() => {
    initAgente()
  }, [])

  async function initAgente() {
    const response = await api.get(`${AGENTI}mio/`)
    const { data } = response
    setAgente(data.agente)
  }

  if (!agente) return null

  return agente ? (
    <div className={`widget-agente ${className ? ` ${className}` : ''}`}>
      <div className="widget-agente__content">
        <p className="widget-agente__title">{t('Il mio agente')}</p>
        <p className="widget-agente__name">
          {agente.nome} {agente.cognome}
        </p>
        <div className="widget-agente__contacts">
          {agente.indirizzo && (
            <div className="widget-agente__contact">
              <span className="widget-agente__contact__icon">
                <Pin />
              </span>
              <span className="widget-agente__contact__value">
                {agente.indirizzo} {agente?.cap && ` - ${agente.cap}`}
                {agente?.citta && ` ${agente.citta}`}
                {agente?.provincia && ` (${agente.provincia})`}
              </span>
            </div>
          )}
          {agente.telefono && (
            <div className="widget-agente__contact">
              <span className="widget-agente__contact__icon">
                <Phone />
              </span>
              <span className="widget-agente__contact__value">{agente.telefono}</span>
            </div>
          )}
          {agente.email && (
            <div className="widget-agente__contact">
              <span className="widget-agente__contact__icon">
                <Email />
              </span>
              <span className="widget-agente__contact__value">{agente.email}</span>
            </div>
          )}
        </div>
      </div>
      <div className="widget-agente__actions">
        {agente.email && (
          <Button
            variant="ghost"
            label={t("Manda un'email")}
            icon={<ArrowRightLong />}
            iconPos="right"
            href={`mailto:${agente.email}`}
            external
          />
        )}
        {(agente.mobile || agente.mobile2) && (
          <Button
            variant="secondary"
            label={t('Contatta su Whatsapp')}
            href={`https://wa.me/${getItalianPhone(agente.mobile || agente.mobile2)}`}
            external
            newPage
            className="widget-agente__actions__wa"
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default WidgetAgente
