import React from 'react'

const Tag = props => {
  const { children = '', className = '' } = props

  return (
    <div className={`tag ${className ? ` ${className}` : ''}`}>
      <div className="tag__content">{children}</div>
    </div>
  )
}

export default Tag
