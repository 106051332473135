import { LOGIN_URL } from 'utils'
import { useRouter } from 'next/router'
import { sendGTMData } from 'utils/gtm.js'

// componente dummy per redirigere al login esterno

const Login = ({ next = '' }) => {
  sendGTMData({
    event: 'GAevent',
    eventID: '25',
    eventCategory: 'registrazione_login_popup',
    eventAction: 'login_ok',
    eventLabel: 'non_valorizzata',
  })

  const router = useRouter()
  if (
    next.includes('/registrazione/reset-password') ||
    router.asPath.includes('/registrazione/reset-password')
  ) {
    router.push(`${LOGIN_URL}?next=/area-personale`)
    return null
  }
  router.push(`${LOGIN_URL}?next=${next || router.asPath}`)

  return null
}

export default Login
