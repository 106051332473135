import React, { useEffect, useState } from 'react'
import SlickSlider from 'react-slick'
import { Tab } from 'react-components/atoms'

const TabGroup = (props) => {
  const { className = '', tabs = [], initialKey = '', variant = 'grey', slider = true } = props

  const [activeTab, setActiveTab] = useState(
    initialKey ? initialKey : tabs.length > 0 ? tabs[0].key : ''
  )

  const sliderSettings = {
    arrows: false,
    dots: false,
    autoplay: false,
    infinite: false,
    variableWidth: true,
  }

  useEffect(() => {
    if (initialKey) initActiveTab()
  }, [initialKey])

  function initActiveTab() {
    setActiveTab(initialKey)
  }

  const tabTriggers = tabs.map((tab) => (
    <Tab.Trigger
      key={`tab-group-trigger__${tab.key}`}
      label={tab.label}
      icon={tab.icon}
      active={tab.key === activeTab}
      onClick={() => setActiveTab(tab.key)}
      anchorId={tab.key}
      variant={variant}
    />
  ))

  return tabs.length > 0 ? (
    <div
      className={`tab-group ${!slider ? 'tab-group--no-slider' : ''} ${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="tab-group__triggers">
        {slider ? <SlickSlider {...sliderSettings}>{tabTriggers}</SlickSlider> : tabTriggers}
      </div>
      <div className="tab-group__contents">
        {tabs.map((tab) => (
          <Tab active={tab.key === activeTab} variant={variant} key={tab.key}>
            {tab.content}
          </Tab>
        ))}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default TabGroup
