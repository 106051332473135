import Footer from './Footer'
import Header from './Header'
import { Configure, InstantSearch } from 'react-instantsearch-dom'
import SVG from 'react-inlinesvg'
import algoliasearch from 'algoliasearch/lite'
import dynamic from 'next/dynamic'
import qs from 'qs'
import Router, { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { api, ENTITAS_INDEX, hasToolbar, LOGIN_URL } from 'utils'
import React from 'react'
import { useUtente } from 'hooks'
import { UTENTE } from 'utils/endpoint'
import { CheckoutHeader, PopupGenerico } from 'react-components/organisms'
import { ModalPopupConfermaScuola } from 'react-components/organisms/PopupConfermaScuola/PopupConfermaScuola'
import aa from 'search-insights'

const Toolbar = dynamic(() => import('../atomic/Toolbar'))

const Sprite = React.memo(() => (
  <SVG
    style={{ position: 'absolute', top: '0', left: '0', display: 'none' }}
    src="/images/svg/sprite.svg"
  />
))

const algoliaClient = algoliasearch('U4BSY5DLQ2', '419789a02a88dfc3fa8d570f5e628b9f')

aa('init', {
  appId: 'U4BSY5DLQ2',
  apiKey: '419789a02a88dfc3fa8d570f5e628b9f',
})

const searchClient = {
  search(requests) {
    if (requests.every(({ params }) => !params.query)) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          processingTimeMS: 0,
        })),
      })
    }
    return algoliaClient.search(requests)
  },
}

const createURL = (state) => `?${qs.stringify(state)}`

const urlToSearchState = (router) => {
  const query = router.asPath.split('?')
  if (!query.length > 1) return router.query
  else return qs.parse(query[1])
}

const DEBOUNCE_TIME = 400

const Layout = (props) => {
  const { headerVariant, footerVariant, loginRequired } = props
  const { toolbarItem, toolbarPage } = props.children.props
  const toolbarProps = { toolbarItem, toolbarPage }
  const page = props.children.props.page || props.children.props.pagina

  const router = useRouter()
  const [utente, loading] = useUtente()

  const [searchState, setSearchState] = useState(urlToSearchState(router))
  const [debouncedSetState, setDebouncedSetState] = useState(null)

  const searchStateToUrl = (searchState) =>
    searchState ? `${router.pathname}${createURL(searchState)}` : ''

  const onSearchStateChange = (updatedSearchState) => {
    if (router.pathname.includes('ricerca')) {
      clearTimeout(debouncedSetState)

      setDebouncedSetState(
        setTimeout(() => {
          Router.push(searchStateToUrl(updatedSearchState), undefined, { shallow: true })
        }, DEBOUNCE_TIME)
      )
    }

    setSearchState(updatedSearchState)
  }

  useEffect(() => {
    const checkUtente = setTimeout(() => {
      if (!loading) {
        // redirect verso login CAS
        if (loginRequired && !utente) router.replace(`${LOGIN_URL}?next=${router.asPath}`)

        // utente GIM che non ha completato la registrazione -> logout
        if (utente && !utente.normalizzato && !router.asPath.includes('/registrazione')) {
          api.get(UTENTE + 'logout/').then((response) => {
            window.location.href = response.data.redirect_url
          })
        }
      }
    }, 300)

    return () => clearTimeout(checkUtente)
  }, [utente, loading, loginRequired])

  const isMinisito = router.asPath.startsWith('/calendario-avvento')

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={ENTITAS_INDEX}
      searchState={searchState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
    >
      {!isMinisito ? (
        <>
          <ModalPopupConfermaScuola />
          <PopupGenerico />
          {hasToolbar(utente) && <Toolbar {...toolbarProps} />}
          {headerVariant === 'checkout' ? <CheckoutHeader /> : <Header page={page} />}
        </>
      ) : null}
      {props.children}
      {!isMinisito && footerVariant !== 'none' && <Footer />}
      <Sprite />
      <Configure clickAnalytics />
    </InstantSearch>
  )
}

export default Layout
