import React from 'react'
import { FormError } from 'react-components/atoms'

const FormInput = (props) => {
  const {
    type = 'text',
    placeholder = '',
    name = '',
    value = '',
    min = '',
    max = '',
    size = 'md',
    className = '',
    readOnly = false,
    disabled = false,
    status = '',
    errorMessage = '',
    onBlur = () => {},
    onFocus = () => {},
    onChange = () => {},
    onKeyDown = () => {},
  } = props

  const classNames = `
    form-input 
    form-input--${size}
    ${status ? ` form-input--${status}` : ''}
    ${className ? ` ${className}` : ''}`

  return (
    <div className={classNames}>
      <input
        type={type}
        name={name}
        value={value}
        min={min}
        max={max}
        className="form-input__input"
        placeholder={placeholder}
        disabled={disabled}
        readOnly={readOnly}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
      <FormError
        className="form-input__error"
        message={errorMessage}
        visible={status === 'error'}
      />
    </div>
  )
}

export default FormInput
