import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function LandingRivisteSearch({ className }) {
  return (
    <IconBase name="landing-riviste-search" w={26} h={25} className={className}>
      <path
        d="M10.0293 17.3867C14.4182 17.3867 17.9761 13.9436 17.9761 9.69629C17.9761 5.44898 14.4182 2.00586 10.0293 2.00586C5.64041 2.00586 2.08252 5.44898 2.08252 9.69629C2.08252 13.9436 5.64041 17.3867 10.0293 17.3867Z"
        stroke="currentColor"
        strokeWidth="3"
        strokeMiterlimit="10"
        fill="none"
      />
      <path
        d="M15.7744 14.9893L24.0996 23.0459"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        stroke="currentColor"
        fill="none"
      />
    </IconBase>
  )
}

export default LandingRivisteSearch
