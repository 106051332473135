import React, { useRef, useState, useEffect } from 'react'
import { Image } from 'react-components/atoms'
import SlickSlider from 'react-slick'
import { CAS_DOMAIN } from 'utils'
import { useTrans } from 'hooks'
import { Modal, Button } from 'react-components/atoms'

const AreaPersonaleOnBoardingModal = props => {
  const { open = false, setOpen = null, className = '' } = props

  const t = useTrans()
  const [sliderCurrentSlide, setSliderCurrentSlide] = useState(0)
  let slider = useRef(null)

  useEffect(() => {
    if (!open) setSliderCurrentSlide(0)
  }, [open])

  const sliderSettings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: false,
    arrows: true,
    dots: true,
    beforeChange: (prevSlide, nextSlide) => {
      setSliderCurrentSlide(nextSlide)
    },
  }

  const handlePrev = () => {
    slider.slickPrev()
  }

  const handleNext = () => {
    slider.slickNext()
  }

  // FAKE
  const menu = {
    children_visibili: [
      {
        image: '/images/onboarding/step-1.png',
        name: t('Scopri la nuova area personale di GiuntiScuola.it!'),
        description: t(
          'Qui potrai trovare i contenuti salvati, gestire i tuoi interessi, personalizzare la tua bacheca e tanto altro!'
        ),
      },
      {
        image: '/images/onboarding/step-2.png',
        name: t('Personalizza la tua bacheca'),
        description: t(
          'Abbiamo inserito le funzioni che possono servirti di più, ma puoi scegliere di aggiungere quelle più comode per te.'
        ),
      },
      {
        image: '/images/onboarding/step-3.png',
        name: t('Organizza i tuoi materiali'),
        description: t('Trova, attiva, scarica la versione digitale dei tuoi libri scolastici.'),
      },
      {
        image: '/images/onboarding/step-4.png',
        name: t('Ricevi le novità dalla redazione'),
        description: t(
          'Scegli quali discipline seguire, ricevi gli ultimi contenuti dalla redazione e salvali nella tua libreria!'
        ),
      },
    ],
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      closeButton
      className={`area-personale-on-boarding-modal ${className ? ` ${className}` : ''}`}
    >
      <div className="area-personale-on-boarding-modal__slider">
        <SlickSlider ref={self => (slider = self)} {...sliderSettings}>
          {menu?.children_visibili?.map((item, index) => (
            <div className="area-personale-on-boarding-modal__slide" key={index}>
              <div className="area-personale-on-boarding-modal__slide__image-box">
                <Image
                  src={item?.image}
                  alt={item?.name.toAltTitle()}
                  title={item?.name.toAltTitle()}
                  className="area-personale-on-boarding-modal__slide__image"
                  layout="responsive"
                  width={320}
                  height={250}
                  priority={index === 0}
                />
              </div>
              <p className="area-personale-on-boarding-modal__slide__title">{item?.name}</p>
              <div
                className="area-personale-on-boarding-modal__slide__description"
                dangerouslySetInnerHTML={{ __html: item?.description }}
              />
            </div>
          ))}
        </SlickSlider>
        <div className="area-personale-on-boarding-modal__nav">
          <Button
            className={`area-personale-on-boarding-modal__nav-item area-personale-on-boarding-modal__nav-item--prev${
              sliderCurrentSlide === 0
                ? ' area-personale-on-boarding-modal__nav-item--disabled'
                : ''
            }`}
            onClick={handlePrev}
            variant="ghost"
            label={t('Indietro')}
          />
          <Button
            className={`area-personale-on-boarding-modal__nav-item area-personale-on-boarding-modal__nav-item--next`}
            onClick={
              sliderCurrentSlide + 1 === menu.children_visibili.length
                ? () => setOpen(false)
                : handleNext
            }
            variant="secondary"
            label={t(sliderCurrentSlide + 1 === menu.children_visibili.length ? 'Fine' : 'Avanti')}
          />
        </div>
      </div>
    </Modal>
  )
}

export default AreaPersonaleOnBoardingModal
