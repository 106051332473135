import Icon from './Icon'

function Salva({ saved, onClick = () => {}, horizontal = false }) {
  return (
    <div className={`salva ${horizontal ? 'salva--horizontal' : ''}`} onClick={onClick}>
      <Icon
        id={saved ? 'heart-full' : 'heart'}
        classes="icon--heart"
        tooltip={saved ? 'Rimuovi dalla tua libreria' : 'Aggiungi alla tua libreria'}
      />
    </div>
  )
}

export default Salva
