import GenericMenuListItem from './GenericMenuListItem'
import React from 'react'

const GenericMenuList = ({ classes, with_icons, menu }) => {
  const children = Object.values(menu.children ? menu.children : []).map((item, k) => {
    return <GenericMenuListItem classes={classes} with_icons={with_icons} item={item} key={k} />
  })

  return <ul className={[classes, '-menu'].join('')}>{children}</ul>
}

export default GenericMenuList
