import React from 'react'
import ReactTooltip from 'react-tooltip'
import Tooltip from 'components/atomic/Tooltip'
import { renderToString } from 'react-dom/server'

const IconBase = ({ name, className, stroke, w, h, fillNone = false, children, tooltip = '' }) =>
  tooltip ? (
    <>
      <svg
        viewBox={`0 0 ${w} ${h ? h : w}`}
        xmlns="http://www.w3.org/2000/svg"
        className={`icon icon--${name}${className ? ' ' + className : ''}`}
        data-for={tooltip}
        data-tip={renderToString(<Tooltip value={tooltip} />)}
        fill={fillNone ? 'none' : ''}
      >
        {children}
      </svg>
      <ReactTooltip
        id={tooltip}
        html
        type="light"
        place="right"
        effect="solid"
        className="react-tooltip"
      />
    </>
  ) : (
    <svg
      viewBox={`0 0 ${w} ${h ? h : w}`}
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--${name}${className ? ' ' + className : ''}`}
      fill={fillNone ? 'none' : ''}
    >
      {children}
    </svg>
  )

export default IconBase
