import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function CatLibro({ className }) {
  return (
    <IconBase name="cat-libro" w={30} h={24} className={className}>
      <path
        id="Shape"
        //  fill="#2A2A2A"
        d="M22.5,0.2c-2.7,0-5.5,0.5-7.5,2c-2-1.5-4.8-2-7.5-2c-2,0-4.1,0.3-5.8,1.1C0.7,1.8,0,2.7,0,3.8v15.4
	c0,1.8,1.7,3.1,3.4,2.6c1.3-0.3,2.8-0.5,4.1-0.5c2.1,0,4.4,0.4,6.2,1.3c0.8,0.4,1.7,0.4,2.5,0c1.8-0.9,4.1-1.3,6.2-1.3
	c1.4,0,2.8,0.1,4.1,0.5c1.7,0.4,3.4-0.9,3.4-2.6V3.8c0-1.1-0.7-2.1-1.7-2.5C26.5,0.5,24.4,0.2,22.5,0.2z M27.2,17.6
	c0,0.9-0.8,1.5-1.6,1.3c-1-0.2-2.1-0.3-3.1-0.3c-2.3,0-5.7,0.9-7.5,2V5c1.8-1.2,5.2-2,7.5-2c1.3,0,2.5,0.1,3.7,0.4
	c0.6,0.1,1.1,0.7,1.1,1.3V17.6z"
      />
      <path
        id="Path"
        // fill="#2A2A2A"
        d="M17.7,9.1c-0.4,0-0.8-0.3-1-0.7c-0.2-0.5,0.1-1.1,0.7-1.3c2.1-0.7,4.8-0.9,7.3-0.6
	c0.6,0.1,1,0.6,0.9,1.1c-0.1,0.6-0.6,1-1.1,0.9c-2.2-0.3-4.6-0.1-6.4,0.5C17.9,9.1,17.8,9.1,17.7,9.1z"
      />
      <path
        id="Path_1_"
        //  fill="#2A2A2A"
        d="M17.7,12.7c-0.4,0-0.8-0.3-1-0.7c-0.2-0.5,0.1-1.1,0.7-1.3c2.1-0.7,4.8-0.9,7.3-0.6
	c0.6,0.1,1,0.6,0.9,1.1c-0.1,0.6-0.6,1-1.1,0.9c-2.2-0.3-4.6-0.1-6.4,0.5C17.9,12.7,17.8,12.7,17.7,12.7z"
      />
      <path
        id="Path_2_"
        // fill="#2A2A2A"
        d="M17.7,16.3c-0.4,0-0.8-0.3-1-0.7c-0.2-0.5,0.1-1.1,0.7-1.3c2.1-0.7,4.8-0.9,7.3-0.6
	c0.6,0.1,1,0.6,0.9,1.1c-0.1,0.6-0.6,1-1.1,0.9c-2.2-0.3-4.6-0.1-6.4,0.5C17.9,16.3,17.8,16.3,17.7,16.3z"
      />
    </IconBase>
  )
}

export default CatLibro
