import React from 'react'
import { useTrans } from 'hooks'
import { Button, ImageBox } from 'react-components/atoms'

const TypLibriBox = props => {
  const { className = '' } = props

  const t = useTrans()

  return (
    <div className={`typ-libri-box ${className ? ` ${className}` : ''}`}>
      <div className="typ-libri-box__content">
        <p className="typ-libri-box__title">{t('Libri di testo')}</p>
        <p className="typ-libri-box__description">
          {t('Accedi ai tuoi libri di testo nell’area riservata')}
        </p>
        <div className="typ-libri-box__cta">
          <Button label={t('Accedi')} href="/area-personale/libreria/libri" />
        </div>
      </div>
      <ImageBox
        src="/images/typ-libri.png"
        maxWidth={261}
        maxHeight={261}
        classPrefix="typ-libri-box"
      />
    </div>
  )
}

export default TypLibriBox
