import React, { useEffect, useState } from 'react'
import { api } from 'utils'
import { FORMAZIONECORSI, GETMODULIUTENTE } from 'utils/endpoint'
import FormazioneModuloCard from 'react-components/molecules/FormazioneModuloCard/FormazioneModuloCard'
import EsploraAltroItem from 'react-components/organisms/EsploraAltroItem/EsploraAltroItem'
import { useSelector } from 'react-redux'

const TabCorsiIscritto = () => {
  const [moduliIscritto, setModuliIscritto] = useState([])
  const { utente } = useSelector((state) => state.utente)

  useEffect(() => {
    if (utente) {
      fetchModuliIscritto()
    }
  }, [utente])

  async function fetchModuliIscritto() {
    const response = await api.get(`${FORMAZIONECORSI}${GETMODULIUTENTE}`, {
      params: { in_corso: true },
    })
    const { data } = response
    setModuliIscritto(data)
  }

  return (
    <div className="tab-corsi-iscritto">
      <div className="tab-corsi-iscritto__list">
        {moduliIscritto.map((modulo) => (
          <div className="tab-corsi-iscritto__item">
            <FormazioneModuloCard item={modulo} areaPersonale={true} />
          </div>
        ))}
        <div className="tab-corsi-iscritto__item">
          <EsploraAltroItem />
        </div>
      </div>
    </div>
  )
}

export default TabCorsiIscritto
