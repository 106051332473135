import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function LogoSymbolLandingRivisteBg({ className }) {
  return (
    <IconBase name="logo-symbol-landing-riviste-bg" w={838} h={498} className={className}>
      <path
        d="M828.414 156.929C811.387 75.8973 720.909 23.5432 620.75 35.5479C520.591 47.5527 441.465 130.919 453.818 217.287C459.947 249.149 475.665 278.385 498.872 301.088C522.079 323.79 551.67 338.879 583.691 344.337C563.361 378.754 532.755 405.953 496.166 422.12C459.577 438.286 418.84 442.609 379.667 434.482C340.494 426.355 304.85 406.186 277.733 376.804C250.616 347.421 233.386 310.298 228.46 270.641V259.637C215.439 109.911 346.982 -29.1444 528.938 -53.4874C601.384 -63.7999 675.272 -53.2735 741.943 -23.142C677.595 -100.891 587.295 -152.81 487.665 -169.341C388.035 -185.872 285.779 -165.904 199.719 -113.112C113.658 -60.3197 49.5851 21.7438 19.2921 117.974C-11.0008 214.205 -5.47466 318.126 34.8535 410.61C75.1816 503.094 147.598 577.916 238.773 621.306C329.948 664.696 433.746 673.733 531.062 646.754C628.379 619.775 712.664 558.595 768.404 474.475C824.145 390.355 847.59 288.956 834.423 188.942C834.423 177.938 830.751 167.267 828.748 155.596"
        stroke="none"
      />
    </IconBase>
  )
}

export default LogoSymbolLandingRivisteBg
