import React from 'react'
import { WContainer } from 'react-components/atoms'
import { LandingRivisteBox } from 'react-components/molecules'

const LandingRivisteNovita = props => {
  const { item = undefined, className = '' } = props

  return item ? (
    <div className={`landing-riviste-novita ${className ? ` ${className}` : ''}`}>
      <WContainer>
        {!!item.name && <p className="landing-riviste-novita__title">{item.name}</p>}
        {item.children.length > 0 && (
          <div className="landing-riviste-novita__list">
            {item.children.map((x, i) => (
              <LandingRivisteBox key={x.pk} item={x} color={x.color} />
            ))}
          </div>
        )}
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default LandingRivisteNovita
