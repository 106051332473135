import React, { useState } from 'react'
import { useTrans } from 'hooks'
import { Edit } from 'react-components/atoms/Icons'
import { Button, SelectionButton } from 'react-components/atoms'

import { api } from 'utils'
import { ELEMENTIRACCOLTA } from 'utils/endpoint'
import StrumentoCard from 'components/card/StrumentoCard'
import ArticoloCard from 'components/card/ArticoloCard'
import LezioneCard from 'components/card/LezioneCard'
import RaccoltaCard from 'components/card/RaccoltaCard'

const AreaPersonaleRaccoltaContenutiEditForm = ({
  setContenutiRaccolteFromProps,
  contenuti,
  raccoltaId,
}) => {
  const t = useTrans()
  const [selectionActive, setSelectionActive] = useState(false)
  const [selected, setSelected] = useState([])

  const handleSelected = id => {
    selected?.includes(id)
      ? setSelected(prev => prev?.filter(item => item !== id))
      : setSelected(prev => [...prev, id])
  }

  const onClose = () => {
    setSelected([])
    setSelectionActive(false)
  }

  const _handleSubmit = async () => {
    await api
      .delete(`${ELEMENTIRACCOLTA}bulk_delete/`, { data: { ids: selected } })
      .catch(err => console.log(err))
    setContenutiRaccolteFromProps?.([...contenuti?.filter(i => !selected?.includes(i?.id))])
    onClose()
  }

  return (
    <div
      className={`area-personale-raccolta-contenuti-edit-form${
        selectionActive ? ' area-personale-raccolta-contenuti-edit-form--selection-active' : ''
      }`}
    >
      <div className="area-personale-raccolta-contenuti-edit-form__head">
        <p className="area-personale-raccolta-contenuti-edit-form__title">
          {t('Modifica i contenuti della raccolta')}
        </p>
        <div className="area-personale-raccolta-contenuti-edit-form__actions">
          {selectionActive ? (
            <>
              <Button
                label={t('Annulla')}
                variant="ghost"
                onClick={() => setSelectionActive(false)}
              />
              <Button label={t('Elimina contenuti')} onClick={() => _handleSubmit()} />
            </>
          ) : (
            <Button
              label={t('Seleziona contenuti')}
              variant="ghost"
              icon={<Edit />}
              iconPos="right"
              onClick={() => setSelectionActive(true)}
            />
          )}
        </div>
      </div>
      <div className="area-personale-raccolta-contenuti-edit-form__list">
        {contenuti.map(item => (
          <div className="area-personale-raccolta-contenuti-edit-form__item">
            <SelectionButton
              visible={selectionActive}
              selected={selected?.includes(item?.id)}
              setSelected={() => handleSelected(item.id)}
            />
            <div className="area-personale-raccolta-contenuti-edit-form__item__card">
              {renderCard(item?.elemento, item?.id)}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AreaPersonaleRaccoltaContenutiEditForm

export const renderCard = (item, id) => {
  switch (item?.model_class) {
    case 'strumento':
      return <StrumentoCard strumento={item} horizontal />
    case 'articolo':
      return <ArticoloCard articolo={item} horizontal />
    case 'lezione':
      return <LezioneCard articolo={item} horizontal />
    default:
      return <RaccoltaCard raccolta={item} horizontal />
  }
}

export const renderCardWithProps = (item, props) => {
  if (!(item && item?.model_class)) return null

  switch (item?.model_class) {
    case 'strumento':
      return <StrumentoCard strumento={item} {...props} />
    case 'articolo':
      return <ArticoloCard articolo={item} {...props} />
    case 'lezione':
      return <LezioneCard articolo={item} {...props} />
    default:
      return <RaccoltaCard raccolta={item} {...props} />
  }
}
