import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Profilo({ className }) {
  return (
    <IconBase name="profilo" w={20} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.167 5.83334C14.167 8.13542 12.3024 10 10.0003 10C7.69824 10 5.83366 8.13542 5.83366 5.83334C5.83366 3.53126 7.69824 1.66667 10.0003 1.66667C12.3024 1.66667 14.167 3.53126 14.167 5.83334ZM1.66699 16.25C1.66699 13.4792 7.21908 12.0833 10.0003 12.0833C12.7816 12.0833 18.3337 13.4792 18.3337 16.25V17.2917C18.3337 17.8646 17.8649 18.3333 17.292 18.3333H2.70866C2.13574 18.3333 1.66699 17.8646 1.66699 17.2917V16.25Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default Profilo
