import React, { useState } from 'react'
import { ChevronRight, ChevronLeft } from 'react-components/atoms/Icons'

const VoceMenuSecondoLivelloMobile = (props) => {
  const {
    label = '',
    children = null,
    initOpen = false,
    className = '',
    onClick = () => {},
  } = props

  const [open, setOpen] = useState(initOpen)

  return (
    <div
      className={`voce-menu-secondo-livello-mobile ${className ? ` ${className}` : ''} ${
        open ? 'voce-menu-secondo-livello-mobile--open' : ''
      }`}
    >
      <div
        className="voce-menu-secondo-livello-mobile__trigger"
        onClick={() => {
          onClick()
          setOpen(!open)
        }}
      >
        <span className="voce-menu-secondo-livello-mobile__trigger__label">{label}</span>
        <span className="voce-menu-secondo-livello-mobile__trigger__arrow">
          {open ? <ChevronRight /> : <ChevronLeft />}
        </span>
      </div>
      {children}
    </div>
  )
}

export default VoceMenuSecondoLivelloMobile
