import React from 'react'
import Link from 'next/link'
import { Blank } from '../Icons'

const VoceMenuTerzoLivelloDesktop = (props) => {
  const { label = '', url, className = '', onClick = () => {}, blank = false } = props

  return (
    <Link legacyBehavior href={url}>
      <a
        className={`terzo-desktop ${className}`}
        onClick={onClick}
        target={blank ? '_blank' : null}
        rel={blank ? 'nofollow' : null}
      >
        {label}
        {blank && <Blank />}
      </a>
    </Link>
  )
}

export default VoceMenuTerzoLivelloDesktop
