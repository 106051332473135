import React, { useState } from 'react'
import { CAS_DOMAIN } from 'utils'
import { ArrowDown2, ArrowUp2 } from 'react-components/atoms/Icons'
import { Image } from 'react-components/atoms'

const BundlePanelItem = (props) => {
  const { item, className = '' } = props

  const [open, setOpen] = useState(false)

  return (
    <div
      className={`bundle-panel-item ${className ? ` ${className}` : ''} ${
        open ? 'bundle-panel-item--open' : ''
      }`}
    >
      <div className="bundle-panel-item__top" onClick={() => setOpen(!open)}>
        <div className="box-bundle-item__modal__image">
          {item.mainImage && (
            <Image
              src={CAS_DOMAIN + item.mainImage}
              alt={`${item.titolo}`}
              title={item.titolo.toAltTitle()}
              width={60}
              height={60}
            />
          )}
        </div>
        <p className="bundle-panel-item__title">{item.titolo}</p>
        {open ? <ArrowUp2 /> : <ArrowDown2 />}
      </div>
      <div className="bundle-panel-item__bottom">
        <div
          className="bundle-panel-item__description"
          dangerouslySetInnerHTML={{ __html: item.descrizione }}
        />
      </div>
    </div>
  )
}

export default BundlePanelItem
