import React from 'react'
import { useTrans } from 'hooks'
import { WImage, Button } from 'react-components/atoms'
import { sendGTMData } from '../../../utils/gtm'

const FormazioneCorsoCard = (props) => {
  const { item = undefined, className = '', imageInside = false, inDetail = false } = props
  const t = useTrans()

  const handleGTM = () => {
    sendGTMData({
      event: 'GAevent',
      eventID: '32',
      eventCategory: 'formazione',
      eventAction: item.tag.toGTMFormat(),
      eventLabel: item.titolo.toGTMFormat(),
    })
  }

  return item ? (
    <div
      className={`formazione-corso-card ${className ? ` ${className}` : ''} ${
        imageInside ? `formazione-corso-card--image-inside` : ''
      }`}
    >
      <div
        className="formazione-corso-card__bg"
        style={{ backgroundColor: inDetail ? '' : item.theme_color || '#ED740B' }}
      >
        {!imageInside ? (
          <div
            className={`formazione-corso-card__image-box ${
              item.imageMobile ? ' formazione-corso-card__image-box--desktop' : ''
            }`}
          >
            <WImage
              src={item.main_image}
              maxWidth={767}
              maxHeight={324}
              alt={item.titolo.toAltTitle()}
              title={item.titolo.toAltTitle()}
            />
          </div>
        ) : (
          <></>
        )}
        <div
          className={`formazione-corso-card__main ${
            imageInside ? `formazione-corso-card__main--image-inside` : ''
          }`}
        >
          {imageInside ? (
            <div
              className={`formazione-corso-card__image-box ${
                item.imageMobile ? ' formazione-corso-card__image-box--desktop' : ''
              } ${imageInside ? `formazione-corso-card__image-box--image-inside` : ''} ${
                inDetail ? ' formazione-corso-card__image-box--detail' : ''
              }`}
            >
              <WImage
                src={item.main_image}
                maxWidth={767}
                maxHeight={324}
                alt={item.titolo.toAltTitle()}
                title={item.titolo.toAltTitle()}
              />
            </div>
          ) : (
            <></>
          )}
          <div>
            <div className="formazione-corso-card__tags">
              {!!item.tag && (
                <span className="formazione-corso-card__tag formazione-corso-card__tag--tipologia">
                  {item.tag}
                </span>
              )}
              {item.numero_moduli != null ? (
                <span className="formazione-corso-card__tag formazione-corso-card__tag--num-moduli">
                  {t(item.numero_moduli === 1 ? '{0} disponibile' : '{0} disponibili', [
                    item.numero_moduli,
                  ])}
                </span>
              ) : (
                <></>
              )}
            </div>
            {!!item.titolo ? (
              inDetail ? (
                <h1 className="formazione-corso-card__title formazione-corso-card__title--detail">
                  {item.titolo}
                </h1>
              ) : (
                <p className="formazione-corso-card__title">{item.titolo}</p>
              )
            ) : null}
            {!!item.descrizione && (
              <div
                className={`formazione-corso-card__description ${
                  inDetail
                    ? ' formazione-corso-card__description--detail'
                    : ' formazione-corso-card__description--listing'
                }`}
                dangerouslySetInnerHTML={{
                  __html: item.descrizione,
                }}
              />
            )}
            {!!item.slug && !imageInside && (
              <div className="formazione-corso-card__cta-box">
                <Button
                  label={t('Scopri il corso')}
                  variant="secondary"
                  href={'/formazione/corso/' + item.slug}
                  onClick={handleGTM}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default FormazioneCorsoCard
