import GenericMenuList from './GenericMenuList'
import { connect } from 'react-redux'
import React from 'react'

const GenericMenu = React.memo(props => {
  let classes = props.classes ? props.classes : ''
  let id = props.id ? props.id : 'main'

  const menu = props.menu.menu.find(k => k.key == id)

  if (!menu) return ''

  return (
    <div className={classes}>
      <GenericMenuList menu={menu} classes={classes} with_icons={props.with_icons} />
    </div>
  )
})

const mapStateToProps = state => {
  return {
    menu: state.menu,
  }
}

export default connect(mapStateToProps, null)(GenericMenu)
