import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function SaveSelected({ className }) {
  return (
    <IconBase name="save-selected" w={24} className={className}>
      <path
        d="M17.1427 3.19995H6.85695C5.72552 3.19995 4.81009 4.11195 4.81009 5.22662L4.7998 21.44L11.9998 18.4L19.1998 21.44V5.22662C19.1998 4.11195 18.2741 3.19995 17.1427 3.19995Z"
        // fill="#2A2A2A"
      />
    </IconBase>
  )
}

export default SaveSelected
