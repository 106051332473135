import { useState, useEffect } from 'react'

export default function useWindowDimensions() {
  function getWindowDimensions() {
    const width = window.innerWidth
    const height = window.innerHeight
    return {
      width,
      height,
    }
  }

  const [windowDimensions, setWindowDimensions] = useState({ width: 1920, height: 935 })

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    handleResize()

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}
