import React from 'react'
import { useTrans } from 'hooks'

const CheckoutIndirizzoCard = props => {
  const { indirizzo = undefined, fatturazione = false, className = '' } = props

  const t = useTrans()

  return indirizzo ? (
    <div className={`checkout-indirizzo-card ${className ? ` ${className}` : ''}`}>
      <p className="checkout-indirizzo-card__title">
        {t(fatturazione ? 'Indirizzo di fatturazione' : 'Indirizzo di spedizione')}
      </p>
      <div className="checkout-indirizzo-card__content">
        {indirizzo.privato ? (
          <p>
            {indirizzo.nome} {indirizzo.cognome}
          </p>
        ) : (
          <p>{indirizzo.ragioneSociale}</p>
        )}
        <p>
          {indirizzo.indirizzo}, {indirizzo.civico} - {indirizzo.cap} {indirizzo.citta}{' '}
          {!!indirizzo.provincia && <>({indirizzo.provincia})</>}
        </p>
        {fatturazione && (
          <p>
            {indirizzo.privato ? (
              <>
                {t('Codice Fiscale: ')}
                {indirizzo.cf}
              </>
            ) : (
              <>
                {t('P.IVA: ')}
                {indirizzo.piva}
              </>
            )}
          </p>
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default CheckoutIndirizzoCard
