import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function ArrowBack({ className }) {
  return (
    <IconBase name="arrow-back" w={24} className={className} fillNone>
      <path
        d="M11 5L4 12L11 19"
        stroke="#2A2A2A"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x="4" y="11" width="17" height="2" rx="1" fill="#2A2A2A" />
    </IconBase>
  )
}

export default ArrowBack
