import React from 'react'
import { Formik, Form, validateYupSchema, yupToFormErrors } from 'formik'
import * as yup from 'yup'
import { useScuolaUtils, useTrans } from 'hooks'
import { getFormFieldStatus } from 'components/forms-utils/utils'
import { Button, Modal, FormLayout, FormField, FormInput, FormSelect } from 'react-components/atoms'

import { optionsClassi } from 'components/registrazione/utils'
import { useRouter } from 'next/router'

const AreaPersonaleAddScuolaModal = ({ open, setOpen, ItaliaJSON }) => {
  const router = useRouter()
  const {
    ProvinciaItaliane,
    CittaItaliane,
    Cap,
    setCap,
    Scuole,
    getScuole,
    handleScuolaSubmit,
    checkEstero,
    checkNessunaDellePrecedenti,
  } = useScuolaUtils(false)
  const t = useTrans()

  const validationSchema = yup.object().shape({
    citta: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    cap: yup.string().nullable(),
    provincia: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    regione: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    classe: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    scuola: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    sezione: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
  })

  const validationSchemaEstero = yup.object().shape({
    scuola: yup.string().nullable(),
    citta: yup.string().nullable(),
    cap: yup.string().nullable(),
    provincia: yup.string().nullable(),
    regione: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    classe: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
    sezione: yup.string().required(t('Questo campo non può essere vuoto')).nullable(),
  })

  const getSchema = (values) => {
    return checkEstero(values) ? validationSchemaEstero : validationSchema
  }

  return (
    <Modal open={open} setOpen={setOpen} closeButton className="area-personale-add-scuola-modal">
      <Formik
        isInitialValid={false}
        initialValues={{
          nome: '',
          citta: '',
          cap: '',
          provincia: '',
          regione: '',
          classe: '',
          sezione: '',
          scuola: '',
          nuova: '',
        }}
        onSubmit={(values) => {
          handleScuolaSubmit(values, () => router.reload(), true)
        }}
        validate={(values) => {
          try {
            const schema = getSchema(values)
            validateYupSchema(values, schema, true, values)
          } catch (err) {
            return yupToFormErrors(err) //for rendering validation errors
          }

          return {}
        }}
      >
        {({ values, touched, errors, handleBlur, handleChange, setFieldValue, isValid }) => (
          <Form>
            <Modal.Content title={t('Aggiungi una scuola')}>
              <FormLayout>
                <FormField
                  label={`${t('Regione')}*`}
                  status={getFormFieldStatus({ touched, errors }, 'regione')}
                  errorMessage={errors?.regione}
                >
                  <FormSelect
                    value={values.regione || ''}
                    options={ItaliaJSON}
                    onBlur={handleBlur('regione')}
                    onChange={(selectedOption) => {
                      setFieldValue('provincia', null)
                      setFieldValue('citta', null)
                      setFieldValue('cap', null)
                      setFieldValue('scuola', null, true)
                      setFieldValue('scuola_id', null, true)
                      setFieldValue('regione', selectedOption.value, true)
                      setFieldValue('classe', null, true)
                    }}
                    status={getFormFieldStatus({ touched, errors }, 'regione')}
                    searchable
                  />
                </FormField>
                <FormField
                  label={`${t('Provincia')}${checkEstero(values) ? '' : '*'}`}
                  status={getFormFieldStatus({ touched, errors }, 'provincia')}
                  errorMessage={errors?.provincia}
                >
                  {checkEstero(values) ? (
                    <FormInput
                      value={values?.provincia}
                      onBlur={handleBlur('provincia')}
                      onChange={handleChange('provincia')}
                      status={getFormFieldStatus({ touched, errors }, 'provincia')}
                    />
                  ) : (
                    <FormSelect
                      value={values.provincia || ''}
                      options={values?.regione ? ProvinciaItaliane(values?.regione) : []}
                      onBlur={handleBlur('provincia')}
                      onChange={(selectedOption) => {
                        setFieldValue('citta', null)
                        setFieldValue('cap', null)
                        setFieldValue('scuola', null, true)
                        setFieldValue('scuola_id', null)

                        setFieldValue('provincia', selectedOption.value, true)
                      }}
                      status={getFormFieldStatus({ touched, errors }, 'provincia')}
                      searchable
                    />
                  )}
                </FormField>
                <FormField
                  label={`${t('Città')}${checkEstero(values) ? '' : '*'}`}
                  status={getFormFieldStatus({ touched, errors }, 'citta')}
                  errorMessage={errors?.citta}
                >
                  {checkEstero(values) ? (
                    <FormInput
                      value={values?.citta}
                      onBlur={handleBlur('citta')}
                      onChange={handleChange('citta')}
                      status={getFormFieldStatus({ touched, errors }, 'citta')}
                    />
                  ) : (
                    <FormSelect
                      options={values?.provincia ? CittaItaliane(values?.provincia) : []}
                      value={values.citta || ''}
                      onBlur={handleBlur('citta')}
                      onChange={(selectedOption) => {
                        getScuole(selectedOption?.value)
                        setCap(selectedOption?.cap?.flatMap((cp) => ({ label: cp, value: cp })))
                        setFieldValue('citta', selectedOption?.label, true)
                      }}
                      status={getFormFieldStatus({ touched, errors }, 'citta')}
                      searchable
                    />
                  )}
                </FormField>
                <FormField
                  label={`${t('CAP')}`}
                  status={getFormFieldStatus({ touched, errors }, 'cap')}
                  errorMessage={errors?.cap}
                >
                  {checkEstero(values) ? (
                    <FormInput
                      value={values?.cap}
                      onBlur={handleBlur('cap')}
                      onChange={handleChange('cap')}
                      status={getFormFieldStatus({ touched, errors }, 'cap')}
                    />
                  ) : (
                    <FormSelect
                      options={values?.citta ? Cap ?? [] : []}
                      value={values.cap || ''}
                      onBlur={handleBlur('cap')}
                      onChange={(selectedOption) => {
                        getScuole(null, selectedOption?.value)
                        setFieldValue('scuola', null, true)
                        setFieldValue('scuola_id', null)

                        setFieldValue('cap', selectedOption?.label, true)
                      }}
                      status={getFormFieldStatus({ touched, errors }, 'cap')}
                      searchable
                    />
                  )}
                </FormField>
                <FormField
                  label={`${t('Scuola di appartenenza')}${checkEstero(values) ? '' : '*'}`}
                  status={getFormFieldStatus({ touched, errors }, 'scuola')}
                  errorMessage={errors?.scuola}
                  helper={t('Seleziona una scuola corrispondente al cap inserito')}
                  layout="full"
                >
                  {checkEstero(values) ? (
                    <FormInput
                      value={values?.scuola}
                      onBlur={handleBlur('scuola')}
                      onChange={handleChange('scuola')}
                      status={getFormFieldStatus({ touched, errors }, 'scuola')}
                    />
                  ) : (
                    <FormSelect
                      value={values.scuola}
                      options={values?.citta ? Scuole ?? [] : []}
                      onBlur={handleBlur('scuola')}
                      onChange={(selectedOption) => {
                        setFieldValue('scuola', selectedOption.value, true)
                        setFieldValue('scuola_id', selectedOption?.scuola_id)
                      }}
                      status={getFormFieldStatus({ touched, errors }, 'scuola')}
                      searchable
                    />
                  )}
                </FormField>
                {checkNessunaDellePrecedenti(values) ? (
                  <FormField
                    label={`${t('Nome della scuola')}${checkEstero(values) ? '' : '*'}`}
                    status={getFormFieldStatus({ touched, errors }, 'nuova')}
                    errorMessage={errors?.nuova}
                    helper={t('Inserisci il nome della scuola')}
                    layout="full"
                  >
                    <FormInput
                      value={values?.nuova}
                      onBlur={handleBlur('nuova')}
                      onChange={handleChange('nuova')}
                      status={getFormFieldStatus({ touched, errors }, 'nuova')}
                    />
                  </FormField>
                ) : null}
                <FormField
                  label={`${t('Classe')}*`}
                  status={getFormFieldStatus({ touched, errors }, 'classe')}
                  errorMessage={errors?.classe}
                >
                  <FormSelect
                    value={values.classe}
                    options={optionsClassi}
                    onBlur={handleBlur('classe')}
                    onChange={(selectedOption) =>
                      setFieldValue('classe', selectedOption.value, true)
                    }
                    status={getFormFieldStatus({ touched, errors }, 'classe')}
                  />
                </FormField>
                <FormField
                  label={`${t('Sezione')}*`}
                  status={getFormFieldStatus({ touched, errors }, 'sezione')}
                  errorMessage={errors?.sezione}
                >
                  <FormInput
                    value={values.sezione}
                    onBlur={handleBlur('sezione')}
                    onChange={handleChange('sezione')}
                    status={getFormFieldStatus({ touched, errors }, 'sezione')}
                  />
                </FormField>
              </FormLayout>
            </Modal.Content>
            <Modal.Buttons>
              <Button disabled={!isValid} label={t('Aggiungi scuola')} type="submit" />
            </Modal.Buttons>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default AreaPersonaleAddScuolaModal
