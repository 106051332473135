import React, { useContext } from 'react'
import { Button } from 'react-components/atoms'
import { PlusCircle } from 'react-components/atoms/Icons'
import { Image } from 'react-components/atoms'
import { CartContext } from 'utils/context'
import { useTrans } from 'hooks'
import { CAS_DOMAIN, formatPrice } from 'utils'
import { IoPiu } from 'react-components/atoms/Icons'
import BannerInserto from 'react-components/molecules/BannerInserto/BannerInserto'

const AbbonamentoCard = (props) => {
  const { item = null, className = '' } = props

  const t = useTrans()
  const context = useContext(CartContext)

  const domain = process.env.NODE_ENV === 'production' ? '' : 'http://localhost:3000'
  const io = `${domain}/images/iopiu/io.png`
  return (
    <div className={`abbonamento-card ${className ? ` ${className}` : ''}`}>
      {item.label && <span className="abbonamento-card__label">{item.label}</span>}
      <div className="abbonamento-card__content">
        <div className="abbonamento-card__content-description">
          {item.rivista && item.rivista.codice === 'io-piu' ? (
            <>
              <div>
                <IoPiu />
              </div>
              <p className="abbonamento-card__title">{item.descrizione}</p>
            </>
          ) : (
            <>
              <div className="abbonamento-card__image-mobile">
                <Image
                  src={CAS_DOMAIN + item?.rivista?.main_image}
                  width={145}
                  height={40}
                  alt={item?.rivista?.titolo?.toAltTitle()}
                  title={item?.rivista?.titolo?.toAltTitle()}
                  //layout="responsive"
                />
              </div>
              <div className="abbonamento-card__info">
                <p className="abbonamento-card__title">
                  {item.titolo_card || item?.rivista?.titolo}
                </p>
                <p className="abbonamento-card__description">{item.descrizione}</p>
                {item.rivista?.slug == 'vita-scolastica' ? (
                  <div className="abbonamento-card__inserto abbonamento-card__inserto-desktop">
                    <BannerInserto
                      text={t('All’interno l’inserto')}
                      imageSrc={'/images/riviste/psicologia_scuola_logo.png'}
                      titolo={item?.rivista?.titolo}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {item.rivista?.slug == 'vita-scolastica' ? (
                  <div className="abbonamento-card__inserto abbonamento-card__inserto-mobile">
                    <BannerInserto
                      text={t('In ogni numero l’inserto “Psicologia e Scuola”')}
                      imageSrc={'/images/riviste/psicologia_scuola_logo.png'}
                      titolo={item?.rivista?.titolo}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}
        </div>

        <div className="abbonamento-card__price">
          {item.prodotto?.prezzo &&
            item.prodotto?.prezzoScontato &&
            item.prodotto?.prezzo !== item.prodotto?.prezzoScontato && (
              <span className="abbonamento-card__full-price">
                {formatPrice(item.prodotto.prezzo)}
              </span>
            )}
          {(item.prodotto?.prezzo || item.prodotto?.prezzoScontato) && (
            <span className="abbonamento-card__final-price">
              {formatPrice(item.prodotto?.prezzoScontato || item.prodotto?.prezzo)}
            </span>
          )}
        </div>
        <div className="abbonamento-card__cta">
          <Button
            variant="primary"
            colorVariant={item.rivista.codice === 'io-piu' ? 'orange' : ''}
            label={t('Abbonati ora')}
            onClick={() => {
              context.addAbbonamentoToCart(item.rivista.id, true, 'abbonamentocard', item)
            }}
          />
          <Button
            className="abbonamento-card__button"
            variant="ghost"
            icon={<PlusCircle />}
            label={t('Leggi di piu')}
            href={item.rivista.url}
          />
        </div>
      </div>

      {item.rivista.codice === 'io-piu' ? (
        <div className="abbonamento-card__image-desktop">
          <Image
            src={io}
            width={354}
            height={236}
            alt={item.rivista.titolo.toAltTitle()}
            title={item.rivista.titolo.toAltTitle()}
            //layout="responsive"
          />
        </div>
      ) : (
        <div className="abbonamento-card__image-desktop-no-io">
          <Image
            src={item?.rivista?.immagine_ultimo_numero}
            width={257}
            height={350}
            alt={item.rivista.titolo.toAltTitle()}
            title={item.rivista.titolo.toAltTitle()}
            //layout="responsive"
          />
        </div>
      )}
    </div>
  )
}

export default AbbonamentoCard
