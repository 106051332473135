import React from 'react'
import { Follow, Share } from 'react-components/organisms'

const Actions = props => {
  const { item = null, follow = false, share = false, labelGTM = '' } = props

  if (!item) return null
  if (!(follow || share)) return null

  return (
    <div className={`actions`}>
      {follow && <Follow item={item} variant="square" labelGTM={labelGTM} />}
      {share && <Share item={item} variant="square" labelGTM={labelGTM} />}
    </div>
  )
}

export default Actions
