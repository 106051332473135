import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Target({ className }) {
  return (
    <IconBase name="target" w={40} className={className}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0384 15.4578C17.5901 15.4578 18.1251 15.5378 18.6484 15.6611L20.6451 13.6644C19.5134 13.2028 18.2968 12.9578 17.0384 12.9578C14.4868 12.9578 12.0868 13.9511 10.2834 15.7561C8.47844 17.5611 7.48511 19.9594 7.48511 22.5111C7.48511 25.0644 8.47844 27.4628 10.2834 29.2661C12.0868 31.0711 14.4868 32.0661 17.0384 32.0661C19.5901 32.0661 21.9901 31.0711 23.7951 29.2661C26.5901 26.4694 27.2851 22.3594 25.8851 18.9061L23.8834 20.9078C24.4184 23.2028 23.8118 25.7128 22.0268 27.4994C20.6934 28.8311 18.9234 29.5661 17.0384 29.5661C15.1551 29.5661 13.3851 28.8311 12.0518 27.4994C10.7184 26.1661 9.98511 24.3944 9.98511 22.5111C9.98511 20.6278 10.7184 18.8561 12.0518 17.5244C13.3834 16.1911 15.1551 15.4578 17.0384 15.4578Z"
        fill="#2A2A2A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M29.2292 15.561C32.2825 20.9227 31.5392 27.876 26.9725 32.4444C21.4942 37.9227 12.5858 37.9227 7.10583 32.4444C1.62917 26.9677 1.62917 18.056 7.10583 12.5777C9.7575 9.92602 13.2875 8.46436 17.0392 8.46436C19.5208 8.46436 21.8975 9.11602 23.9942 10.316L25.8142 8.49436C23.2075 6.85602 20.1942 5.96436 17.0392 5.96436C12.6192 5.96436 8.4625 7.68436 5.3375 10.811C-1.1125 17.2627 -1.1125 27.761 5.3375 34.2127C8.56417 37.4377 12.8025 39.051 17.0392 39.051C21.2775 39.051 25.5142 37.4377 28.7392 34.2127C34.2875 28.6627 35.0492 20.1244 31.0525 13.7377L29.2292 15.561Z"
        fill="#2A2A2A"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M38.9736 5.11726H34.8869C34.6352 5.11726 34.4319 4.91392 34.4319 4.66392V0.575591C34.4319 0.0639243 33.8136 -0.192742 33.4519 0.168924L29.1636 4.45726C29.1052 4.51559 29.0636 4.58892 29.0436 4.66892L27.7069 10.0756L18.4152 19.3673C17.1502 18.8423 15.5902 19.0723 14.5952 20.0689C13.2469 21.4156 13.2469 23.6073 14.5952 24.9539C15.2686 25.6289 16.1552 25.9656 17.0386 25.9656C17.9236 25.9656 18.8069 25.6289 19.4819 24.9556C20.1336 24.3039 20.4936 23.4339 20.4936 22.5123C20.4936 22.0239 20.3886 21.5539 20.1952 21.1223L29.4752 11.8439L34.8802 10.5056C34.9602 10.4856 35.0336 10.4456 35.0919 10.3873L39.3802 6.09726C39.7419 5.73559 39.4869 5.11726 38.9736 5.11726Z"
        fill="#2A2A2A"
      />
    </IconBase>
  )
}

export default Target
