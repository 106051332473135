import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function ArrowLeftLong({ className }) {
  return (
    <IconBase name="arrow-left-long" w={24} className={className}>
      <path d="M7.99 11H20V13H7.99V16L4 12L7.99 8V11Z" stroke="none" />
    </IconBase>
  )
}

export default ArrowLeftLong
