import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function ChevronRight({ className }) {
  return (
    <IconBase name="chevron-right" w={25} h={24} className={className}>
      <path
        d="M7.87994 2.99006C8.36994 2.50006 9.15994 2.50006 9.64994 2.99006L17.9599 11.3001C18.3499 11.6901 18.3499 12.3201 17.9599 12.7101L9.64994 21.0201C9.15994 21.5101 8.36994 21.5101 7.87994 21.0201C7.38994 20.5301 7.38994 19.7401 7.87994 19.2501L15.1199 12.0001L7.86994 4.75006C7.38994 4.27006 7.38994 3.47006 7.87994 2.99006Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default ChevronRight
