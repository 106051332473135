import React from 'react'
import { useTrans } from 'hooks'

import { Time, Calendar, Profilo } from 'react-components/atoms/Icons'
const BadgeOreFormazione = (props) => {
  const { ore = '', version = null } = props
  const t = useTrans()
  return (
    <div className="badge-ore-formazione">
      <span
        className={`badge-ore-formazione__icon badge-ore-formazione__icon--${
          version ? version : ' white--white'
        }`}
      >
        <Time />
      </span>
      <span
        className="badge-ore-formazione__label"
        dangerouslySetInnerHTML={{
          __html:
            ore && ore > 0
              ? `Vale <strong>${ore?.toString()} ore di formazione</strong>`
              : t('Non rilascia attestato'),
        }}
      ></span>
    </div>
  )
}

export default BadgeOreFormazione
