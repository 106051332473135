import React from 'react'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'
import italia from '../utils/italia.json'
import { api } from 'utils'
import { REGISTRAZIONE, SCUOLE } from 'utils/endpoint'
import { sendGTMData } from 'utils/gtm.js'

export default function useScuolaUtils(includeOrdine) {
  const [Cap, setCap] = React.useState([])
  const [Scuole, setScuole] = React.useState([])

  const checkEstero = values => {
    return values?.regione === 'a' ? true : false
  }

  const checkNessunaDellePrecedenti = values => {
    return values?.scuola === 'a' ? true : false
  }

  const CittaItaliane = React.useCallback(
    provincia =>
      italia
        ?.map(item => ({
          cap: item?.cap,
          label: item?.nome,
          value: item?.nome,
          provincia: item?.provincia?.nome,
        }))
        ?.filter(ct => ct?.provincia === provincia)
        .flat(),
    []
  )

  const ItaliaJSON = React.useMemo(
    () =>
      uniqBy(
        [
          ...sortBy(
            italia?.map(item => ({
              ...item?.regione,
              label: item?.regione?.nome,
              value: item?.regione?.nome,
            })),
            ['label']
          ),
          { label: 'Scuola estera', codice: 'a', value: 'a' },
        ],
        'codice'
      ),
    [italia]
  )

  const ProvinciaItaliane = React.useCallback(
    regione =>
      sortBy(
        uniqBy(
          italia
            ?.filter(prov => prov?.regione?.nome === regione)
            ?.flat()
            ?.flatMap(item => ({
              ...item?.provincia,
              label: item?.provincia?.nome,
              value: item?.provincia?.nome,
              sigla: item?.sigla,
            })),
          'codice'
        ),
        ['label']
      ),
    []
  )

  const ProvinciaItalianeAll = React.useCallback(
    () =>
      sortBy(
        uniqBy(
          italia?.flat()?.flatMap(item => ({
            ...item?.provincia,
            label: item?.provincia?.nome,
            value: item?.provincia?.nome,
            sigla: item?.sigla,
          })),
          'codice'
        ),
        ['label']
      ),
    []
  )

  const ProvinciaItalianeAllSigla = React.useCallback(
    () =>
      sortBy(
        uniqBy(
          italia?.flat()?.flatMap(item => ({
            label: item?.sigla,
            value: item?.sigla,
          })),
          'value'
        ),
        ['label']
      ),
    []
  )

  const handleScuolaSubmit = async (values, callBack, addScuola, id) => {
    const url = addScuola ? 'add_scuola/' : 'edit_scuola/'
    api
      .post(REGISTRAZIONE + url, {
        values,
        isEstero: checkEstero(values),
        ...(id && { id: id }),
      })
      .then(() => {
        sendGTMData({
          event: 'GAevent',
          eventID: '13',
          eventCategory: 'form',
          eventAction: 'imposta_scuola',
        })
        callBack?.()
      })
      .catch(error => console.log(error))
  }

  const getScuole = (selectedComune, selectedCap) => {
    const params = {
      ...(selectedComune && { comune: selectedComune }),
      ...(selectedCap && { cap_scuola: selectedCap }),
    }
    api
      .get(SCUOLE, { params })
      .then(response => {
        const scuole = response.data
          .sort((a, b) => {
            if (a.nome_scuola < b.nome_scuola) {
              return -1
            }
            if (a.nome_scuola > b.nome_scuola) {
              return 1
            }
            return 0
          })
          .map(({ id, nome_scuola, tipologia_grado_istruzione }) => ({
            scuola_id: id,
            value: nome_scuola,
            label: includeOrdine ? nome_scuola : `${nome_scuola} (${tipologia_grado_istruzione})`,
            ordine: tipologia_grado_istruzione,
          }))

        setScuole([...scuole, { value: 'a', label: 'Nessuna delle precedenti' }])
      })
      .catch(error => {
        console.log(error)
      })
  }

  return {
    ProvinciaItaliane,
    ProvinciaItalianeAll,
    ProvinciaItalianeAllSigla,
    CittaItaliane,
    ItaliaJSON,
    Cap,
    setCap,
    getScuole,
    Scuole,
    handleScuolaSubmit,
    checkEstero,
    checkNessunaDellePrecedenti,
  }
}
