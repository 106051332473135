import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function Lock({ className }) {
  return (
    <IconBase name="lock" w={16} className={className}>
      <path
        d="M7.99984 11.3332C8.73317 11.3332 9.33317 10.7332 9.33317 9.99984C9.33317 9.2665 8.73317 8.6665 7.99984 8.6665C7.2665 8.6665 6.6665 9.2665 6.6665 9.99984C6.6665 10.7332 7.2665 11.3332 7.99984 11.3332ZM11.9998 5.33317H11.3332V3.99984C11.3332 2.15984 9.83984 0.666504 7.99984 0.666504C6.15984 0.666504 4.6665 2.15984 4.6665 3.99984V5.33317H3.99984C3.2665 5.33317 2.6665 5.93317 2.6665 6.6665V13.3332C2.6665 14.0665 3.2665 14.6665 3.99984 14.6665H11.9998C12.7332 14.6665 13.3332 14.0665 13.3332 13.3332V6.6665C13.3332 5.93317 12.7332 5.33317 11.9998 5.33317ZM5.93317 3.99984C5.93317 2.85984 6.85984 1.93317 7.99984 1.93317C9.13984 1.93317 10.0665 2.85984 10.0665 3.99984V5.33317H5.93317V3.99984ZM11.9998 13.3332H3.99984V6.6665H11.9998V13.3332Z"
        //  fill="#BBBBBB"
      />
    </IconBase>
  )
}

export default Lock
