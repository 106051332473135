import React from 'react'
import { Button } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { sendGTMData } from '../../../utils/gtm'

const BoxAttivaLibro = props => {
  const {
    libro = {
      titolo: '',
    },
    className = '',
    adozionale = false,
  } = props

  if (!libro.box_attiva_libro) return null

  const t = useTrans()

  const handleGTM = () => {
    sendGTMData({
      event: 'GAevent',
      eventID: '27',
      eventCategory: 'card_volumi',
      eventAction: 'attiva',
      eventLabel: libro.titolo.toGTMFormat(),
    })
  }

  return (
    <div className={`box-attiva-libro ${className}`}>
      <div className="box-attiva-libro__content">
        <p className="box-attiva-libro__title">
          {t('Sei già in possesso di un volume')} "{libro.titolo}"?
        </p>
        <p className="box-attiva-libro__description">
          {adozionale
            ? t(
                "Per attivare il libro, nell'area personale, completa il tuo profilo con i dati della scuola."
              )
            : t(
                'Attivando il libro tramite il codice ISBN, che trovi nella quarta di copertina del volume, sbloccherai tanti materiali extra.'
              )}
        </p>
      </div>
      <Button
        className="box-attiva-libro__cta"
        label="Attiva libro"
        href={adozionale ? '/area-personale' : '/area-personale/libreria'}
        variant="primary"
        inverted
        onClick={() => {
          handleGTM()
        }}
      />
    </div>
  )
}

export default BoxAttivaLibro
