import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function ArrowRight({ className }) {
  return (
    <IconBase name="arrow-right" w={18} h={12} className={className}>
      <path d="M1.00002 5.05621H14.17L11.29 2.28387C10.9 1.90845 10.9 1.30201 11.29 0.926585C11.68 0.551164 12.31 0.551164 12.7 0.926585L17.29 5.345C17.68 5.72042 17.68 6.32687 17.29 6.70229L12.7 11.1207C12.31 11.4961 11.68 11.4961 11.29 11.1207C10.9 10.7453 10.9 10.1388 11.29 9.76341L14.17 6.98145H1.00002C0.45002 6.98145 1.90735e-05 6.54827 1.90735e-05 6.01883C1.90735e-05 5.48939 0.45002 5.05621 1.00002 5.05621Z" />
    </IconBase>
  )
}

export default ArrowRight
