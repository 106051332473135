import TagManager from 'react-gtm-module'
import { getTipoStrumento } from '.'
import { getTipoParascolastico } from 'utils/index'

const GTM_PAGE_TYPES = [
  'homepage',
  'azienda',
  'contatti',
  'registrazione',
  'temi',
  'didattica',
  'nido_infanzia',
  'scuola_infanzia',
  'scuola_primaria',
  'scuola_secondaria',
  'psicopedagogia',
  'formazione',
  'adozionali',
  'parascolastico',
  'riviste',
  'area_personale',
]

const GTM_PAGE_TYPES_MAP = {
  discipline: 'scuola_primaria',
  area: 'area_personale',
  nido: 'nido_infanzia',
  scuolaprimaria: 'scuola_primaria',
  scuolasecondaria: 'scuola_secondaria',
  scuoladellinfanzia: 'scuola_infanzia',
  corsoadozionale: 'adozionali',
  trova: 'azienda',
  autori: 'autori',
  iopiu: 'io_plus',
  ricerca: 'search',
  checkout: 'checkout',
  thank_you_page: 'thank_you_page',
  cookie: 'azienda',
  privacy: 'azienda',
  chi: 'azienda',
  condizioni: 'azienda',
  sostenibilita: 'azienda',
  formazionelarchivio: 'formazione',
  formazione_corsi_all: 'formazione',
}

// match con urls.json
const GTM_PAGE_CAT_MAP = {
  'homepage': 'homepage',
  'contatti': 'contatti',
  'contatti-form-success': 'contatti',
  'faq-pagina-detail': 'contatti',
  'registrazione-index': 'registrazione',
  'registrazione-typ': 'registrazione',
  'discipline-all': 'pagina_di_categoria',
  'temi-principali': 'pagina_di_categoria',
  'temi-all': 'pagina_di_categoria',
  'nido': 'pagina_di_categoria',
  'scuoladellinfanzia': 'pagina_di_categoria',
  'scuolaprimaria': 'pagina_di_categoria',
  'scuolasecondaria': 'pagina_di_categoria',
  'psicopedagogia': 'pagina_di_categoria',
  'formazione': 'pagina_elenco',
  'formazione-corsi-all': 'pagina_di_categoria',
  'formazione-corsi-all-filtered': 'pagina_di_categoria',
  'parascolastico-all': 'pagina_elenco',
  'corsoadozionale-all': 'pagina_elenco',
  'discipline-detail': 'pagina_di_categoria',
  'temi-detail': 'pagina_di_categoria',
  'parascolastico-detail': 'pagina_dettaglio',
  'corsoadozionale-detail': 'pagina_dettaglio',
  'strumenti-detail': 'pagina_di_dettaglio',
  'raccolte-detail': 'pagina_di_dettaglio',
  'autori-detail': 'autori',
  'autori-all': 'autori',
  'autori-contatta': 'autori',
  'autori-contatta-form-success': 'autori',
  'autori-faq': 'autori',
  'articoli-detail': 'pagina_di_dettaglio',
  'riviste-detail': 'pagina_di_dettaglio',
  'nido-strumenti': 'strumenti',
  'nido-articoli': 'lezioni_e_articoli',
  'nido-autori': 'autori',
  'nido-raccolte': 'raccolte',
  'scuoladellinfanzia-strumenti': 'strumenti',
  'scuoladellinfanzia-articoli': 'lezioni_e_articoli',
  'scuoladellinfanzia-autori': 'autori',
  'scuoladellinfanzia-raccolte': 'raccolte',
  'scuolaprimaria-strumenti': 'strumenti',
  'scuolaprimaria-articoli': 'lezioni_e_articoli',
  'scuolaprimaria-autori': 'autori',
  'scuolaprimaria-raccolte': 'raccolte',
  'scuolasecondaria-strumenti': 'strumenti',
  'scuolasecondaria-articoli': 'lezioni_e_articoli',
  'scuolasecondaria-autori': 'autori',
  'scuolasecondaria-raccolte': 'raccolte',
  'psicopedagogia-strumenti': 'strumenti',
  'psicopedagogia-articoli': 'lezioni_e_articoli',
  'psicopedagogia-autori': 'autori',
  'psicopedagogia-raccolte': 'raccolte',
  'checkout': 'checkout',
  'checkout-success': 'thank_you_page',
  'area-personale': 'area_personale',
  'cookie-policy': 'azienda',
  'privacy-policy': 'azienda',
  'il-mondo-giunti': 'azienda',
  'chi-siamo': 'azienda',
  'condizioni-di-vendita': 'azienda',
  'sostenibilita': 'azienda',
  'formazionelarchivio-della-formazione': 'formazione',
}
const GTM_PAGE_DEFAULT = 'altro'
const GTM_CONTENT_DEFAULT = 'no_content'
const GTM_ID = 'GTM-K4GWSLX'
const getGTMProps = (pagina) => {
  let pageType = GTM_PAGE_DEFAULT
  let pageCat = GTM_PAGE_DEFAULT

  if (pagina.pagina) {
    pagina.page = pagina.pagina
  }

  if (pagina.disciplina) {
    pagina.item = pagina.disciplina
  }

  if (pagina.tema) {
    pagina.item = pagina.tema
  }

  if (pagina.autore) {
    pagina.item = pagina.autore
  }

  if (pagina.page && pagina.page.chiave) {
    pagina.page.chiave = pagina.page.chiave.replaceAll('/', '')
    let chiaveSplitType = pagina.page.chiave.split('-')[0].replaceAll('/', '')

    if (GTM_PAGE_TYPES.includes(chiaveSplitType)) {
      pageType = chiaveSplitType
    }
    if (GTM_PAGE_TYPES_MAP[chiaveSplitType]) {
      pageType = GTM_PAGE_TYPES_MAP[chiaveSplitType]
    }
    if (pagina.page.chiave == 'articoli-detail') {
      pageType = 'temi'
    }
    if (pagina.page.chiave == 'raccolte-detail') {
      pageType = 'raccolte'
    }
    if (pagina.page.chiave == 'strumenti-detail') {
      pageType = 'strumenti'
    }
    if (pagina.page.chiave == 'checkout-success') {
      pageType = 'thank_you_page'
    }
    if (pagina.page.chiave == 'il-mondo-giunti') {
      pageType = 'azienda'
    }
    if (pagina.page.chiave == 'formazione-corsi-all') {
      pageType = 'formazione'
    }
    if (GTM_PAGE_CAT_MAP[pagina.page.chiave]) {
      pageCat = GTM_PAGE_CAT_MAP[pagina.page.chiave]
    } else if (pagina.page.chiave.startsWith('area-personale')) {
      pageCat = 'area_personale'
    } else if (pagina.page.chiave.startsWith('formazione')) {
      pageCat =
        pagina.corsoFormazione?.titolo?.toGTMFormat() ||
        pagina.modulo?.nome_corso?.toGTMFormat() ||
        ''
    }
  }

  let contentType = GTM_CONTENT_DEFAULT
  if (pagina.item && pagina.item.model_class && pagina.item.model_class == 'strumento') {
    contentType = getTipoStrumento(pagina.item).split(' ')[0]
    if (contentType == 'scheda') {
      contentType = 'scheda_didattica'
    }
  } else if (pagina.item && pagina.item.tipologia) {
    contentType = pagina.item.tipologia
  } else if (pagina.item && pagina.item.model_class) {
    contentType = pagina.item.model_class
  }

  if (pagina.page?.chiave == 'temi-detail' || pagina.page?.chiave == 'discipline-detail') {
    contentType = GTM_CONTENT_DEFAULT
  }

  let contentMember = GTM_CONTENT_DEFAULT
  if (pagina.item && pagina.item.membership == 'member') {
    contentMember = 'io_plus'
  }

  if (pagina.item && pagina.item.membership == 'tutti') {
    contentMember = 'standard'
  }

  return {
    pageType,
    pageCat,
    contentType,
    contentMember,
  }
}

export const initTagManager = () => {
  TagManager.initialize({
    gtmId: GTM_ID,
    dataLayerName: 'dataLayer',
  })
}

export const updateGTMDataLayer = (paginaCorrente, utente) => {
  const utenteProps = {
    userType: 'utente_non_registrato',
    userID: 'utente_non_loggato',
    userRole: 'non_valorizzato',
    userSchool: 'non_valorizzato',
  }

  if (utente) {
    utenteProps.userType = 'utente_registrato'

    if (utente.id) {
      utenteProps.userID = utente.id
    }

    if (utente.is_adottatario) {
      utenteProps.userType = 'utente_adottatario'
    }

    if (utente.is_member) {
      utenteProps.userType = 'utente_membro_io_plus'
    }

    if (utente.is_adottatario && utente.is_member) {
      utenteProps.userType = 'utente_adottatario_membro_io_plus'
    }

    if (utente.ruolo) {
      utenteProps.userRole = utente.ruolo.toGTMFormat()
    }

    if (
      utente.utente_scuola &&
      utente.utente_scuola.length &&
      utente.utente_scuola[0].scuola &&
      utente.utente_scuola[0].scuola.tipologia_grado_istruzione
    ) {
      utenteProps.userSchool =
        utente.utente_scuola[0].scuola.tipologia_grado_istruzione.toGTMFormat()
    }
  }

  const gtmProps = getGTMProps(paginaCorrente)
  TagManager.dataLayer({
    dataLayer: {
      event: 'wSetup',
      ...gtmProps,
      ...utenteProps,
      mktCookie: document?.cookie
        ? document?.cookie
            .replace(/(?:(?:^|.*;\s*)CookieConsent\s*\=\s*([^;]*).*$)|^.*$/, '$1')
            .includes('marketing:true')
          ? 1
          : 0
        : 0,
    },
    dataLayerName: 'dataLayer',
  })

  initTagManager()

  TagManager.dataLayer({
    dataLayer: {
      event: 'virtual_pageview',
    },
    dataLayerName: 'dataLayer',
  })
}

export const sendGTMData = (data) => {
  TagManager.dataLayer({
    dataLayer: data,
    dataLayerName: 'dataLayer',
  })
}

export const getProductDetailsBase = (product) => {
  let prezzoScontato = product.prezzoScontato
    ? product.prezzoScontato.toFixed(2)
    : product.prodotto?.prezzoScontato
    ? product.prodotto.prezzoScontato.toFixed(2)
    : product.prezzo
    ? product.prezzo.toFixed(2)
    : product.prodotto?.prezzo
    ? product.prodotto.prezzo.toFixed(2)
    : parseFloat('0').toFixed(2)
  let subcategory = null
  let variant = null

  subcategory = product.sottocategoria ? product.sottocategoria.toGTMFormat() : null
  if (product.model_class == 'parascolastico') {
    subcategory = getTipoParascolastico(product.tassonomia).toGTMFormat()
  }
  if (product.model_class == 'corsoadozionale') {
    subcategory = product.tipologia ? product.tipologia.toGTMFormat() : null
  }
  variant = product.membership_index ? product.membership_index : null

  variant = variant == 'tutti' ? 'risorsa_libera' : variant == 'member' ? 'risorsa_io_plus' : null
  return {
    name: product.titolo?.toGTMFormat(),
    id: product.isbn?.toGTMFormat() || product.sku?.toGTMFormat() || 'nessun_sku',
    price: prezzoScontato,
    brand: 'giunti_scuola',
    category: product.model_class
      ? product.model_class.toGTMFormat()
      : product.tipologia
      ? product.tipologia.toGTMFormat()
      : null,
    variant:
      prezzoScontato != parseFloat('0').toFixed(2)
        ? 'prodotto_ecommerce'
        : variant
        ? variant
        : 'standard',
    dimension12: subcategory ? subcategory : 'nessuna_sottocategoria',
  }
}
export const getProductDetails = (product, discount = null, parent = null, quantity = 1) => {
  if (parent) {
    discount = (parent.absoluteDiscount / parent.quantita).toFixed(2)
  }
  let result = getProductDetailsBase(product)
  result.quantity = quantity
  if (discount) result.price = Math.abs(result.price - discount).toFixed(2)
  if (parent && parent.prezzoUnitarioScontato)
    result.price = parent.prezzoUnitarioScontato.toFixed(2)
  return result
}

export const sendProductClickGTM = (product, list_type, position) => {
  let product_details = getProductDetailsBase(product)
  product_details.position = position
  sendGTMData({
    event: 'productClick',
    ecommerce: {
      click: {
        actionField: {
          list: list_type,
        },
        products: [product_details],
      },
    },
  })
}

export const sendProductDetailGTM = (product, list_type) => {
  sendGTMData({
    event: 'productDetail',
    ecommerce: {
      click: {
        actionField: {
          list: list_type,
        },
        products: [getProductDetailsBase(product)],
      },
    },
  })
}

export const sendProductImpressionGTM = (list_of_product, list_type) => {
  if (list_of_product.length == 0) return
  let product_impressions = []
  list_of_product.map((product, index) => {
    let product_to_add = getProductDetailsBase(product)
    product_to_add.list = list_type
    product_to_add.position = index + 1
    product_impressions.push(product_to_add)
  })

  let dictionary = {
    event: 'productImpression',
    ecommerce: {
      currencyCode: 'eur',
      impressions: product_impressions,
    },
  }
  sendGTMData(dictionary)
}
