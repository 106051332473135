import React, { useState, useEffect } from 'react'
import { Widget } from 'react-components/molecules'
import { api } from 'utils'
import { AGENTI } from 'utils/endpoint'

const WidgetCol = props => {
  const { size = 1, widgets = [], className = '', bannerPropaganda = false, children } = props

  const isAgente = size === 2 && widgets[0]?.type === 'agente'

  const [agente, setAgente] = useState(null)

  useEffect(() => {
    if (isAgente) initAgente()
  }, [])

  async function initAgente() {
    const response = await api.get(`${AGENTI}mio/`)
    const { data } = response
    setAgente(data.agente)
  }

  if (isAgente && !agente) return null

  return (
    <div
      className={`widget-col widget-col--size-${size}${
        widgets.length > 0 || bannerPropaganda ? ' widget-col--stacked' : ''
      }${className ? ` ${className}` : ''}`}
    >
      {widgets.map((widget, i) => (
        <Widget widget={widget} key={i} />
      ))}
      {children}
    </div>
  )
}

export default WidgetCol
