import '../public/global.sass'

import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'

import { ApolloProvider } from '@apollo/client'
import { useApollo } from 'gql/client'
import Manutenzione from 'components/template/Manutenzione'
import App from 'next/app'
import Router, { useRouter } from 'next/router'
import NProgress from 'nprogress'
import { useEffect, useMemo } from 'react'
import { useSelector, useStore } from 'react-redux'
import { REDIRECTS, CHECK_REVALIDATE } from 'utils/endpoint'
import Layout from 'components/layout/Layout'
import { fetchMenu } from 'containers/menu'
import { setPaginaCorrente } from 'containers/pagina_corrente'
import { fetchEdit, setRoute } from 'containers/toolbar'
import { fetchTraduzioni } from 'containers/traduzioni'
import { fetchUtente } from 'containers/utente'
import { wrapper } from '../store'
import { api, isEmpty } from 'utils'
import { initTagManager, updateGTMDataLayer } from 'utils/gtm.js'
import Custom404 from './404'
import React from 'react'
import { CartContextProvider } from 'utils/context'

// if (typeof window !== 'undefined' && process.env.NODE_ENV !== 'production') {
//   const ReactDOM = require('react-dom')
//   const axe = require('@axe-core/react')
//   axe.default(React, ReactDOM, 1000)
// }

NProgress.configure({
  showSpinner: false,
  template: "<div class='nprogress__bar' role='bar'></div>",
})

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

const Greenboxshop = ({ Component, pageProps, err, cookies }) => {
  const router = useRouter()
  const store = useStore()

  const apolloStore = useApollo(pageProps, cookies)
  const { utente, inMaintenance, fetching } = useSelector((state) => state.utente)
  const { edit } = useSelector((state) => state.toolbar)
  const { paginaCorrente } = useSelector((state) => state.paginaCorrente)

  const layoutProps = {
    headerVariant: Component.headerVariant || 'default',
    footerVariant: Component.footerVariant || 'default',
    loginRequired: Component.loginRequired || false,
  }

  useEffect(() => {
    if (isEmpty(pageProps)) {
      let pagina = { pagina: { chiave: '404', titolo: '404' } }
      if (router.asPath.includes('/area-personale'))
        pagina = { pagina: { chiave: 'area-personale', titolo: 'area-personale' } }
      store.dispatch(setPaginaCorrente(pagina))
    } else {
      store.dispatch(setPaginaCorrente(pageProps))
    }
  }, [router.asPath])

  useEffect(() => {
    if (!isEmpty(paginaCorrente) && !fetching) {
      updateGTMDataLayer(paginaCorrente, utente)
    }
  }, [paginaCorrente, fetching])

  useEffect(() => {
    if (pageProps?.revalidate)
      handleCheckRevalidate(router.asPath.split('?')?.[0], pageProps.revalidate)
  }, [pageProps?.revalidate])

  const handleCheckRevalidate = async (path, revalidate) => {
    await api.get(CHECK_REVALIDATE, { params: { path, revalidate } })
  }

  const DefaultLayout = Component.Layout || Layout

  if (typeof window !== 'undefined') {
    document.body.classList.remove('w-noscroll')
  }

  const isNotFound = useMemo(
    () => pageProps.statusCode && pageProps.statusCode === 404,
    [pageProps]
  )

  const manutenzione = inMaintenance || 'false'

  if (JSON.parse(manutenzione)) {
    return <Manutenzione /> // controllo sugli header di UtenteCorrente
  }

  return (
    <ApolloProvider client={apolloStore}>
      <CartContextProvider>
        <DefaultLayout {...pageProps} {...layoutProps}>
          {isNotFound ? <Custom404 /> : <Component {...pageProps} err={err} key={edit} />}
        </DefaultLayout>
      </CartContextProvider>
    </ApolloProvider>
  )
}

Greenboxshop.getInitialProps = wrapper.getInitialAppProps((store) => async (app) => {
  const isAnteprima = app.ctx.query ? Object.keys(app.ctx.query).includes('anteprima') : false
  const headers = app.ctx.req?.headers?.cookie ? { cookie: app.ctx.req.headers.cookie } : undefined

  const {
    ctx: { req, res },
  } = app

  if (res) {
    const route = app.ctx.req.url.split('?')[0]

    const response = await api.get(REDIRECTS, { params: { old_path: route } })
    const redirects = await response.data

    if (redirects.length) {
      res.writeHead(301, {
        Location: redirects[0].new_path,
      })
      res.end()
    }
  }

  if (req && isAnteprima) {
    req['headers']['anteprima'] = true
  }

  await store.dispatch(fetchUtente(headers))

  const utente = store?.getState()?.utente?.utente
  const isStaff = utente?.is_staff
  const isCachable = !isStaff

  await store.dispatch(fetchTraduzioni(isCachable))
  await store.dispatch(fetchMenu(isCachable))

  if (isStaff) {
    await store.dispatch(fetchEdit(headers))
    await store.dispatch(setRoute(app.ctx.asPath))
  }

  if (res && isStaff) {
    res.setHeader('Cache-Control', 'max-age=0, no-cache, no-store, must-revalidate')
  }

  const appProps = await App.getInitialProps(app)

  return { ...appProps, cookies: headers?.cookie }
})

export default wrapper.withRedux(Greenboxshop)
