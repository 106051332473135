import React from 'react'
import { Image } from 'react-components/atoms'
import { useTrans } from 'hooks'
import { CAS_DOMAIN } from 'utils'
import { DBookEasy, ArrowRightLong, ChevronRight } from 'react-components/atoms/Icons'
import { Badge, Button } from 'react-components/atoms'

const LibroCard = props => {
  const {
    libro = {
      main_image: '',
      titolo: '',
      descrizione: '',
      nuovo: false,
    },
    type = '',
    layout = '',
    className = '',
  } = props

  const t = useTrans()

  const dbookeasy = libro.link_dbook_easy_completo || libro.link_dbook_easy

  return (
    <div
      className={`libro-card${layout ? ` libro-card--${layout}` : ''}${
        className ? ` ${className}` : ''
      }`}
    >
      <div className="libro-card__image-box">
        <Image
          src={
            libro.main_image
              ? `${CAS_DOMAIN}${libro.main_image}`
              : 'https://via.placeholder.com/200x250'
          }
          width={200}
          height={250}
          alt={libro?.titolo?.toAltTitle()}
          title={libro?.titolo?.toAltTitle()}
        />
      </div>
      <div className="libro-card__content">
        <div className="libro-card__head">
          {libro.nuovo && <Badge label={t('Nuovi')} />}
          <p className="libro-card__title">{libro.titolo}</p>
        </div>
        <div className="libro-card__description">{libro.tipologia}</div>
        {!layout && (
          <>
            <div className="libro-card__logo">
              <DBookEasy />
            </div>
            <div className="libro-card__actions">
              {dbookeasy && (
                <Button
                  variant="ghost"
                  colorVariant="darkblue"
                  label={t('Sfoglia online')}
                  labelMobile={t('Vai al tuo dBookEasy online')}
                  icon={<ArrowRightLong />}
                  iconPos="right"
                  href={dbookeasy}
                  external
                  newPage
                />
              )}
              <Button
                variant="ghost"
                colorVariant="darkblue"
                label={t('Fruizione offline')}
                labelMobile={t('Scarica dBookEasy offline')}
                icon={<ArrowRightLong />}
                iconPos="right"
                href="/dbookeasy-libro-digitale-gs"
              />
            </div>
          </>
        )}
        <div className="libro-card__cta">
          {type == 'corsoadozionale' && !libro.has_risorse ? null : (
            <>
              <Button
                variant="secondary"
                size="sm"
                label={layout ? t('Contenuti sbloccati') : t('Guida e contenuti digitali')}
                className="libro-card__cta-guide libro-card__cta-guide--desktop"
                href={`/area-personale/libri/${libro.slug}?tipologia=${type}`}
                external
              />
              <Button
                label={layout ? t('Contenuti sbloccati') : t('Guida e contenuti digitali')}
                icon={<ChevronRight />}
                iconPos="right"
                className="libro-card__cta-guide libro-card__cta-guide--mobile"
                href={`/area-personale/libri/${libro.slug}?tipologia=${type}`}
                external
              />
            </>
          )}
          {layout && !('in_catalogo' in libro && !libro.in_catalogo) ? (
            <>
              <Button
                variant="secondary"
                size="sm"
                label={t('Tutti i contenuti')}
                className="libro-card__cta-guide libro-card__cta-guide--desktop libro-card__cta-guide--contenuti"
                href={libro.url}
                external
              />
              <Button
                label={t('Tutti i contenuti')}
                icon={<ChevronRight />}
                iconPos="right"
                className="libro-card__cta-guide libro-card__cta-guide--mobile libro-card__cta-guide--contenuti"
                href={libro.url}
                external
              />
            </>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default LibroCard
