import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function LandingRivisteLogoPs({ className }) {
  return (
    <IconBase name="landing-riviste-logo-ps" w={405} h={126} className={className}>
      <path
        d="M207.251 85.6011C205.811 83.347 203.694 81.5547 201.172 80.455C198.474 79.1979 194.806 78.5693 190.169 78.5693C185.701 78.5693 182.134 79.2507 179.468 80.6135C176.949 81.85 174.834 83.7179 173.351 86.015C171.901 88.3536 170.958 90.9442 170.577 93.6345C170.135 96.5112 169.914 99.4147 169.916 102.322C169.903 105.413 170.093 108.503 170.486 111.572C170.791 114.227 171.676 116.792 173.082 119.104C174.523 121.329 176.644 123.081 179.161 124.128C181.86 125.327 185.529 125.926 190.169 125.926C194.627 125.926 198.193 125.271 200.866 123.959C203.385 122.782 205.505 120.954 206.983 118.685C208.439 116.366 209.383 113.789 209.756 111.112C210.199 108.235 210.42 105.331 210.417 102.424C210.431 99.3327 210.241 96.2434 209.848 93.1746C209.537 90.5076 208.653 87.9298 207.251 85.6011ZM194.394 109.42C194.333 110.876 194.112 112.322 193.733 113.733C193.547 114.582 193.066 115.347 192.368 115.905C191.718 116.315 190.949 116.521 190.169 116.493C189.405 116.523 188.65 116.317 188.019 115.905C187.34 115.332 186.864 114.573 186.654 113.733C186.241 112.327 186.002 110.879 185.939 109.42C185.821 107.608 185.762 105.277 185.762 102.429C185.762 99.5812 185.821 97.2372 185.939 95.3975C185.996 93.9252 186.232 92.4641 186.643 91.0436C186.854 90.2026 187.331 89.443 188.009 88.8666C188.641 88.457 189.394 88.251 190.159 88.2789C190.938 88.2535 191.706 88.4589 192.357 88.8666C193.054 89.4274 193.535 90.1936 193.722 91.0436C194.103 92.4689 194.324 93.9282 194.383 95.3975C194.502 97.2372 194.561 99.5812 194.561 102.429C194.561 105.277 194.505 107.608 194.394 109.42Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M150.013 109.63C150.013 111.524 149.721 112.887 149.136 113.718C148.552 114.549 147.562 114.973 146.164 114.991C144.756 114.991 143.758 114.573 143.17 113.739C142.579 112.9 142.289 111.531 142.289 109.65V79.7295H126.416V113.882C126.354 115.917 126.682 117.947 127.384 119.871C127.923 121.329 128.836 122.638 130.039 123.678C131.157 124.601 132.507 125.234 133.957 125.518C135.564 125.835 137.202 125.989 138.843 125.978C144.534 125.978 148.379 123.83 150.378 119.534H150.555V125.058H165.874V79.7295H150.013V109.63Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M100.352 91.1714C100.569 90.2874 101.079 89.4934 101.809 88.9076C102.467 88.4772 103.252 88.257 104.05 88.279C105.752 88.279 106.928 88.9212 107.576 90.2056C107.975 91.1015 108.241 92.0457 108.366 93.0111C108.564 94.4956 108.652 95.9915 108.63 97.4878H122.895C122.921 94.8856 122.641 92.2889 122.062 89.7457C121.574 87.587 120.58 85.5608 119.154 83.8177C117.68 82.107 115.77 80.7825 113.607 79.9697C111.285 79.043 108.364 78.5814 104.846 78.5848C102.034 78.567 99.2283 78.8481 96.482 79.4229C93.9412 79.9481 91.6029 81.1313 89.7255 82.8417C87.8156 84.5689 86.3052 87.0525 85.1944 90.2925C84.0835 93.5324 83.5263 97.8284 83.5227 103.181C83.5227 108.097 84.0208 112.045 85.017 115.026C86.0132 118.007 87.4071 120.324 89.1988 121.976C90.9781 123.618 93.2175 124.738 95.6488 125.201C98.4024 125.722 101.205 125.974 104.012 125.952C110.764 125.952 115.637 124.446 118.633 121.435C120.243 119.658 121.363 117.527 121.89 115.236C122.589 112.242 122.914 109.18 122.857 106.114H108.63V109.042C108.63 111.887 108.234 113.841 107.442 114.904C107.064 115.42 106.555 115.837 105.962 116.115C105.369 116.393 104.711 116.523 104.05 116.493C103.252 116.515 102.467 116.295 101.809 115.864C101.085 115.293 100.575 114.515 100.352 113.647C99.9374 112.239 99.6869 110.793 99.6049 109.333C99.4616 107.524 99.3881 105.223 99.3845 102.429C99.3809 99.6358 99.4544 97.3344 99.6049 95.5254C99.6909 94.053 99.9414 92.5935 100.352 91.1714Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M74.2884 99.874C72.5805 98.8405 70.7299 98.0374 68.7897 97.4875C66.8224 96.9322 64.9877 96.4025 63.2856 95.8982C61.7871 95.4865 60.344 94.9102 58.9856 94.1812C58.4447 93.9002 57.9974 93.4807 57.6944 92.9704C57.3913 92.4601 57.2446 91.8793 57.2709 91.2939C57.2429 90.807 57.3314 90.3205 57.5297 89.8708C57.728 89.4211 58.031 89.0201 58.4158 88.6978C59.2335 88.0658 60.2654 87.7388 61.3184 87.778C61.8898 87.7411 62.4631 87.815 63.0036 87.9951C63.5441 88.1751 64.0406 88.4577 64.4628 88.8256C65.1938 89.5257 65.5378 90.8697 65.5378 92.8014H79.2711C79.2711 83.3167 73.3137 78.5726 61.399 78.5692C58.5528 78.5176 55.7152 78.8848 52.987 79.6577C50.8212 80.2704 48.8094 81.2963 47.0745 82.6728C45.5663 83.8889 44.3758 85.4214 43.5968 87.1494C42.8215 88.9131 42.432 90.8083 42.4519 92.7196C42.4519 95.5678 43.0234 97.8691 44.1665 99.6236C45.245 101.315 46.7022 102.76 48.4343 103.855C50.1453 104.917 51.9959 105.761 53.9384 106.364C55.9057 106.977 57.7404 107.563 59.4425 108.122C60.9471 108.589 62.3831 109.237 63.7157 110.049C64.2617 110.377 64.7076 110.836 65.0085 111.379C65.3094 111.923 65.4549 112.532 65.4303 113.145C65.4605 113.689 65.3522 114.232 65.1145 114.728C64.8768 115.223 64.5168 115.657 64.065 115.992C63.145 116.674 62.0064 117.035 60.84 117.014C60.1854 117.049 59.5325 116.921 58.9461 116.642C58.3596 116.363 57.86 115.944 57.4967 115.425C57.0516 114.855 56.75 114.196 56.6152 113.498C56.4336 112.673 56.3453 111.832 56.3518 110.989H42.5056C42.5056 115.956 43.9569 119.704 46.8594 122.232C49.762 124.759 54.5332 126.015 61.1732 125.998C63.8985 126.028 66.614 125.684 69.2358 124.976C71.4383 124.396 73.4816 123.367 75.2237 121.961C76.8276 120.648 78.1116 119.02 78.9862 117.188C79.8871 115.258 80.3364 113.166 80.3031 111.055C80.3031 108.158 79.7333 105.822 78.5885 104.064C77.5073 102.376 76.037 100.943 74.2884 99.874Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M139.015 61.8742C141.6 61.8772 144.177 61.6116 146.701 61.0821C149.146 60.5737 151.425 59.5112 153.345 57.9853C155.418 56.2692 157.021 54.0997 158.016 51.6638C159.188 48.9861 159.773 45.5536 159.773 41.3666V36.9309H143.949C143.949 39.7756 143.876 42.1331 143.729 44.0035C143.643 45.5166 143.39 47.0169 142.976 48.4801C142.751 49.404 142.229 50.2383 141.482 50.8666C140.768 51.3619 139.898 51.6123 139.015 51.577C137.945 51.6305 136.893 51.3025 136.064 50.6571C135.347 50.0439 134.79 48.9554 134.392 47.3916C133.947 45.3097 133.697 43.1942 133.645 41.0702C133.527 38.4231 133.468 35.0895 133.468 31.0694C133.468 27.0493 133.527 23.7139 133.645 21.0634C133.697 18.9394 133.948 16.824 134.392 14.742C134.776 13.185 135.333 12.0965 136.064 11.4765C136.893 10.8311 137.945 10.5031 139.015 10.5566C139.714 10.5144 140.411 10.6664 141.021 10.9939C141.63 11.3214 142.127 11.8105 142.45 12.4015C143.166 13.6279 143.525 15.9429 143.525 19.3463V22.6987H159.37V18.9171C159.414 16.2733 159.052 13.6376 158.295 11.0932C157.619 8.87966 156.412 6.84531 154.769 5.14995C153.02 3.42104 150.862 2.11406 148.475 1.33767C145.426 0.378894 142.225 -0.0706399 139.015 0.0089972C134.79 0.0089972 131.239 0.554095 128.362 1.64429C125.526 2.69871 123.099 4.55348 121.406 6.959C119.643 9.41875 118.389 12.628 117.644 16.5868C116.851 21.3763 116.491 26.2215 116.569 31.0694C116.513 35.8421 116.822 40.6123 117.493 45.3424C118.106 49.2773 119.235 52.4866 120.879 54.9702C122.475 57.4298 124.892 59.31 127.743 60.3105C130.678 61.353 134.435 61.8742 139.015 61.8742Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M176.511 60.3107C179.378 61.3669 182.9 61.8966 187.079 61.9C191.257 61.9035 194.779 61.3737 197.646 60.3107C200.492 59.2743 202.925 57.4155 204.601 54.996C206.364 52.5124 207.619 49.3032 208.364 45.3682C209.193 40.6526 209.576 35.8758 209.509 31.0952C209.576 26.3146 209.193 21.5378 208.364 16.8221C207.601 12.8872 206.346 9.67795 204.601 7.19435C202.909 4.75259 200.486 2.84823 197.646 1.72634C194.772 0.58163 191.25 0.00927734 187.079 0.00927734C182.908 0.00927734 179.385 0.58163 176.511 1.72634C173.675 2.84 171.253 4.7333 169.556 7.16369C167.8 9.64729 166.546 12.8566 165.793 16.7915C164.964 21.5071 164.581 26.2839 164.649 31.0645C164.581 35.8452 164.964 40.6219 165.793 45.3376C166.549 49.2725 167.804 52.4818 169.556 54.9654C171.228 57.3963 173.661 59.2662 176.511 60.3107ZM181.709 21.0892C181.761 18.9653 182.011 16.8498 182.456 14.7678C182.84 13.2109 183.397 12.1224 184.128 11.5023C184.984 10.9022 186.02 10.5784 187.084 10.5784C188.148 10.5784 189.184 10.9022 190.04 11.5023C190.757 12.119 191.314 13.2075 191.712 14.7678C192.157 16.8498 192.407 18.9653 192.459 21.0892C192.577 23.7398 192.636 27.0751 192.636 31.0952C192.636 35.1153 192.577 38.4489 192.459 41.096C192.407 43.2201 192.157 45.3355 191.712 47.4175C191.329 48.9812 190.771 50.0697 190.04 50.6829C189.184 51.2831 188.148 51.6068 187.084 51.6068C186.02 51.6068 184.984 51.2831 184.128 50.6829C183.411 50.0697 182.854 48.9812 182.456 47.4175C182.011 45.3355 181.761 43.2201 181.709 41.096C181.591 38.4489 181.532 35.1153 181.532 31.0952C181.532 27.0751 181.591 23.7313 181.709 21.0637V21.0892Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path d="M111.382 10.9858H95.0042V60.9543H111.382V10.9858Z" fill="#2B3A4E" stroke="none" />
      <path
        d="M52.0035 58.3173C53.9567 59.6997 56.213 60.6447 58.6041 61.082C61.5755 61.6418 64.5999 61.9072 67.6288 61.8741C74.8529 61.8741 80.3713 60.4381 84.184 57.5661C87.9967 54.6941 89.9049 50.0744 89.9085 43.707C89.9085 40.0821 89.2491 37.1386 87.9304 34.8764C86.7086 32.7275 85.0104 30.8566 82.9531 29.3931C80.965 28.005 78.7972 26.8663 76.503 26.005C74.2133 25.1362 72.0632 24.2845 70.053 23.4498C68.2364 22.7193 66.5558 21.715 65.0757 20.4756C64.4221 19.8825 63.9109 19.1623 63.5769 18.3641C63.243 17.5659 63.0941 16.7087 63.1406 15.8508C63.1293 14.5438 63.5609 13.268 64.3715 12.2123C65.1957 11.1255 66.4875 10.5804 68.2469 10.577C68.9611 10.5435 69.6707 10.7027 70.2937 11.0364C70.9167 11.3701 71.4277 11.8646 71.7676 12.4627C72.5679 13.8605 72.963 15.4354 72.9125 17.0262V18.4059H88.2368C88.2368 11.9874 86.6368 7.3132 83.4369 4.3833C80.2369 1.45339 75.0554 -0.00474375 67.8922 0.0088837C60.9691 0.0088837 55.8037 1.59989 52.3959 4.7819C48.9881 7.96391 47.286 12.5955 47.2896 18.6768C47.2896 22.1927 47.922 25.0391 49.187 27.2161C50.3462 29.2832 51.9682 31.0834 53.9385 32.4899C55.8533 33.8405 57.9459 34.9469 60.1629 35.7809C62.3953 36.619 64.4647 37.4418 66.3711 38.2492C68.1208 38.9624 69.7295 39.9544 71.128 41.1825C71.7676 41.7879 72.2665 42.5141 72.5924 43.3143C72.9184 44.1144 73.064 44.9707 73.02 45.8278C73.0637 47.298 72.5825 48.739 71.6547 49.916C71.1895 50.4719 70.591 50.9138 69.9092 51.2045C69.2275 51.4952 68.4824 51.6264 67.7363 51.5871C67.0123 51.6122 66.2911 51.4882 65.6222 51.2236C64.9533 50.959 64.3525 50.56 63.8609 50.054C62.8611 49.0319 62.3613 47.2331 62.3613 44.6575V43.2062H46.4994V45.3014C46.4189 47.9823 46.8975 50.6518 47.9077 53.1559C48.7672 55.1914 50.1808 56.9728 52.0035 58.3173Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M16.9149 38.1831H22.634C29.3851 38.1831 34.5075 36.6347 38.0013 33.5378C41.4951 30.441 43.242 25.794 43.242 19.5969C43.2592 17.1327 42.897 14.6793 42.167 12.3148C41.5195 10.1764 40.3999 8.19377 38.8828 6.49925C37.3229 4.80962 35.3804 3.47823 33.2014 2.6052C30.6336 1.60385 27.8758 1.11842 25.1012 1.17943H0.537109V60.9699H16.9149V38.1831ZM16.9149 12.4016H19.9948C24.2196 12.4016 26.3338 14.829 26.3374 19.6838C26.3374 22.193 25.7999 24.0378 24.7249 25.208C23.6499 26.3783 22.0642 26.966 20.011 26.966H16.9149V12.4016Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M284.626 114.827V92.7306C284.626 89.77 284.171 87.3563 283.26 85.4893C282.423 83.6982 281.082 82.1615 279.385 81.0485C277.576 79.9074 275.537 79.138 273.397 78.7897C270.885 78.3543 268.335 78.1438 265.781 78.1611C263.797 78.1757 261.818 78.3587 259.868 78.7079C257.768 79.0637 255.76 79.803 253.955 80.8849C252.063 82.0468 250.493 83.6243 249.376 85.4842C248.143 87.4602 247.527 90.0971 247.527 93.3949H261.266C261.326 91.0101 261.708 89.4055 262.41 88.581C263.115 87.7736 264.227 87.3648 265.754 87.3648C268.456 87.3648 269.807 88.7599 269.807 91.5501C269.882 92.7146 269.499 93.8641 268.732 94.7747C268.015 95.531 266.813 96.1034 265.125 96.4918L258.874 97.9124C256.684 98.3589 254.555 99.0448 252.531 99.9566C250.98 100.653 249.6 101.651 248.478 102.885C247.462 104.074 246.728 105.458 246.328 106.942C245.857 108.75 245.635 110.609 245.667 112.472C245.648 114.279 245.885 116.08 246.371 117.827C246.8 119.366 247.582 120.796 248.661 122.013C249.762 123.215 251.174 124.124 252.757 124.649C254.728 125.281 256.8 125.579 258.879 125.528C261.167 125.563 263.426 125.035 265.437 123.995C267.34 122.997 268.819 121.4 269.618 119.478H269.796C269.914 120.428 270.027 121.318 270.145 122.156C270.26 122.983 270.467 123.797 270.763 124.583H286.088C285.398 123.276 284.978 121.855 284.851 120.398C284.677 118.546 284.601 116.687 284.626 114.827ZM269.833 108.215C269.833 110.729 269.452 112.603 268.689 113.836C267.925 115.069 266.662 115.683 264.899 115.676C264.35 115.69 263.806 115.575 263.315 115.341C262.823 115.108 262.4 114.763 262.083 114.337C261.324 113.288 260.947 112.032 261.008 110.76C260.953 109.51 261.193 108.265 261.712 107.116C262.267 106.118 263.17 105.334 264.265 104.898C265.289 104.441 266.374 104.117 267.49 103.937C267.911 103.899 268.319 103.779 268.689 103.583C269.059 103.388 269.383 103.123 269.64 102.803H269.817L269.833 108.215Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path d="M111.306 0.555664H94.9717V7.34213H111.306V0.555664Z" fill="#2B3A4E" stroke="none" />
      <path d="M349.793 0.555664H333.458V7.34213H349.793V0.555664Z" fill="#2B3A4E" stroke="none" />
      <path
        d="M230.687 109.094V0.00927734H214.352V109.094V124.624H214.857H240.797H241.265V109.094H240.797H230.687Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M34.454 86.7612C32.8719 84.5712 30.7712 82.7649 28.321 81.4874C25.5737 80.1021 22.4964 79.4155 19.3877 79.4944C16.8797 79.502 14.4042 80.0342 12.1367 81.053C9.7391 82.1166 7.61619 83.6674 5.91776 85.5961C4.03148 87.7648 2.57455 90.2407 1.61771 92.9038C0.485762 96.0784 -0.0597949 99.4152 0.00519153 102.767C-0.0151533 105.865 0.362096 108.954 1.12858 111.965C1.7861 114.627 2.95607 117.149 4.57937 119.406C6.11143 121.493 8.1778 123.174 10.5833 124.291C13.0308 125.443 15.9799 125.99 19.4307 125.932C24.494 125.932 28.7528 124.506 32.2072 121.654C35.6616 118.803 37.7041 114.779 38.3348 109.584H36.5664C35.8139 114.493 33.9003 118.169 30.8258 120.612C27.7513 123.055 23.8812 124.281 19.2157 124.291C16.048 124.291 13.3712 123.743 11.1853 122.646C9.03245 121.589 7.18641 120.045 5.81026 118.149C4.3664 116.111 3.32796 113.838 2.74647 111.444C2.06475 108.707 1.70421 105.906 1.67146 103.094H38.8132C38.9359 100.128 38.6303 97.1602 37.9048 94.2734C37.2459 91.5902 36.0764 89.0443 34.454 86.7612ZM1.66609 101.382C1.79564 98.5248 2.38477 95.7036 3.41298 93.0162C4.29589 90.701 5.5964 88.5497 7.25615 86.659C8.76817 84.952 10.6539 83.5795 12.7817 82.6372C14.9067 81.7082 17.2208 81.2353 19.5597 81.2523C22.1896 81.1987 24.7946 81.7473 27.1546 82.8519C29.3081 83.8927 31.1832 85.387 32.6372 87.2212C34.1547 89.1478 35.2862 91.3236 35.9751 93.6397C36.7326 96.1569 37.1108 98.7636 37.0985 101.382H1.66609Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path d="M349.798 10.9756H333.42V60.944H349.798V10.9756Z" fill="#2B3A4E" stroke="none" />
      <path
        d="M302.261 14.7421C302.644 13.1852 303.23 12.0967 304.019 11.4766C304.948 10.8212 306.089 10.4957 307.244 10.5568C308.095 10.4991 308.941 10.7273 309.633 11.2018C310.326 11.6762 310.821 12.3667 311.033 13.1528C311.617 14.6041 311.909 16.9208 311.909 20.1028H327.76C327.84 16.9582 327.335 13.825 326.266 10.8481C325.39 8.49978 323.972 6.36774 322.117 4.6084C320.338 2.97968 318.179 1.7748 315.817 1.09252C313.235 0.348192 310.549 -0.0171416 307.851 0.00913536C303.035 0.00913536 299.102 0.690508 296.053 2.05325C293.098 3.34139 290.603 5.42331 288.877 8.04252C287.139 10.6624 285.964 13.8989 285.351 17.7521C284.696 22.1616 284.387 26.6113 284.427 31.0644C284.427 36.0316 284.588 40.4401 284.91 44.2898C285.106 47.6283 285.895 50.9111 287.243 53.9994C288.34 56.4788 290.258 58.5508 292.704 59.9018C295.112 61.2168 298.4 61.8726 302.567 61.8692C303.966 61.8706 305.362 61.787 306.749 61.6188C308.071 61.4643 309.371 61.1695 310.625 60.7399C311.829 60.32 312.935 59.6813 313.882 58.8593C314.879 57.9771 315.648 56.8874 316.129 55.6756H316.301L316.655 60.9494H327.75V28.4735H306.615V39.0211H312.431C312.46 41.1347 312.327 43.2474 312.033 45.3425C311.862 46.6689 311.491 47.965 310.931 49.1906C310.587 49.978 309.986 50.6393 309.216 51.0763C308.494 51.4253 307.693 51.6008 306.884 51.5873C305.814 51.6409 304.761 51.3129 303.933 50.6675C303.196 50.0542 302.643 48.9657 302.261 47.402C301.808 45.3175 301.555 43.1983 301.503 41.0703C301.385 38.4232 301.328 35.0896 301.331 31.0695C301.335 27.0494 301.392 23.7141 301.503 21.0635C301.556 18.9391 301.809 16.8234 302.261 14.7421Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M258.013 61.8745C262.181 61.8745 265.703 61.3447 268.581 60.2852C271.427 59.2487 273.859 57.39 275.536 54.9705C277.292 52.4869 278.546 49.2776 279.299 45.3427C280.128 40.627 280.511 35.8503 280.444 31.0696C280.511 26.289 280.128 21.5122 279.299 16.7966C278.535 12.8617 277.281 9.6524 275.536 7.1688C273.84 4.73651 271.418 2.84137 268.581 1.72634C265.7 0.58163 262.177 0.00927734 258.013 0.00927734C253.85 0.00927734 250.327 0.58163 247.446 1.72634C244.609 2.83865 242.187 4.73224 240.491 7.16369C238.728 9.64729 237.465 12.8566 236.701 16.7915C235.872 21.5071 235.489 26.2839 235.556 31.0645C235.489 35.8452 235.872 40.6219 236.701 45.3376C237.465 49.2725 238.728 52.4818 240.491 54.9654C242.161 57.3972 244.595 59.2674 247.446 60.3107C250.32 61.3532 253.842 61.8745 258.013 61.8745ZM252.638 21.0637C252.692 18.9395 252.944 16.8241 253.391 14.7423C253.772 13.2092 254.326 12.0951 255.062 11.4768C255.919 10.8759 256.955 10.5516 258.019 10.5516C259.083 10.5516 260.119 10.8759 260.975 11.4768C261.711 12.0951 262.265 13.1836 262.652 14.7423C263.097 16.8243 263.347 18.9397 263.399 21.0637C263.517 23.7142 263.577 27.0495 263.577 31.0696C263.577 35.0897 263.517 38.4234 263.399 41.0705C263.348 43.1945 263.098 45.31 262.652 47.3919C262.265 48.9557 261.711 50.0442 260.975 50.6574C260.119 51.2583 259.083 51.5825 258.019 51.5825C256.955 51.5825 255.919 51.2583 255.062 50.6574C254.326 50.0442 253.772 48.9557 253.391 47.3919C252.943 45.3102 252.691 43.1947 252.638 41.0705C252.52 38.4234 252.463 35.0897 252.466 31.0696C252.47 27.0495 252.527 23.7142 252.638 21.0637Z"
        fill="#2B3A4E"
        stroke="none"
      />
      <path
        d="M389.703 1.18457H369.1L354.05 60.975H371.25L372.922 50.5091H385.892L387.569 60.975H405L389.703 1.18457ZM374.819 38.7708L379.313 13.4033H379.49L383.978 38.7708H374.819Z"
        fill="#2B3A4E"
        stroke="none"
      />
    </IconBase>
  )
}

export default LandingRivisteLogoPs
