import React from 'react'
import { useTrans } from 'hooks'
import { Bin } from 'react-components/atoms/Icons'
import { Button, ImageBox } from 'react-components/atoms'
import { AreaPersonaleRaccoltaForm } from 'react-components/organisms'
import { sendGTMData } from '../../../utils/gtm'

const AreaPersonaleRaccoltaEditForm = ({ raccolta, setRaccolta, raccoltaId }) => {
  const t = useTrans()
  const handleGTM = () => {
    sendGTMData({
      event: 'GAevent',
      eventID: '17',
      eventCategory: 'raccolta',
      eventAction: 'elimina_raccolta',
      eventLabel: raccolta.pubblica ? 'pubblica' : 'privata',
    })
  }
  return (
    <div className="area-personale-raccolta-edit-form">
      <div className="area-personale-raccolta-edit-form__sidebar">
        <ImageBox
          src={raccolta?.main_image || '/images/card/raccolta.jpg'}
          maxWidth={216}
          maxHeight={216}
          classPrefix="area-personale-raccolta-edit-form"
        />
      </div>
      <div className="area-personale-raccolta-edit-form__main">
        <p className="area-personale-raccolta-edit-form__title">
          {t('Modifica i dettagli della raccolta')}
        </p>
        <AreaPersonaleRaccoltaForm
          setRaccolta={setRaccolta}
          raccoltaId={raccoltaId}
          initialValues={{
            ...raccolta,
            pubblica: raccolta?.pubblica ? 'si' : 'no',
          }}
        />
        <div className="area-personale-raccolta-edit-form__actions">
          <Button
            label={t('Annulla modifiche')}
            variant="ghost"
            className="area-personale-raccolta-edit-form__action--cancel"
          />
          <Button
            label={t('Elimina questa raccolta')}
            variant="ghost"
            icon={<Bin />}
            iconPos="right"
            onClick={handleGTM}
            className="area-personale-raccolta-edit-form__action--delete"
          />
        </div>
      </div>
    </div>
  )
}

export default AreaPersonaleRaccoltaEditForm
