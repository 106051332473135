import { PAGEBUILDER } from './endpoint'
import axios from 'axios'

const http = require('http')
const https = require('https')

export const API_HOST = process.env.CLIENT_API_HOST
export const API_PREFIX = '/d/api/'
export const API_FULL_PATH = `${API_HOST}${API_PREFIX}`

export const api = axios.create({
  baseURL: API_FULL_PATH,
  httpAgent: new http.Agent({ keepAlive: true }), // test per pre-render in staging
  httpsAgent: new https.Agent({ keepAlive: true }), // test per pre-render in staging
  withCredentials: true,
})

export const toggle = (array, value) => {
  const index = array.indexOf(value)

  if (index === -1) {
    array.push(value)
  } else {
    array.splice(index, 1)
  }
}

String.prototype.capitalize = function () {
  return this.toLowerCase().charAt(0).toUpperCase() + this.slice(1)
}

String.prototype.toTitleCase = function () {
  return this.toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

String.prototype.toGTMFormat = function () {
  return this.toLowerCase()
    .replace(/[^a-z0-9\s]/gi, '')
    .replace(/[\s]/g, '_')
}

String.prototype.toAltTitle = function () {
  return this ? `${this} | ${BRAND}`.replace(/  +/g, ' ') : BRAND
}

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0 && obj.constructor === Object
}

export function slugify(Text) {
  return Text.toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')
}

export function getPlaceholderName(placeholder) {
  if (placeholder.includes('Eta')) {
    return 'Età'
  }
  return placeholder
}

export function getMenuName(label) {
  if (label.includes('Strumento')) {
    return 'Materiali'
  }
  if (label.includes('Utente')) {
    return 'Autori'
  }
  if (label.includes('Numerorivista')) {
    return 'Numeri riviste'
  }
  if (label.includes('Rivista')) {
    return 'Riviste'
  }
  if (label.includes('Paginafaq')) {
    return "FAQ & Domande all'esperto"
  }
  if (label.includes('Articolo')) {
    return 'Articoli'
  }
  if (label.includes('Lezione')) {
    return 'Lezioni'
  }
  if (label.includes('Pagina')) {
    return 'Pagine'
  }
  if (label.includes('Raccolta')) {
    return 'Raccolte'
  }
  if (label.includes('Parascolastico')) {
    return 'Parascolastici'
  }
  if (label.includes('Volume')) {
    return 'Volumi'
  }
  if (label.includes('Corsoformazione')) {
    return 'Corsi di formazione'
  }
  if (label.includes('Modulo')) {
    return 'Moduli corsi di formazione'
  }
  if (label.includes('Tutti')) {
    return 'Free'
  }
  return label
}

export function checkIconTipoStrumento(tipo) {
  let iconclass = {}
  tipo = tipo.toLowerCase()
  if (tipo.includes('audiolibro')) {
    iconclass.icon = 'cat-audiolibro'
    iconclass.type = 'audiolibro'
  } else if (tipo.includes('audio')) {
    iconclass.icon = 'cat-audio'
    iconclass.type = 'audio'
  } else if (tipo.includes('ebook')) {
    iconclass.icon = 'cat-ebook'
    iconclass.type = 'ebook'
  } else if (tipo.includes('evento')) {
    iconclass.icon = 'cat-evento'
    iconclass.type = 'evento'
  } else if (tipo.includes('immagine')) {
    iconclass.icon = 'cat-immagine'
    iconclass.type = 'immagine'
  } else if (tipo.includes('interattiva')) {
    iconclass.icon = 'cat-interattivo'
    iconclass.type = 'interattivo'
  } else if (tipo.includes('libro')) {
    iconclass.icon = 'cat-libro'
    iconclass.type = 'libro'
  } else if (tipo.includes('oggetti')) {
    iconclass.icon = 'cat-oggetti'
    iconclass.type = 'oggetti'
  } else if (tipo.includes('scheda')) {
    iconclass.icon = 'cat-scheda'
    iconclass.type = 'scheda'
  } else if (tipo.includes('software')) {
    iconclass.icon = 'cat-software'
    iconclass.type = 'software'
  } else if (tipo.includes('testo')) {
    iconclass.icon = 'cat-testo'
    iconclass.type = 'testo'
  } else if (tipo.includes('video')) {
    iconclass.icon = 'cat-video'
    iconclass.type = 'video'
  } else {
    iconclass = null
  }
  return iconclass
}

export const getPlaceholder = async (params) => {
  const key = `key=${params.key}`
  const contentTypeId = params.content_type_id ? `&content_type_id=${params.content_type_id}` : ''
  const objectId = params.object_id ? `&object_id=${params.object_id}` : ''

  const is_pdf = params.isPdf ? `&is_pdf=true` : ''

  // const isAnteprima = params.toolbar.route?.includes('?anteprima') ? `&anteprima=true` : ''
  const isAnteprima = params.headers?.anteprima ? `&anteprima=true` : ''

  const response = await api.get(
    `${PAGEBUILDER}?${key}${contentTypeId}${objectId}${is_pdf}${isAnteprima}`,
    { headers: params.headers }
  )
  const placeholder = response.data

  return placeholder
}

export async function getSearchTrends() {
  const stored = JSON.parse(sessionStorage.getItem('trends') || '[]')

  if (stored.length) {
    return stored
  } else {
    const response = await axios.get(
      `https://analytics.de.algolia.com/2/searches?index=giuntiscuola_prod_entitas&limit=5`,
      {
        headers: {
          'X-Algolia-Application-Id': 'U4BSY5DLQ2',
          'X-Algolia-API-Key': '3176a2623dced6cacb5131819897e589',
        },
      }
    )
    const { searches } = response.data
    const trends = searches
      .map((s) => s.search)
      .filter((s) => s !== 'undefined')
      .slice(0, 4)
    sessionStorage.setItem('trends', JSON.stringify(trends))
    return trends
  }
}

export const initServiceWorker = () => {
  if ('serviceWorker' in window.navigator && process.env.ENV !== 'development') {
    window.navigator.serviceWorker
      .register('/sw.js')
      .catch((err) => console.error('Service worker registration failed', err))
  }
}

export const scrollToRef = (ref) => window.scrollTo({ top: ref.current.offsetTop - 95 })

export const getTipoStrumento = (strumento) => {
  const tipoStrumentoDefault = 'oggetti'

  if (strumento.tipo_strumento) {
    return strumento.tipo_strumento.toLowerCase()
  }

  if (strumento.tassonomia) {
    const attrTipo = strumento.tassonomia.find((attr) => attr.nome === 'Tipo Strumento')

    if (attrTipo && attrTipo.valori_attributi.length) {
      return attrTipo.valori_attributi[0].nome.toLowerCase()
    }
  }

  return tipoStrumentoDefault
}

export function isDisabled(item, utente) {
  if (item?.extra?.pdf_file || item?.extra?.file_url) return false // ha i permessi per scaricare
  if (item?.extra?.membership === MEMBER || item?.membership_index === MEMBER) {
    if (utente) {
      return !utente.is_member
    } else {
      return true
    }
  } else {
    return false
  }
}

export const hasPerm = (utente, perm, item = null) => {
  if (item && item.visibilita === TUTTI) return true
  if (!utente) return false
  return utente.is_staff
    ? true
    : utente.permessi.includes(perm)
    ? true
    : item?.extra
    ? item?.extra?.has_perm
    : false
}

export const hasPagePerm = (props, utente, loginRequired) => {
  // area personale
  if (!utente && loginRequired) return false

  if ('pagina' in props || 'page' in props) {
    const pagina = props.pagina || props.page

    // nessun permesso sulla pagina
    if (!pagina.gruppi.length) return true
    // utente non loggato
    else if (!utente) return false
    // utente staff
    else if (utente.is_staff) return true
    // match permessi pagina - permessi utente
    else return utente.permessi.some((p) => pagina.gruppi.includes(p))
  } else return true
}

export const DOMAIN = 'https://www.giuntiscuola.it'
export const BRAND = 'Giunti Scuola'

export const ADMIN_DOMAIN =
  process.env.APP_ENV === 'staging'
    ? 'https://staging-giuntiscuola.webranking.tech/d/admin/'
    : process.env.ENV === 'production'
    ? 'https://admin.giuntiscuola.it/'
    : 'http://localhost:9999/d/admin/'

export const AREA_AGENTI_URL = 'area-agenti/'

export const FORMAZIONE = 'formazione'

// TEMPLATE PAGINE CMS
export const DEFAULT = 'default'
export const PROGETTI_SPECIALI = 'progetti_speciali'
export const IMMAGINE_HEADER = 'immagine_header'

// TIPI VISIBILITA
export const TUTTI = 'tutti'
export const MEMBER = 'member'

// TIPI ARTICOLO
export const ARTICOLO = 'articolo'
export const LEZIONE = 'lezione'

// TIPI PERMESSO
export const SCARICA = 'scarica'
export const AGGIUNGI = 'aggiungi-alla-raccolta'
export const VALUTA = 'valuta-commenta'
export const SALVA = 'salva'
export const CONSIGLIA = 'consiglia'
export const CONTATTI_ARTICOLO = 'contatti-articolo'
export const SEGUI = 'segui'
export const CONTATTI_ESPERTO = 'contatti-esperto'
export const SFOGLIA = 'vedi-sfogliabile'

// CAS
const CAS_SERVER_URL = `https://centralauthentication${
  process.env.APP_ENV === 'staging' ? '-qlt' : ''
}.giunti.it/cas/login?platform=giuntiscuola2`
export const CAS_DOMAIN =
  process.env.APP_ENV === 'staging'
    ? 'https://staging-giuntiscuola.webranking.tech'
    : process.env.ENV === 'production'
    ? DOMAIN
    : 'http://localhost:9999'
export const LOGIN_URL =
  process.env.NODE_ENV == 'development'
    ? 'http://localhost:9999/dummy-login/'
    : `${CAS_SERVER_URL}&service=${CAS_DOMAIN}/d/accounts/login`

// NEWSLETTER
export const NEWSLETTER = [
  "Scuola dell'infanzia",
  'Scuola primaria',
  'Scuola secondaria di I grado',
  'Scuola secondaria di II grado',
  'Psicopedagogia',
  'Offerte speciali',
  'Formazione',
]

// ALGOLIA
export const ENTITAS_INDEX =
  process.env.APP_ENV === 'staging'
    ? 'giuntiscuola_staging_entitas'
    : process.env.ENV === 'production'
    ? 'giuntiscuola_prod_entitas'
    : 'giuntiscuola_dev_entitas'
export const AGENTI_INDEX =
  process.env.APP_ENV === 'staging'
    ? 'giuntiscuola_dev_agenti_scuole'
    : process.env.ENV === 'production'
    ? 'giuntiscuola_prod_agenti_scuole'
    : 'giuntiscuola_dev_agenti_scuole'

export const ID_RIVISTE = [2, 5, 1, 6] // 7 disabilitato (nidi)
export const ID_IOPIU = 8

export const isFlipchart = (strumento) => {
  const formati = strumento.tassonomia.find((t) => t.nome.includes('formati'))
  if (formati) {
    const flipchart = formati.valori_attributi.find((t) => t.nome === 'flipchart')
    if (flipchart) return true
  }
  return false
}

export const isSme = (strumento) => {
  const formati = strumento.tassonomia.find((t) => t.nome.includes('formati'))
  if (formati) {
    const sme = formati.valori_attributi.find((t) => t.nome === 'sme')
    if (sme) return true
  }
  return false
}

export const downloadables = ['libro', 'immagine', 'scheda', 'software', 'testo', 'risorsa']

export const classify = (term) => term.replace(/_/g, '-').replace(/ /g, '-')

export const RECAPTCHA_KEY = '6Le1R8cZAAAAAHIGaUfpwXLDHIMps3dN2SzxXDxt'

export const mappingCta = {
  evento: 'Iscriviti',
  libro: 'Scarica',
  oggetti: 'Acquista',
  risorsa: 'Scarica',
  audio: 'Ascolta',
  audiolibro: 'Ascolta',
  ebook: 'Leggi',
  immagine: 'Scarica',
  scheda: 'Scarica',
  software: 'Scarica',
  testo: 'Scarica',
  video: 'Guarda video',
}

export const mappingValuta = {
  articolo: "Valuta l'articolo",
  strumento: 'Valuta lo strumento',
  raccolta: 'Valuta la raccolta',
  utente: null,
  tema: null,
  disciplina: null,
  pagina: 'Valuta il progetto',
  parascolastico: 'Valuta',
  corsoadozionale: 'Valuta',
}

export const checkboxMapping = {
  true: '1',
  false: '0',
}

export function getWaLabel(hit) {
  if (hit.model_class == 'utente') return 'autore'
  else if (hit.model_class == 'strumento') return getTipoStrumento(hit)
  else return hit.model_class
}

export function getCollapsed() {
  if (typeof window !== 'undefined') return localStorage.getItem('last-filter') || 0
  else return 0
}

export function checkCollapsed(value) {
  return getCollapsed() !== value
}

export function manageCollapsed(value) {
  localStorage.setItem('last-filter', value)
}

export function clearCollapsed() {
  localStorage.removeItem('last-filter')
}

export function getItalianPhone(number) {
  if (!number) return ''

  let phone = number.replace(/ /g, '').replace(/-/g, '').replace(/\D/g, '')

  // rimosso perchè non gestisce numeri che effettivamente senza prefisso iniziano con 39 e ci sono
  // if (!phone.startsWith('39')) phone = `+39${phone}`
  phone = `+39${phone}`

  return phone
}

export function hasToolbar(utente) {
  return utente && (utente.is_staff || utente.is_impersonate) && !utente.permessi.includes('agenti')
}
export function getRivistaDate(date) {
  const d = new Date(date)

  const day = d.getDay()
  const mese = d.getMonth()
  const year = d.getFullYear()

  var options = { month: 'long', year: 'numeric' }
  var date = new Date(year, mese, day).toLocaleString('it-IT', options)

  return date.capitalize()
}

export function getDateStringFromDay(day) {
  const d = new Date()

  const mese = d.getMonth()
  const year = d.getFullYear()

  var options = { weekday: 'long', month: 'long', day: 'numeric' }
  var date = new Date(year, mese, day).toLocaleString('it-IT', options)

  return date.capitalize()
}

export function getGiornoCorrente(router) {
  if (!router) return new Date().getDate()

  const giorno = router?.query?.giorno

  const isLocalOrStaging =
    process.env.NODE_ENV !== 'production' || process.env.APP_ENV === 'staging'

  if (isLocalOrStaging && giorno && giorno < 25) return Number(giorno)

  return new Date().getDate()
}

export const EMAIL_REDAZIONE = 'redazione@giunti.it'

export function getAttributiByChiave(tassonomia, attributo, includeProperties) {
  if (!tassonomia) return []
  if (!tassonomia.length) return []

  const attr = tassonomia.find((item) => item.chiave == attributo)

  if (attr && attr.valori_attributi?.length) {
    const valori = attr.valori_attributi
    if (includeProperties) return valori
    else return valori.map((v) => v.nome)
  }

  return []
}

export function getPrimoAttributoByChiave(tassonomia, attributo, includeProperties) {
  const attributi = getAttributiByChiave(tassonomia, attributo, includeProperties)

  if (attributi.length) {
    return attributi[0]
  }

  return ''
}

export function getTipoParascolastico(tassonomia, includeProperties) {
  return getPrimoAttributoByChiave(tassonomia, 'tipo-parascolastico', includeProperties)
}

export function getMateria(tassonomia, includeProperties) {
  return getPrimoAttributoByChiave(tassonomia, 'disciplina-map', includeProperties)
}

export function getClasse(tassonomia, includeProperties) {
  return getPrimoAttributoByChiave(tassonomia, 'classe-map', includeProperties)
}

export function getOrdine(tassonomia, includeProperties) {
  return getPrimoAttributoByChiave(tassonomia, 'ordine_di_scuola-map', includeProperties)
}

export function getOrdini(tassonomia, includeProperties) {
  return getAttributiByChiave(tassonomia, 'ordine_di_scuola-map', includeProperties)
}

export function getDiscipline(tassonomia, includeProperties) {
  return getAttributiByChiave(tassonomia, 'disciplina-map', includeProperties)
}

export function getTemi(tassonomia, includeProperties) {
  return getAttributiByChiave(tassonomia, 'tema-map', includeProperties)
}

export function getClassi(tassonomia, includeProperties) {
  return getAttributiByChiave(tassonomia, 'classe-map', includeProperties)
}

export function renderAutori(autori, classes) {
  const redazione = <span className={`${classes}__author`}>di Redazione GiuntiScuola</span>

  if (!autori.length) return redazione

  const autore = autori[0]

  let autoreLabel = null

  if (!autore || autore.email === EMAIL_REDAZIONE) {
    autoreLabel = redazione
  } else {
    autoreLabel = (
      <span className={`${classes}__author`}>
        di {autori.map((a) => a.titolo).join(', ')}
        {autori.length === 1 && autore.headline ? ', ' + autore.headline : ''}
      </span>
    )
  }

  return autoreLabel
}

export function getImage(src) {
  if (!src) return ''
  const imgHost = process.env.NODE_ENV == 'development' ? 'http://localhost:9999' : ''
  return `${imgHost}${src}`
}

export function formatPrice(price) {
  if (!price) return ''
  return `€ ${price.toFixed(2).replace('.', ',')}`
}

// da YYYY-MM-DD HH:MM a Date
export function datetimeParse(value) {
  const parts = value.replace(' ', '-').replace(':', '-').split('-')
  return new Date(parts[0], parts[1] - 1, parts[2], parts[3] || 0, parts[4] || 0, parts[5] || 0)
}

// da DD/MM/YYYY HH:MM a Date
export function datetimeParseItalian(value) {
  const parts = value.split(/[-:/ ]/)
  const year = parseInt(parts[2], 10)
  const month = parseInt(parts[1], 10) - 1
  const day = parseInt(parts[0], 10)
  const hour = parseInt(parts[3] || 0, 10)
  const minute = parseInt(parts[4] || 0, 10)
  const second = parseInt(parts[5] || 0, 10)

  return new Date(year, month, day, hour, minute, second)
}

// da YYYY-MM-DD a Date
export function dateParse(value, splitChar = '-') {
  const parts = value.split(splitChar)
  return new Date(parts[0], parts[1] - 1, parts[2], 0, 0, 0)
}

// Da YYYY-MM-DD a DD/MM/YYYY
export function formatDate(value, splitChar = '-') {
  return dateParse(value, splitChar).toLocaleDateString('it-IT')
}

export function escape(s) {
  return s
    .replace(/&amp;/g, '&')
    .replace(/&#39;/g, "'")
    .replace(/&quot;/g, '"')
}

export function isDocente(utente) {
  if (!utente || !utente?.ruolo) return false
  return utente.ruolo === 'Insegnante' || utente.ruolo === 'i'
}

export function getBoxAbbonamento(box, utente) {
  if (!box.length || utente?.permessi?.includes(box.codice)) return []
  if (utente?.is_member) return box.filter((i) => i.codice !== 'io-piu')
  return box
}

export const PRODOTTO_SPEDITO = 'spedito'
export const PRODOTTO_ABBONAMENTO = 'abbonamento'
export const PRODOTTO_DIGITALE = 'digitale'
export const PRODOTTO_DROPSHIPPING = 'dropshipping'
export const CODICE_IVA_IO_PIU = 'V7'

export const BLACK_IMAGE = 'https://upload.wikimedia.org/wikipedia/commons/4/49/A_black_image.jpg'

export function isRinnovabile(subscription) {
  return (
    subscription &&
    new Date(subscription?.renewal_enabled_date) < new Date() &&
    new Date() < new Date(subscription?.final_gracing_end_date)
  )
}

export function getYoutubeThumbnail(link) {
  if (!link || link.includes('playlist')) return BLACK_IMAGE
  if (!link.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)) return BLACK_IMAGE
  return `https://img.youtube.com/vi/${link
    .match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/)
    .pop()}/hqdefault.jpg`
}

export function getVimeoThumbnail(link) {
  if (!link) return BLACK_IMAGE
  if (!link.match(/vimeo\.com.(.{9})/)) return BLACK_IMAGE
  return `https://vumbnail.com/${link.match(/vimeo\.com.(.{9})/).pop()}.jpg`
}

export function stripHTML(myString) {
  return myString.replace(/(<([^>]+)>)/gi, '')
}

export function getCookie(cookieName) {
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const cookieArray = decodedCookie.split(';')

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i]
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1)
    }
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length)
    }
  }
  return ''
}

export const EXCLUDED_PAGES = ['/cookie-policy']
