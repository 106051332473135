// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
// import { Integrations } from '@sentry/tracing'

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN || 'https://7bc3429be5044f499051c9dabf49e3fd@o19983.ingest.sentry.io/6092764',
  tracesSampleRate: 0.2,
  enabled: process.env.NODE_ENV !== 'development',
  ignoreErrors: [
    'Unhandled Promise Rejection: Request failed with status code 401',
    'Unhandled Promise Rejection: Network Error',
    'Unhandled Promise Rejection: Request failed with status code 401',
    'Request failed with status code 401',
  ],
  integrations: [
    new Sentry.Integrations.BrowserTracing({
      tracingOrigins: [
        'staging-giuntiscuola.webranking.tech',
        'www.giuntiscuola.it',
        'giuntiscuola.it',
      ],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
})
