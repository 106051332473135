import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function PlusCircle({ className }) {
  return (
    <IconBase name="plus-circle" w={16} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.00004 1.33325C4.32004 1.33325 1.33337 4.31992 1.33337 7.99992C1.33337 11.6799 4.32004 14.6666 8.00004 14.6666C11.68 14.6666 14.6667 11.6799 14.6667 7.99992C14.6667 4.31992 11.68 1.33325 8.00004 1.33325ZM8.00004 4.66658C7.63337 4.66658 7.33337 4.96658 7.33337 5.33325V7.33325H5.33337C4.96671 7.33325 4.66671 7.63325 4.66671 7.99992C4.66671 8.36658 4.96671 8.66658 5.33337 8.66658H7.33337V10.6666C7.33337 11.0333 7.63337 11.3333 8.00004 11.3333C8.36671 11.3333 8.66671 11.0333 8.66671 10.6666V8.66658H10.6667C11.0334 8.66658 11.3334 8.36658 11.3334 7.99992C11.3334 7.63325 11.0334 7.33325 10.6667 7.33325H8.66671V5.33325C8.66671 4.96658 8.36671 4.66658 8.00004 4.66658ZM2.66671 7.99992C2.66671 10.9399 5.06004 13.3333 8.00004 13.3333C10.94 13.3333 13.3334 10.9399 13.3334 7.99992C13.3334 5.05992 10.94 2.66659 8.00004 2.66659C5.06004 2.66659 2.66671 5.05992 2.66671 7.99992Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default PlusCircle
