import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function ShoppingBag({ className }) {
  return (
    <IconBase name="shopping-bag" w={20} className={className}>
      <path
        d="M4.26889 6.83337L15.7311 6.83337L16.4311 17.3334L3.56889 17.3334L4.26889 6.83337Z"
        strokeWidth="2"
        stroke="currentColor"
        fill="currentColor"
        strokeLinejoin="round"
      />
      <path
        d="M6.6665 10.8333V5.83333C6.6665 3.99238 8.15889 2.5 9.99984 2.5C11.8408 2.5 13.3332 3.99238 13.3332 5.83333V10.8333"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
        stroke="currentColor"
      />
    </IconBase>
  )
}

export default ShoppingBag
