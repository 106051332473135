import React from 'react'
import { Button } from 'react-components/atoms'

const AttivaContenutoCard = props => {
  const { title = '', description = '', ctaLabel = '', ctaLink = '', className = '' } = props

  return (
    <div className={`attiva-contenuto-card ${className ? ` ${className}` : ''}`}>
      {title && <p className="attiva-contenuto-card__title">{title}</p>}
      {description && <p className="attiva-contenuto-card__description">{description}</p>}
      {ctaLabel && ctaLink && (
        <div className="attiva-contenuto-card__cta">
          <Button label={ctaLabel} href={ctaLink} />
        </div>
      )}
    </div>
  )
}

export default AttivaContenutoCard
