import React from 'react'
import { MinusCircle, PlusCircle } from '../Icons'

const QuantityHandler = props => {
  const {
    value = 1,
    min = 1,
    max = undefined,
    onChange = undefined,
    inputDisabled = false,
    className = '',
  } = props

  return (
    <div className={`quantity-handler ${className ? ` ${className}` : ''}`}>
      <button
        className="quantity-handler__button quantity-handler__button--minus"
        disabled={min !== undefined && min !== null && value <= min}
        onClick={() => onChange(value - 1, 'remove')}
      >
        <MinusCircle />
      </button>
      <input
        className="quantity-handler__input"
        value={value}
        onChange={e => {
          const intv = parseInt(e.target.value) || 0
          onChange(
            max !== undefined && max !== null && intv > max
              ? max
              : min !== undefined && min !== null && intv < min
              ? min
              : intv
          )
        }}
        disabled={inputDisabled}
      />
      <button
        className="quantity-handler__button quantity-handler__button--plus"
        disabled={max !== undefined && max !== null && value >= max}
        onClick={() => onChange(value + 1, 'add')}
      >
        <PlusCircle />
      </button>
    </div>
  )
}

export default QuantityHandler
