import React from 'react'
import { Close } from 'react-pagebuilder/pb-components/icons'

const NotificationBar = props => {
  const {
    label = '',
    icon = null,
    variant = 'info', // per ora solo info
    onClose = undefined,
    className = '',
  } = props

  return (
    <div
      className={`notification-bar notification-bar--${variant} ${
        className ? ` ${className}` : ''
      }`}
    >
      {icon && <span className="notification-bar__icon">{icon}</span>}
      <div className="notification-bar__label" dangerouslySetInnerHTML={{ __html: label }} />
      {!!onClose && (
        <button
          className="notification-bar__close"
          type="button"
          aria-label="Close"
          onClick={onClose}
        >
          <Close />
        </button>
      )}
    </div>
  )
}

export default NotificationBar
