import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function CheckboxMarked({ className }) {
  return (
    <IconBase name="checkbox-marked" w={24} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3H19C20.11 3 21 3.9 21 5V19C21 20.1 20.11 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3ZM4.8 12.2177L9.94286 17.4L19.2 8.07178L17.7497 6.6L9.94286 14.4668L6.25029 10.7562L4.8 12.2177Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default CheckboxMarked
