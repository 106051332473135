import React, { useContext } from 'react'
import { useTrans } from 'hooks'
import { formatPrice } from 'utils'
import { Close, Info2, Promo } from 'react-components/atoms/Icons'
import { Button, NotificationBar } from 'react-components/atoms'
import { ProdottoCartCard, EmptyCartMessage, MessaggioCheckout } from 'react-components/molecules'
import { RiepilogoOrdine } from 'react-components/organisms'
import {
  prodottiSpediti,
  prodottiDigitali,
  prodottiAbbonamento,
  prodottiDropshipping,
  CART_SECTIONS,
  LIMITE_FREE_SHIPPING,
  isIoPiu,
} from 'utils/cart'
import {
  PRODOTTO_SPEDITO,
  PRODOTTO_ABBONAMENTO,
  PRODOTTO_DIGITALE,
  PRODOTTO_DROPSHIPPING,
} from 'utils'
import { CartContext, initialCart } from 'utils/context'

const CartPanel = (props) => {
  const { showCart = false, closeCart = undefined, className = '' } = props

  const t = useTrans()

  const context = useContext(CartContext)
  const cart = context ? context.cart : initialCart

  const items = {
    spedito: prodottiSpediti(cart?.items),
    digitale: prodottiDigitali(cart?.items),
    abbonamento: prodottiAbbonamento(cart?.items),
    dropshipping: prodottiDropshipping(cart?.items),
  }

  const onQuantitaChange = (item, quantita) => {
    let discount = (item.absoluteDiscount / (quantita - (quantita - item.quantita))).toFixed(2)
    context.addToCart(
      item.prodotto,
      quantita - item.quantita,
      false,
      'minicart',
      discount,
      null,
      'navigazione_standard'
    )
  }

  const onRemove = (item) => {
    let discount = (
      item.absoluteDiscount /
      (item.quantita - (item.quantita - item.quantita))
    ).toFixed(2)
    context.removeFromCart(
      item.cartRow.prodotto,
      item.cartRow.quantita,
      false,
      'minicart',
      discount,
      'navigazione_standard'
    )
  }

  return (
    <>
      {showCart && <div className="cart-panel-overlay" onClick={closeCart} />}
      <div
        className={`cart-panel ${showCart ? 'cart-panel--open' : ''} ${
          className ? ` ${className}` : ''
        }`}
      >
        <button onClick={closeCart} className="cart-panel__close">
          <Close />
        </button>
        <p className="cart-panel__title">
          {t('Il tuo carrello')}{' '}
          <span className="cart-panel__num">
            ({t(cart?.numProdotti === 1 ? '{0} prodotto' : '{0} prodotti', [cart?.numProdotti])})
          </span>
        </p>
        {cart?.items?.length > 0 ? (
          <>
            <div className="cart-panel__scrollable">
              <MessaggioCheckout className="cart-panel__notification-bar" />
              <NotificationBar
                icon={<Promo />}
                variant="info-blue"
                label={t(
                  '<strong>Hai un codice sconto?</strong><br>Potrai inserirlo nel prossimo step'
                )}
                className="cart-panel__notification-bar"
              />
              {cart.totaleProdottiScontato < LIMITE_FREE_SHIPPING && (
                <NotificationBar
                  icon={<Info2 />}
                  label={t(
                    'Aggiungi <strong>{0}</strong> di prodotti per ottenere <strong>la spedizione gratuita</strong>',
                    [formatPrice(LIMITE_FREE_SHIPPING - cart.totaleProdottiScontato)]
                  )}
                  className="cart-panel__notification-bar"
                />
              )}
              {items[PRODOTTO_DIGITALE].length + items[PRODOTTO_ABBONAMENTO].length > 1 && (
                <NotificationBar
                  icon={<Info2 />}
                  label={t(
                    "Hai più di 1 abbonamento nel carrello. Verrà finalizzato solo l'acquisto dell'<strong>ultimo aggiunto</strong>."
                  )}
                  className="cart-template__notification-bar"
                />
              )}
              <div className="cart-panel__products">
                {CART_SECTIONS.filter((section) => items[section.tipo].length > 0).map((section) =>
                  items[section.tipo].map((x) => (
                    <ProdottoCartCard
                      key={x.pk}
                      tipologia={x.prodotto.tipologia}
                      titolo={x.prodotto.titolo}
                      mainImage={x.prodotto.mainImage}
                      prezzo={x.total}
                      prezzoScontato={x.totalDiscounted}
                      labelText={x.omaggio ? 'Omaggio' : ''}
                      boxed
                      bordered
                      showQuantita={
                        section.tipo === PRODOTTO_SPEDITO || section.tipo === PRODOTTO_DROPSHIPPING
                          ? !x.omaggio
                          : false
                      }
                      removable={!x.omaggio}
                      quantita={x.quantita}
                      onQuantitaChange={(newValue, action) => {
                        onQuantitaChange(x, newValue)
                      }}
                      onRemove={() => onRemove(x)}
                      inCart
                      isIoPiu={isIoPiu(x.prodotto)}
                      riga={x}
                      rinnovo={x.prodotto.rinnovo}
                      is_prevendita={x.prodotto.isPrevendita}
                      disclaimerPrevendita={x.prodotto.disclaimerPrevendita}
                      garanzia={x.prodotto.garanzia}
                      garanziaApplicata={x.cartRow.garanzia}
                      garanziaStyleInverted={true}
                    />
                  ))
                )}
              </div>
            </div>
            <div className="cart-panel__bottom-shadow" />
            <RiepilogoOrdine
              totaleProdotti={cart.totaleProdottiScontato}
              speseSpedizione={cart.sistemaSpedizione?.totalDiscounted}
              sistemaSpedizione={cart.sistemaSpedizione}
              totaleServizi={cart.totaleServizi}
              totale={cart.totaleScontato}
              promoApplicate={cart.appliedPromotions}
              className="cart-panel__riepilogo"
            />
            <div className="cart-panel__bottom">
              <Button label={t('Vai al checkout')} href="/cart" />
            </div>
          </>
        ) : (
          <EmptyCartMessage />
        )}
      </div>
    </>
  )
}

export default CartPanel
