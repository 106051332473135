import React from 'react'
import {
  LandingRivisteRivista,
  LandingRivisteVs,
  LandingRivisteSi,
  LandingRivistePs,
} from 'react-components/atoms/Icons'
import { WContainer } from 'react-components/atoms'
import { LandingRivisteSlider } from 'react-components/molecules'

const LandingRivisteCartacea = props => {
  const { productCode = '', item = undefined, setZoomModalItem = undefined, className = '' } = props

  return item ? (
    <div
      className={`landing-riviste-cartacea u-landing-riviste-bg-light ${
        className ? ` ${className}` : ''
      }`}
    >
      {productCode === 'vs' && (
        <span className="landing-riviste-cartacea__bg">
          <LandingRivisteVs />
        </span>
      )}
      {productCode === 'si' && (
        <span className="landing-riviste-cartacea__bg">
          <LandingRivisteSi />
        </span>
      )}
      {productCode === 'ps' && (
        <span className="landing-riviste-cartacea__bg">
          <LandingRivistePs />
        </span>
      )}
      <WContainer>
        <div className={'landing-riviste-cartacea__content'}>
          {!!item.name && (
            <p className="landing-riviste-cartacea__title u-landing-riviste-color-dark">
              <span className="landing-riviste-cartacea__title-icon">
                <LandingRivisteRivista />
              </span>
              <span className="landing-riviste-cartacea__title-label">{item.name}</span>
            </p>
          )}
          {!!item.description && (
            <div
              className="landing-riviste-cartacea__description"
              dangerouslySetInnerHTML={{ __html: item.description }}
            />
          )}
        </div>
        <div className="landing-riviste-cartacea__list">
          <LandingRivisteSlider
            items={item.children}
            color="inherit"
            setZoomModalItem={setZoomModalItem}
          />
        </div>
      </WContainer>
    </div>
  ) : (
    <></>
  )
}

export default LandingRivisteCartacea
