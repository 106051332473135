import React from 'react'
import { FormazioneCorsoCard } from 'react-components/molecules'
import { useRouter } from 'next/router'
import Evidenza from 'components/atomic/Evidenza'
import SlickSlider from 'react-slick'
import sortedUniqBy from 'lodash/sortedUniqBy'
import sortBy from 'lodash/sortBy'
import { slugify } from '../../../utils'

const FormazioneCorsiListing = props => {
  const { className = '', corsiFormazione = null, filters = null } = props

  const sliderSettings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 2,
    variableWidth: true,
    arrows: false,
    // centerMode: true,
    // centerPadding: '1500px',
  }

  const router = useRouter()

  const changeFilter = (item, remove) => {
    dataLayer.push({
      event: 'GAevent',
      eventID: '31',
      eventCategory: 'formazione_filtri',
      eventAction: remove ? 'filtro_deselezionato' : 'filtro_selezionato',
      eventLabel: item.nome.toGTMFormat(),
    })
    if (remove) router.push('./')
  }

  const tassonomia_filters = sortedUniqBy(
    sortBy(
      corsiFormazione
        .map(item => item.tassonomia)
        .flat()
        .filter(a => a.chiave === 'tipo-corsoformazione')
        .map(a => a.valori_attributi)
        .flat()
        .map(v => ({
          ...v,
          url: router.asPath.includes(v.slug) ? null : `/formazione/corsi/${v.slug}`,
          selected: router.asPath.includes(v.slug),
        }))
        .concat(
          filters?.map(v => ({
            ...v,
            url: router.asPath.includes(slugify(v.nome))
              ? null
              : `/formazione/corsi/${slugify(v.nome)}`,
            selected: router.asPath.includes(slugify(v.nome)),
          }))
        ),
      ['nome']
    ),
    'id'
  )
    .filter(Boolean)
    .slice(0, 7)

  return (
    <div className={`formazione-corsi-listing ${className ? ` ${className}` : ''}`}>
      <div className="formazione-corsi-listing__list">
        {tassonomia_filters?.length ? (
          <>
            <div className="formazione-corsi-listing__list__filters formazione-corsi-listing__list__filters--desktop">
              {tassonomia_filters.map(item => (
                <Evidenza
                  className="formazione-corsi-listing__list__filters__item"
                  item={item}
                  selected={item?.selected}
                  show_close={true}
                  onClick={() => changeFilter(item, item?.selected)}
                />
              ))}
            </div>
            <SlickSlider
              className="filtri-catalogo__slider formazione-corsi-listing__list__filters--mobile"
              {...sliderSettings}
            >
              {tassonomia_filters.map(item => (
                <Evidenza
                  item={item}
                  selected={item?.selected}
                  show_close={true}
                  onClick={() => changeFilter(item, item?.selected)}
                />
              ))}
            </SlickSlider>
          </>
        ) : (
          <></>
        )}
        {corsiFormazione
          ? corsiFormazione.map(x => <FormazioneCorsoCard item={x} key={x.pk} />)
          : ''}
      </div>
    </div>
  )
}

export default FormazioneCorsiListing
