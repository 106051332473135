import { applyMiddleware, createStore } from 'redux'
import { createWrapper } from 'next-redux-wrapper'
import createRootReducer from './root-reducer'
import promise from 'redux-promise-middleware'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

const middleware = composeWithDevTools(applyMiddleware(promise, thunk))

const makeStore = () => createStore(createRootReducer, middleware)

export const wrapper = createWrapper(makeStore, { debug: false })
