import React from 'react'

function Columns1({ className }) {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={`icon icon--columns1 ${className ? className : ''}`}
    >
      <rect
        fill="none"
        height="18"
        rx="2"
        ry="2"
        width="18"
        x="3"
        y="3"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export default Columns1
