import React from 'react'
import IconBase from 'react-components/atoms/IconBase/IconBase'

function ArrowDown2({ className }) {
  return (
    <IconBase name="arrow-down2" w={24} className={className}>
      <path
        d="M6.51382 10.9788L11.0025 15.4905C11.6784 16.1698 12.7703 16.1698 13.4462 15.4905L17.9349 10.9788C19.0267 9.88133 18.2469 8 16.7044 8H7.72699C6.18453 8 5.42197 9.88133 6.51382 10.9788Z"
        stroke="none"
      />
    </IconBase>
  )
}

export default ArrowDown2
