import React, { useEffect, useState } from 'react'
import { api } from 'utils'
import { FORMAZIONECORSI, GETMODULIUTENTE } from 'utils/endpoint'
import FormazioneModuloCardProfilo from 'react-components/molecules/FormazioneModuloCardProfilo/FormazioneModuloCardProfilo'
import { useSelector } from 'react-redux'

const TabCorsiConclusi = () => {
  const [moduliConclusi, setModuliConclusi] = useState([])
  const { utente } = useSelector((state) => state.utente)

  useEffect(() => {
    if (utente) {
      fetchModuliConclusi()
    }
  }, [utente])

  async function fetchModuliConclusi() {
    const response = await api.get(`${FORMAZIONECORSI}${GETMODULIUTENTE}`, {
      params: { in_corso: false },
    })
    const { data } = response
    setModuliConclusi(data)
  }

  return (
    <div className="tab-corsi-iscritto">
      <div className="tab-corsi-iscritto__list">
        {moduliConclusi.map((modulo) => (
          <div className="tab-corsi-iscritto__item">
            <FormazioneModuloCardProfilo item={modulo} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default TabCorsiConclusi
