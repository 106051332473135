import React from 'react'
import Link from 'next/link'

const Widget = (props) => {
  const { widget = {}, className = '' } = props

  if (!widget?.widget) return null

  const classes = `widget${className ? ` ${className}` : ''}`

  return widget.link ? (
    <Link legacyBehavior href={widget.link}>
      <a className={classes} target={widget.blank ? '_blank' : null}>
        {React.cloneElement(widget.widget, widget.widget_options)}
      </a>
    </Link>
  ) : (
    <div className={classes}>{React.cloneElement(widget.widget, widget.widget_options)}</div>
  )
}

export default Widget
