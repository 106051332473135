import React from 'react'
import { useTrans } from 'hooks'
import { Pin, Phone, Email, ArrowRightLong } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'

import { getItalianPhone } from 'utils'

const AreaPersonaleAgenteCard = props => {
  const { agente = {}, className = '' } = props

  const t = useTrans()

  return (
    <div className={`area-personale-agente-card ${className ? ` ${className}` : ''}`}>
      <div className="area-personale-agente-card__head">
        <p className="area-personale-agente-card__title">{t('Il mio agente')}</p>
        <p className="area-personale-agente-card__name">
          {agente.nome} {agente.cognome}
        </p>
      </div>
      <div className="area-personale-agente-card__contacts">
        {agente.indirizzo && (
          <div className="area-personale-agente-card__contact">
            <span className="area-personale-agente-card__contact__icon">
              <Pin />
            </span>
            <span className="area-personale-agente-card__contact__value">
              {agente.indirizzo} {agente?.cap && ` - ${agente.cap}`}
              {agente?.citta && ` ${agente.citta}`}
              {agente?.provincia && ` (${agente.provincia})`}
            </span>
          </div>
        )}
        {agente.telefono && (
          <div className="area-personale-agente-card__contact">
            <span className="area-personale-agente-card__contact__icon">
              <Phone />
            </span>
            <span className="area-personale-agente-card__contact__value">{agente.telefono}</span>
          </div>
        )}
        {agente.email && (
          <div className="area-personale-agente-card__contact">
            <span className="area-personale-agente-card__contact__icon">
              <Email />
            </span>
            <span className="area-personale-agente-card__contact__value">{agente.email}</span>
          </div>
        )}
      </div>
      <div className="area-personale-agente-card__actions">
        {agente.email && (
          <a href={`mailto:${agente.email}`}>
            <Button
              variant="ghost"
              label={t('Contatta per email')}
              icon={<ArrowRightLong />}
              iconPos="right"
              link={`mailto:${agente.email}`}
            />
          </a>
        )}
        {(agente.mobile || agente.mobile2) && (
          <Button
            variant="ghost"
            label={t('Contatta su Whatsapp')}
            href={`https://wa.me/${getItalianPhone(agente.mobile || agente.mobile2)}`}
            external
            newPage
            icon={<ArrowRightLong />}
            iconPos="right"
            className="area-personale-agente-card__actions__wa"
          />
        )}
      </div>
    </div>
  )
}

export default AreaPersonaleAgenteCard
