import React, { useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import AreaPersonaleMenu from './menu/AreaPersonaleMenu'
import GenericMenu from './menu/GenericMenu'
import MainMenu from './menu/MainMenu'
import { useSelector } from 'react-redux'
import dynamic from 'next/dynamic'
import { sendGTMData } from 'utils/gtm.js'
import { useRouter } from 'next/router'
import { Profilo, IoPiu, Hamburger, LogoColored, Cart } from 'react-components/atoms/Icons'
import { CartPanel } from 'react-components/organisms'
import { HeaderCart } from 'react-components/atoms'
import { hasToolbar } from 'utils'
import { useIsClient, useTrans } from 'hooks'
import { CartContext } from 'utils/context'

const HeaderSearch = dynamic(() => import('../atomic/HeaderSearch'))
const MainMenuMobile = dynamic(() => import('./menu/MainMenuMobile'))
const Login = dynamic(() => import('../atomic/Login'))
const PopupCommerciale = dynamic(() => import('../atomic/PopupCommerciale'))

const Header = React.memo((props) => {
  const { page = null } = props

  const router = useRouter()

  const isClientSide = useIsClient(false)

  const { utente } = useSelector((state) => state.utente)

  const { cart, showCart, openCart, closeCart } = useContext(CartContext)

  const t = useTrans()

  const [login, setLogin] = useState(false)
  const [next, setNext] = useState('')

  const [showMainMenuMobile, setShowMainMenuMobile] = useState(false)
  const [showAreaPersonaleMenuMobile, setShowAreaPersonaleMenuMobile] = useState(false)

  const [windowScroll, setWindowScroll] = useState(0)

  const classes = hasToolbar(utente) ? 'header--toolbar-active ' : ''

  const handleGTM = (item) => {
    if (item) {
      sendGTMData({
        event: 'GAevent',
        eventID: '03',
        eventCategory: 'header',
        eventAction: item.toGTMFormat(),
        eventLabel: 'non_valorizzata',
      })
    }
  }

  const handleLogin = (login) => setLogin(login)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [windowScroll])

  useEffect(() => {
    if (router.query && 'login' in router.query) handleLogin(true)
    if (router.query && 'next' in router.query) setNext(router.query.next)
  }, [router.query])

  useEffect(() => {
    if (utente?.is_staff || utente?.is_impersonate) document.body.classList.add('w-toolbar')
  }, [utente])

  const handleScroll = () => {
    if (window.scrollY > 0) document.body.classList.add('w-scrolled')
    else document.body.classList.remove('w-scrolled')
    // if (window.scrollY < windowScroll || windowScroll === 0) {
    //   document.body.classList.remove('w-scrolldown')
    // } else {
    //   document.body.classList.add('w-scrolldown')
    // }
    setWindowScroll(window.scrollY)
  }

  const handleToggleMenu = (areaPersonale) => {
    if (areaPersonale) {
      checkOpenAreaPersonale()
    } else {
      setShowMainMenuMobile(!showMainMenuMobile)
    }
  }

  useEffect(() => {
    if (showMainMenuMobile) setShowMainMenuMobile(false)
    if (showAreaPersonaleMenuMobile) setShowAreaPersonaleMenuMobile(false)
  }, [router.asPath])

  useEffect(() => {
    if (showAreaPersonaleMenuMobile) {
      document.body.classList.add('w-noscroll')
      document.body.classList.add('w-menuareapersonaleopen')
    } else {
      document.body.classList.remove('w-noscroll')
      document.body.classList.remove('w-menuareapersonaleopen')
    }
  }, [showAreaPersonaleMenuMobile])

  function checkOpenAreaPersonale() {
    if (
      !Array.from(document.body.classList).includes('w-menuareapersonaleopen') &&
      showAreaPersonaleMenuMobile
    ) {
      // se è stato chiuso tramite backdrop o close icon
      document.body.classList.add('w-noscroll')
      document.body.classList.add('w-menuareapersonaleopen')
    } else {
      setShowAreaPersonaleMenuMobile(!showAreaPersonaleMenuMobile)
    }
  }

  const isArePersonale = router.asPath?.includes('/area-personale')

  return (
    <>
      <header id="header" className={`header ${classes}`}>
        <div className="header__top">
          <Link legacyBehavior href="/io-piu">
            <a className="header__top__iopiu" onClick={() => handleGTM('scopri io plus')}>
              {t('Scopri')} <IoPiu />
            </a>
          </Link>
          <GenericMenu id="header-top" classes="header__top__menu" with_icons="true" />
          <div className="userbar">
            {utente ? (
              <AreaPersonaleMenu cart={cart} openCart={openCart} />
            ) : (
              <div className="userbar__container">
                <HeaderCart />
                <a
                  className="userbar__login"
                  onClick={() => {
                    handleLogin(true)
                    handleGTM('accedi o registrati')
                  }}
                >
                  {t('Accedi o registrati')} <Profilo />
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="header__content">
          <span
            className={`header__button header__button--menu ${
              showMainMenuMobile ? 'header__button--open' : ''
            }`}
            onClick={() => handleToggleMenu(false)}
          >
            <Hamburger />
          </span>
          <div id="logo" className="header__logo">
            <Link legacyBehavior href="/">
              <a onClick={() => handleGTM('logo header')}>
                <LogoColored />
              </a>
            </Link>
          </div>

          <MainMenu />

          <div className="header__right">
            <HeaderCart className="header-cart--mobile" href="/cart" />

            {utente ? (
              isArePersonale ? (
                <a
                  className={`header__button header__button--link`}
                  onClick={() => handleToggleMenu(true)}
                >
                  <Profilo />
                </a>
              ) : (
                <Link legacyBehavior href="/area-personale">
                  <a className={`header__button header__button--link`}>
                    <Profilo />
                  </a>
                </Link>
              )
            ) : (
              <span
                className={`header__button header__button--login`}
                onClick={() => {
                  handleLogin(true)
                  handleGTM('accedi o registrati')
                }}
              >
                <Profilo />
              </span>
            )}
          </div>
          <HeaderSearch page={page} />
        </div>
      </header>
      {isClientSide && (
        <>
          <MainMenuMobile isOpen={showMainMenuMobile} close={() => setShowMainMenuMobile(false)} />
          <CartPanel showCart={showCart} closeCart={closeCart} />
        </>
      )}
      {login && <Login isOpen={login} setLogin={handleLogin} next={next} />}
      <PopupCommerciale />
    </>
  )
})

export default Header
