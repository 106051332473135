import React from 'react'
import { useTrans } from 'hooks'
import { formatPrice } from 'utils'
import { Download } from 'react-components/atoms/Icons'
import { Button } from 'react-components/atoms'

const AreaPersonaleDettagliOrdineCard = props => {
  const { ordine = undefined, className = '' } = props

  const t = useTrans()
  const details = [
    { key: 'data_ordine', label: t('Data ordine'), value: ordine.data },
    { key: 'totale', label: t('Totale'), value: formatPrice(ordine.prezzoTotaleScontato) },
    { key: 'stato', label: t('Stato'), value: ordine.stato?.nome },
  ]

  return ordine ? (
    <div className={`area-personale-dettagli-ordine-card ${className ? ` ${className}` : ''}`}>
      <div className="area-personale-dettagli-ordine-card__details">
        {details.map(x => (
          <div key={x.key} className="area-personale-dettagli-ordine-card__detail">
            <span className="area-personale-dettagli-ordine-card__detail-label">{x.label}</span>
            <span className="area-personale-dettagli-ordine-card__detail-value">{x.value}</span>
          </div>
        ))}
      </div>
      <div className="area-personale-dettagli-ordine-card__actions">
        {ordine.stato.chiave === 'spedito' && ordine.trackingUrl && (
          <Button
            variant="secondary"
            label={t('Traccia spedizione')}
            className="area-personale-dettagli-ordine-card__track"
            href={ordine.trackingUrl}
            newPage
            external
          />
        )}
        {ordine.fatturaWeb && (
          <Button
            variant="ghost"
            label={t('Scarica ricevuta')}
            icon={<Download />}
            iconPos="right"
            className="area-personale-dettagli-ordine-card__receipt"
            href={ordine.fatturaWeb}
            download
            external
            newPage
          />
        )}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default AreaPersonaleDettagliOrdineCard
