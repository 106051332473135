import { MEMBER, SALVA, api, hasPerm, isDisabled } from 'utils'
import React, { useEffect, useMemo, useState } from 'react'

import { ELEMENTOSALVATO } from 'utils/endpoint'
import Icon from 'components/atomic/Icon'
import Ioplus from 'components/atomic/Ioplus'
import Link from 'next/link'
import { useDispatch, useSelector } from 'react-redux'
import { setRaccolta } from 'containers/raccolta'
import { sendGTMData, sendProductClickGTM } from 'utils/gtm.js'
import Scarica from 'components/atomic/Scarica'
import Salva from 'components/atomic/Salva'
import Modifica from 'components/atomic/Modifica'

const RaccoltaCard = React.memo((props) => {
  const {
    raccolta,
    isEditable = false,
    onRemove = null,
    isEmbedded = false,
    horizontal = false,
    size = '',
    variant = null,
    position = null,
    parentList = '',
  } = props

  const { utente } = useSelector((state) => state.utente)
  const dispatch = useDispatch()

  const [saved, setSaved] = useState(false)

  const thumb = raccolta?.preview_image
    ? raccolta.preview_image
    : raccolta?.main_image
    ? raccolta?.main_image
    : '/images/card/raccolta.jpg'

  const params = {
    content_type_id: raccolta?.model,
    object_id: raccolta?.id || raccolta?.object_id,
  }

  const handleGTM = (action) => {
    sendGTMData({
      event: 'GAevent',
      eventID: '15',
      eventCategory: 'interazione_contenuti',
      eventAction: action,
      eventLabel: 'raccolta',
      moreInfo: raccolta.titolo.toGTMFormat(),
    })
  }

  const handleSave = async () => {
    if (hasPerm(utente, SALVA)) {
      handleGTM('bookmark')
      api.post(ELEMENTOSALVATO, { params }).then(() => checkSaved())
    }
  }

  const handleUnsave = async () => {
    if (hasPerm(utente, SALVA)) {
      handleGTM('bookmark')
      api.delete(ELEMENTOSALVATO, { data: params }).then(() => checkSaved())
    }
  }

  const checkSaved = async () => {
    api.get(ELEMENTOSALVATO, { params }).then((response) => {
      setSaved(response.data['salvato'])
    })
  }

  useEffect(() => {
    setSaved(raccolta?.saved)
  }, [raccolta?.saved])

  useEffect(() => {
    if (isEmbedded && hasPerm(utente, SALVA)) checkSaved()
  }, [])

  const disabled = useMemo(() => isDisabled(raccolta, utente), [raccolta, utente])

  const disabledGradient = 'linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)), '

  const handleSetRaccolta = () => {
    sendGTMData({
      event: 'GAevent',
      eventID: '17',
      eventCategory: 'raccolta',
      eventAction: 'modifica_raccolta',
      eventLabel: raccolta.pubblica ? 'pubblica' : 'privata',
    })
    dispatch(setRaccolta(raccolta))
  }

  return (
    <article
      className={`raccolta-card ${horizontal ? 'raccolta-card--horizontal' : ''} ${
        disabled ? 'raccolta-card--disabled' : ''
      } ${size ? `raccolta-card--${size}` : ''} ${variant ? ` raccolta-card--${variant}` : ''}`}
    >
      {raccolta.fileUrl ? (
        <Scarica
          href={fileUrl}
          download
          onClick={() => handleGTM('download')}
          horizontal={horizontal}
        />
      ) : (
        raccolta.extra.membership == MEMBER && (
          <Ioplus tooltip="Contenuto riservato agli abbonati io+" horizontal={horizontal} />
        )
      )}
      {hasPerm(utente, SALVA) && !isEditable ? (
        saved ? (
          <Salva onClick={handleUnsave} horizontal={horizontal} saved />
        ) : (
          <Salva onClick={handleSave} horizontal={horizontal} />
        )
      ) : null}
      {isEditable && <Modifica onClick={handleSetRaccolta} horizontal={horizontal} />}
      <Link legacyBehavior href={raccolta.url}>
        <a
          className="raccolta-card__image-box"
          style={{
            backgroundImage: `${disabled ? disabledGradient : ''}url(${thumb})`,
          }}
          onClick={() => {
            handleGTM('click')
            sendProductClickGTM(
              raccolta,
              parentList ? parentList : 'navigazione_standard',
              position
            )
          }}
        />
      </Link>
      <Link legacyBehavior href={raccolta.url}>
        <a
          className="raccolta-card__content"
          onClick={() => {
            handleGTM('click')
            sendProductClickGTM(
              raccolta,
              parentList ? parentList : 'navigazione_standard',
              position
            )
          }}
        >
          <p className="raccolta-card__title">{raccolta.titolo}</p>
          <p className="raccolta-card__abstract">{raccolta.descrizione}</p>
        </a>
      </Link>
    </article>
  )
})

export default RaccoltaCard
